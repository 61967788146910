import React, { Component }  from 'react';
import  '../../commonStyle/common.css';
import $ from 'jquery';
import {connect} from 'react-redux';
import Pagination from "react-js-pagination";
import deletIcon from '../../images/delete.png';
import editpencil from '../../images/edit-pencil.png';
import {newDemoList, deleteDemographics, emptyDemoProps} from '../../actions/actionsBag/Demographic';
import {Link} from 'react-router-dom';
import { alertMessage, emptyDialogdata }  from '../../alertsDialog/Action';

  class DemographicsListTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            search: "",
            currentPage: 1,
            userId : "",
            userType : "",
            demoList : [],
            totalPage : 0,
            showloader: false,
        }
        this.handlePageChange = this.handlePageChange.bind(this);
      }

   
  // componentWillMount(){
   
  // }

  componentWillReceiveProps(nextProps){

    if(nextProps.confirmDialog === true){
      this.props.dispatch(emptyDialogdata());
      this.props.dispatch(deleteDemographics(  nextProps.confirmData)); 
    }

    if( nextProps.totalDemographics &&  nextProps.totalDemographics >0){
      this.setState({
        totalPage : nextProps.totalDemographics,
        demoList : nextProps.demoList,
        showloader: true,
      });


    }else{
      this.setState({
        totalPage : 0,
        showloader: true,
      });
    }

    if( nextProps.demoDeleteSuccess && nextProps.demoDeleteMessage ){
      this.alertDialog( nextProps.demoDeleteMessage);
      this.props.dispatch(newDemoList( 1));
    }else if((nextProps.demoDeleteSuccess === false)  && nextProps.demoDeleteMessage){
        this.alertDialog( nextProps.demoDeleteMessage);
    }
  }
  
  alertDialog( message){
    let alertData ={};
    alertData.type ="alert";
    alertData.message = message;
    this.props.dispatch(alertMessage(alertData));
    this.props.dispatch(emptyDemoProps());
  }

  componentWillMount(){
    this.props.dispatch(newDemoList(1));

  $(".dropdown").on('click',function() { 
      var t = (this.id); 
    if(t === "all-rm-shadow"){
      var all= document.getElementsByClassName("dropdown");
    
      for(let a=0; a< all.length; a++){
          if ( $( all[a] ).removeClass( "active" ) ) {
        
        }
      
        $(all[0]).addClass("active");
      }
    }else if(t === "live-rm-shadow"){
      
      var live= document.getElementsByClassName("dropdown");
    
      for(let a=0; a< live.length; a++){
          if ( $( live[a] ).removeClass( "active" ) ) {
        }
        $(live[1]).addClass("active");
      }
    }else if(t === "pause-rm-shadow"){
      var pause= document.getElementsByClassName("dropdown");
    
      for(var a=0; a< pause.length; a++){
          if ( $( pause[a] ).removeClass( "active" ) ) {
        
        }
      
        $(pause[2]).addClass("active");
      }
  }else if(t === "com-rm-shadow"){
      var com= document.getElementsByClassName("dropdown");
    
      for(let a=0; a< com.length; a++){
          if ( $( com[a] ).removeClass( "active" ) ) {
        
        }
      
        $(com[3]).addClass("active");
      }
    }
  });  
}

  handlePageChange(pageNumber){
    this.props.dispatch(newDemoList(pageNumber));
    this.setState({ currentPage : pageNumber, demoList:[]});
  }

  deleteDemographics( id){
    let alertData ={};
    alertData.type ="confirmalert";
    alertData.message = "Are you sure to delete this item ?";
    alertData.deleteId = id;
    this.props.dispatch(alertMessage(alertData));
    // this.props.dispatch(deleteDemographics( id)); 
}

  tableZoom(){
    $(".alltablestruct").toggleClass("zoom-me");
    // $("#routercontainer").toggleClass("router-containter-pd");
    // document.getElementById("routercontainer").style.padding="0px";

  }

    render(){
        
        let demoItems = "";

  
        if (this.state.demoList && this.state.demoList.length> 0 ) {
         
          let sr = (this.state.currentPage * 10)-10;
            demoItems = this.state.demoList.map((list, i) => {

            return <tbody> <tr>  
                <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom">{sr+i+1}</td>
                  <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.name}</td>

                  <td className=" tbl-padd-top tbl-padd-left tbl-padd-bottom">
                    <div className="flex-show">    
                      <div className="icons">
                            <div className="delete" onClick={this.deleteDemographics.bind(this, list._id)}>
                              <img  height="20px" width="20px" src={deletIcon}/>
                            </div>
                          </div>&nbsp;&nbsp;

                          <div className="icons">
                            <Link to = {{      
                                  pathname: '/update-demographic',
                                  state: {
                                      editDemographicsData : list,
                                    }
                                }}> <div className="pencil"> <img src={editpencil}  height="20px" width="20px"/> </div></Link>
                          </div>
                      
                    </div>
                  </td>
                      </tr>
                  </tbody>
             });
          }else{
            demoItems = "";
          }

          let showTable ="";
          if (this.state.demoList && this.state.demoList.length> 0 ) {
            showTable =<table className="tbl-new-est" >
                    <thead >
                      <tr>
                            <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom ">S.no.</th>
                            <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Role Name</th>
                            <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Action</th>
                        </tr>
                        </thead>
                      {demoItems}
                  </table>
          }else if( this.state.showloader === true ){
            showTable = <div className="datanotfound"> No record found</div>
          }else{
            showTable = <div className="spiner-data"><div className="tblspinner"></div></div>
          }
          
          


        return(
            <div className="list-style">
                <div className="table-st">
                    <div className="alltablestruct forms-rd-shadow">
                         {/* serachbox and stausbtn */}
                        <div id="search-staus">

                            <div className="flex-show  tbl-h-font">
                            Demographic List
                            </div>
                            
                            <div className="flex-show tbl-zoom" >
                            <span onClick={this.tableZoom} className="full-screen mr-rhs-20 mr-top-10" > </span>
                            </div>
                        </div>
                        {/* serachbox and stausbtn */}

                            {/* table */}
                            <div style={{marginTop: "20px", overflowX: "auto"}}>
                                 {showTable}
                            </div>
                            {/* table */}
                   
                      <div id="pageinationstyle">
                       
                      <Pagination
                          activePage={this.state.currentPage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalPage}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                        />
                        </div>
                    
                    </div>
                </div>
            </div>
    )}
}

const mapStateToProps = (state) => {
  return {
    
    demoList: state.demographics.demoList, 
    totalDemographics : state.demographics.totalDemographics,
    demoDeleteSuccess : state.demographics.demoDeleteSuccess,
    demoDeleteMessage : state.demographics.demoDeleteMessage,
    confirmDialog :  state.alertsDialog.confirmDialog,
    confirmData : state.alertsDialog.confirmData
  }
}

export default connect(mapStateToProps)(DemographicsListTable);

const initialState = {

}



const invoice = (state = initialState, action) => {

	switch (action.type) {

		case 'SET_INVOICED':
			return {
                ...state,
                invoicedList : action.invoicedResult.invoicedList,
                totalInvoice : action.invoicedResult.totalInvoice
			}
        
        case 'SET_CREATE_INVOICE':
            return{
                ...state,
                readyInvoiceList : action.invoiceResult.readyToInvoiceList,
                totalReadyInvoice : action.invoiceResult.totalReadyInvoice,
                createSuccessInvoiceAlert : null
            }
    
            
        case 'SET_RES_ALERT_CREATE_INVOICE':
            return{
                ...state,
                createSuccessInvoiceAlert : action.response,
            }
                
        case 'SET_READY_INVOICE_LIST':
            return{
                ...state,
                readyInvoiceList : action.readyInvoice.readyToInvoiceList,
                totalInvoiceReady : action.readyInvoice.totalReadyInvoice
            }      

        case 'SET_EMPTY_INVOICE_RESPONSE':
            return{
                ...state,
                createSuccessInvoiceAlert : null,
                invoiceDeleteSuccess : null,
                invoiceUpdatedMessage : null,
                invoiceMailMessage : null,
            }

        case 'SET_DELETE_INVOICE' :
            return{
                ...state,
                invoiceDeleteSuccess : action.invoiceDeleteResult.success,
                invoicedList : action.invoiceDeleteResult.invoicedList
            }

        case 'SET_APPROVED_INVOICED_LIST' :
            return{
                ...state,
                invoicedList : action.invoiced.invoicedList,
                totalInvoice : action.invoiced.totalInvoice
            }

        case 'SET_INVOICE_RECIEPT' :
            return{
            ...state,
            invoiceData : action.invoiceResult.invoiced,
            invoiceHasClone : action.invoiceResult.hasClone,
            cloneInvoiceData : action.invoiceResult.cloneInvoiceData
            }
        
        case 'SET_FINAL_INVOICED' :
            return{
              ...state,
              invoiceUpdatedMessage : action.invoiceUpdate.message
            }

        case 'SET_DELETE_INVOICE_FROM_INVOICE' :
            return{
                ...state,
                invoiceDeleteSuccess : action.invoiceDeleteResult.success,
                invoicedList : action.invoiceDeleteResult.invoicedList
            }

        case 'SET_DELETE_INVOICE_FAIL_FROM_INVOICE' :
            return{
                ...state,
                invoiceDeleteSuccess : action.invoiceDeleteResult.success,
            }
    
        case 'SET_INVOICE_MAIL' :
            return{
                ...state,
            invoiceMailMessage: action.invoieMail.message
            }


		default:
		return state;
	}

};
export default invoice;
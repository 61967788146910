import React, { Component }  from 'react';
import { connect } from 'react-redux';
import InvoiceHeader from '../../components/Invoice/invoiceHeader.jsx';
import CreateInvoice from '../../components/Invoice/createInvoice.jsx';
import { emptyClientProps, getStudyDataForInvoiced} from '../../actions/actionsBag/clientAction';
import { getAllReadyInvoices, invoiceCreate, emptyResponseInvoiced } from '../../actions/actionsBag/invoiceAction';
import goback from '../../images/goback.png';
import {Link} from 'react-router-dom';
import { alertMessage }  from '../../alertsDialog/Action';


class CreateInvoiceView extends Component {

        constructor(props) {
            super(props)
            this.state = {
              studyData : ""
            }
            this.invoiceCreate = this.invoiceCreate.bind(this);
          }

    componentWillMount() {
      this.props.dispatch( emptyClientProps());
      if( this.props.location.state === undefined){
        this.props.history.push("error");
    }else{
      const { editstudyData } = this.props.location.state;
      this.props.dispatch(getStudyDataForInvoiced(editstudyData._id));
      }
    }

    componentWillReceiveProps( nextProps){
      
      if( nextProps.invoiceStudyDataSuccess && nextProps.invoiceStudyDataSuccess){
        this.setState({ studyData : nextProps.invoiceStudyData});
      }else if( nextProps.invoiceStudyResMsg){
        alert( nextProps.invoiceStudyResMsg);
        this.props.history.push("/");
      }

      if( nextProps.createSuccessInvoiceAlert && nextProps.createSuccessInvoiceAlert.success === true){
          let alertData ={};
          alertData.type ="alert";
          alertData.message = nextProps.createSuccessInvoiceAlert.message;
          this.props.dispatch(emptyResponseInvoiced());
          this.props.dispatch(alertMessage(alertData));
          this.props.history.push("/invoice-list");
      }else if( nextProps.createSuccessInvoiceAlert && nextProps.createSuccessInvoiceAlert.success === false){
        
        let alertData ={};
          alertData.type ="alert";
          alertData.message = nextProps.createSuccessInvoiceAlert.message;
          this.props.dispatch(emptyResponseInvoiced());
          this.props.dispatch(alertMessage(alertData));
        
      }
    }

    invoiceCreate( data){
      this.props.dispatch( invoiceCreate( data));
    }
    

    render(){

      const header = 'Create Invoice';
      const headerText = 'Here you can create new Invoice';
      const headerButton = <Link to="/invoice-list"> <button className="back-btn back-btn-color" ><img src={goback} height="13px" width="13px" /><span> Back</span></button></Link>

      let createInvoice = "";

        if(this.state.studyData){
          createInvoice = <CreateInvoice
                            studyData = {this.state.studyData}
                            invoiceCreate = {this.invoiceCreate}
                          />
        }

        return(
            <div style={{ width:"100%"}}>  
                <InvoiceHeader 
                  header = {header}
                  headerText = {headerText}
                  headerButton = {headerButton}  
                />
              { createInvoice }
            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
      invoiceStudyDataSuccess : state.client.invoiceStudyDataSuccess,
      invoiceStudyData    : state.client.invoiceStudyData,
      invoiceStudyResMsg  : state.client.invoiceStudyResMsg,
      createSuccessInvoiceAlert : state.invoice.createSuccessInvoiceAlert
    }
  }
  
export default connect(mapStateToProps)(CreateInvoiceView);
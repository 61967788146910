import React, { Component }  from 'react';
import { connect } from 'react-redux';
import RoleHeader from '../../components/Role/roleHeader.jsx';
import AssignUserPermission from '../../components/Role/rolePermission.jsx';
import {getPermissionByRole, assingPermissionRole, blankResRoles} from '../../actions/actionsBag/roles';
import goback from '../../images/goback.png';
import {Link} from 'react-router-dom';
import { alertMessage }  from '../../alertsDialog/Action';

class CreateRoleView extends Component {

        constructor(props) {
            super(props)

            this.queryString =  window.location.pathname;

            if (this.queryString !== "") {
            this.roleId = this.queryString.split('/')[2]
            }

            this.state = {
                roleId : this.roleId
            }
            this.assingPermissionRole = this.assingPermissionRole.bind(this);
            this.showRoleList = this.showRoleList.bind(this);
            this.blankResRoles = this.blankResRoles.bind(this);
            this.showAlertMessage = this.showAlertMessage.bind(this);
        }

    componentWillMount() {
        this.props.dispatch(blankResRoles());
        this.props.dispatch(getPermissionByRole(this.state.roleId));
      }

    assingPermissionRole( data){
        this.props.dispatch(assingPermissionRole(data));
    }
    
    blankResRoles(){
        this.props.dispatch(blankResRoles());
    }

    showRoleList(){
        this.props.history.push("/role-list");
    }
    
    showAlertMessage(message){
            let alertData ={};
            alertData.type ="alert";
            alertData.message = message;
            this.props.dispatch(alertMessage(alertData));
    }

    render(){

        const header = 'Assign Permission';
        const headerText = 'Here to asign user permission';
        const headerButton = <Link to="/role-list"> <button className="back-btn back-btn-color" ><span> Role List</span></button></Link>

        let assignPermissionHtml ="";
        if(this.props.showRolePermission && this.props.rolePermissionData){
         assignPermissionHtml = 
                                        <AssignUserPermission
                                            rolePermissionSuccess = {this.props.rolePermissionSuccess}
                                            rolePermissionData    = {this.props.rolePermissionData}
                                            assingPermissionRole = {this.assingPermissionRole}
                                            showRoleList = {this.showRoleList}
                                            rolePermissionUpdate = {this.props.rolePermissionUpdate}
                                            rolePermissionUpdateMsg = {this.props.rolePermissionUpdateMsg}
                                            blankResRoles  = {this.blankResRoles} 
                                            showAlertMessage = {this.showAlertMessage}
                                        />
                                    
        }

        return(
            <div style={{ width:"100%"}}>  
                <RoleHeader 
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                />
                { assignPermissionHtml }
            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        showRolePermission      :   state.roles.showRolePermission,
        rolePermissionSuccess   :   state.roles.rolePermissionSuccess,
        rolePermissionData      :   state.roles.rolePermissionData,
        rolePermissionUpdate    :   state.roles.rolePermissionUpdate,
        rolePermissionUpdateMsg :   state.roles.rolePermissionUpdateMsg
    }
  }
  
export default connect(mapStateToProps)(CreateRoleView);
import { getParticipantCountApi, getParticipantDataApi, thirdPartyParticipantApi, studyParticipantApi, statusParticipantApi,
          getProgressApi , getCountDataByMonthApi, getCountDataByYearApi, getUserListByAdminApi, getLiveStudiesByUserApi,
          softDeleteLiveStudiesApi, getServertimeApi,
          getArchivingProgressApi} from "../../api/apiBag/dashboardApi";

import { startSpinner, endSpinner } from "../../loadingSpinner/Action";

export const setParticipantCount = (participantCount) => {
    return {
      type: "SET_PARTICIPANT_COUNT",
      participantCount
  
    }
  }

  export const setParticipantData = (participant) => {
    return {
      type: "SET_PARTICIPANT_DATA",
      participant
  
    }
  }
  
  export const setThirdPartyParticipant = (participant) => {
    return {
      type: "SET_THIRDPARTY_PARTICIPANT_DATA",
      participant
    }
  }

  export const setstudyParticipant = (participant) => {
    return {
      type: "SET_STUDY_PARTICIPANT_DATA",
      participant
  
    }
  }

  export const setProgressRes = (progressData) => {
    return {
      type: "SET_PROGRESS_DATA",
      progressData
  
    }
  }

  //setParticipantCountByMonth

  export const setParticipantCountByMonth = (participantMonthCount) => {
    return {
      type: "SET_PARTICIPANT_MONTH_COUNT",
      participantMonthCount
    }
  }

  //setParticipantCountByYear
  export const setParticipantCountByYear = (participantYearCount) => {
    return {
      type: "SET_PARTICIPANT_YEAR_COUNT",
      participantYearCount
    }
  }

  //setUsersList
  export const setUsersList = (users) => {
    return {
      type: "SET_USERS_LIST",
      users
    }
  }

  export const setDashboardStudiesList = (studies) => {
    return {
      type: "SET_DASHBOARD_STUDIES_LIST",
      studies
    }
  }

  //setemptystudiesList
  export const setemptystudiesList = () => {
    return {
      type: "SET_DASHBOARD_STUDIES_LIST_EMPTY"
    }
  }

  export const setLiveStudyDeleteRes = ( response) => {
    return {
      type: "SET_REMOVE_STUDY_RESPONSE",
      response
      
    }
  }
  
//
export const setServerTime = ( response) => {
  return {
    type: "SET_SERVER_TIME",
    response
  }
}

  export const emptyResponseDashboard = () =>{
    return{
      type: "SET_EMPTY_DASHBOARD_RES",
    }
  }

  export const getParticipantCount = (countYear, currentMonth, adminID) => {
    return dispatch => {
      dispatch(startSpinner());
        getParticipantCountApi(countYear, currentMonth, adminID).then((result) => {
          dispatch(endSpinner());
        dispatch(setParticipantCount(result));
  
      });
    }
  }

  export const getParticipantData = () => {
    return dispatch => {
      dispatch(startSpinner());
        getParticipantDataApi().then((result) => {
          dispatch(endSpinner());
        dispatch(setParticipantData(result));
  
      });
    }
  }

  export const thirdPartyParticipant = (id, adminID) => {
    return dispatch => {
      dispatch(startSpinner());
      thirdPartyParticipantApi(id, adminID).then((result) => {
        dispatch(endSpinner());
        dispatch(setThirdPartyParticipant(result));
  
      });
    }
  }

  export const studyParticipant = (id) => {
    return dispatch => {
      dispatch(startSpinner());
      studyParticipantApi(id).then((result) => {
        dispatch(endSpinner());
        dispatch(setstudyParticipant(result));
  
      });
    }
  }

  export const statusParticipant = (id) => {
    return dispatch => {
      dispatch(startSpinner());
      statusParticipantApi(id).then((result) => {
        dispatch(endSpinner());
        dispatch(setstudyParticipant(result));
  
      });
    }
  }

  export const getProgress = (id) => {
    return dispatch => {
      dispatch(startSpinner());
      getProgressApi(id).then((result) => {
        dispatch(endSpinner());
        dispatch(setProgressRes(result));
  
      });
    }
  }


  export const getArchivingProgress = (id) => {
    return dispatch => {
      dispatch(startSpinner());
      getArchivingProgressApi(id).then((result) => {
        dispatch(endSpinner());
        dispatch(setProgressRes(result));
  
      });
    }
  }

  //getCountDataByMonth
  export const getCountDataByMonth = (countYear, countMonth, adminID) => {
    return dispatch => {
      dispatch(startSpinner());
        getCountDataByMonthApi(countYear, countMonth, adminID).then((result) => {
          dispatch(endSpinner());
        dispatch(setParticipantCountByMonth(result));
      });
    }
  }

  //getCountDataByYear

  export const getCountDataByYear = (countYear, adminId) => {
    return dispatch => {
      dispatch(startSpinner());
      getCountDataByYearApi(countYear, adminId).then((result) => {
          dispatch(endSpinner());
        dispatch(setParticipantCountByYear(result));
      });
    }
  }

  //getUserListByAdmin
export const getUserListByAdmin = (id, type) => {
  return dispatch => {
   dispatch(startSpinner());
    getUserListByAdminApi(id, type).then((result) => {
       dispatch(endSpinner());
      dispatch(setUsersList(result));
    });
  }
}

//getLiveStudiesByUser
export const getLiveStudiesByUser = ( currentPage, userid) => {
  return dispatch => {
    dispatch(setemptystudiesList());
    dispatch(startSpinner());
    getLiveStudiesByUserApi( currentPage, userid).then((result) => {
        dispatch(endSpinner());
        dispatch(setDashboardStudiesList(result));
    });
  }
}

export const softDeleteLiveStudies = (data) =>{
  return dispatch => {
    dispatch(startSpinner());
    softDeleteLiveStudiesApi(data).then((result) => {
      dispatch(endSpinner());
      dispatch(setLiveStudyDeleteRes(result));

    });
  }
}

//getServertime
export const getServertime = () =>{
  return dispatch => {
    getServertimeApi().then((result) => {
      dispatch(setServerTime(result));
    });
  }
}
import React, { Component }  from 'react';
import '../../commonStyle/common.css';
import $ from 'jquery';
import deletIcon from '../../images/delete2.png';


export default class UpdateQuestionDiv extends Component {
    constructor(props) {
        super(props)
        this.state = {
          demographicId : "",
          demographicName : "",
          queryID : "",
          querytext:"",
          querylanguage : "",
          oldQuerytype : "",
          querytype : "",
          validationMsg: {},
          questionList : [],
        }
        this.updateQuery  = this.updateQuery.bind(this);
        this.onChange = this.onChange.bind(this);
        this.deleteQuestion = this.deleteQuestion.bind( this);
      }

      componentWillMount(){
        if(this.props.demoQuestionData){
          this.setState({
            demographicId : this.props.demoId,
            queryID : this.props.demoQuestionData._id,
            demographicName : this.props.demoName,
            querytext : this.props.demoQuestionData.question,
            querylanguage : this.props.demoQuestionData.language,
            querytype : this.props.demoQuestionData.queryType,
            oldQuerytype : this.props.demoQuestionData.queryType,
          });
        }
      }

      onChange(e) {

        this.setState({ [e.target.name]: e.target.value });
      }

      handleValidation() {
        let valid = true;
        let error = {};  

        if (this.state.querytext === "") {
            valid = false;
            $("#error1").addClass("showerror");
            this.setState({
              querytexterror : "Enter role name"
            });
        }

        if (this.state.querytype === "") {
          valid = false;
          $("#error2").addClass("showerror");
          this.setState({
            querytypeerror : "Select question type"
          });
        }

        if (this.state.querylanguage === "") {
          valid = false;
          $("#error3").addClass("showerror");
          this.setState({
            querylanguageerror : "Select language"
          });
        }
        
        this.setState({ validationMsg: error });
        return valid;
      }

      updateQuery() {
        
        if (this.handleValidation()) {
          
          let queryData = {};
          queryData.newDemoId = this.state.demographicId;
          queryData.id = this.state.queryID
          queryData.queryText = this.state.querytext;
          queryData.language = this.state.querylanguage;
          queryData.type = this.state.querytype;
          if( this.state.oldQuerytype  === this.state.querytype){
            queryData.changeQueryType = false;
            this.props.updateQuery(queryData);
          }else{
            queryData.changeQueryType = true;
            this.props.updateQueryByQueryType( queryData);
          }
        }
      }

      tableZoom(){
        $(".alltablestruct").toggleClass("zoom-me");
      }

      deleteQuestion(){
       this.props.deleteQuestion(this.state.queryID);
      }

    render(){

    
        return(
            <div style={{marginTop: "2%"}}>
                <div className="table-st ">
                    <div className="forms-rd-shadow" style={{ width: "100%",paddingBottom:"50px", backgroundColor:"#ffffff", borderRadius:"4px" }}>
                        <div className="addVendor-main-div">
                        <div className="form-title">
                            <h4 className="rm-margin" style={{textAlign: "center"}}>Update Question</h4>
                        </div>

                        <br/>
                        <div style = {{ display: "flex", justifyContent: "space-between"}}>
                        <span style={{ color:"black" , textAlign:"left"}}>Demographic Name : { this.props.demoName }</span>
                        <span style={{ cursor:"pointer"}}  onClick={this.deleteQuestion}>
                              <img src={deletIcon}/>
                            </span>
                            </div>
                        <div className = "formgroup">
                          <div class="formgroup-col-1 rltv-pos" >
                          <label>Question*</label>
                            <input className="form-controll " name="querytext" placeholder="Enter question text" value={this.state.querytext} onChange={this.onChange}/>
                            <div style={{ width:"98%"}} className="errordiv  cus-width-2" id="error1">{this.state.querytexterror }</div>
                          </div>
                        </div>

                        <div className = "formgroup">
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>Question Type*</label>
                                    <select className="form-controll" style={{width:"100%", padding: "2px"}}  name="querytype" value={this.state.querytype} onChange={this.onChange}>
                                      <option value="">Select question type</option>
                                      <option value="checkbox">Options</option>
                                      <option value="radio">Radio</option>
                                      <option value="text">Text</option>
                                      <option value="range">Range</option>
                                    </select>
                                    <div className="errordiv cus-width-2" id="error2">{this.state.querytypeerror }</div>
                                </div>
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>Language*</label>
                                    <select className="form-controll" style={{width:"100%", padding: "2px"}}  name="querylanguage" value={this.state.querylanguage} onChange={this.onChange}>
                                      <option value="">Select question language</option>
                                      <option value="english"> English </option>
                                    </select>
                                    <div className="errordiv cus-width-2" id="error3">{this.state.querylanguageerror }</div>
                                </div>
                            </div>

                        <div style={{textAlign: "center"}}>
                          <button onClick={this.updateQuery}   style={{marginTop: "27px",textAlign: "center"}} className="list-btn list-btn-color">
                          <span> Update Question </span></button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
    )}
}

import React, { Component }  from 'react';
import  '../../commonStyle/common.css';
import $ from 'jquery';
import { Route , withRouter} from 'react-router-dom';
import { getUserListByAdmin } from '../../actions/actionsBag/dashboard';
import { hideDialog, confirmAction } from '../../alertsDialog/Action';
import appConfig from "../../api/apiConfig";
import {connect} from 'react-redux';
import './style.css';


class AlertDialogBox extends Component {

    constructor(props) {
        super(props)
        this.state = {
           showMe : false
        }
        this.onDialogClose = this.onDialogClose.bind(this);
    }

componentWillMount(){
   
}
    componentWillReceiveProps(nextProps){
     
    }

    onDialogCloseWithAction(data){
       
       this.props.dispatch(hideDialog());
        // alert(actionName+""+data);
        // this.props.dispatch(actionName(data));
        this.props.dispatch(confirmAction(data));
    }

    onDialogClose(){
      
        if(this.props.alertPushRoute && this.props.alertPushRoute === "pushToClonned"){
            this.props.dispatch(hideDialog());
            // this.props.history.push({
            //     pathname: '/Clone-Study',
            //     state: { cloneMainStudyID : "GNN240120202227" }
            //   });

        } else {
            this.props.dispatch(hideDialog());
        }
       
    
      }


    render(){

           
       
        let showBelowButton = "";
      
        if(this.props.alertType === "alert"){
            showBelowButton =   <div style={{display: "flex",alignItems: "center",justifyContent: "center",marginTop: "5px"}}>
                                    <button className="list-btn"  style={{width: "100px"}} onClick={this.onDialogClose}>Ok</button>
                                    </div>
        }else if(this.props.alertType === "confirmalert"){
            showBelowButton = <div style={{display: "flex",alignItems: "center",justifyContent: "space-between",marginLeft: "10%", marginRight: "10%",marginTop: "5px"}}>
                                <button className="list-btn" style={{width: "100px"}} onClick={this.onDialogCloseWithAction.bind(this,this.props.alertData)}>Yes</button>
                                <button className="list-btn"  style={{width: "100px"}} onClick={this.onDialogClose}>No</button>
                                </div>
                               
        }
       
        return(
           
           <div className="alert-box-container">
               <div className="alert-box" style={{borderRadius: "5px"}}>
                    <div style={{borderTopLeftRadius: "5px",borderTopRightRadius: "5px",backgroundColor: "blue",color: "white",height:"20%",textAlign: "center",display: "flex",alignItems: "center",justifyContent: "center"}}>
                        Alert !!!
                    </div>
                    <div style={{height:"60%",textAlign: "center",color: "black",display: "flex",alignItems: "center",justifyContent: "center"}}>
                            {this.props.displayMessage}
                            {/* {showAlertText} */}
                        </div>
                        {/* <div style={{borderTop: "1px solid gray",height:"20%",textAlign: "center",display: "flex",alignItems: "center",justifyContent: "center"}}>
                            <button className="list-btn" onClick={this.onDialogClose}>Ok</button>
                        </div> */}
                        <div style={{borderTop: "1px solid gray",height:"20%",textAlign: "center"}}>
                            {showBelowButton}
                        </div>
                    </div>
               </div>
              
    )}
}
const mapStateToProps=(state)=>{
    return{
       
    }
  }
  
  export default connect(mapStateToProps)(AlertDialogBox);

import React, { Component }  from 'react';
import { connect } from 'react-redux';
import RoleHeader from '../../components/Role/roleHeader.jsx';
import { sendMailToUser, blankResUser } from '../../actions/actionsBag/users';
import goback from '../../images/goback.png';
import {Link} from 'react-router-dom';
import { alertMessage }  from '../../alertsDialog/Action';

import $ from 'jquery';

    class CreateSendMailView extends Component {

        constructor(props) {
            super(props)
            this.state = {
                user_mail : "",
                user_text : "",
                mail_subject : ""
            }
            this.sendMailToUser = this.sendMailToUser.bind(this);
            this.onChange = this.onChange.bind(this);
        }

    componentWillMount() {

       
      }

    componentWillReceiveProps( nextProps){
        if (nextProps.userMailResSuccess === true && nextProps.userMailResSuccess != undefined && nextProps.userMailResMessage != undefined ) {
            if(nextProps.userMailResMessage){
                let alertData ={};
                alertData.type ="alert";
                alertData.message = nextProps.userMailResMessage;
                this.props.dispatch(alertMessage(alertData));
              this.setState({ user_mail  : "" , user_text : "", mail_subject : ""});
             
              this.props.dispatch(blankResUser());
            }
        } else if(nextProps.userMailResMessage){
            let alertData ={};
            alertData.type ="alert";
            alertData.message = nextProps.userMailResMessage;
            this.props.dispatch(alertMessage(alertData));
            this.props.dispatch(blankResUser()); 
        }
    }

    handleValidation() {
        let valid = true;
        let error = {};

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        let tempemail = this.state.user_mail;

        if(this.state.user_mail === ""){
            valid = false;
            $("#error1").addClass("showerror");
            this.setState({
                usermailerror: "Enter e-mail"
            });  
        }else if(!pattern.test(tempemail)){
            this.setState({ usermailerror: "Enter correct e-mail !" });
            $("#error1").addClass("showerror");
        }

        if(this.state.user_text === ""){
            valid = false;
            $("#error2").addClass("showerror");
            this.setState({
                usertexterror: "Enter message"
            });  
        }

        if(this.state.mail_subject === ""){
            valid = false;
            $("#error3").addClass("showerror");
            this.setState({
                mailsubjecterror: "Enter subject"
            });  
        }
        
        this.setState({ validationMsg: error });
        return valid;
      }
    
      onChange(e){
        if( e.target.name === "user_mail" && e.target.value !== "")
        $("#error1").removeClass("showerror");
        if( e.target.name === "user_text" && e.target.value !== "")
        $("#error2").removeClass("showerror");
        if( e.target.name === "mail_subject" && e.target.value !== "")
        $("#error3").removeClass("showerror");
        this.setState({ [e.target.name]: e.target.value});
      }
        
      sendMailToUser(){
        let roleData = {};
        roleData.user_mail = this.state.user_mail;
        roleData.user_text = this.state.user_text;
        roleData.mail_subject = this.state.mail_subject;
        roleData.created_by_id = localStorage.getItem('auth_user_id');
        if (this.handleValidation()) {
           this.props.dispatch(sendMailToUser(roleData));
        }
      }



    render(){

        const header = 'Send Mail';
        const headerText = 'Here you send mail to user';
        const headerButton = "";


        return(
            <div style={{ width:"100%"}}>  
                <RoleHeader 
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                />
                <div style={{marginTop: "2%"}}>
                <div className="table-st">
                    <div className="forms-rd-shadow" style={{ width: "100%",paddingBottom:"50px", backgroundColor:"#ffffff", borderRadius:"4px" }}>
                        <div className="addVendor-main-div">
                        <div className="form-title">
                            <h4 className="rm-margin"> Send Mail</h4>
                        </div>
                        <hr/>
                        <div className = "formgroup">
                            <div class="formgroup-col-6 rltv-pos" >
                                <label>User mail*</label>
                                <input className="form-controll " name="user_mail" placeholder="Enter e-mail" value={this.state.user_mail} onChange={this.onChange}></input>
                                <div className="errordiv cus-width-2" id="error1" >{this.state.usermailerror}</div>
                            </div>
                            <div class="formgroup-col-6 rltv-pos" >
                                <label>Subject*</label>
                                <input className="form-controll " name="mail_subject" placeholder="Enter subject" value={this.state.mail_subject} onChange={this.onChange}></input>
                                <div className="errordiv cus-width-2" id="error3" >{this.state.mailsubjecterror}</div>
                            </div>
                           
                        </div>

                        <div className = "formgroup">
                            <div class="formgroup-col-6 rltv-pos" >
                                <label>Message</label>
                                <textarea type="text" style={{ width :"100%"}} className="form-controll " name="user_text" placeholder="Enter text message" value={this.state.user_text} onChange={this.onChange}></textarea>
                                <div className="areaerror cus-width-8" id="error2" >{this.state.usertexterror}</div>
                            </div>
                            <div class="formgroup-col-2" >
                                <button  onClick={this.sendMailToUser}  style={{marginTop: "27px", width: "110px"}} className="list-btn list-btn-color">
                                <span> Send </span></button>
                            </div>
                        </div>
                        
                        </div>
                    </div>
                </div>
            </div>
            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        userMailResMessage : state.users.userMailResMessage,
        userMailResSuccess : state.users.userMailResSuccess,
    }
  }
  
export default connect(mapStateToProps)(CreateSendMailView);
import React, { Component }  from 'react';
import { connect } from 'react-redux';
import RoleHeader from '../../components/Role/roleHeader.jsx';
import UpdateRoleDiv from '../../components/Role/updateRole.jsx';
import { updateRole, blankResRoles} from '../../actions/actionsBag/roles';
import goback from '../../images/goback.png';
import {Link} from 'react-router-dom';
import { alertMessage }  from '../../alertsDialog/Action';

    class UpdateRoleView extends Component {

        constructor(props) {
            super(props)
            this.state = {
                role_id : "",
                role_type : "",
                updated_role_type : ""
            }
            this.updateRole = this.updateRole.bind(this);
            this.updatePermission = this.updatePermission.bind(this);
        }

    componentWillMount() {
        if( this.props.location.state === undefined){
            this.props.history.push("error");
        }else{
    
        const { editRoleData } = this.props.location.state;
           
        this.setState({
            role_id : editRoleData.role_id,
            role_type : editRoleData.role_type,
          });
        }
      }

    componentWillReceiveProps( nextProps){
        if (nextProps.updateRoleSuccess === true && nextProps.updateRoleMessage != undefined ) {
            if(nextProps.updateRoleMessage){
                this.setState({ role_type : this.state.updated_role_type});
                let alertData ={};
                alertData.type ="alert";
                alertData.message = nextProps.updateRoleMessage;
                this.props.dispatch(alertMessage(alertData));
                this.props.dispatch(blankResRoles());
                this.props.history.push('/assign-permission/'+ this.state.role_id);
            }
        } else if(nextProps.updateRoleMessage){
            let alertData ={};
            alertData.type ="alert";
            alertData.message = nextProps.updateRoleMessage;
            this.props.dispatch(alertMessage(alertData));
            this.props.dispatch(blankResRoles());
        }
    }

    updateRole(data){
        this.setState({ updated_role_type : data});
        let roleData = {};
        roleData.roleid = this.state.role_id;
        roleData.roletype = data;
        roleData.updated_by = localStorage.getItem('auth_user_id');
        this.props.dispatch(updateRole(roleData));
    }

    updatePermission(){
        this.props.history.push('/assign-permission/'+ this.state.role_id);
    }

    render(){

        const header = 'Update Role';
        const headerText = "Here you can update role and permission for user's";
        const headerButton = <Link to="/role-list"> <button className="back-btn back-btn-color" ><img src={goback} height="13px" width="13px" /><span> Back</span></button></Link>


        return(
            <div style={{ width:"100%"}}>  
                <RoleHeader 
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                />
                <UpdateRoleDiv
                    role_type = { this.state.role_type}
                    updateRole = {this.updateRole}
                    role_id = {this.state.role_id}
                    updatePermission = {this.updatePermission}
                />
            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        updateRoleSuccess : state.roles.updateRoleSuccess,
        updateRoleMessage : state.roles.updateRoleMessage,
    }
  }
  
export default connect(mapStateToProps)(UpdateRoleView);
import React, { Component }  from 'react';
import TopHeader from '../src/containers/headerMenu';
import LeftMenu from '../src/containers/leftMenu/leftMenu';
import './commonStyle/common.css';

export default class Layout extends Component {

    

    render(){
        return(
            <div>  
            <div><TopHeader/></div>
            <div><LeftMenu/></div>
            <div id="routercontainer" className="allrouters">
                
            {this.props.children}
            </div>
            {/* <footer class="main-footer">
                <div class="copyright">Copyright &copy; 2019 by <a href="http://www.leonidsconsulting.com/">Leonids Consulting</a>. All rights reserved.</div>
            </footer> */}
            </div>
          
    )}
}
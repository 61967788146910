import {addClientApi, getClientListApi, getClientByUserApi,
   updateClientApi, softClientDeleteApi , getAllCountryListApi, 
   getStudyDataForInvoicedApi, uploadClientLogoApi} from "../../api/apiBag/clientApi";
import {startSpinner, endSpinner} from "../../loadingSpinner/Action";


export const setClient = (clientAddResult) => {
  return{
    type : "SET_CLIENT_ADD",
    clientAddResult
  }
}


// emptyClientProps
export const emptyClientProps = () => {
    return{
      type : "SET_CLIENT_EMPTY_PROPS",
    }
  }


  //emptyUploadLogoProps
  export const emptyUploadLogoProps = () => {
    return{
      type : "SET_CLIENT_LOGO_EMPTY_PROPS",
    }
  }

// setClientList
export const setClientList = (clientsResult) => {
  return{
    type : "SET_CLIENT_LIST",
    clientsResult
  }
}


// setClientByUser
export const setClientByUser = (clientData) => {
  return{
    type : "SET_CLIENT_DATA",
    clientData
  }
}

// setClientUpdate
export const setClientUpdate = (clientUpdate) => {
  return{
    type : "SET_CLIENT_UPDATE",
    clientUpdate
  }
}


// setClientDelete
export const setClientDelete = (clientDelete) => {
  return{
    type : "SET_CLIENT_DELETE",
    clientDelete
  }
}

//setAllCountryList
export const setAllCountryList = (response) => {
  return{
    type : "SET_ALL_COUNTRY_LIST",
    response
  }
}

//setInvoiceStudyData
export const setInvoiceStudyData = (response) => {
  return{
    type : "SET_INVOICE_STUDY_DATA",
    response
  }
}



export const setLogoUploadResult = (response) => {
  return{
    type : "SET_LOGO_UPLOAD_RESULT",
    response
  }
}

export const addClient = (data) => {

  return dispatch => {
    dispatch(startSpinner());
    addClientApi(data).then((result) => {
      dispatch(endSpinner());
          dispatch(setClient(result));
      // else{
      //   dispatch(setLoginFail(result));
      // }
    });
  }
}

// getClientList
export const getClientList = (data) => {

  return dispatch => {
    dispatch(startSpinner());
    getClientListApi(data).then((result) => {
      dispatch(endSpinner());
     if(result.success)
          dispatch(setClientList(result));
      // else{
      //   dispatch(setLoginFail(result));
      // }
    });
  }
}


export const uploadClientLogo = (data,clientId) => {

  return dispatch => {
    dispatch(startSpinner());
    uploadClientLogoApi(data, clientId).then((result) => {
      dispatch(endSpinner());
     if(result.success)
          dispatch(setLogoUploadResult(result));
    });
  }
}
// getClientByUser

export const getClientByUser = (data) => {
  return dispatch => {
    dispatch(startSpinner());
    getClientByUserApi(data).then((result) => {
      dispatch(endSpinner());
     if(result.success)
          dispatch(setClientByUser(result));
    });
  }
}

// updateClient
export const updateClient = (data) => {

  return dispatch => {
    dispatch(startSpinner());
    updateClientApi(data).then((result) => {
      dispatch(endSpinner());
      dispatch(setClientUpdate(result));
    });
  }
}


// softClientDelete
export const softClientDelete = (clinetData) => {

  return dispatch => {
    dispatch(startSpinner());
    softClientDeleteApi(clinetData).then((result) => {
      dispatch(endSpinner());
      dispatch(setClientDelete(result));
    });
  }
}

//getAllCountryList
export const getAllCountryList = () => {

  return dispatch => {
    dispatch(startSpinner());
    getAllCountryListApi().then((result) => {
      dispatch(endSpinner());
      dispatch(setAllCountryList(result));
    });
  }
}

//getStudyDataForInvoiced
export const getStudyDataForInvoiced = ( study_id) => {

  return dispatch => {
    dispatch(startSpinner());
    getStudyDataForInvoicedApi(study_id).then((result) => {
      dispatch(endSpinner());
      dispatch(setInvoiceStudyData(result));
    });
  }
}
import React, { Component }  from 'react';
import '../../commonStyle/common.css';
import { addThirdParty, emptyVendorProps } from '../../actions/actionsBag/vendor';
import deletIcon from '../../images/delete.png';
import editpencil from '../../images/edit-pencil.png';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import add from '../../images/add.png';
import $ from 'jquery';


export default class AddQuestionDiv extends Component {
    constructor(props) {
        super(props)
        this.state = {
          demographicId : "",
          demographicName : "",
          querytext:"",
          querylanguage : "",
          querytype : "",
          validationMsg: {},
          // questionList : [],
        }
        this.addQuery  = this.addQuery.bind(this);
        this.onChange = this.onChange.bind(this);
      }

      componentWillMount(){
        if(this.props.demographicsData){
          this.setState({
            demographicId : this.props.demographicsData._id,
            demographicName : this.props.demographicsData.name
          });
        }
      }

      // componentWillReceiveProps( nextProps){
      //   if( nextProps.demoQuestionList && nextProps.demoQuestionList.success && nextProps.demoQuestionList.query.length>0){
      //     this.setState({ questionList : nextProps.demoQuestionList.query });
      //   }
      // }

      onChange(e) {

        this.setState({ [e.target.name]: e.target.value });
      }

      handleValidation() {
        let valid = true;
        let error = {};  

        if (this.state.querytext === "") {
            valid = false;
            $("#error1").addClass("showerror");
            this.setState({
              querytexterror : "Enter role name"
            });
        }

        if (this.state.querytype === "") {
          valid = false;
          $("#error2").addClass("showerror");
          this.setState({
            querytypeerror : "Select question type"
          });
        }

        if (this.state.querylanguage === "") {
          valid = false;
          $("#error3").addClass("showerror");
          this.setState({
            querylanguageerror : "Select language"
          });
        }
        
        this.setState({ validationMsg: error });
        return valid;
      }

      addQuery() {
        
        if (this.handleValidation()) {
          
          let queryData = {};
          queryData.newDemoId = this.state.demographicId;
          queryData.queryText = this.state.querytext;
          queryData.language = this.state.querylanguage;
          queryData.type = this.state.querytype;
          this.props.addQuery(queryData);
        }
      }

      tableZoom(){
        $(".alltablestruct").toggleClass("zoom-me");
      }

      softDeleteQuestion(){
        alert("softDeleteQuestion");
      }


    render(){

      // let questionItems = "";

        
      //   if (this.state.questionList && this.state.questionList.length > 0 ) {
      //       questionItems = this.state.questionList.map((list, i) => {

      //       return <tbody> <tr>  
      //           <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom">{i+1}</td>
      //             <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.question}</td>
      //             <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.queryType}</td>
      //             <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.language}</td> 

      //             <td className=" tbl-padd-top tbl-padd-left tbl-padd-bottom">
      //               <div className="flex-show">
      //                 <div className="icons" >
      //                       <div className="delete" onClick={ this.softDeleteQuestion.bind(this,list._id) }>
      //                         <img src={deletIcon}/>
      //                       </div>
      //                     </div>

      //                     <div className="icons">
      //                       <Link to = {{      
      //                             pathname: '/add-option',
      //                             state: {
      //                               questionData : list,
      //                             }
                                  
      //                           }}> <div className="pencil"><img src={editpencil}  height="20px" width="20px"/> </div></Link>
      //                     </div>  
      //               </div>
      //             </td>
      //                 </tr>
      //             </tbody>
      //        });
      //     }else{
      //       questionItems =   <tbody> <tr>
      //         <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom">1</td>
      //         <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom"> question text</td>
      //         <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom"> radio</td>
      //         <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom"> en</td>
      //         <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom"> <div className="flex-show">    
      //                 <div className="icons" >
      //                       <div className="delete" onClick={this.softDeleteQuestion}>
      //                         <img src={deletIcon}/>
      //                       </div>
      //                     </div>

      //                     <div className="icons">
      //                       <Link to = {{      
      //                             pathname: '/add-option',
      //                             // state: {
      //                             //   editRoleData : list,
      //                             // }
                                  
      //                           }}> <div className="pencil"><img src={editpencil}  height="20px" width="20px"/> </div></Link>
      //                     </div>  
      //               </div> </td>
      //         </tr>  </tbody>
      //     }


      // let showTable = "";
      // if (this.state.questionList && this.state.questionList.length >  0 ) {
      //   showTable =  <table className="tbl-new-est" >
      //   <thead>
      //     <tr>
      //           <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">S.No.</th>
      //           <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Question</th>
      //           <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Question Type</th>
      //           <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Language</th>
      //           <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Action</th>
      //       </tr>
      //       </thead>
      //   {questionItems}
      // </table>
      // }else if(this.state.showloader === true){
      //   showTable = <div className="datanotfound"> No record found</div>
      // }else{
      //   showTable = <div className="spiner-data"><div className="tblspinner"></div></div>
      // }

        return(
            <div style={{marginTop: "2%"}}>
                <div className="table-st ">
                    <div className="forms-rd-shadow" style={{ width: "100%",paddingBottom:"50px", backgroundColor:"#ffffff", borderRadius:"4px" }}>
                        <div className="addVendor-main-div">
                        <div className="form-title">
                            <h4 className="rm-margin" style={{textAlign: "center"}}>Add Question</h4>
                        </div>

                        <br/>
                        <span style={{ color:"black" , textAlign:"left"}}>Demographic Name : {this.state.demographicName}</span>
                        <div className = "formgroup">
                          <div class="formgroup-col-1 rltv-pos" >
                          <label>Question*</label>
                            <input className="form-controll " name="querytext" placeholder="Enter question text" value={this.state.querytext} onChange={this.onChange}/>
                            <div style={{ width:"98%"}} className="errordiv  cus-width-2" id="error1">{this.state.querytexterror }</div>
                          </div>
                        </div>

                        <div className = "formgroup">
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>Question Type*</label>
                                    <select className="form-controll" style={{width:"100%", padding: "2px"}}  name="querytype" value={this.state.querytype} onChange={this.onChange}>
                                      <option value="">Select question type</option>
                                      <option value="checkbox">Options</option>
                                      <option value="radio">Radio</option>
                                      <option value="text">Text</option>
                                      <option value="range">Range</option>
                                    </select>
                                    <div className="errordiv cus-width-2" id="error2">{this.state.querytypeerror }</div>
                                </div>
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>Language*</label>
                                    <select className="form-controll" style={{width:"100%", padding: "2px"}}  name="querylanguage" value={this.state.querylanguage} onChange={this.onChange}>
                                      <option value="">Select question language</option>
                                      <option value="english"> English </option>
                                    </select>
                                    <div className="errordiv cus-width-2" id="error3">{this.state.querylanguageerror }</div>
                                </div>
                            </div>

                        <div style={{textAlign: "center"}}>
                          <button onClick={this.addQuery}   style={{marginTop: "27px",textAlign: "center"}} className="list-btn list-btn-color">
                          <img src={add} height="12px" width="12px"/><span> Add Question </span></button>
                        </div>
                        </div>
                    </div>
                </div>

{/* //.......................................... */}
              
                {/* <div className="table-st" style={{ marginTop: "2%"}}>
                    <div className="alltablestruct forms-rd-shadow">
         
                        <div id="search-staus">

                            <div className="flex-show  tbl-h-font">
                               Question's list
                            </div>
                            
                            <div className="flex-show tbl-zoom" >
                            <span onClick={this.tableZoom} className="full-screen mr-rhs-20 mr-top-10"> </span>
                            </div>
                        </div>
                   
                            <div style={{marginTop: "20px", overflowX: "auto"}}>
                                {}
                            </div>
                           
                   
                      
                    </div>
                </div> */}
    

            </div>
    )}
}

import React, { Component }  from 'react';
import cloneStudyListHeaderForCloneStudy from '../../components/StudyClone/cloneStudyListHeaderFoCloneStudy';
import CloneStudyListTable from '../../components/StudyClone/cloneStudyListTable';
import { cloneStudiesList, softDelete , search} from '../../actions/actionsBag/study';
import {connect} from 'react-redux';

export class CloneStudyList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userId : "",
            userType : "",
            projectStatus : "all",
            loginUserID : "",
            cloneMainStudyID : '',
            cloneMainStudyName : ""
        }
        // this.getStudiesByUser = this.getStudiesByUser.bind(this);
        this.studiesListBypage = this.studiesListBypage.bind(this);
         this.searchStudy = this.searchStudy.bind(this);
    }        

    componentWillMount(){

        if( this.props.location.state === undefined){
            this.props.history.push("error");
        }else{
        const { cloneMainStudyID } = this.props.location.state;
        const {cloneMainStudyName} = this.props.location.state;
        this.setState({
            cloneMainStudyID : cloneMainStudyID,
            cloneMainStudyName : cloneMainStudyName
          });
        let userBy = localStorage.getItem("auth_user_id");
        let userType = localStorage.getItem("auth_user_type");
        this.setState({ userId : userBy, userType : userType, loginUserID : userBy , cloneMainStudyID : cloneMainStudyID});
        this.props.dispatch(cloneStudiesList(1, userBy, userType, this.state.projectStatus, cloneMainStudyID));
        this.timer = null;
        }
    }

    //   getStudiesByUser(data){
    //     this.setState({ userId : data.user_id, userType : data.role_type});
    //     this.props.dispatch(studiesList(1, data.user_id, data.role_type, this.state.projectStatus ));
    // }

    studiesListBypage( pageNumber , projectStatus){
        this.setState({ projectStatus : projectStatus })
        this.props.dispatch(cloneStudiesList(pageNumber, this.state.userId, this.state.userType, projectStatus, this.state.cloneMainStudyID));
    }

    searchStudy( serchIteam){
        // let data = {};
        // data.searchItem = serchIteam;
        // data.userID = this.state.userId;
        // data.userType = this.state.userType;
        // this.props.dispatch(search( data ));
    }

    render(){
        return(
            <div>
                <cloneStudyListHeaderForCloneStudy
                    getStudiesByUser = { this.getStudiesByUser}
                />
                <div className="create-form ">
                    <div className="create-form-container all-header">
                        <div className="forms-rd-shadow toggle-form show-me " id="show-form-1">
                        <div className="form-title">
                                <h4 className="rm-margin">Study Name</h4>
                                <p className="form-title-text">{this.state.cloneMainStudyName}</p>
                                </div>
                        </div>
                    </div>
                </div>
                <CloneStudyListTable
                    studiesListBypage = {this.studiesListBypage}
                    searchStudy = { this.searchStudy}
                />
            </div>
    )}
}

const mapStateToProps=(state)=>{
    return{
       
    }
  }
  
  export default connect(mapStateToProps)(CloneStudyList);
import React, { Component }  from 'react';
import StudyUpdate from '../../components/Study/studyUpdate';
import UpdateStudyHeader from '../../components/Study/updateStudyHeader';
import {connect} from 'react-redux';
import '../../pages/Study/tempstyle.css';
import $ from 'jquery';
import appConfig from '../../api/apiConfig';
import expandbutton from '../../images/expand-button.png';

class RedirectsUrl extends Component {

    constructor( props){
        super(props)
        this.state ={
            studyOverQuotaUrl : "",
            studySuccess : "",
            studyTerminateUrl : "",
            study_id : ""
        }
        this.copySuccessUrl = this.copySuccessUrl.bind(this);
        this.copyOverQuotaUrl = this.copyOverQuotaUrl.bind(this);
        this.copyTerminateuRL = this.copyTerminateuRL.bind(this);
        this.CopyDefaultVendorUrl = this.CopyDefaultVendorUrl.bind(this);
    }

    componentDidMount(){
        let studyData = this.props.editstudyData;
        this.setState({
            study_id : studyData._id,
            studyOverQuotaUrl: appConfig.url.overQuotaUrl + "?UID=xxxxxxxxx&PID=" + studyData._id,
            studySuccess: appConfig.url.successUrl + "?UID=xxxxxxxxx&PID=" + studyData._id,
            studyTerminateUrl: appConfig.url.terminateUrl + "?UID=xxxxxxxxx&PID=" + studyData._id
        })
    }    

    copySuccessUrl() {
        let copyText = document.getElementById("CopySuccessUrl");
        copyText.select();
        document.execCommand("Copy");
    }
    
    copyOverQuotaUrl() {
        let copyText = document.getElementById("CopyOverQuotaUrl");
        copyText.select();
        document.execCommand("Copy");
    }

    copyTerminateuRL() {
        let copyText = document.getElementById("CopyTerminateUrl");
        copyText.select();
        document.execCommand("Copy");
    }

    CopyDefaultVendorUrl(){
        let copyText = document.getElementById("CopyDefaultVendorUrl");
        copyText.select();
        document.execCommand("Copy");
    }

    render(){
        let showDefaultVendorUrl = "";
        let studyUrl = "";
        if(this.props.defaultVendorSuccess === true){
          
             studyUrl =  appConfig.url.participantUrl + "?SID=" + this.state.study_id + "&TID=" + this.props.defaultVendorId + "&UID={uid}";
            showDefaultVendorUrl = <div><span ><b>Default Vendor Url</b></span><br/>
                                    <div className="url-input">    
                                        <input style={{ width:"90%"}} value ={ studyUrl} id="CopyDefaultVendorUrl" className="url-text" type="text"/>
                                        <button onClick={this.CopyDefaultVendorUrl}  className="url-btn-color url-btn">Copy Url</button>
                                    </div></div>
        }

        return(
            <div >
                  <div className="form-title">
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                                <div>
                                    <h4 className="rm-margin card-text-h4" style={{fontWeight: "500 !important"}}>Redirect</h4>
                                    </div>
                                <div className="pro-tgl-btn pro-tgl-btn-shdw" id="tg-btn-2">
                                    {/* <div id="rotate-icon-2">&#11167;</div> */}
                                    <div id="rotate-icon-2"><img src={expandbutton} height="15px" width="15px"/></div>
                                </div>
                            </div>
                        <div className="url-mr-top tg-hide" id="hide-me">
                            <div className="url-input">
                                <span style={{marginRight: "8px"}}><b>Complete</b></span>
                                <input value ={ this.state.studySuccess} id="CopySuccessUrl" className="url-text" type="text"/>
                                <button onClick={this.copySuccessUrl} className="url-btn-color url-btn">Copy Url</button>
                            </div>

                            <div className="url-input">
                                <span style={{marginRight: "5px"}}><b>Terminate</b></span>
                                <input value ={ this.state.studyTerminateUrl} id="CopyTerminateUrl" className="url-text" type="text"/>
                                <button onClick={this.copyTerminateuRL} className="url-btn-color url-btn">Copy Url</button>
                            </div>

                            <div className="url-input">
                                <span style={{marginRight: "8px"}}><b>Quotafull</b></span>
                                <input value ={ this.state.studyOverQuotaUrl} id="CopyOverQuotaUrl" className="url-text" type="text"/>
                                <button onClick={this.copyOverQuotaUrl}  className="url-btn-color url-btn">Copy Url</button>
                            </div>

                            {showDefaultVendorUrl}
                        </div>
                    </div>
            </div>

    )}
}

const mapStateToProps=(state)=>{
    return{
      
    }
  }
  
  export default connect(mapStateToProps)(RedirectsUrl);
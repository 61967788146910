import React, { Component }  from 'react';
import '../../commonStyle/common.css';
import { addThirdParty, emptyVendorProps } from '../../actions/actionsBag/vendor';
import { connect } from 'react-redux';
import add from '../../images/add.png';
import $ from 'jquery';


class AddDemographicsDiv extends Component {
    constructor(props) {
        super(props)
        this.state = {
          demographicName:"",
          validationMsg: {}
        }
        this.createDemographics  = this.createDemographics.bind(this);
        this.onChange = this.onChange.bind(this);
      }

      onChange(e) {
        if(e.target.value !== "")
        $("#error1").removeClass("showerror");
        this.setState({ [e.target.name]: e.target.value });
      }

      handleValidation() {
        let valid = true;
        let error = {}
    
        
    
        if(this.state.demographicName === ""){
            valid = false;
            $("#error1").addClass("showerror");
              this.setState({
                demonameerror: "Enter demographic name"
              });
          }
    
        this.setState({ validationMsg: error });
        return valid;
      }

      createDemographics() {
        let DemoData = {};
        DemoData.name = this.state.demographicName;
        if (this.handleValidation()) {
            this.props.addNewDemo(DemoData);
        }
      }


    render(){

        return(
            <div style={{marginTop: "2%"}}>
                <div className="table-st ">
                    <div className="forms-rd-shadow" style={{ width: "100%",paddingBottom:"50px", backgroundColor:"#ffffff", borderRadius:"4px" }}>
                        <div className="addVendor-main-div">
                        <div className="form-title">
                            <h4 className="rm-margin" style={{textAlign: "center"}}>Add Demographic</h4>
                        </div>
                       
                        <div className = "formgroup">
                                <div class="formgroup-col-1 rltv-pos" >
                                    <input className="form-controll " name="demographicName" placeholder="Demographic Name" value={this.state.demographicName} onChange={this.onChange}/>
                                    <div className="errordiv cus-width-9" id="error1" >{this.state.demonameerror}</div>
                                </div>
                            </div>
                            <div style={{textAlign: "center"}}>
                                    <button onClick={this.createDemographics}   style={{marginTop: "27px",textAlign: "center"}} className="list-btn list-btn-color">
                                    <img src={add} height="12px" width="12px"/><span>Add demographic </span></button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
    )}
}
const mapStateToProps=(state)=>{
    return{
       
    }
  }
  export default connect(mapStateToProps)(AddDemographicsDiv);


import React, { Component } from 'react';
import '../../commonStyle/common.css';
import $ from 'jquery';
import { connect } from 'react-redux';
import { getAllclients, getAllBDEbyAdmin, UpdateStudiesData,
     emptyStudyResponse, invitationToPanellist, initialiseInvitationRes,
     updateInMarket } from '../../actions/actionsBag/study';
import { Link } from 'react-router-dom';
import { alertMessage } from '../../alertsDialog/Action';
import expandbutton from '../../images/expand-button.png';

class ApiStudyUpdate extends Component {

    constructor(props) {
        super(props)
        this.state = {
            studyName: "",
            description: "",
            orignalRequirment: "",
            firstPartyUrl: "",
            firstPartyUrlTest: "",
            studyId: "",
            status: "",
            clientID: "",
            loi: "",
            IR: "",
            cpc: "",
            bdeUserId: "",
            invoiced: "",
            invitationPopUpData: '',
            allowLink: false,
            inMarket: false,
            allowDemo: false,
            allowRouter: false,
            paymentReceived: false,
            stopEditingData: false,
            stopEditingMarketData : false,
            newUpdatedValue: "",
            newUpdatedComment: "",
            updateStudyShow: true
        }
        this.onStudyNameChange = this.onStudyNameChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.onRequirementChange = this.onRequirementChange.bind(this);
        this.onLoiChange = this.onLoiChange.bind(this);
        this.onIrChange = this.onIrChange.bind(this);
        this.onCostChange = this.onCostChange.bind(this);
        this.onFirstPartyUrlChange = this.onFirstPartyUrlChange.bind(this);
        this.onTestUrlChange = this.onTestUrlChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onBdeChange = this.onBdeChange.bind(this);
        this.onClientChange = this.onClientChange.bind(this);
        this.onAllowLinkChange = this.onAllowLinkChange.bind(this);
        this.onAllowDemoChange = this.onAllowDemoChange.bind(this);
        this.onAllowRouterChange = this.onAllowRouterChange.bind(this);
        this.UpdateStudy = this.UpdateStudy.bind(this);
        this.addPlaceholder = this.addPlaceholder.bind(this);
        this.onNewValueChange = this.onNewValueChange.bind(this);
        this.onCommentChange = this.onCommentChange.bind(this);
        this.sendInvitationToPanelreward = this.sendInvitationToPanelreward.bind(this);
        this.onAllowMarketChange = this.onAllowMarketChange.bind(this);
        this.updateInMarket = this.updateInMarket.bind(this);
    }

    componentWillMount() {

       // if (localStorage.getItem("edit_study_data") === "stop") {
            this.setState({
                stopEditingData: true
            });
      //  }


        if (localStorage.getItem("is_Study_update") == 1)
            this.setState({ updateStudyShow: false });

        if (this.props.editstudyData) {
            let studyData = this.props.editstudyData;

            let allowDemoCheck = false;
            let allowRouterCheck = false;
            let checkedUniqueLink = false;
            let inMarket = false;

            if (studyData.allowDemo)
                allowDemoCheck = true;

            if(studyData.inMarket){
                inMarket = true
            }   

            if (studyData.uniqueLink)
                checkedUniqueLink = true;

            if (studyData.allowRouter)
                allowRouterCheck = true;

            let isLive = false;
            if (studyData.status === "live") {
                isLive = true;
            }

            localStorage.setItem("study_Name", studyData.survey_name);
            localStorage.setItem("study_Id", studyData.survey_id);
            localStorage.setItem("study_Is_live", isLive);

            this.setState({
                studyName: studyData.survey_name,
                description: studyData.description,
                orignalRequirment: studyData.complete_needed,
                firstPartyUrl: studyData.surveyentryurl,
                firstPartyUrlTest: studyData.firstPartyUrlTest,
                fees: studyData.fees,
                studyId: studyData.survey_id,
                loi: studyData.overall_loi,
                IR: studyData.overall_ir,
                status: studyData.survey_status,
                checked: checkedUniqueLink,
                allowDemo: allowDemoCheck,
                allowRouter: allowRouterCheck,
                cpc: studyData.cpi,
                clientID: studyData.client_id,
                invoiced: studyData.invoiced,
                bdeUserId: studyData.bde_id,
                inMarket : inMarket
            });
        }

        if (localStorage.getItem("auth_user_type") !== "bde") {
            this.props.dispatch(getAllBDEbyAdmin(localStorage.getItem('auth_user_id')));
        }

        // this.props.dispatch(getAllclients( localStorage.getItem("auth_user_id"), localStorage.getItem("auth_user_type") ));
    }

    onAllowMarketChange(e){
        if (e.target.value === false) {

        } else {
           // $("#error13").removeClass("showerror");
        }
        this.setState({
            inMarket: !this.state.inMarket
        });
    }

    updateInMarket(){
       let data = {};
       data.inMarket = this.state.inMarket;
       data.surveyId = this.state.studyId;
       this.props.dispatch(updateInMarket(data));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.invitationResp && nextProps.invitationResp.message) {
            let alertData = {};
            alertData.type = "alert";
            alertData.message = nextProps.invitationResp.message;
            this.props.dispatch(initialiseInvitationRes());
            this.props.dispatch(alertMessage(alertData));
        }



        if (nextProps.clientsList && nextProps.clientsList.length > 0) {
            this.setState({ clientListData: nextProps.clientsList });
        }

        if (nextProps.bdeUsersList && nextProps.bdeUsersList.length > 0) {

            this.setState({ showBDEusersList: true, bdeUsersData: nextProps.bdeUsersList });
        }
        if (nextProps.studySuccess && nextProps.studySuccess.success === true) {
            this.setState({
                studyName: "",
                description: "",
                orignalRequirment: "",
                firstPartyUrl: "",
                firstPartyUrlTest: "",
                studyId: "",
                status: "",
                clientID: "",
                loi: "",
                IR: "",
                cpc: "",
                bdeUserId: "",
                allowLink: false,
                allowDemo: false,
                allowRouter: false,
            });
        }

        if (nextProps.studySuccess && nextProps.studySuccess.message) {
            // alert(nextProps.studySuccess.message);
            let alertData = {};
            alertData.type = "alert";
            alertData.message = nextProps.studySuccess.message;
            this.props.dispatch(alertMessage(alertData));
            this.props.dispatch(emptyStudyResponse());
        }

        if (nextProps.studyApiUpdateMessage && nextProps.studyApiUpdateMessage) {
            // alert(nextProps.studyUpdateMessage);
            let alertData = {};
            alertData.type = "alert";
            alertData.message = nextProps.studyApiUpdateMessage;
            this.props.dispatch(alertMessage(alertData));
            this.props.dispatch(emptyStudyResponse());
        } else if (nextProps.studyUpdateMessage) {
            // alert(nextProps.studyUpdateMessage);
            let alertData = {};
            alertData.type = "alert";
            alertData.message = nextProps.studyUpdateMessage;
            this.props.dispatch(alertMessage(alertData));
            this.props.dispatch(emptyStudyResponse());
        }



    }

    componentDidMount() {

    }

    onStudyNameChange(e) {
        if (e.target.value !== "")
            $("#error1").removeClass("showerror");

        if (e.target.value.charAt(0) === " ") {
            this.setState({
                studyName: e.target.value.trim()
            });
        } else {
            this.setState({
                studyName: e.target.value
            });
        }
    }

    onDescriptionChange(e) {

        if (e.target.value !== "")
            $("#error2").removeClass("showerror");

        if (e.target.value.charAt(0) === " ") {
            this.setState({
                description: e.target.value.trim()
            });
        } else {
            this.setState({
                description: e.target.value
            });
        }
    }

    onRequirementChange(e) {
        if (e.target.value !== "")
            $("#error3").removeClass("showerror");

        if (e.target.value.match(/^[0-9]+$/)) {
            this.setState({
                orignalRequirment: e.target.value.trim()
            });
        } else if (e.target.value === "") {
            this.setState({ orignalRequirment: "" });
        } else {
            alert('Please Input Numeric Characters More Then Zero');
        }
    }

    onLoiChange(e) {
        if (e.target.value !== "")
            $("#error4").removeClass("showerror");

        if (e.target.value.match(/^[0-9]+$/)) {
            this.setState({
                loi: e.target.value
            });
        } else if (e.target.value === "") {
            this.setState({ loi: "" });
        } else {
            alert('Please Input Numeric Characters More Then Zero');
        }
    }

    onIrChange(e) {
        if (e.target.value !== "")
            $("#error5").removeClass("showerror");

        if (e.target.value.match(/^[0-9]+$/)) {
            if (parseInt(e.target.value) > 0 && parseInt(e.target.value) <= 100) {
                this.setState({ IR: e.target.value });
            }
        } else if (e.target.value === "") {
            this.setState({ IR: "" });
        } else {
            alert('Please Input Numeric Characters More Then Zero');
        }
    }

    onCostChange(e) {
        if (e.target.value !== "")
            $("#error6").removeClass("showerror");

        if (e.target.value.match(/^[0-9]+$/)) {
            this.setState({
                cpc: e.target.value
            });
        } else if (e.target.value === "") {
            this.setState({ cpc: "" });
        } else {
            alert('Please Input Numeric Characters More Then Zero');
        }
    }

    onFirstPartyUrlChange(e) {
        if (e.target.value !== "")
            $("#error7").removeClass("showerror");

        if (e.target.value.charAt(0) === " ") {
            this.setState({
                firstPartyUrl: e.target.value.trim()
            });
        } else {
            this.setState({
                firstPartyUrl: e.target.value
            });
        }
    }

    onTestUrlChange(e) {
        if (e.target.value !== "")
            $("#error8").removeClass("showerror");

        if (e.target.value.charAt(0) === " ") {
            this.setState({
                firstPartyUrlTest: e.target.value.trim()
            });
        } else {
            this.setState({
                firstPartyUrlTest: e.target.value
            });
        }
    }

    onStatusChange(e) {
        if (e.target.value === "") {

        } else {
            $("#error9").removeClass("showerror");
        }
        this.setState({
            status: e.target.value
        });
    }


    onBdeChange(e) {
        if (e.target.value === "") {

        } else {
            $("#error10").removeClass("showerror");
        }

        this.setState({
            bdeUserId: e.target.value
        });
    }

    onClientChange(e) {
        if (e.target.value === "") {

        } else {
            $("#error11").removeClass("showerror");
        }
        this.setState({
            clientID: e.target.value
        });
    }

    onAllowLinkChange(e) {
        if (e.target.value === false) {

        } else {
            $("#error12").removeClass("showerror");
        }
        this.setState({
            allowLink: !this.state.allowLink
        });
    }

    onAllowDemoChange(e) {
        if (e.target.value === false) {

        } else {
            $("#error13").removeClass("showerror");
        }
        this.setState({
            allowDemo: !this.state.allowDemo
        });
    }

    onAllowRouterChange(e) {
        if (e.target.value === false) {

        } else {
            $("#error14").removeClass("showerror");
        }
        this.setState({
            allowRouter: !this.state.allowRouter
        });
    }

    addPlaceholder() {
        if (this.state.firstPartyUrl) {
            if (!this.state.firstPartyUrl.includes("<pid>")) {
                this.setState({
                    firstPartyUrl: this.state.firstPartyUrl + "<pid>"
                });
            }
        } else {
            alert("Please enter first party url first !");
        }
    }

    handleValidation() {
        let valid = true;

        if (this.state.studyName === null || this.state.studyName === "") {
            valid = false;
            $("#error1").addClass("showerror");
            this.setState({
                studynameerror: "Enter study name"
            });
        }

        if (this.state.orignalRequirment === null || this.state.orignalRequirment === "") {
            this.setState({
                reqerror: "Enter requirment"
            });
            valid = false;
            $("#error3").addClass("showerror");
        } else if (isNaN(this.state.orignalRequirment)) {
            this.setState({
                reqerror: "Number only"
            });
            valid = false;
            $("#error3").addClass("showerror");
        }

        if (this.state.loi === null || this.state.loi === "") {
            valid = false;
            $("#error4").addClass("showerror");
            this.setState({
                loierror: "Enter LOI "
            });
        } else if (isNaN(this.state.loi)) {
            this.setState({
                loierror: "Number only"
            });
            valid = false;
            $("#error4").addClass("showerror");
        }


        if (this.state.IR === null || this.state.IR === "") {
            valid = false;
            $("#error5").addClass("showerror");
            this.setState({
                irerror: "Enter IR "
            });
        } else if (isNaN(this.state.IR)) {
            this.setState({
                irerror: "Number only"
            });
            valid = false;
            $("#error5").addClass("showerror");
        }


        if (this.state.cpc === null || this.state.cpc === "") {
            valid = false;
            $("#error6").addClass("showerror");
            this.setState({
                cpcerror: "Enter CPI "
            });
        } else if (isNaN(this.state.cpc)) {
            this.setState({
                cpcerror: "Number only"
            });
            valid = false;
            $("#error6").addClass("showerror");
        }

        if (this.state.firstPartyUrl === null || this.state.firstPartyUrl === "") {
            valid = false;
            $("#error7").addClass("showerror");
            this.setState({
                urlerror: "Enter first party url "
            });
        }

        // if(this.state.firstPartyUrlTest === ""){
        //     valid = false;
        //     $("#error8").addClass("showerror");
        //     this.setState({
        //         testurlerror: "Enter first party test url "
        //     });
        // }

        if (this.state.status === null || this.state.status === "") {
            valid = false;
            $("#error9").addClass("showerror");
            this.setState({
                statuserror: "Choose status"
            });
        }

        // if(this.state.clientID == null || this.state.clientID === ""){
        //     valid = false;
        //     $("#error11").addClass("showerror");
        //     this.setState({
        //        clienterror: "Choose client"
        //     });
        // }

        // if(this.state.allowLink === false){
        //     valid = false;
        //     $("#error12").addClass("showerror");
        //     this.setState({
        //        allowlinkerror: "Allow link"
        //     });
        // }

        // if(this.state.allowDemo === false){
        //     valid = false;
        //     $("#error13").addClass("showerror");
        //     this.setState({
        //        allowdemoerror: "Allow demo"
        //     });
        // }

        // if(this.state.allowRouter === false){
        //     valid = false;
        //     $("#error14").addClass("showerror");
        //     this.setState({
        //        allowroutererror: "Allow router"
        //     });
        // }

        // if(this.state.allowRouter === true && this.state.allowDemo === false){
        //     valid = false;
        //     $("#error13").addClass("showerror");
        //     this.setState({
        //         allowdemoerror: "Allow demo"
        //     });
        // }

        return valid;
    }


    UpdateStudy() {
        let studyData = {};
        studyData.survey_name = this.state.studyName;
        // studyData.description = this.state.description;
        studyData.complete_needed = this.state.orignalRequirment;
        studyData.surveyentryurl = this.state.firstPartyUrl;
        // studyData.firstPartyUrlTest = this.state.firstPartyUrlTest;
        // studyData.fees = this.state.fees;
        studyData.survey_status = this.state.status;
        studyData.overall_loi = this.state.loi;
        studyData.overall_ir = this.state.IR;
        studyData.cpi = this.state.cpc;
        studyData.survey_id = this.state.studyId;
        // studyData.checked = this.state.checked;
        // studyData.allowDemo = this.state.allowDemo;
        // studyData.allowRouter = this.state.allowRouter;
        // studyData.bdeUserId = this.state.bdeUserId;
        studyData.createdById = localStorage.getItem('auth_user_id');
        studyData.createdByRole = localStorage.getItem('auth_user_type');
        // studyData.clientID = this.state.clientID;
        // studyData.paymentReceived = this.state.paymentReceived;
        studyData.updateSource = 'master';
        if (this.handleValidation()) {
            // this.props.dispatch(UpdateStudiesData(studyData));
        }
    }

    onNewValueChange(e) {
        if (e.target.value !== "")
            $("#error21").removeClass("showerror");
        this.setState({ newUpdatedValue: e.target.value });
    }

    onCommentChange(e) {
        if (e.target.value !== "")
            $("#error22").removeClass("showerror");
        this.setState({ newUpdatedComment: e.target.value });
    }

    handleValidation1() {
        let valid = true;

        if (this.state.newUpdatedValue === "") {
            valid = false;
            $("#error21").addClass("showerror");
            this.setState({
                newValerror: "Enter new value"
            });
        }

        if (this.state.newUpdatedComment === "") {
            valid = false;
            $("#error22").addClass("showerror");
            this.setState({
                newCommenterror: "Enter comment here"
            });
        }


        return valid;
    }

    sendInvitationToPanelreward() {

        let isLive = false;
        if (this.state.status === "live") {
            isLive = true;
        }

        let data = {};
        data.studyId = this.state.studyId;
        data.study_name = this.state.studyName;
        data.is_live = isLive;
        data.description = this.state.description;
        data.cpi = this.state.cpi;


        if (this.handleValidation1()) {
            this.props.dispatch(invitationToPanellist(data));
            document.getElementById("invitaionbox").style.display = "none";
        }
    }


    render() {

        let clientsItem = "";
        let clients = this.state.clientListData;
        if (this.state.clientListData) {
            clientsItem = clients.map((client, i) => {
                return <option key={client.client_id} value={client.client_id}>{client.name}</option>
            });
        }

        let bdeUsersItem = "";
        if (this.state.showBDEusersList) {

            let bdeUsers = this.state.bdeUsersData;
            bdeUsersItem = bdeUsers.map((bde) =>
                <option key={bde.user_id} value={bde.user_id}>{bde.user_name}</option>
            );
        }

        let showBtn = ""
        if (this.state.stopEditingData === true) {
            showBtn = <button style={{ marginTop: "40px" }} className="list-btn " onClick={this.updateInMarket}>Update In Market</button>
        
            // showBtn = <Link to={{
            //     pathname: "/create-invoice",
            //     state: {
            //         editstudyData: this.props.editstudyData,
            //     }
            // }} >
            //     <button style={{ marginTop: "40px" }} className="list-btn " >Create Invoice</button></Link>
        } else {
            showBtn = <button disabled={this.state.updateStudyShow} style={{ marginTop: "40px" }} className="list-btn " onClick={this.UpdateStudy}>Update Study</button>
        }
        let showStudyName = '';
        //  code comment for design test

        // if(this.state.studyName.length >20){
        //    showStudyName = this.state.studyName.substr(0,20)+"...";
        // }else{
        //     showStudyName = this.state.studyName;
        // }

        return (
            <div>
                <div className="invitationalert" id="invitaionbox">
                    <div className="alert-box-shdw def-st">
                        <div style={{ textAlign: "center" }}><h2>{showStudyName}</h2></div>
                        <div className="formgroup" style={{ width: "100%", paddingLeft: "85px" }}>
                            <div className="formgroup-col-7 rltv-pos" >
                                <label>Update Requirement*</label>
                                <input className="form-controll " name="role_type" placeholder="Update Requirement" value={this.state.newUpdatedValue} onChange={this.onNewValueChange} ></input>
                                <div className="errordiv cus-width-8" id="error21" >{this.state.newValerror}</div>
                            </div>
                        </div>
                        <div className="formgroup " style={{ width: "100%", paddingLeft: "85px" }}>
                            <div className="formgroup-col-7 rltv-pos" >
                                <label>Study Description*</label>
                                <textarea className="form-controll " height="100px" width="200px" name="role_type" placeholder="Enter study description" value={this.state.newUpdatedComment} onChange={this.onCommentChange} ></textarea>
                                <div className="areaerror cus-width-8" id="error22" >{this.state.newCommenterror}</div>
                            </div>
                        </div>
                        <div style={{ marginTop: "20px", textAlign: "center" }}>
                            <button className="list-btn" onClick={this.sendInvitationToPanelreward}>Send</button>
                        </div>
                    </div>
                </div>
                <div className="form-title">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <h4 className="rm-margin">Study Name</h4>
                            <p className="form-title-text">{this.props.editstudyData.survey_name}</p>
                        </div>
                        <div className="pro-tgl-btn pro-tgl-btn-shdw" id="tg-btn-1">
                            {/* <div id="rotate-icon-1">&#11167;</div> */}
                            <div id="rotate-icon-1"><img src={expandbutton} height="15px" width="15px" /></div>
                        </div>
                    </div>
                    <hr />
                </div>

                <div className="formgroup">
                    <div className="formgroup-col-1 rltv-pos">
                        <label>Study Name</label>
                        <input className="form-controll " disabled={this.state.stopEditingData} value={this.state.studyName} onChange={this.onStudyNameChange}></input>
                        {/* <div className="editbtnst">G</div> */}
                        <div className="errordiv" id="error1">{this.state.studynameerror}</div>
                    </div>
                </div>

                {/* <div className = "formgroup">
                                <div class="formgroup-col-1 rltv-pos" >
                                    <label>Project Description</label>
                                    <input className="form-controll " value={this.state.description} onChange={this.onDescriptionChange}></input>
                                    <div className="errordiv" id="error2">{this.state.descerror}</div>
                                </div>
                            </div> */}

                <div className="formgroup">
                    <div className="formgroup-col-3 rltv-pos" >
                        <label>Orignal Requirment</label>
                        <input className="form-controll " disabled={this.state.stopEditingData} value={this.state.orignalRequirment} onChange={this.onRequirementChange}></input>
                        {/* <div className="editbtnst">G</div> */}
                        <div className="errordiv cus-width-1" id="error3">{this.state.reqerror}</div>
                    </div>
                    <div className="formgroup-col-3 rltv-pos" >
                        <label>LOI In Min</label>
                        <input className="form-controll " disabled={this.state.stopEditingData} value={this.state.loi} onChange={this.onLoiChange}></input>
                        <div className="errordiv cus-width-1" id="error4">{this.state.loierror}</div>
                    </div>
                    <div className="formgroup-col-3 rltv-pos" >
                        <label>IR %</label>
                        <input className="form-controll " disabled={this.state.stopEditingData} value={this.state.IR} onChange={this.onIrChange}></input>
                        <div className="errordiv cus-width-1" id="error5">{this.state.irerror}</div>
                    </div>
                    <div className="formgroup-col-3 rltv-pos" >
                        <label>CPI</label>
                        <input className="form-controll " disabled={this.state.stopEditingData} value={this.state.cpc} onChange={this.onCostChange}></input>
                        <div className="errordiv cus-width-1" id="error6">{this.state.cpcerror}</div>
                    </div>
                </div>


                <div className="formgroup">
                    <div className="formgroup-col-8 rltv-pos" >
                        <label>First Party Live Url</label>
                        <input className="form-controll " disabled={this.state.stopEditingData} value={this.state.firstPartyUrl} onChange={this.onFirstPartyUrlChange}></input>
                        <div className="errordiv cus-width-2" id="error7">{this.state.urlerror}</div>
                    </div>
                    <div className="formgroup-col-2" >

                        <button onClick={this.addPlaceholder} style={{ marginTop: "27px", width: "-webkit-fill-available" }} className="list-btn list-btn-color">Add Placeholder</button>

                    </div>
                </div>


                {/* <div className = "formgroup">
                                <div class="formgroup-col-1 rltv-pos" >
                                    <label>First Party Test Url</label>
                                    <input className="form-controll " disabled={this.state.stopEditingData} value={this.state.firstPartyUrlTest} onChange={this.onTestUrlChange}></input>
                                    <div className="errordiv" id="error8">{this.state.testurlerror}</div>
                                </div>
                            </div> */}

                <div className="formgroup">
                    <div className="formgroup-col-1 rltv-pos" >
                        <label>Status</label>
                        <select className="form-controll" disabled={this.state.stopEditingData} style={{ width: "100%", padding: "2px" }} value={this.state.status} onChange={this.onStatusChange}>
                            <option value="">Select Status</option>
                            <option value="22">Live</option>
                            <option value="23">Pause</option>
                            <option value="21">Complete</option>
                        </select>
                        <div className="errordiv" id="error9">{this.state.statuserror}</div>
                    </div>
                    {/* <div class="formgroup-col-4 rltv-pos" >
                                    <label>BDE</label>
                                    <select className="form-controll" disabled={this.state.stopEditingData} style={{width:"100%", padding: "2px"}} value={this.state.bdeUserId} onChange={this.onBdeChange}>
                                        <option value="">Select BDE</option>
                                     {bdeUsersItem}
                                    </select>
                                    <div className="errordiv cus-width-3" id="error10">{this.state.bdeerror}</div>
                                </div>
                               
                               
                                <div class="formgroup-col-4 rltv-pos" >
                                    <label>Client</label>
                                    <select className="form-controll" disabled={this.state.stopEditingData} style={{width:"100%", padding: "2px"}} value={this.state.clientID}  onChange={this.onClientChange}>
                                        <option value="">Select Client</option>
                                       {clientsItem}
                                    </select>
                                    <div className="errordiv cus-width-3" id="error11">
                                     {this.state.clienterror}
                                    </div>
                                </div> */}
                </div>

                <div className="check-box-list flex-show check-list-st mr-top-30">
                            <div class="form-group mr-right-20 rltv-pos" >
                                <div className="errordivcheck cus-width-2" id="error12">
                                    <div className="arrow_box">
                                    {this.state.inMarketError}
                                    </div>
                                </div>
                                <input type="checkbox" disabled={this.state.stopEditingMarketData} id="html" onChange={this.onAllowMarketChange} checked={this.state.inMarket}/>
                                <label for="html">In Market</label>
                            </div>
                            </div>

                {/* <div className="check-box-list flex-show check-list-st mr-top-30">
                            <div class="form-group mr-right-20 rltv-pos" >
                                <div className="errordivcheck cus-width-2" id="error12">
                                    <div className="arrow_box">
                                    {this.state.allowlinkerror}
                                    </div>
                                </div>
                                <input type="checkbox" disabled={this.state.stopEditingData} id="html" onChange={this.onAllowLinkChange} checked={this.state.allowLink}/>
                                <label for="html">Allow Unique Link</label>
                            </div>

                            <div class="form-group mr-right-20  rltv-pos">
                                <div className="errordivcheck cus-width-2" id="error13">
                                        <div className="arrow_box">
                                            {this.state.allowdemoerror}
                                        </div>
                                    </div>
                                <input type="checkbox" disabled={this.state.stopEditingData} id="css"  onChange={this.onAllowDemoChange}  checked={this.state.allowDemo}/>
                                <label for="css">Allow Demographics</label>
                            </div>

                            <div class="form-group mr-right-20  rltv-pos">
                            <div className="errordivcheck cus-width-2" id="error14">
                                    <div className="arrow_box">
                                    {this.state.allowroutererror}
                                    </div>
                                </div>
                                <input type="checkbox" disabled={this.state.stopEditingData} id="javascript" onChange={this.onAllowRouterChange} checked={this.state.allowRouter} />
                                <label for="javascript">Allow Router</label>
                            </div>  
                    </div> */}

                <div style={{ display: "flex", justifyContent: "center" }}>
                    {/* <button style={{marginTop: "40px"}} className="list-btn " onClick={this.UpdateStudy}>Update Study</button> */}
                    {showBtn}
                </div>
            </div>

        )
    }
}
const mapStateToProps = (state) => {
    return {
        // clientsList    : state.studies.clientsList,
        // bdeUsersList : state.studies.bdeUsersList,
        // studySuccess : state.studies.studySuccess,
         studyApiUpdateSuccess : state.studies.studyApiUpdateSuccess,
         studyApiUpdateMessage : state.studies.studyApiUpdateMessage,
        // invitationResp : state.studies.invitationResp
    }
}

export default connect(mapStateToProps)(ApiStudyUpdate);
const initialState = {
    members : [],
    showAddMember : false,
  
  }
  
  const roles = (state = initialState , action) =>{
    switch (action.type) {
        
        case 'SET_SHOW_CREATE_ROLE_PAGE' :
          return {
            ...state,
            showCreateRole : true,
            editRoleData: null
          }

        case 'SET_ADD_ROLE_RES' : 
          return {
            ...state,
            showCreateRole : true,
            showRolePermission : null,
            addRoleSuccess  : action.response.success,
            addRoleMessage  : action.response.message,
            addRoleResponse : action.response
          }

        case 'SET_EMPTY_RESPONSE':
         return { 
            ...state,
            addRoleSuccess  : null,
            addRoleMessage  : null,
            updateRoleSuccess : null,
            updateRoleMessage : null,
            rolePermissionUpdate : null,
            rolePermissionUpdateMsg : null,
            roleDeleteSuccess : null,
            roleDeleteMessage : null,
            rolePermissionData :null,
         }

        case 'SET_ALL_ROLE_RES':
         return{
            ...state,
            showCreateRole : false,
            allRoleSuccess  : action.response.success,
            allRoleData  : action.response.data,
            rolePermissionSuccess : null,
            rolePermissionData : null,
            showRolePermission : null,
         }

        case 'SET_EDIT_ROLE':
        return{
          ...state,
          showCreateRole : true,
          editRoleData : action.data,
          showRolePermission : null,
        }

        case 'SET_ROLE_UPDATE_RES':
        return{
          ...state,
          showCreateRole : true,
          updateRoleSuccess  : action.response.success,
          updateRoleMessage  : action.response.message
        }

        case 'SET_ROLE_PERMISSION_DATA_RES':
        return{
          ...state,
          showRolePermission : true,
          rolePermissionSuccess : action.response.success,
          rolePermissionData : action.response.data,
        }

        case 'SET_ROLE_PERMISSION_UPDATE_RES':
        return{
          ...state,
          showRolePermission : true,
          rolePermissionUpdate : action.response.success,
          rolePermissionUpdateMsg : action.response.message,
        }

        case 'SET_ALL_ROLE_WITH_PAGE':
        return{
          ...state,
          rolePermissionData :null, 
          totalRolesCount : action.response.totalRoles,
          rolesData : action.response.data,
        }

        case 'SET_ROLE_DELETE':
          return{
            ...state,
            rolePermissionData :null,
            roleDeleteSuccess : action.response.success,
            roleDeleteMessage : action.response.message
          }

        default:
            return state;
          }
  };

  export default roles;
  
import React, { Component }  from 'react';
import {getConstraintsList,  } from '../../../actions/actionsBag/demoMapping';
import { alertMessage }  from '../../../alertsDialog/Action';
import { connect,  } from 'react-redux';
import {Link} from 'react-router-dom';
import Pagination from "react-js-pagination";
import deletIcon from '../../../images/delete.png';
import editpencil from '../../../images/edit-pencil.png';
import add from '../../../images/add.png';
import $ from 'jquery';

class  ConstraintsList  extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: "",
            currentPage: 1,
            studyId : "",
            constraintsList : [],
            totalPage : 0,
            showloader : false
        }
        this.handlePageChange = this.handlePageChange.bind(this);
      }


  componentWillReceiveProps(nextProps){

    if( nextProps.constraintsResponse && nextProps.constraintsResponse.success &&  nextProps.constraintsResponse.constraintsList && nextProps.constraintsResponse.constraintsList.length >0){
      this.setState({
        totalPage : nextProps.constraintsResponse.totalConstraints,
        constraintsList : nextProps.constraintsResponse.constraintsList,
        showloader: true,
      });
    }else{
      this.setState({
        totalPage : 0,
        showloader: true,
      });
    }
  }
  


  componentWillMount(){
    let studyId = localStorage.getItem("study_Id");
    this.setState({ studyId : studyId });
    this.props.dispatch(getConstraintsList( studyId, 1));
  }

  handlePageChange(pageNumber){

    this.setState({ currentPage : pageNumber});
    this.props.dispatch(getConstraintsList( this.state.studyId, pageNumber));
  }


  tableZoom(){
    $(".alltablestruct").toggleClass("zoom-me");
    // $("#routercontainer").toggleClass("router-containter-pd");
    // document.getElementById("routercontainer").style.padding="0px";

  }

    render(){
        
        let roleItems = "";
        let paginationView = "";
        let sr = (this.state.currentPage * 4)-4;
        if (this.state.constraintsList && this.state.constraintsList.length> 0 ) {

            roleItems = this.state.constraintsList.map((list, i) => {


              let showOption = "";
              if(list.queryType === "range"){
                showOption = list.range_option;
              }else{
                showOption = list.options;
              }

            return <tbody> <tr>  
                  <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom">{i+1}</td>
                  <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.demo_name}</td>
                  
                  <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.question}</td>

                  <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{showOption}</td>

                  <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.queryType}</td>

                  <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.quota}</td>

                  
                  </tr>
                  </tbody>
             });
          }else{
            roleItems = "";
          }


          let showTable = "";
          if (this.state.constraintsList && this.state.constraintsList.length > 0 ) {
            showTable =  <table className="tbl-new-est" >
            <thead>
              <tr>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">S.No.</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Demographic Name</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Query</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Options</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Query Type</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Quota %</th>
                </tr>
                </thead>
              {roleItems}
          </table>
          }else if(this.state.showloader === true){
            showTable = <div className="datanotfound"> No record found</div>
          }else{
            showTable = <div className="spiner-data"><div className="tblspinner"></div></div>
          }
         


          if(this.state.totalPage > 0){
              paginationView =   <div id="pageinationstyle">
                       
                      <Pagination
                          activePage={this.state.currentPage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalPage}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                        />
                </div> 
          } else{
            paginationView = "";
          }


        return(
            <div className="list-style">
                <div className="table-st">
                    <div className="alltablestruct forms-rd-shadow">
                         {/* serachbox and stausbtn */}
                        <div id="search-staus">

                            <div className="flex-show  tbl-h-font">
                            Applied Constraints List
                            </div>
                            
                            <div className="flex-show tbl-zoom" >
                            <span onClick={this.tableZoom} className="full-screen mr-rhs-20 mr-top-10"> </span>
                            </div>
                        </div>
                        {/* serachbox and stausbtn */}

                            {/* table */}
                            <div style={{marginTop: "20px", overflowX: "auto"}}>
                                {showTable}
                            </div>
                            {/* table */}
                   
                                {paginationView}

                    </div>
                </div>
            </div>
    )}
}

const mapStateToProps = (state) => {
  return {
    constraintsResponse : state.demoMapped.constraintsResponse,
  }
}

  export default connect(mapStateToProps)(ConstraintsList);



const siteURL = "http://testspapi.panelreward.com/api/";
const baseURL = "http://testspapi.panelreward.com/";
// // const panelrewardSiteURL = "https://apimy.panelreward.com/api/"; // live production

const url = {
  participantUrl: "http://testparticipant.panelreward.com/",
  terminateUrl: "http://testst.panelreward.com/",
  successUrl: "http://testsc.panelreward.com/",
  overQuotaUrl: "http://testsq.panelreward.com/",
  testUrl: "http://testparticipanttest.panelreward.com/",
  baseURL : baseURL
}


//const siteURL = "https://spapi.panelreward.com/api/"
// const panelrewardSiteURL = "https://apimy.panelreward.com/api/"; // live production
//const baseURL = "https://testspapi.panelreward.com/";
// const url = {
//   participantUrl: "https://participant.panelreward.com/",
//   terminateUrl: "https://st.panelreward.com/",
//   successUrl: "https://sc.panelreward.com/",
//   overQuotaUrl: "https://sq.panelreward.com/",
//   testUrl: "https://participanttest.panelreward.com/",
//   baseURL : baseURL
// }

//local
const panelrewardSiteURL = "";
// const siteURL = "http://localhost:8089/api/";

// const url = {
//  participantUrl: "http://localhost:3000/participant/",
//  //participantUrl : "http://208.109.53.27:3000/participant/",
//  terminateUrl: "http://localhost:4003/study-terminate/",
//  successUrl: "http://localhost:4005/study-success/",
//  overQuotaUrl: "http://localhost:4004/study-overQuota/",
//  testUrl : "http://localhost:3000/participant-test"
// }

const appConfig = {
  siteURL: siteURL,
  panelrewardSiteURL: panelrewardSiteURL,
  url: url
}

export default appConfig

import React, { Component }  from 'react';
import AddProjectMapping from '../../components/ProjectActivities/addProjectMapping';
import ApiUpdateStudyHeader from '../../components/StudySurvey/apiupdateStudyHeader';
import ThirdPartyOverallReport from '../../components/ProjectActivities/thirdPartyOverAllReport';
import ApiMappingList from '../../components/ProjectActivitiesSurvey/apiMappingList';
import { getThirdPartyListByStudies, dataByStudyId, addMapping, emptyResUpdateMapping, getProgressReportDataByStudyMap, 
       sendInvitationToVendors, initialiseInvitationRes } from '../../actions/actionsBag/study';
import { connect } from 'react-redux';
import $ from 'jquery';
import { alertMessage }  from '../../alertsDialog/Action';

export class ApiProjectMapping extends Component {
    constructor(props) {
        super(props)
        this.state = {
            study_id : ""
        }
        this.selectActivity = this.selectActivity.bind(this);
        this.addMapping = this.addMapping.bind(this);
        this.sendInvitationToVendors = this.sendInvitationToVendors.bind( this);
    }

    componentWillMount(){
        let study_id =  localStorage.getItem("edit_study_id");
       if(study_id){
            this.setState({ study_id : study_id });

        this.props.dispatch(dataByStudyId(study_id)); 
        this.props.dispatch(getThirdPartyListByStudies(study_id));
        this.props.dispatch( getProgressReportDataByStudyMap(study_id));
       }else{
           this.props.history.push('/studylist');
       }

    }

    componentDidMount(){
        $(document).ready(function(){
            $("#tg-btn-4").click(function(){
            $("#show-form-4").toggleClass("show-full-div");
            $("#map-hide-1").toggleClass("box-hide-1");
            $("#rotate-icon-4").toggleClass("rotate-tgl");
            });

            $("#tg-btn-5").click(function(){

             $("#show-form-5").toggleClass("show-full-div");
             $("#map-hide-2").toggleClass("box-hide-2");
             $("#rotate-icon-5").toggleClass("rotate-tgl");
            });

        });
    }

    componentWillReceiveProps( nextProps){
        if( nextProps.mappingSuccess && nextProps.mappingMsg != undefined ){
            this.props.dispatch( emptyResUpdateMapping());
            // alert( nextProps.mappingMsg);
            let alertData ={};
            alertData.type ="alert";
            alertData.message = nextProps.mappingMsg;
          this.props.dispatch(alertMessage(alertData));
            this.props.dispatch(dataByStudyId(this.state.study_id)); 
            this.props.dispatch(getThirdPartyListByStudies(this.state.study_id));
        }else if( nextProps.mappingSuccess === false && nextProps.mappingMsg != undefined ){
            this.props.dispatch( emptyResUpdateMapping());
            // alert( nextProps.mappingMsg);
            let alertData ={};
            alertData.type ="alert";
            alertData.message = nextProps.mappingMsg;
          this.props.dispatch(alertMessage(alertData));
            //this.props.dispatch(getThirdPartyListByStudies( this.state.study_id));
        }

        //invitationVendorResp
        if( nextProps.invitationVendorResp && nextProps.invitationVendorResp.success === true ){
            this.props.dispatch( initialiseInvitationRes());
            let alertData ={};
            alertData.type ="alert";
            alertData.message = nextProps.invitationVendorResp.message;
          this.props.dispatch(alertMessage(alertData));
        }else{
            if( nextProps.invitationVendorResp && nextProps.invitationVendorResp.success === false && nextProps.invitationVendorResp.message ){
                this.props.dispatch( initialiseInvitationRes());
                let alertData ={};
                alertData.type ="alert";
                alertData.message = nextProps.invitationVendorResp.message;
              this.props.dispatch(alertMessage(alertData));
            }
        }
    }

    selectActivity(activityName){
        this.props.history.push(activityName);
    }

    gotoBack(){ 
        window.history.back();
    }

    addMapping( data){
        data.studyId = this.state.study_id;
        this.props.dispatch( addMapping( data));
    }

    sendInvitationToVendors(){
        
        this.props.dispatch( sendInvitationToVendors( this.state.study_id));
    }

    render(){
        return(
            <div>
                
              
                <ApiUpdateStudyHeader
                    selectActivity = {this.selectActivity}
                    editstudyData = {this.state.editstudyData}
                    gotoBack = {this.gotoBack}
                    sendInvitationToVendors = {this.sendInvitationToVendors}
                />
                <div className="create-form ">
                    <div className="create-form-container all-header">
                        <div className="forms-rd-shadow toggle-form show-me " id="show-form-1">
                        <div className="form-title">
                                <h4 className="rm-margin">Study Name</h4>
                                <p className="form-title-text">{localStorage.getItem("study_Name")}</p>
                                </div>
                        </div>
                    </div>
                </div>


                <div className="create-form ">
                    <div className="create-form-container all-header">
                        <div className="forms-rd-shadow toggle-form show-me " id="show-form-1">
                            <AddProjectMapping
                                thirdPartListByStudySuccess = { this.props.thirdPartListByStudySuccess}
                                thirdPartListByStudyData = { this.props.thirdPartListByStudyData}
                                addMapping = { this.addMapping}
                            />
                        </div>
                    </div>
                </div>

                <div className="create-form ">
                    <div className="create-form-container all-header">
                        <div className="forms-rd-shadow toggle-form show-me"  id="show-form-4">
                            <ThirdPartyOverallReport
                                progressDataStudyMap = { this.props.progressDataStudyMap}
                            />
                        </div>
                    </div>
                </div>

                <div className="create-form  ">
                    <div className="create-form-container all-header">
                        <div className="forms-rd-shadow toggle-form show-me"  id="show-form-5">
                            <ApiMappingList
                                mappingSuccess = { this.props.mappingSuccess}
                                mapping = {this.props.mapping}
                                thirdPartListByStudySuccess = { this.props.thirdPartListByStudySuccess}
                                thirdPartListByStudyData = { this.props.thirdPartListByStudyData}
                            />
                        </div>
                    </div>
                </div>


            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        mappingSuccess : state.studies.mappingSuccess,
        mapping: state.studies.mapping,
        thirdPartListByStudySuccess : state.studies.thirdPartListByStudySuccess,
        thirdPartListByStudyData    : state.studies.thirdPartListByStudyData,
        mappingMsg : state.studies.mappingMsg,
        progressDataStudyMap : state.studies.progressDataStudyMap,
        invitationVendorResp : state.studies.invitationVendorResp
    }
  }
  
  export default connect(mapStateToProps)(ApiProjectMapping);
import React, { Component }  from 'react';
import {connect} from 'react-redux';
import '../../pages/Study/tempstyle.css';
import '../../commonStyle/common.css';
import $ from 'jquery';
import expandbutton from '../../images/expand-button.png';
import righicon from '../../images/righticon.png';
import download from '../../images/downloadicon.png';
import userboxicon from '../../images/userboxicon.png';



class archivingStudyProgress extends Component {
    constructor(props){
        super(props)
        this.state = {
            search: "all",
            isTestParticipantsShown : false,
            updateStudyProgressData : {},
        }
        this.onChange = this.onChange.bind(this);
        this.participantAsExcel = this.participantAsExcel.bind(this);
        this.showTestParticipants = this.showTestParticipants.bind(this);
    }
    componentDidMount(){ 
    }

    componentWillReceiveProps(nextProps){
       this.setState({
        updateStudyProgressData : nextProps.updateStudyProgressData
       }); 
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    participantAsExcel(){
        this.props.participantAsExcelBystudy(this.state.search, this.state.isTestParticipantsShown);
    }
    
    showTestParticipants(){
          this.setState({
            isTestParticipantsShown : true
          });
    }

    render(){

    let totalIpFails = 0;
    let participantItems = "";
    let totalCompletes = 0;
    let totalOverQuota = 0;
    let totalTerminates = 0;
    let totalLoiFails = 0;
    let loi = 0;
    let ir = 0;
    let avgLoi = 0;
    let progressData = {};
    
   
    if(this.state.isTestParticipantsShown){
        let studySummaryData = this.state.updateStudyProgressData;

        if(this.state.updateStudyProgressData  && this.state.updateStudyProgressData.participantData ){
            studySummaryData.participantData = this.state.updateStudyProgressData.participantData.filter(data=>data._id.includes("GNNTEST"));
        }
        
        progressData = studySummaryData;
    } else {
        progressData = this.state.updateStudyProgressData;
    }

     
    
    if (progressData) {

        // if (progressData.totalCompletes) {
        // totalCompletes = progressData.totalCompletes;
        // }

        // if (progressData.totalOverQuota) {
        // totalOverQuota = progressData.totalOverQuota;
        // }

        // if (progressData.totalTerminates) {
        // totalTerminates = progressData.totalTerminates;
        // }

        // if (progressData.totalIpFails) {
        // totalIpFails = progressData.totalIpFails;
        // }

        
        if (progressData.loi) {
           
            loi = progressData.loi.loi;
            totalCompletes = progressData.loi.completed;
            totalOverQuota = progressData.loi.overQuota;
            totalTerminates = progressData.loi.terminate;
            totalIpFails = progressData.loi.ipFailed;
        }

        // if (progressData.loi && progressData.loi.loi) {
        // loi = progressData.loi.loi;
        // }
        if (progressData.irCount) {
        ir = progressData.irCount
        }
        if (progressData.avgLoi) {
        avgLoi = progressData.avgLoi.average
        }
        if (progressData.totalLoiFails) {
        totalLoiFails = progressData.totalLoiFails
        }
    }
    if (progressData && progressData.participantData && progressData.participantData.length > 0) {
        participantItems = progressData.participantData.filter(
        (participants, index) => {
            if (this.state.search === "all") {
            return true;
            }
            else if (participants.status.indexOf(this.state.search) !== -1) {
            return true;
            }
        }).map((item, index) => {
            let loi = "";
            let status = "";

            let date ="";
            let time="";
            let endTime= "";
            
            if(item.createdAt.includes("T")){
                date = item.createdAt.split("T")[0];
                time = item.createdAt.split("T")[1].split(".")[0];
            }else{
                date = item.createdAt.split(" ")[0];
                time = item.createdAt.split(" ")[1].split(".")[0];
            }         
           
            if(item.endLoi === null || item.endLoi === "null"){
                if(item.updatedAt.includes("T")){
                    endTime = item.updatedAt.split("T")[1].split(".")[0];
                }else{
                    endTime = item.updatedAt.split(" ")[1].split(".")[0];
                }
            } else {
                if(item.endLoi.includes("T")){
                    endTime = item.endLoi.split("T")[1].split(".")[0];
                }else{
                    endTime = item.endLoi.split(" ")[1].split(".")[0];
                } 
                 
            }

            if (item.loi) {
            if (typeof item.loi == "number") {

                item.loi.toFixed(2);

            } else {

                loi = parseFloat(item.loi).toFixed(2);
            }
            }
            switch (item.status) {

            case "1":
                status = "Completed";
                break;
            case "2":
                status = "Over Quota";
                break;
            case "3":
                status = "Terminated";
                break;
            case "0":
                status = "Not Completed";
                break;
            case "4":
                status = "Internal Terminated";
                break;
            case "5":
                status = "Internal Over Quota";
                break;
            case "6":
                status = "Failed In Loi";
                break;
            case "7":
                status = "Failed In Ip";
                break;
            case "12":
                status = "Vendor Over Quota";
            }
            return <tr className="s" key={index}>
            {/* <td className="tdd">{index + 1}</td> */}
            <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{item._id}</td>
            <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{item.uid}</td>
            <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{date}</td>
            <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{time}</td>
            <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{endTime}</td>
            <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{item.participantIp}</td>
            <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{Math.round(item.loi)}</td>
            {/* <td className="tdd">{loi}</td> */}
            <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{status}</td>
            </tr>
        })
    }

    let showTable ="";
    if(progressData && progressData.participantData && progressData.participantData.length > 0){
        showTable =  <table className="mappingtable" id="pro-tbl">
        <thead  className="gradienthead" >
          <tr>
            <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-fix">ID</th>
            <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-fix">Respondent ID</th>
            <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-fix">Started Date</th>
            <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-fix">Started Time	</th>
            <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-fix">End Time	</th>
            
            <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-fix">IP Address</th>
            <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-fix">LOI</th>
            <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-fix">Status</th>
            </tr>
            </thead>
            <tbody>
            {participantItems}
            </tbody>
          {/* {clientList} */}
      </table>
    }else if(progressData && progressData.success && progressData.participantData.length == 0){
        showTable = <div className="datanotfound"> No record found !</div>
    }else{
        showTable = <div className="spiner-data"><div className="tblspinner"></div></div>
    }
    

        return(
            <div >
                  <div className="form-title">
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                                <div>
                                    <h4 className="rm-margin card-text-h4">Progress Report</h4>
                                    </div>
                                <div className="pro-tgl-btn pro-tgl-btn-shdw" id="tg-btn-3">
                                    <div id="rotate-icon-3"><img src={expandbutton} height="15px" width="15px"/></div>
                                </div>
                            </div>
                       <div className="url-mr-top pro-tbl-hide" id="pro-tbl-hide-me">
                            <div className="pro-card-header card-border" >
                    {/* first count */}
                    <div className="pro-first-layer">
                        {/* first count 1st */}
                        <div className="pro-bottom-layer box-1-color" >
                         <div className="pro-card-content" >
                            <p>Complete</p>
                            <h3><b style={{color:"white"}}>{totalCompletes}</b></h3></div>
                         <div className="pro-card-img pro-card-img-circle" ><img src={righicon}/></div>
                        </div>
                        <div className="pro-bottom-layer box-2-color">
                        <div className="pro-card-content" >
                            <p >Quota full</p>
                            <h3><b style={{color:"white"}}>{totalOverQuota}</b></h3></div>
                            <div className="pro-card-img pro-card-img-circle" ><img src={userboxicon}/></div>
                        </div>
                        {/* first count 1st */}
                    </div>
                    {/* first count */}
                    {/* Second count */}
                    <div className="pro-first-layer">
                        {/* second count 2nd */}
                        <div className="pro-bottom-layer box-3-color">
                            <div className="pro-card-content" >
                                <p >Terminates</p>
                                <h3><b style={{color:"white"}}>{totalTerminates}</b></h3></div>
                                <div className="pro-card-img pro-card-img-circle" ><img src={download}/></div>
                        </div>
                        <div>
                           <div ><button  onClick={this.participantAsExcel} className="pro-btn ">Export To Excel</button></div>
                            <div>
                                <select id="selectstatus"  className="updateMappingSearchByStatus" name="search" value={this.state.search} onChange={this.onChange}>
                                <option value="all">Filter By Status</option>
                                <option value="1">Completed</option>
                                <option value="2">Over Quota</option>
                                <option value="3">Terminated</option>
                                <option value="0">Not Completed</option>
                                <option value="7">Failed In Ip</option>
                                <option value="6">Failed In Loi</option>
                                <option value="12">Vendor Over Quota</option>
                            </select>
                            </div>
                            <div style={{marginTop : "10px" }}><button  onClick={this.showTestParticipants} className="pro-btn ">Test Participants</button></div>
                           

                        </div>
                        {/* second count 2nd */}
                    </div>
                     {/* Second count */}
                </div>

            <div style={{marginTop: "40px", overflowX: "auto",height: "500px", overflowY: "auto"}} >
                {showTable}
            </div>


                    </div>
                </div>
            </div>

    )}
}

const mapStateToProps=(state)=>{
    return{
      
    }
  }
  
  export default connect(mapStateToProps)(archivingStudyProgress);
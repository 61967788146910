import React, { Component }  from 'react';
import '../../commonStyle/common.css';
import { uploadClientLogo, emptyUploadLogoProps } from '../../actions/actionsBag/clientAction';
import { connect } from 'react-redux';
import ExampleComponent from "react-rounded-image";
import ImageUploader from 'react-images-upload';
import add from '../../images/add.png';
import { alertMessage }  from '../../alertsDialog/Action';
import appConfig from "../../api/apiConfig";
import $ from 'jquery';
import UpdateClientHeader from './updateClientHeader.jsx';
import goback from '../../images/goback.png';
import { Link } from 'react-router-dom';
//var FormData = require('form-data');


class  UploadClientLogo  extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: "",
      pictures: [],
      validationMsg: {},
      isImageSelected : false,
      isShowImage : false,
      imageToShow : "",
      imageUrl : "",
      clientId : "",
      baseUrl : "https://testspapi.panelreward.com/clients/logo/",
      editClientData : null
    }
   
    this.UploadUniqueLinkFile = this.UploadUniqueLinkFile.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.updateImage = this.updateImage.bind(this);
    this.onDrop = this.onDrop.bind(this);


  }


  componentDidMount(){
    const { imageUrl } = this.props.location.state;
    const { clientId } = this.props.location.state;
    const { editClientData } = this.props.location.state;
    

    if(imageUrl != null){
        this.setState({
            imageUrl : imageUrl,
            clientId : clientId,
            isShowImage : true,
            editClientData : editClientData
        });
    } else {
        this.setState({
            imageUrl : imageUrl,
            clientId : clientId,
            editClientData : editClientData
        });
    }
    

  }

  updateImage(){
    this.setState({
        pictures: [],
        isImageSelected : false,
        isShowImage : false
    }); 
  }


  uploadImage(e){

    // uploadClientLogo
    var form = new FormData();
    //form.append('username', 'Chris');
    let file = this.state.pictures[0];
    form.append('file', file);

   // console.log(form.entries());
    this.props.dispatch(uploadClientLogo(form, this.state.clientId));
  
  }

  
  onDrop(picture) {

    let isSelected = false;
    
    if(picture.length > 0)
    isSelected = true;

    this.setState({
        pictures: this.state.pictures.concat(picture),
        isImageSelected : isSelected,
    });
}

  handleValidation() {
    let valid = true;
    let error = {};  

    if (this.state.file === "") {
      valid = false;
      $("#error1").addClass("showerror");
      this.setState({
        rolenameerror: "Please choose file"
      });
    }else if(this.state.file && !this.state.file.type.includes("csv")){
      valid = false;
      $("#error1").addClass("showerror");
      this.setState({
        rolenameerror: "Please upload csv file only!"
      });
    }else{
      this.setState({ rolenameerror : "" });
    }
    
    
    return valid;
  }

  handleFileUpload(e) {
    let file = e.target.files[0];
    this.setState({ file: file }); 
  }
    
  UploadUniqueLinkFile(){
    var formData = new FormData();
    formData.append('file', this.state.file);
    if (this.handleValidation()) {
        
        // this.props.dispatch(uploadDoc(formData, localStorage.getItem("edit_study_id")))
    }
  }
  


  componentWillReceiveProps( nextProps){

    if(nextProps.uploadLogoResult && nextProps.uploadLogoResult.success === true){
       
        // fileName 

        this.setState({
            imageUrl : nextProps.uploadLogoResult.fileName,
            isShowImage : true
        });
        

        let alertData ={};
        alertData.type ="alert";
        alertData.message =  nextProps.uploadLogoResult.message;
        this.props.dispatch(alertMessage(alertData));
        this.props.dispatch(emptyUploadLogoProps());
    }


  }


    render(){


      const header = 'Update Client Logo';
      const headerText = 'Here you can update client Logo';
      const headerButton = <Link 
      to = {{      
        pathname: '/update-client',
        state: {
          editClientData : this.state.editClientData
          }
      }}
      > <button className="back-btn back-btn-color" ><img src={goback} height="13px" width="13px" /><span> Back</span></button></Link>
     

        let showImageChoose = "";
        let showImageLogo = "";

        let updateImage = "";
        let uploadImage = "";

        if(this.state.isShowImage === false){
            showImageChoose = <ImageUploader
            withIcon={true}
            singleImage = {true}
            withPreview = {true}
            buttonText='Choose image'
            onChange={this.onDrop}
            imgExtension={['.jpg', '.gif', '.png', '.gif']}
            maxFileSize={5242880}
        />

        if(this.state.isImageSelected === true){
            uploadImage = <button className="list-btn list-btn-color"  onClick={this.uploadImage} >Upload</button>
        }


        } else {
            showImageLogo = <ExampleComponent
            image={this.state.baseUrl +  this.state.imageUrl}
            roundedColor="#66A5CC"
            imageWidth="120"
            imageHeight="120"
            roundedSize="8"
            />

          
            updateImage = <button className="list-btn list-btn-color"  onClick={this.updateImage} >Update</button>
              

        }

        return(

       
                

          <div style={{ width:"100%"}}>  
              <UpdateClientHeader 
                  header = {header}
                  headerText = {headerText}
                  headerButton = {headerButton}
              />
              <div style={{marginTop: "2%",marginBottom: "50px"}}>

                <div className="table-st">
                    <div className="forms-rd-shadow" style={{ width: "100%",paddingBottom:"50px", backgroundColor:"#ffffff", borderRadius:"4px" }}>
                        <div className="addVendor-main-div">
                        <div className="form-title">
                            <h4 className="rm-margin">Update Logo</h4>
                        </div>
                        <hr/>
                        <div className = "formgroup">

                        
                        {showImageChoose}
                        {showImageLogo}

                            </div>
                        <div className = "formgroup">
                            <div class="formgroup-col-7 rltv-pos" >
                                {updateImage}
                                {uploadImage}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    )}
}
const mapStateToProps=(state)=>{
    return{
        uploadLogoResult: state.client.uploadLogoResult,
    }
  }
  export default connect(mapStateToProps)(UploadClientLogo);


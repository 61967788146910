import React, { Component }  from 'react';
import CreateStudyHeader from '../../components/Study/createStudyHeader';
import CreateStudyForm from '../../components/Study/createStudyForm';
import {connect} from 'react-redux';

class CreateStudy extends Component {

    constructor(props) {
        super(props)
        this.state = {
          
          
        }
    
        this.goToList = this.goToList.bind(this);
    }

    goToList( data){
        // this.props.history.push("/studylist");
        this.props.history.push({
            pathname: '/Study-List-Update',
            state: { editstudyData : data }
        });
    }
    

    render(){
        return(
            <div>
                <CreateStudyHeader/>
                <CreateStudyForm
                goToList = {this.goToList}
                />
            </div>
    )}
}

const mapStateToProps=(state)=>{
    return{
        clientsList    : state.studies.clientsList,
        bdeUsersList : state.studies.bdeUsersList,
        studySuccess : state.studies.studySuccess,
    }
  }
  
  export default connect(mapStateToProps)(CreateStudy);
import appConfig from "../../api/apiConfig";


export const getConstraintsListApi = (sid, page) => {

    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(appConfig.siteURL + 'getConstraintsList/' + sid + '/' + page, {
        method: "GET",
        headers: headers,
    });

    return fetch(request).then(response => {
        return response.json().then(client => {
            return client;
        });
    }).catch(error => { return error; });
}


export const getParticipantsReplyDataApi = (sid, page) => {

    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(appConfig.siteURL + 'getParticipantsReplyData/' + sid + '/' + page, {
        method: "GET",
        headers: headers,
    });

    return fetch(request).then(response => {
        return response.json().then(client => {
            return client;
        });
    }).catch(error => { return error; });
}

export const getDemoMappedListApi = (sid, page) => {

    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(appConfig.siteURL + 'getMappedDemographics/' + sid + '/' + page, {
        method: "GET",
        headers: headers,
    });

    return fetch(request).then(response => {
        return response.json().then(client => {
            return client;
        });
    }).catch(error => { return error; });
}


// getPreviewQuestionsApi
export const getPreviewQuestionsApi = (sid) => {

    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(appConfig.siteURL + 'getPreviewQuestions/' + sid, {
        method: "GET",
        headers: headers,
    });

    return fetch(request).then(response => {
        return response.json().then(client => {
            return client;
        });
    }).catch(error => { return error; });
}



//getAllDemographicsApi

export const getAllNotMapDemographicsApi = (sid) => {

    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(appConfig.siteURL + 'getNotMappedDemographics/' + sid, {
        method: "GET",
        headers: headers,
    });

    return fetch(request).then(response => {
        return response.json().then(client => {
            return client;
        });
    }).catch(error => { return error; });
}

//getDemoQueryDataForApplyApi
export const getDemoQueryDataForApplyApi = (demoId, sid) => {

    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(appConfig.siteURL + 'getDemoQueryDataForApply/' + demoId + '/' + sid, {
        method: "GET",
        headers: headers,
    });

    return fetch(request).then(response => {
        return response.json().then(client => {
            return client;
        });
    }).catch(error => { return error; });
}

export const studyDemoMappingApi = (data) => {
    let demoQueryData = JSON.stringify(data);

    const headers = Object.assign({
        'Content-type': 'application/json',
        'Accept': '*/*'
    });
    const request = new Request(appConfig.siteURL + 'studyDemoMappingNew/', {
        method: "POST",
        headers: headers,
        body: demoQueryData

    });

    return fetch(request).then(response => {
        return response.json().then(statusResponse => {
            return statusResponse;
        });
    }).catch(error => {
        return error;
    });
}



export const studyDemoAgeMappingApi = (data) => {
    let demoQueryData = JSON.stringify(data);

    const headers = Object.assign({
        'Content-type': 'application/json',
        'Accept': '*/*'
    });
    const request = new Request(appConfig.siteURL + 'studyDemoAgeMapping/', {
        method: "POST",
        headers: headers,
        body: demoQueryData

    });

    return fetch(request).then(response => {
        return response.json().then(statusResponse => {
            return statusResponse;
        });
    }).catch(error => {
        return error;
    });
}


export const getQueryAndOptionApi = (screenerId, projectId) => {

    const headers = Object.assign({
        'Content-type': 'application/json',
        'Accept': '*/*'
    });
    const request = new Request(appConfig.siteURL + 'getQueryAndOption/' + projectId + '/' + screenerId, {
        method: "GET",
        headers: headers,

    });

    return fetch(request).then(response => {
        return response.json().then(statusResponse => {
            return statusResponse;
        });
    }).catch(error => {
        return error;
    });
}



export const addConstrainstsApi = (data) => {

    let demoQueryData = JSON.stringify(data);

    const headers = Object.assign({
        'Content-type': 'application/json',
        'Accept': '*/*'
    });
    const request = new Request(appConfig.siteURL + 'addConstrainsts', {
        method: "POST",
        headers: headers,
        body: demoQueryData

    });

    return fetch(request).then(response => {
        return response.json().then(statusResponse => {
            return statusResponse;
        });
    }).catch(error => {
        return error;
    });
}



export const removeDemoFromProjectApi = (data) => {

    // console.log(data);
    let demoQueryData = JSON.stringify(data);

    const headers = Object.assign({
        'Content-type': 'application/json',
        'Accept': '*/*'
    });
    const request = new Request(appConfig.siteURL + 'removeDemoFromProject', {
        method: "POST",
        headers: headers,
        body: demoQueryData

    });

    return fetch(request).then(response => {
        return response.json().then(statusResponse => {
            return statusResponse;
        });
    }).catch(error => {
        return error;
    });
}


export const removeRangeOptionsApi = (data) => {
    let rangeOptionData = JSON.stringify(data);

    const headers = Object.assign({
        'Content-type': 'application/json',
        'Accept': '*/*'
    });
    const request = new Request(appConfig.siteURL + 'removeRangeOption/', {
        method: "POST",
        headers: headers,
        body: rangeOptionData

    });

    return fetch(request).then(response => {
        return response.json().then(statusResponse => {
            return statusResponse;
        });
    }).catch(error => {
        return error;
    });
}


//updateQuestionOrderApi
export const updateQuestionOrderApi = (data) => {
    let rangeOptionData = JSON.stringify(data);

    const headers = Object.assign({
        'Content-type': 'application/json',
        'Accept': '*/*'
    });
    const request = new Request(appConfig.siteURL + 'updateQuestionOrder/', {
        method: "POST",
        headers: headers,
        body: rangeOptionData

    });

    return fetch(request).then(response => {
        return response.json().then(statusResponse => {
            return statusResponse;
        });
    }).catch(error => {
        return error;
    });
}

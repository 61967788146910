
const initialState = {
  show: "",
  editable: "",
  item: ""
  }
  


const thirdParty = (state = initialState , action) =>{
    //state.memberSponsees=[];
    switch (action.type) {
  
      case 'SET_THIRD_PARTY_DATA' :
        return {
          ...state,
          thirdPartyList: action.thirdPartyList.result,
          totalpages: action.thirdPartyList.totalPages,
          show: false,
          editable: false,
          item: "",
          result: "",
          vendorsListForStudy: ""
        }    

        case 'SET_THIRD_PARTY' :
        return {
          ...state,
          result: action.result,
        }
        
        case 'SET_THIRD_PARTY_FOR_STUDY_LIST' :
        return {
          ...state,
          vendorsListForStudy: action.result,
        }

        case 'EDIT_THIRD_PARTY_DATA' :
        return {
          ...state,
          item: action.item,
          editable: true
        }  

        case 'INITIALIZE_DATA' :
        return {
          ...state,
          item: "",
          editable: false,
          result: ""
        } 

        case 'REMOVE_THIRD_PARTY' :
        return {
          ...state,
          removeSuccess: action.response.success,
          removeMessage: action.response.message
        }  
        

        case 'EMPTY_VENDOR_PROPS':
        return{
          ...state,
          result :null,
          removeSuccess: null,
          removeMessage: null,
        }

        default:
            return state;
          }
  
  };
  export default thirdParty;
import React, {Component} from 'react';
import {connect} from 'react-redux';

// import 'react-overlay-loader/styles.css';
import './style.css';
import {getServertime} from '../actions/actionsBag/dashboard';
import panelrewardlogo from './panelreward-logo.png';
import profileimage from './profileimage.jpg';
import '../commonStyle/common.css';
import userImg from '../userimage/user1.png';
import { Z_BLOCK } from 'zlib';
import UserProfilePopup from './userProfilePopup';
import $ from 'jquery';
import dash from '../images/menu-slidbar.png';
var a = 0;


class TopHeader extends Component {
  constructor(props){
      super(props)
       
        this.state={
            leftDrowerToggle : true,
            windowSize : window.innerWidth ,
            monthNames : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],

            status: false,
            runningTime: 0,
            currentCount : 0,
            serverHH : 0,
            serverMM : 0,
            timePeriod: "",
         }
        
  }

  componentWillMount(){
    this.props.dispatch(getServertime());
  }


componentDidMount(){

  

$(document).ready( function() {
$('#profileshow').click( function(e) {
    e.preventDefault(); // stops link from making page jump to the top
    e.stopPropagation(); // when you click the button, it stops the page from seeing it as clicking the body too
    $('#userpopup').toggle();
});

$('#logoutpart1').click( function(e) {
  // e.preventDefault(); // stops link from making page jump to the top
  e.stopPropagation(); // when you click the button, it stops the page from seeing it as clicking the body too
  // $('#userpopup').toggle();
});

$('body').click( function() {
    $('#userpopup').hide();
});

});

}



componentWillUnmount() {
  // use intervalId from the state to clear the interval
  clearInterval(this.state.intervalId);
}



toggleLeftdrower(){
  this.setState({
    leftDrowerToggle : !this.state.leftDrowerToggle
  });
  this.changeStyle();
}




changeStyle(){
  if(this.state.leftDrowerToggle === true){
    a =1;
    // $('#headers-all').removeClass("router-header1");
    // $('#all-tbl').removeClass("table-st");
    // $('#headers-all').addClass("router-header1-close");
    // $('#all-tbl').addClass("table-st-close");
    
    document.getElementById("leftsubcontainer").style.width = "0px";
    // document.getElementById("headers-all").style.paddingLeft = "15%";
    //   document.getElementById("headers-all").style.paddingRight = "15%";
      

    if( this.state.windowSize>1380){
      let y =document.getElementsByClassName("all-header");
      for(let a = 0; a< y.length; a++){
        y[a].style.paddingLeft = "15%";
        y[a].style.paddingRight = "15%";
      }
      let x =document.getElementsByClassName("table-st");
      for(let a = 0; a< x.length; a++){
        x[a].style.paddingLeft = "15%";
        x[a].style.paddingRight = "15%";
      }
    }else if( 1080 < this.state.windowSize < 1380){
      let y =document.getElementsByClassName("all-header");
      for(let a = 0; a< y.length; a++){
        y[a].style.paddingLeft = "8%";
        y[a].style.paddingRight = "8%";
      }
      let x =document.getElementsByClassName("table-st");
      for(let a = 0; a< x.length; a++){
        x[a].style.paddingLeft = "8%";
        x[a].style.paddingRight = "8%";
      }
      let z =document.getElementsByClassName("list-style");
        for(let a = 0; a<z.length; a++){
         z[a].style.marginRight = "0%";
        }
    }
    // container-padd
    document.getElementById("routercontainer").style.marginLeft = "0px";
    
     }else{
      // document.getElementById("headers-all").style.paddingLeft = "10%";
      // document.getElementById("headers-all").style.paddingRight = "10%";
      if( this.state.windowSize>1380){
        let y =document.getElementsByClassName("all-header");
        for(let a = 0; a< y.length; a++){
          y[a].style.paddingLeft = "10%";
          y[a].style.paddingRight = "10%";
        }
        let x =document.getElementsByClassName("table-st");
        for(let a = 0; a<x.length; a++){
          x[a].style.paddingLeft = "10%";
          x[a].style.paddingRight = "10%";
        }
        
      }else if( 1080 < this.state.windowSize < 1380){
        let y =document.getElementsByClassName("all-header");
        for(let a = 0; a< y.length; a++){
          y[a].style.paddingLeft = "2%";
          y[a].style.paddingRight = "2%";
        }
        let x =document.getElementsByClassName("table-st");
        for(let a = 0; a<x.length; a++){
          x[a].style.paddingLeft = "2%";
          x[a].style.paddingRight = "2%";
        }
        let z =document.getElementsByClassName("list-style");
        for(let a = 0; a<z.length; a++){
         z[a].style.marginRight = "0%";
        }
      }
      
      // $('#headers-all').removeClass("router-header1-close");
      // $('#all-tbl').removeClass("table-st-close");
      // $('#headers-all').addClass("router-header1");
      // $('#all-tbl').addClass("table-st");
     
      // let x =document.getElementsByClassName("router-header1");
      // for(let a = 0; a<x.length; a++){
      //   x[a].style.paddingLeft = "2%";
      //   x[a].style.paddingRight = "2%";
  
      // }
      document.getElementById("leftsubcontainer").style.width = "225px";
      document.getElementById("routercontainer").style.marginLeft = "225px";
      // document.getElementById("leftmenubar").style.display ="block";
     }

    //  if(a === 0 && winSize < 1024){
    //   $(".table-st").add("container-padd-1");
    //   $(".router-header1").addClass("container-padd-1");
      
    // }else{
    //   $(".table-st").addClass("container-padd");
    //   $(".router-header1").addClass("container-padd");
    // }
}




componentWillReceiveProps( nextProps){
  if(nextProps.serverTimeData.date){

    let hh = nextProps.serverTimeData.date.split('T')[1].split(':')[0];
    let mm = nextProps.serverTimeData.date.split('T')[1].split(':')[1];
    let timePeriod ="";
    if(hh < 12){
      timePeriod = 'am'
    }else{
      timePeriod = 'pm'
    }
    this.setState ({ serverHH : hh, serverMM : mm, timePeriod :  timePeriod});
    clearInterval(this.interval);
    this.interval = setInterval(() => { 
        if(this.state.serverMM === 60){
          let hh =  parseInt(this.state.serverHH) + 1;
          if(hh<10)
          hh = '0'+hh;

          if(hh < 12){
            this.setState({ timePeriod : 'am'});
          }else{
            this.setState({ timePeriod : 'pm'});
          }

          if(parseInt(this.state.serverHH) === 24){
            this.props.dispatch(getServertime());
            this.setState({ serverMM: '00', serverHH : '00' });
          }else{
            this.setState({ serverMM: '00', serverHH : hh });
          }
          
        }else{
          let mm = parseInt(this.state.serverMM) + 1;
          if(mm<10)
          mm = '0'+mm
          this.setState({serverMM: mm});
        }
    }, 60000);

   
  }
}

componentWillUnmount() {
  clearInterval(this.interval);
}
 
  render(){

    var d = new Date();
    let month = this.state.monthNames[d.getMonth()];
    let year  = d.getFullYear();
    let day = d.getDate();   
    
    if( this.props.serverTimeData && this.props.serverTimeData.date){
      
      var dd = new Date( this.props.serverTimeData.date );
      month = this.state.monthNames[dd.getMonth()];
      year  = dd.getFullYear();
      day   = dd.getDate();
      if(day <10)
      day = '0'+day;
    }
 
    return (
        <div className="mainheader shadow">
          <div className = "containerfluid">
            <div className = "mainheader-row align-items-center">

              <div className = "mainheader-col-left flex-show" >
                <div className = "mainheader-slide-menu-btn" id="leftdrower"onClick={() => this.toggleLeftdrower()} ><img  src={dash} height="25px" width="25px"/></div>
                <div><a className="mainheader-logo"><img  src={panelrewardlogo}/></a></div>
              </div>

              <div className = "mainheader-col-center" >
                <ul className="mainheader-time-day">
                  <li className="mainheader-text-right">
                    <p>
                      <span className="color-secondary">{month}</span>
                      <small className="sm-color">{year}</small>
                    </p>
                    <h2 className="head-2">{day}</h2>
                  </li>
                  <li className="mainheader-text-right">
                    <h2><span><sup></sup></span></h2>
                    <p>
                      <span className="color-secondary" > Server Timing</span>
                      <small className="left-align sm-color">{this.state.serverHH+':'+ this.state.serverMM + ' '+this.state.timePeriod }</small>
                    </p>
                  </li>
                </ul>
              </div>

              {/* <div className = "mainheader-col-right" >
                <div className = "mainheader-dropdown mainheader-d-inline-block show">
                  <button >
                    <figure className="mainheader-user-image">
                      <img src={profileimage} />
                    </figure>
                  </button>
                </div>
              </div> */}

              <div className="margin-right1 user-profile-main" id="profileshow">
                    <div className="user-img"><div><img src={userImg}/></div></div>
                    <div>
                      {/* <h6>
                        <small>Welcome,<div className="right-angle-arrow"></div></small>
                        <span>Admin</span>
                      </h6> */}
                      <ul>
                        <li className="color-secondary arrow-after" style={{height: "15px"}}><small style={{fontSize:"12px"}}>Welcome,</small><div className="right-angle-arrow"></div></li>
                        {/* <li className="black-color">{localStorage.getItem('admin_name')}</li> */}
                        <span className="black-color">{localStorage.getItem('admin_name')}</span>
                      </ul>
                   </div>
              </div>
                <div>
                    {<UserProfilePopup/>}
                </div>
            </div>
          </div>
        </div>
     );
  }
}


const mapStateToProps=(state)=>{
  return{
    serverTimeData : state.dashboard.serverTimeData
  }
}

export default connect(mapStateToProps)(TopHeader);

import React, { Component } from 'react';
import { getDemoMappedList, getAllNotMapDemographics, removeDemoFromProject, emptyMapDemoProps } from '../../../actions/actionsBag/demoMapping';
import { alertMessage, emptyDialogdata } from '../../../alertsDialog/Action';
import { connect, } from 'react-redux';
import { Link } from 'react-router-dom';
// import Pagination from "react-js-pagination";
import deletIcon from '../../../images/delete.png';
import editpencil from '../../../images/edit-pencil.png';
import add from '../../../images/add.png';
import $ from 'jquery';

class AddDemoMapList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: "",
      currentPage: 1,
      studyId: "",
      demoMappList: [],
      demoNotMappList: [],
      totalPage: 0,
      showloader: false
    }
    this.handlePageChange = this.handlePageChange.bind(this);
    this.softDeleteDemoMapped = this.softDeleteDemoMapped.bind(this);
  }


  componentWillReceiveProps(nextProps) {



    if (nextProps.demoDeleteSuccess === true && nextProps.demoDeleteMessage) {
      // alert("delete success");
      this.props.dispatch(emptyMapDemoProps());
      let alertData = {};
      alertData.type = "alert";
      alertData.message = nextProps.demoDeleteMessage;
      this.props.dispatch(alertMessage(alertData));
      let studyId = localStorage.getItem("study_Id");
      this.props.dispatch(getDemoMappedList(studyId, 1));
      this.props.dispatch(getAllNotMapDemographics(studyId));


    } else if (nextProps.demoDeleteMessage) {
      // alert("delete fail")
      this.props.dispatch(emptyMapDemoProps());
      let alertData = {};
      alertData.type = "alert";
      alertData.message = nextProps.demoDeleteMessage;
      this.props.dispatch(alertMessage(alertData));

    }

    if (nextProps.confirmDialog === true) {
      this.props.dispatch(emptyDialogdata());
      let data = {}
      data.projectId = nextProps.confirmData;

      let demographicData = localStorage.getItem("demographicsData");
      // alert(nextProps.confirmData);
      let actualData = JSON.parse(demographicData);
      data.queryType = actualData.queryType;
      data.demographicId = actualData.demographicId;
      data.queryId = actualData.queryId;
      this.props.dispatch(removeDemoFromProject(data));
    }

    // if(nextProps.roleDeleteSuccess === true && nextProps.roleDeleteMessage){
    //   let alertData ={};
    //   alertData.type ="alert";
    //   alertData.message =  nextProps.roleDeleteMessage;
    //   this.props.dispatch(alertMessage(alertData));
    //   //this.props.dispatch(getAllRolesByAdminByPage(1));
    //   this.setState({currentPage : 1});
    //   //this.props.dispatch(blankResRoles());
    // }else if(nextProps.roleDeleteMessage){
    //     let alertData ={};
    //     alertData.type ="alert";
    //     alertData.message =  nextProps.roleDeleteMessage;
    //     this.props.dispatch(alertMessage(alertData));
    //     //this.props.dispatch(blankResRoles());
    // }

    if (nextProps.demoMappedListSuccess && nextProps.demoMappedListData.length > 0) {
      this.setState({
        demoMappList: nextProps.demoMappedListData,
        showloader: true,
      });
    } else {
      this.setState({
        showloader: true,
      });
    }

    if (nextProps.demoNotMappedListSuccess && nextProps.demoNotMappedListData.length > 0) {
      this.setState({
        demoNotMappList: nextProps.demoNotMappedListData,
        showloader: true,
      });
    }
  }

  componentWillMount() {
    let studyId = localStorage.getItem("study_Id");
    this.setState({ studyId: studyId });
    this.props.dispatch(getDemoMappedList(studyId, 1));
    this.props.dispatch(getAllNotMapDemographics(studyId));
  }

  handlePageChange(pageNumber) {

    this.setState({ currentPage: pageNumber, demoMappList: [] });

    //this.props.dispatch(getAllRolesByAdminByPage(pageNumber));
  }


  softDeleteDemoMapped(list) {
    localStorage.setItem("demographicsData", JSON.stringify(list));
    let alertData = {};
    alertData.type = "confirmalert";
    alertData.message = "Are you sure to delete this item ?";
    alertData.deleteId = list.studyId;
    this.props.dispatch(alertMessage(alertData));
  }

  tableZoom() {
    $(".alltablestruct").toggleClass("zoom-me");
    // $("#routercontainer").toggleClass("router-containter-pd");
    // document.getElementById("routercontainer").style.padding="0px";

  }

  render() {

    let roleItems = "";
    let rangeItems = "";
    let notDemoMap = "";
    if (this.state.demoNotMappList && this.state.demoNotMappList.length > 0) {

      notDemoMap = this.state.demoNotMappList.map((list, i) => {

        return <tbody> <tr>
          <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom">{this.state.demoMappList[0].length + this.state.demoMappList[1].length + i + 1}</td>
          <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">
            <Link to={{
              pathname: '/apply-demographics',
              state: {
                applyDemoData: list,
              }
            }} >{list.name}</Link></td>

          <td className=" tbl-padd-top tbl-padd-left tbl-padd-bottom">
            <div className="flex-show">

            </div>
          </td>
        </tr>
        </tbody>
      });
    } else {
      notDemoMap = "";
    }



    if (this.state.demoMappList && this.state.demoMappList.length > 0) {

      roleItems = this.state.demoMappList[0].map((list, i) => {

        return <tbody> <tr style={{ backgroundColor: "#5d2cb4", color: "white" }}>
          <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom">{i + 1}</td>
          <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">
            <Link to={{
              pathname: '/apply-demographics',
              state: {
                applyDemoData: list,
              }

            }} style={{ color: "white" }} >{list.name}</Link>
          </td>

          <td className=" tbl-padd-top tbl-padd-left tbl-padd-bottom">
            <div className="flex-show">
              <div className="icons" onClick={this.softDeleteDemoMapped.bind(this, list)}>
                <div className="delete" >
                  <img src={deletIcon} />
                </div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      });


      rangeItems = this.state.demoMappList[1].map((list, i) => {

        return <tbody> <tr style={{ backgroundColor: "#5d2cb4", color: "white" }}>
          <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom">{this.state.demoMappList[0].length + i + 1}</td>
          <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">
            <Link to={{
              pathname: '/apply-demographics',
              state: {
                applyDemoData: list,
              }

            }} style={{ color: "white" }} >{list.name}</Link>
          </td>

          <td className=" tbl-padd-top tbl-padd-left tbl-padd-bottom">
            <div className="flex-show">
              <div className="icons" >
                <div className="delete" onClick={this.softDeleteDemoMapped.bind(this, list)}>
                  <img src={deletIcon} />
                </div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      });



    } else {
      roleItems = "";
    }


    let showTable = "";
    if ((this.state.demoMappList && this.state.demoMappList.length > 0) || (this.state.demoNotMappList && this.state.demoNotMappList.length > 0)) {
      showTable = <table className="tbl-new-est" >
        <thead>
          <tr>
            <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">S.No.</th>
            <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Demographic Name</th>
            <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Action</th>
          </tr>
        </thead>
        {roleItems}
        {rangeItems}
        {notDemoMap}
      </table>
    } else if (this.state.showloader === true) {
      showTable = <div className="datanotfound"> No record found</div>
    } else {
      showTable = <div className="spiner-data"><div className="tblspinner"></div></div>
    }



    return (
      <div className="list-style">
        <div className="table-st">
          <div className="alltablestruct forms-rd-shadow">
            {/* serachbox and stausbtn */}
            <div id="search-staus">

              <div className="flex-show  tbl-h-font">
                Demographic's List
                            </div>
              <div style={{ marginLeft: "auto" }}>

                <Link to="previewquestions"><button style={{ marginTop: "0px", marginRight: "30px" }} className="create-btn create-btn-color" ><span> Preview Questions</span></button></Link>
              </div>
              <div className="flex-show tbl-zoom" >
                <span onClick={this.tableZoom} className="full-screen mr-rhs-20 mr-top-10"> </span>
              </div>
            </div>
            {/* serachbox and stausbtn */}

            {/* table */}
            <div style={{ marginTop: "20px", overflowX: "auto" }}>

              {showTable}
            </div>
            {/* table */}

            {/* <div id="pageinationstyle">
                       
                      <Pagination
                          activePage={this.state.currentPage}
                          itemsCountPerPage={4}
                          totalItemsCount={this.state.totalPage}
                          pageRangeDisplayed={4}
                          onChange={this.handlePageChange}
                        />
                        </div> */}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    demoMappedListSuccess: state.demoMapped.demoMappedListSuccess,
    demoMappedListData: state.demoMapped.demoMappedListData,
    demoNotMappedListSuccess: state.demoMapped.demoNotMappedListSuccess,
    demoNotMappedListData: state.demoMapped.demoNotMappedListData,
    demoDeleteSuccess: state.demoMapped.demoDeleteSuccess,
    demoDeleteMessage: state.demoMapped.demoDeleteMessage,
    confirmDialog: state.alertsDialog.confirmDialog,
    confirmData: state.alertsDialog.confirmData
  }
}

export default connect(mapStateToProps)(AddDemoMapList);


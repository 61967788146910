import React, { Component }  from 'react';
import  '../../commonStyle/common.css';
import $ from 'jquery';
import {connect} from 'react-redux';
import Pagination from "react-js-pagination";
import deletIcon from '../../images/delete.png';
import invoiceDelbtn from '../../images/close32.png';
import { Link } from 'react-router-dom';
import { alertMessage,emptyDialogdata  }  from '../../alertsDialog/Action';
import { getAllInvoiced , emptyResponseInvoiced, approvedAllSelectedInvoiced, sendInvoiceEmail, deleteInvoice} from '../../actions/actionsBag/invoiceAction';
  class InvoiceListTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
          currentPage: 1,
          totalInvoiced : 0,
          selectedMultiselectIds : [],
          showloader: false
        }
        this.onChangeMultiselect = this.onChangeMultiselect.bind(this);
        this.openCreateInvoicePage = this.openCreateInvoicePage.bind(this);
        this.handlePageChange =this.handlePageChange.bind(this);
        this.onApproveAllClicked = this.onApproveAllClicked.bind(this);
        this.onSendInvoiceAllClicked = this.onSendInvoiceAllClicked.bind(this);
      }

      componentWillMount(){
        this.props.dispatch(getAllInvoiced(this.state.currentPage));
      } 

      componentWillReceiveProps(nextProps){
        if(nextProps.invoiceDeleteSuccess === true){
          let alertData ={};
          alertData.type ="alert";
          alertData.message = "This invoice deleted successfully.";
          this.props.dispatch(alertMessage(alertData));
          this.props.dispatch(emptyResponseInvoiced());
        //  alert("Invoice Deleted successfully.");
        }else if(nextProps.invoiceDeleteSuccess === false){
          this.props.dispatch(emptyResponseInvoiced());
          let alertData ={};
          alertData.type ="alert";
          alertData.message = "This invoice not deleted, please contact to support!";
          this.props.dispatch(alertMessage(alertData));
        }
    
        if(nextProps.invoicedList && nextProps.invoicedList.length >0){
          this.setState({
            totalInvoiced : nextProps.totalInvoice,
            showloader: false,
          });
        }else{
          this.setState({
            totalInvoiced : 0,
            showloader: true,
          });
        }
      }
    
  


 


  tableZoom(){
    $(".alltablestruct").toggleClass("zoom-me");
    // $("#routercontainer").toggleClass("router-containter-pd");
    // document.getElementById("routercontainer").style.padding="0px";

  }


  openCreateInvoicePage(){
    this.props.history.push("/create-invoice");
   }
 
   onApproveAllClicked(e){
 
     if(this.state.selectedMultiselectIds.length > 0){
 
       let myDataToServer = [];
       for(let i = 0; i < this.state.selectedMultiselectIds.length;i++){
          let data = {};
          data.invoiceID = this.state.selectedMultiselectIds[i];
          myDataToServer.push(data);
       }
      this.props.dispatch(approvedAllSelectedInvoiced(myDataToServer));
     }else{
       alert("Please choose minimum 1 invoice to approve!");
     }
 
   }
 
   
   onSendInvoiceAllClicked(e){
        
     if(this.props.invoicedList && this.props.invoicedList.length >0){
 
       let myInvoices = [];
       for(let i = 0; i < this.state.selectedMultiselectIds.length;i++){
      //   is_finel_invoice
      let found = this.props.invoicedList.find(element => element.invoiceID  === this.state.selectedMultiselectIds[i]);
     
      if(found){
        let isApproved = found.is_finel_invoice;
        if(isApproved === 1){
          let data = {};
          data.invoiceID = this.state.selectedMultiselectIds[i];
         myInvoices.push(data);
        }
       }
      }
 
      if(myInvoices.length > 0){
          //this.props.dispatch(sendInvoiceEmail(myInvoices));
      }
      else{
       alert("Please choose minimum 1 approved invoice to send invoice on email!");
      }
     }
 
 
 
     }
 
 
 
   onChangeMultiselect(e){
   
     //selectedMultiselectIds
     
     let found = this.state.selectedMultiselectIds.find(element => element  === e.target.value);
   
      if(found){
        // remove here
        let currentIds = this.state.selectedMultiselectIds.filter(data => data != e.target.value);
        this.setState({ selectedMultiselectIds : currentIds });
      }
      else{
            let currentIds = this.state.selectedMultiselectIds;
            currentIds.push(e.target.value);
            this.setState(
              {
               selectedMultiselectIds : currentIds
             }
            );
      }
   }
   
   deleteInvoice(invoiceID){
     this.props.dispatch(deleteInvoice(invoiceID));
   }
 
   handlePageChange = page => {
     this.setState({
       currentPage: page
     });
     this.props.dispatch(getAllInvoiced(page));
   };
   
   

    render(){
      let sr = (this.state.currentPage * 10)-10;
      let showInvoicedList ="";
      let showApproveBtn = "";
      if(this.props.invoicedList && this.props.invoicedList.length >0){
        showApproveBtn =<button className="list-btn" id="allconstraints" style={{marginTop: "10px"}} onClick = {this.onApproveAllClicked}>Approve All</button>
        // showSendEmailBtn =<button id="allconstraints" onClick = {this.onSendInvoiceAllClicked}>Send Invoice All</button> 
        showInvoicedList= this.props.invoicedList.map((item, i) => {
          let showBillFinal = "";
          let showBillStatus = "";
          let showBillAction = "";
          let showViewButton = "";
          let dateEmailOn = "";
  
          let showCheckBoxToOperate = "";
  
  
          if( item.is_finel_invoice == "1" ){
            showBillFinal = "YES";
            showBillStatus = <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Approved</td>
            showBillAction = <td><Link  to={{
              pathname: '/reciept',
              state: {
                invoiceID : item.invoiceID
              }
              }}> <div className="eye-st" style={{margin: "5px"}}><div className="eye"></div></div></Link></td>;
           
            showViewButton =
            <td style={{padding : "10px"}}  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom"><Link  to={{
              pathname: '/invoice-bill',
              state: {
               invoiceID : item.invoiceID
              }
              }}><span>view</span></Link></td>
  
  
            if(item.mail_sent_date != null ){
              if(item.is_mail_sent === 1){
              showBillStatus = <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom" >Email Sent</td>
              }
              else{
                showBillStatus = <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom" >Email Not Sent</td>
              }
              dateEmailOn =   item.mail_sent_date.split("T")[0];
            }
          }else{
            showBillFinal = "NO";
            showBillStatus = <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Not Approved</td>
            showBillAction = <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">
              {/* <button id="createinvoicebtn" onClick={() => this.deleteInvoice(item.invoiceID)}>Cancel</button> */}
              <div style={{display: "flex"}}>
              <div className="eye-st" style={{margin: "5px",display: "flex",justifyContent: "center", alignItems: "center"}}  onClick={() => this.deleteInvoice(item.invoiceID)}><div><img src={invoiceDelbtn} height="18px" width="18px" style={{marginTop: "3px"}}/></div></div>
              <Link  to={{
              pathname: '/reciept',
              state: {
                invoiceID : item.invoiceID
              }
              }}> <div className="eye-st" style={{margin: "5px"}}><div className="eye"></div></div></Link>
              </div>
            
              </td>
            showViewButton = <td style={{padding : "10px"}} className="textcenter">
              <Link  to={{
              pathname: '/reciept',
              state: {
                invoiceID : item.invoiceID
              }
              }}>
            viewProjects</Link></td>
          }
  
  
            
          let found = this.state.selectedMultiselectIds.find(element => element  === item.invoiceID);
            if(found){
              showCheckBoxToOperate =  <td  className="textcenter">
                {/* <input  style={{marginLeft: "0px"}} type = "checkbox" checked = {true} name  = {""} key={item.invoiceID} value ={item.invoiceID}  onChange = {this.onChangeMultiselect} /> {} */}
                      <div class="form-group  rltv-pos">
                                <input type="checkbox" id={item.invoiceID}  key={item.invoiceID} value ={item.invoiceID} onChange = {this.onChangeMultiselect}  checked={this.state.checkMe} />
                                <label for={item.invoiceID}></label>
                            </div>
                </td>
           
            }
            else{
              showCheckBoxToOperate =  <td  className="textcenter">
                {/* <input  style={{marginLeft: "0px"}}  type = "checkbox" checked = {false} name  = {""} key={item.invoiceID} value ={item.invoiceID}  onChange = {this.onChangeMultiselect} /> {} */}
                    <div class="form-group   rltv-pos">
                                <input type="checkbox" id={item.invoiceID}  key={item.invoiceID} value ={item.invoiceID} onChange = {this.onChangeMultiselect} checked={this.state.checkMe} />
                                <label for={item.invoiceID}></label>
                            </div>
                </td>
           
            }
  
            return <tr>
                  <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{ sr+i+1 }</td> 
                  {/* {showCheckBoxToOperate} */}
                  <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{dateEmailOn}</td>
                  <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{item.study_name}</td>
                  <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{item.client_name}</td>
                  <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{ (item.is_clone == '1') ? 'yes' : 'no'}</td>
                  
                  {/* <td className="textcenter">{item.cpc}</td> */}
                  {showBillStatus}
                  {showBillAction}
                  {/* {showViewButton} */}
                </tr>
                }
              );
            } else{

            }

            let showTable = '';
            if(this.props.invoicedList && this.props.invoicedList.length >0){
              showTable =    <table className="tbl-new-est" >
              <thead >
                  <tr>
                      <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">S.no.</th>
                      {/* <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Select</th> */}
                      <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">E-mailed On</th>
                      <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Project Name</th>
                      <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Client Name</th>
                      <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Has Clone</th>
                      <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Bill Status</th>
                      <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Action</th>
                  </tr>
                  </thead>
                 {showInvoicedList}
              </table>
            } else if(this.state.showloader === true ){
              showTable =<div className="datanotfound"> No record found</div>
              showApproveBtn = "";
            } else{
              showTable =<div className="spiner-data"><div className="tblspinner"></div></div>
            }


        return(
            <div className="list-style">
                <div className="table-st">
                    <div className="alltablestruct forms-rd-shadow">
                         {/* serachbox and stausbtn */}
                        <div id="search-staus">

                            <div className="flex-show  tbl-h-font">
                                Invoice List
                              
                            </div>
                            
                            <div className="flex-show tbl-zoom" >
                            <span onClick={this.tableZoom} className="full-screen mr-rhs-20 mr-top-10"></span>
                            </div>
                        </div>
                        {/* serachbox and stausbtn */}
                            {/* table */}
                            {/* {showApproveBtn} */}
                            {/* <div class="form-group   rltv-pos" style={{marginTop: "10px"}}>
                                <input type="checkbox" id="check-all" onChange = {this.selectAll}  />
                                <label for="check-all"></label>
                            </div> */}
                            <div style={{marginTop: "20px", overflowX: "auto"}}>
                            
                                  {showTable}
                              

                            </div>
                            {/* table */}
                   
                      <div id="pageinationstyle">
                       
                      <Pagination
                          activePage={this.state.currentPage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalInvoiced}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                        />
                        </div>
                    
                    </div>
                </div>
            </div>
    )}
}

const mapStateToProps = (state) => {
  return {
    invoicedList : state.invoice.invoicedList,
    totalInvoice : state.invoice.totalInvoice,
    invoiceDeleteSuccess : state.invoice.invoiceDeleteSuccess,
  }
}

export default connect(mapStateToProps)(InvoiceListTable);

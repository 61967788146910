import React, { Component } from 'react';
import { connect } from 'react-redux';
import goback from '../../../images/goback.png';
import { Link } from 'react-router-dom';
import { alertMessage } from '../../../alertsDialog/Action';
import $ from 'jquery';
import deletIcon from '../../../images/delete.png';

export default class ApplyDemoQuery extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedMultiselectIds: [],
      queryType: "",
      queryId: "",
      allTextData: "",
      rangeFrom: "",
      rangeTo: "",
      rangeOptionsData: [],
    }
    this.onInputTextChanged = this.onInputTextChanged.bind(this);
    this.onChangeMultiselect = this.onChangeMultiselect.bind(this);
    this.handleValidation1 = this.handleValidation1.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.onInputTextRangeFromChanged = this.onInputTextRangeFromChanged.bind(this);
    this.onInputTextRangeToChanged = this.onInputTextRangeToChanged.bind(this);
    this.removeRangeOptions = this.removeRangeOptions.bind(this);
  }

  componentWillMount() {

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.demoQueryData && nextProps.demoQueryData.result && nextProps.demoQueryData.result.length > 0) {
      this.setState({ queryId: nextProps.demoQueryData.result[0]._id, queryType: nextProps.demoQueryData.result[0].queryType });
    }
    if (nextProps.demoQueryData && nextProps.demoQueryData.result && nextProps.demoQueryData.result[0].queryType === "radio" && nextProps.demoQueryData.mappingData.length > 0) {
      let selectedOptions = nextProps.demoQueryData.mappingData[0].optionId.split(",");
      this.setState({ selectedMultiselectIds: selectedOptions });
    } else if (nextProps.demoQueryData && nextProps.demoQueryData.result && nextProps.demoQueryData.result[0].queryType === "text" && nextProps.demoQueryData.mappingData.length > 0) {
      let allTextData = nextProps.demoQueryData.mappingData[0].allText;
      this.setState({ allTextData: allTextData });
    } else if (nextProps.demoQueryData && nextProps.demoQueryData.result && nextProps.demoQueryData.result[0].queryType === "range") {
      let mappingData = nextProps.demoQueryData.mappingData;
      this.setState({ rangeOptionsData: mappingData });
    }
    //mappingData


    if (nextProps.userMailResSuccess === true && nextProps.userMailResSuccess != undefined && nextProps.userMailResMessage != undefined) {
      if (nextProps.userMailResMessage) {
        let alertData = {};
        alertData.type = "alert";
        alertData.message = nextProps.userMailResMessage;
        this.props.dispatch(alertMessage(alertData));
        this.setState({ user_mail: "", user_text: "", mail_subject: "" });

        //  this.props.dispatch(blankResUser());
      }
    } else if (nextProps.userMailResMessage) {
      let alertData = {};
      alertData.type = "alert";
      alertData.message = nextProps.userMailResMessage;
      this.props.dispatch(alertMessage(alertData));
      //  this.props.dispatch(blankResUser()); 
    }
  }


  onInputTextRangeFromChanged(e) {
    var decimal = /^[0-9]+$/;
    if (e.target.value === "") {
      this.setState({ rangefromerror: "Can't leave empty !" });
      document.getElementById("rangefromerror").style.display = "block";
    } else if (!e.target.value.match(decimal) && e.target.value === "") {
      this.setState({ rangefromerror: "Please enter a whole number" });
      document.getElementById("rangefromerror").style.display = "block";
    } else if (isNaN(e.target.value) || !e.target.value.match(decimal)) {
      this.setState({ rangefromerror: "Please enter a whole number" });
      document.getElementById("rangefromerror").style.display = "block";
    } else {
      document.getElementById("rangefromerror").style.display = "none";
    }
    this.setState({
      rangeFrom: e.target.value
    });

  }


  onInputTextRangeToChanged(e) {
    var decimal = /^[0-9]+$/;
    if (e.target.value === "") {
      this.setState({ rangetorror: "Can't leave empty !" });
      document.getElementById("rangetorror").style.display = "block";
    } else if (!e.target.value.match(decimal) && e.target.value === "") {
      this.setState({ rangetorror: "Please enter a whole number" });
      document.getElementById("rangetorror").style.display = "block";
    } else if (isNaN(e.target.value) || !e.target.value.match(decimal)) {
      this.setState({ rangetorror: "Please enter a whole number" });
      document.getElementById("rangetorror").style.display = "block";
    } else {
      document.getElementById("rangetorror").style.display = "none";
    }

    this.setState({
      rangeTo: e.target.value
    });

  }




  onInputTextChanged(e) {
    if (e.target.value === "") {
      this.setState({ texterror: "Can't leave empty !" });
      document.getElementById("texterror").style.display = "block";
    } else {
      document.getElementById("texterror").style.display = "none";
    }

    this.setState({
      allTextData: e.target.value
    });
  }



  onChangeMultiselect(e) {

    //selectedMultiselectIds

    let found = this.state.selectedMultiselectIds.find(element => element === e.target.value);

    if (found) {
      // remove here
      let currentIds = this.state.selectedMultiselectIds.filter(data => data != e.target.value);
      this.setState(
        {
          selectedMultiselectIds: currentIds
        }
      );
    }
    else {
      let currentIds = this.state.selectedMultiselectIds;
      currentIds.push(e.target.value);
      this.setState(
        {
          selectedMultiselectIds: currentIds
        }
      );
    }
  }

  handleValidation1() {

    let valid = true;

    if (this.state.allTextData === "") {
      valid = false;
      this.setState({ texterror: "Please enter question comment" });
      document.getElementById("texterror").style.display = "block";
      return valid;
    }
    return valid;
  }


  handleValidation() {

    var decimal = /^[0-9]+$/;
    let valid = true;
    if (this.state.rangeFrom === "") {
      valid = false;
      this.setState({ rangefromerror: "Can't leave empty !" });
      document.getElementById("rangefromerror").style.display = "block";
      return valid;
    } else if (!this.state.rangeFrom.match(decimal) && this.state.rangeFrom === "") {
      valid = false;
      this.setState({ rangefromerror: "Please enter a whole number" });
      document.getElementById("rangefromerror").style.display = "block";
      return valid;
    } else if (isNaN(this.state.rangeFrom) || !this.state.rangeFrom.match(decimal)) {
      valid = false;
      this.setState({ rangefromerror: "Please enter a whole number" });
      document.getElementById("rangefromerror").style.display = "block";
      return valid;
    }
    let rangeFrom = parseInt(this.state.rangeFrom);
    let rangeTo = parseInt(this.state.rangeTo);
    if (this.state.rangeTo === "") {
      valid = false;
      this.setState({ rangetorror: "Can't leave empty !" });
      document.getElementById("rangetorror").style.display = "block";
      return valid;
    } else if (!this.state.rangeTo.match(decimal) && this.state.rangeTo === "") {
      valid = false;
      this.setState({ rangetorror: "Please enter a whole number !" });
      document.getElementById("rangetorror").style.display = "block";
      return valid;
    } else if (isNaN(this.state.rangeTo) || !this.state.rangeTo.match(decimal)) {
      valid = false;
      this.setState({ rangetorror: "Please enter a whole number !" });
      document.getElementById("rangetorror").style.display = "block";
      return valid;
    } else if (rangeFrom > rangeTo) {
      valid = false;
      this.setState({ rangetorror: "Please enter rangeTo is grater than rangeFrom !" });
      document.getElementById("rangetorror").style.display = "block";
      return valid;
    }
    return valid;
  }


  createMappingWithScreener(e) {
    e.preventDefault();
    let data = {};


    data.studyId = this.state.projectId;
    data.demographicId = this.state.screenerId;

    data.queryId = this.state.queryId;

    let queryType = this.state.queryType;

    if (queryType === 'text') {

      if (this.handleValidation1()) {
        data.allText = this.state.allTextData;
        data.lang_code = this.state.languageCode;
        data.lang_code = this.state.languageCode;
        data.queryId = this.state.queryId
        this.props.studyDemoMapping(data);
      }

    } else if (queryType === 'range') {


      if (this.handleValidation()) {
        data.to = this.state.rangeTo;
        data.from = this.state.rangeFrom;
        this.props.studyDemoAgeMapping(data);
      }
    }
    else {


      if (this.state.selectedMultiselectIds === undefined || this.state.selectedMultiselectIds.length <= 0) {
        alert("Please add to questions to apply demographics.");
        return;
      }


      data.optionId = this.state.selectedMultiselectIds;
      data.lang_code = this.state.languageCode;
      data.queryId = this.state.queryId
      this.props.studyDemoMapping(data);
    }
  }

  removeRangeOptions(optionlist) {
    let data = {};
    data.demographicId = optionlist.demographicId;
    data.queryId = optionlist.queryId;
    data.studyId = optionlist.studyId;
    data.ageFrom = optionlist.ageFrom;
    data.ageTo = optionlist.ageTo;
    this.props.removeRangeOptions(data);
  }

  render() {
    let questionsData = "";
    let rangeOptionsData = "";
    let showTable = "";
    if (this.props.demoQueryData && this.props.demoQueryData.result && this.props.demoQueryData.result.length > 0) {
      questionsData = this.props.demoQueryData.result.map((list, i) => {
        let optionData = "";

        if (list.queryType === "radio") {
          optionData = list.options.map((item, i) => {
            let found = this.state.selectedMultiselectIds.find(element => element === item._id);
            if (found) {
              return <div class="form-group mr-right-20  rltv-pos">
                <input type="checkbox" id={item.optionText} checked={true} name={item.optionText} key={item._id} value={item._id} onChange={this.onChangeMultiselect} />
                <label for={item.optionText}>{item.optionText}</label>
              </div>

              // <div style={{color:"black"}} ><br/>
              //         <div id="applyscreenform"><input  type = "checkbox" checked = {true} name  = {item.optionText} key={item._id} value ={item._id}  onChange = {this.onChangeMultiselect} /> {item.optionText}</div>
              //         </div>

            }
            else {
              return <div class="form-group mr-right-20  rltv-pos">
                <input type="checkbox" id={item.optionText} checked={false} name={item.optionText} key={item._id} value={item._id} onChange={this.onChangeMultiselect} />
                <label for={item.optionText}>{item.optionText}</label>
              </div>



              // <div style={{color:"black"}} ><br/>
              //         <div id="applyscreenform"><input  type = "checkbox" checked = {false} name  = {item.optionText} key={item._id} value ={item._id}  onChange = {this.onChangeMultiselect} /> {item.optionText}</div>
              //         </div>

            }
          });
        }

        if (list.queryType === "text") {
          optionData = <ul>
            <li><div style={{ color: "black" }} >Enter text </div><br />
              <div class="formgroup-col-1 rltv-pos formgroup" >
                <input type="text" className="form-controll " maxlength="100" placeholder="Enter your question comment" value={this.state.allTextData} onChange={this.onInputTextChanged} />
                <div style={{ top: "40px" }} className="errordiv cus-width-2" id="texterror">{this.state.texterror}</div>
              </div>
            </li>
          </ul>
        }

        if (list.queryType === "range") {
          optionData = <ul>
            <li>
              <div class="formgroup-col-1 rltv-pos formgroup" >
                <input type="text" className="form-controll " maxlength="100" placeholder="Range From" value={this.state.rangeFrom} onChange={this.onInputTextRangeFromChanged} />
                <div style={{ top: "40px" }} className="errordiv cus-width-2" id="rangefromerror">{this.state.rangefromerror}</div>
              </div>
            </li>
            <br /><br />
            <li>
              <div style={{ marginTop: "0px" }} class="formgroup-col-1 rltv-pos  formgroup" >
                <input type="text" className="form-controll " maxlength="100" placeholder="Range To" value={this.state.rangeTo} onChange={this.onInputTextRangeToChanged} />
                <div style={{ top: "40px" }} className="errordiv cus-width-2" id="rangetorror">{this.state.rangetorror}</div>
              </div>
            </li>
            <br />
          </ul>



          if (this.state.rangeOptionsData && this.state.rangeOptionsData.length > 0) {

            rangeOptionsData = this.state.rangeOptionsData.map((list, i) => {

              return <tbody> <tr>
                <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom">{i + 1}</td>
                <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.ageFrom}</td>

                <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.ageTo}</td>
                <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">
                  <div onClick={() => this.removeRangeOptions(list)} className="icons" >
                    <div className="delete" >
                      <img src={deletIcon} />
                    </div>
                  </div></td>
              </tr>
              </tbody>
            });



            showTable = <table className="tbl-new-est" >
              <thead>
                <tr>
                  <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">S.No.</th>
                  <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">From</th>
                  <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">To</th>
                  <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Action</th>
                </tr>
              </thead>
              {rangeOptionsData}
            </table>


          }





        }


        return <div>
          <div style={{ display: "flex" }}  >
            <h3 style={{ color: "black", width: "100px" }}>Question : </h3><h4 style={{ textAlign: "left", color: "black" }}> {list.question} </h4>
          </div>
          <div>
            Select options
                            {optionData}
          </div>
        </div>
      });
    }

    return (
      <div style={{ width: "100%" }}>
        <div style={{ marginTop: "2%" }}>
          <div className="table-st">
            <div className="forms-rd-shadow" style={{ width: "100%", paddingBottom: "50px", backgroundColor: "#ffffff", borderRadius: "4px" }}>
              <div className="addVendor-main-div">
                <div className="form-title">
                  <h3 className="rm-margin">Apply Demographics in {this.props.demo_name}</h3>
                </div>
                <hr />
                {questionsData}
                <div style={{ marginTop: "100px", textAlign: "center" }}>
                  <button onClick={this.createMappingWithScreener.bind(this)} className="list-btn ">Apply</button>
                </div>

                <br />
                <div style={{ marginTop: "20px", overflowX: "auto" }}>
                  {showTable}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


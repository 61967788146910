import React, { Component }  from 'react';
import  '../../commonStyle/common.css';
import $ from 'jquery';
import { getUserListByAdmin } from '../../actions/actionsBag/dashboard';
import appConfig from "../../api/apiConfig";
import {connect} from 'react-redux';
import goback from '../../images/goback.png';

class CloneStudyListHeader extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedUserID : "",
            selectedUser   : "Filter by users project",
        }
        this.gobackPage = this.gobackPage.bind( this);
    }

componentWillMount(){

    if(window.location.href && window.location.href.split('/').length>3 && window.location.href.split('/')[3] === "demo-mapping-list"){
        localStorage.setItem("header-title","Add-Demographics-Mapping");
        localStorage.setItem("header-text","Here you can mapped demographics, update and set quota on demographics");
    }
    if(window.location.href && window.location.href.split('/').length>3 && window.location.href.split('/')[3] === "participant-replies"){
        localStorage.setItem("header-title","Participants Replies on Demos");
        localStorage.setItem("header-text","Here you can view all the replies on demos");
    }

    let adminID = localStorage.getItem('auth_user_id');
    let adminType = localStorage.getItem('auth_user_type');
    this.props.dispatch(getUserListByAdmin(adminID, adminType));
}

componentDidMount(){

    $(document).ready(function(){
    
        $('#flt-btn').click( function(e) {
            
            e.preventDefault(); // stops link from making page jump to the top
            e.stopPropagation(); // when you click the button, it stops the page from seeing it as clicking the body too
            $('.dropdownitem').toggle();
            
        });
        
        $('.dropdownitem').click( function(e) {
            
            // e.stopPropagation(); // when you click within the content area, it stops the page from seeing it as clicking the body too
            
        });
        
        $('body').click( function() {
           
            $('.dropdownitem').hide();
            
        });
        
    });
}

showselectedUser(e){
       
    if(e === 0){
        this.setState({
            selectedUserID : "",
            selectedUser   : "Filter by users project",
        });
        let data = {};
        data.user_id = localStorage.getItem("auth_user_id");
        data.user_name = localStorage.getItem("auth_user_type");
        this.props.getStudiesByUser(data);
    }else{
        this.setState({
        selectedUserID : e.user_id,
        selectedUser   : e.user_name
        });
        this.props.getStudiesByUser(e);
    }
 }

 exportStudies(){
    let adminID = localStorage.getItem('auth_user_id');
    let adminType = localStorage.getItem('auth_user_type');
    window.open(appConfig.siteURL + "studyInExcel/"+ adminID+"/"+adminType);
 }

 gobackPage(){
     window.history.back();
 }


 selectActivity(activityName){
    if(activityName === "clone-add-mapping"){
        localStorage.setItem("header-title","clone-Add-Mapping");
        localStorage.setItem("header-text","Here you can map the third party");
    }else if(activityName === "demo-mapping-list"){
        localStorage.setItem("header-title","Add-Demographics-Mapping");
        localStorage.setItem("header-text","Here you can mapped demographics, update and set quota on demographics");
    }else if(activityName === "participant-replies"){
        localStorage.setItem("header-title","Participants Replies on Demos");
        localStorage.setItem("header-text","Here you can view all the replies on demos");
      }

    this.props.selectActivity(activityName);
}

    render(){

        let userItem = "";
        let showAddMapping = "";
        let showCreateDemoMap = "";
        let showParticipantReplies = "";
        if(this.props.userListSuccess && this.props.usersListData && this.props.usersListData.length>0){
          userItem = this.props.usersListData.map((item, i) => {
              return <li onClick={this.showselectedUser.bind(this, item)}>{item.user_name}</li>
            });
        }

        if( localStorage.getItem("is_ShowAddMapping") == 1)
        showAddMapping = <li onClick={() => this.selectActivity("clone-add-mapping")}>Add mapping</li>
        if( localStorage.getItem("is_CreateDemoMapping") == 1)
        showCreateDemoMap = <li onClick={() => this.selectActivity("demo-mapping-list")}>Create demo mapping</li>
       
        if( localStorage.getItem("is_CreateDemoMapping") == 1)
        showParticipantReplies = <li onClick={() => this.selectActivity("participant-replies")}>Participant Replies</li>
        

        return(
            <div className="router-header">
                <div className="router-header1 all-header">
                    <div>
                        <h2 className="head-two-st">Clone Studies List</h2>
                        <p className="paragraph-st color-secondary para-st-font">Here you can see all projects and you can search study by id and name</p>
                     </div>

                    <div className="flex-show" >
                        <div><button onClick={this.props.gotoBack} className="list-btn list-btn-color margin-right"><img src={goback} height="13px" width="13px" /><span> Back</span></button></div>
                        <div><button className="list-btn list-btn-color" id="flt-btn"><span >Project Activities<div className="flter-btn-downarrow" style={{paddingRight:"20px"}}></div></span></button>
                        <div class="dropdownitem dropdown-shadow">
                            <ul>
                                {showAddMapping}
                                {showCreateDemoMap}
                                {showParticipantReplies}
                            </ul>
                            </div>
                        </div>
                     </div>
                </div>
            </div>
    )}
}
const mapStateToProps=(state)=>{
    return{
        userListSuccess : state.dashboard.userListSuccess,
        usersListData : state.dashboard.usersListData
    }
  }
  
  export default connect(mapStateToProps)(CloneStudyListHeader);

const initialState = {
    members : [],
    showAddMember : false,
  
  }
  
  const users = (state = initialState , action) =>{
    switch (action.type) {
        
        case 'SET_ADD_USER_RES' :
          return {
            ...state,
            userMessage: action.response.message,
            userAddSuccess: action.response.success,
          }

        case 'SET_UPDATE_USER_RES' :
          return{
            ...state,
            userUpdateMessage: action.response.message,
            userUpdateSuccess: action.response.success,
          }
    
        case 'SET_EMPTY_ADD_USER_RES' :
          return {
            ...state,
            userMessage : null,
            userAddSuccess : null,
            opManagerSuccess : null,
            userUpdateMessage : null,
            userUpdateSuccess : null,
            removeUserSuccess : null,
            removeUserMessage : null,
            userMailResMessage: null,
            userMailResSuccess: null,
          }

        case 'SET_USER_MAIL_RESPONSE':
          return{
            userMailResMessage: action.response.message,
            userMailResSuccess: action.response.success,
          }

        case 'SET_OP_MANAGER_LIST_RES' :
        return {
          ...state,
          opManagerList: action.response.data,
          opManagerSuccess: action.response.success,
         
        }

        case 'SET_USERS_LIST_RES':
        return {
          ...state,
          usersList: action.response.data,
          totalUsers: action.response.totalUsers,
          usersDataSuccess: action.response.success,
          showEditUserProfile : false
        }

        case 'SET_USERS_PROFILE_EDIT':
        return{
          ...state,
          showEditUserProfile : true,
          editUserData : action.data
        }

        case 'SHOW_CREATE_USER_PAGE':
        return{
          ...state,
          showEditUserProfile: true,
          editUserData:false
        }

        case 'SET_REMOVE_USER_RES':
          return{
            ...state,
            removeUserSuccess : action.response.success,
            removeUserMessage : action.response.message,
          }

        default:
            return state;
          }
  };

  export default users;
  
//containers -> Loout -> index.js
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {submitLogOut} from '../../pages/Login/action';


class Logout extends Component {

  componentWillMount(){
    localStorage.clear();
    
    this.props.history.push("/");
    this.props.dispatch(submitLogOut());
  }

  render(){
    return(<div></div>)
  }
}

const mapStateToProps=(state) =>{
  return {
    authenticated : state.login.authenticated
  };
}

export default connect(mapStateToProps)(Logout);

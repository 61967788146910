import React, { Component } from 'react';
import { connect } from 'react-redux';
import DemographicHeader from '../../components/Demographics/demographicHeader.jsx';
import AddOptionDiv from '../../components/Demographics/addOptions.jsx';
import { addQueryOption, emptyOptionsProps, getOptionList, updateOption, deleteOption } from '../../actions/actionsBag/Demographic';
import goback from '../../images/goback.png';
import { alertMessage, emptyDialogdata } from '../../alertsDialog/Action';
import { Link } from 'react-router-dom';

class AddOptionView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            questionData: "",
            ques_id: "",
        }

        this.addQueryOption = this.addQueryOption.bind(this);
        this.updateOption = this.updateOption.bind(this);
        this.softDeleteOption = this.softDeleteOption.bind(this);
    }

    componentWillMount() {
        const { questionData } = this.props.location.state;
        if (questionData) {
            this.setState({ questionData: questionData, ques_id: questionData._id });
            this.props.dispatch(getOptionList(questionData._id));
        } else {
            this.props.history.push('/demographics-list');
        }
    }

    alertDialog(message) {
        let alertData = {};
        alertData.type = "alert";
        alertData.message = message;
        this.props.dispatch(alertMessage(alertData));
        this.props.dispatch(emptyOptionsProps());
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.confirmDialog === true) {
            this.props.dispatch(emptyDialogdata());
            this.props.dispatch(deleteOption(nextProps.confirmData));
        }

        if (nextProps.queryOptionSuccess && nextProps.queryOptionSuccess.success && nextProps.queryOptionSuccess.message) {
            this.alertDialog(nextProps.queryOptionSuccess.message);
        } else if (nextProps.queryOptionError && (nextProps.queryOptionError.success === false) && nextProps.queryOptionError.message) {
            this.alertDialog(nextProps.queryOptionError.message);
        }

        if (nextProps.updateQueryOptionRep && nextProps.updateQueryOptionRep.success && nextProps.updateQueryOptionRep.message) {
            this.alertDialog(nextProps.updateQueryOptionRep.message);
        } else if (nextProps.updateQueryOptionRep && (nextProps.updateQueryOptionRep.success === false) && nextProps.updateQueryOptionRep.message) {
            this.alertDialog(nextProps.updateQueryOptionRep.message);
        }

        if (nextProps.optionDeleteSuccess && nextProps.optionDeleteMessage) {
            this.alertDialog(nextProps.optionDeleteMessage);
            this.props.dispatch(getOptionList(this.state.ques_id));
        } else if ((nextProps.optionDeleteSuccess === false) && nextProps.optionDeleteMessage) {
            this.alertDialog(nextProps.optionDeleteMessage);
        }
    }



    goBack() {
        window.history.back();
    }

    addQueryOption(data) {
        this.props.dispatch(addQueryOption(data));
    }

    updateOption(data) {
        this.props.dispatch(updateOption(data));
    }

    softDeleteOption(optionId) {
        let alertData = {};
        alertData.type = "confirmalert";
        alertData.message = "Are you sure to delete this item ?";
        alertData.deleteId = optionId;
        this.props.dispatch(alertMessage(alertData));
        //this.props.dispatch(deleteOption( optionId));
    }


    render() {

        const header = 'Add Option';
        const headerText = 'Here you can add options of current question';
        const headerButton = <button className="back-btn back-btn-color" onClick={this.goBack}><img src={goback} height="13px" width="13px" /><span> Back</span></button>


        return (
            <div style={{ width: "100%" }}>
                <DemographicHeader
                    header={header}
                    headerText={headerText}
                    headerButton={headerButton}
                />
                <AddOptionDiv
                    questionData={this.state.questionData}
                    addQueryOption={this.addQueryOption}
                    queryOptionList={this.props.queryOptionList}
                    updateOption={this.updateOption}
                    softDeleteOption={this.softDeleteOption}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        queryOptionSuccess: state.demographics.queryOptionSuccess,
        queryOptionError: state.demographics.queryOptionError,
        queryOptionList: state.demographics.queryOptionList,
        updateQueryOptionRep: state.demographics.updateQueryOptionRep,
        optionDeleteSuccess: state.demographics.optionDeleteSuccess,
        optionDeleteMessage: state.demographics.optionDeleteMessage,
        confirmDialog: state.alertsDialog.confirmDialog,
        confirmData: state.alertsDialog.confirmData
    }
}

export default connect(mapStateToProps)(AddOptionView);
import React, { Component } from 'react';
import APIProjectListListHeader from '../../components/StudySurvey/apiProjectsListHeader';
import APIProjectListListTable from '../../components/StudySurvey/apiProjectsListTable';
import { studiesList, softDelete, search, getAPIProjectList,
     searchAPIProject, sortAPIProject } from '../../actions/actionsBag/study';
import { connect } from 'react-redux';

export class APIProjectList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userId: "",
            userType: "",
            projectStatus: "all",
            loginUserID: ""
        }
        this.apiProjectListByPage = this.apiProjectListByPage.bind(this);
        this.searchStudy = this.searchStudy.bind(this);
        this.sortStudy = this.sortStudy.bind(this);
    }

    componentWillMount() {

        let userBy = localStorage.getItem("auth_user_id");
        let userType = localStorage.getItem("auth_user_type");
        // this.setState({ userId : userBy, userType : userType, loginUserID : userBy });
       // this.props.dispatch(getAPIProjectList(1));  // commented to sort default
       let sortData = {};
       sortData.pageNumber = 1;
       sortData.orderIn = "DESC";
       sortData.isAscending = false;
       sortData.sortOn = "survey_id";
       sortData.sortType = "all";
       this.props.dispatch(sortAPIProject(sortData));
        // this.timer = null;
    }


    apiProjectListByPage(pageNumber) {
        // this.setState({ projectStatus : projectStatus })
        this.props.dispatch(getAPIProjectList(pageNumber));
    }

    searchStudy(serchIteam) {
        let data = {};
        data.searchItem = serchIteam;
        data.userID = this.state.userId;
        data.userType = this.state.userType;
        this.props.dispatch(searchAPIProject(data));
    }

    sortStudy(sortData){
     console.log(sortData);
     this.props.dispatch(sortAPIProject(sortData));
    }

    render() {
        return (
            <div>
                <div className="router-header">
                    <div className="router-header1 all-header">
                        <div>
                            <h2 className="head-two-st">API Project List</h2>
                            <p className="paragraph-st color-secondary para-st-font">Here you can see all API project list and you can search by Survey id</p>
                        </div>
                    </div>
                </div>
                <APIProjectListListTable key="temp"
                    apiProjectListByPage={this.apiProjectListByPage}
                    searchStudy={this.searchStudy}
                    sortStudy={this.sortStudy}
                />

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

export default connect(mapStateToProps)(APIProjectList);
import {
  queryApi, queryOptionApi, queryListApi, newDemoApi,
  newDemoListApi, updateOptionApi, updateDemoApi, getQueryOptionApi,
  updateQueryApi, getQueryAndRangeApi, addDemoAgeMappingApi, updateDemoAgeApi, participantCountApi,
  removeAgeApi, getQuestionByDemoApi, getOptionListApi, deleteQuestionApi, deleteOptionApi, deleteDemographicsApi
} from "../../api/apiBag/DemographicsApi";
import {startSpinner, endSpinner} from "../../loadingSpinner/Action";
//import {setMessage} from '../../popupAlert/action';

export const setQuery = (querySuccess) => {
  return {
    type: "SET_QUERY_MESSAGE",
    querySuccess

  }
}

export const setQueryUpdate = (response) => {
  return {
    type: "SET_QUERY_UPDATE_RES",
    response
  }
}


export const emptyQuestionUpdateRes = () => {
  return {
    type: "SET_EMPTY_QUERY_UPDATE_RES",
  }
}

export const setHideDemoList = () => {
  return {
    type: "SET_HIDE_DEMO_LIST"
  }
}

export const setQueryError = (queryError) => {
  return {
    type: "SET_QUERY_ERROR",
    queryError

  }
}


export const setNewDemo = (demoSuccess) => {
  return {
    type: "SET_ADD_NEW_DEMO",
    demoSuccess

  }
}

export const setDemoError = (demoError) => {
  return {
    type: "SET_ADD_DEMO_ERROR",
    demoError

  }
}

export const setQueryList = (queryTextList) => {
  return {
    type: "SET_QUERY_DATA",
    queryTextList

  }
}


export const setQueryOption = (queryOptionSuccess) => {
  return {
    type: "SET_QUERY_OPTION",
    queryOptionSuccess

  }
}

export const setQueryOptionErrorResponse = (queryOptionError) => {
  return {
    type: "SET_QUERY_OPTION_ERROR",
    queryOptionError

  }
}

export const setNewDemoList = (demoList) => {
  return {
    type: "SET_NEW_DEMO_DATA",
    demoList

  }
}

//emptyDemoProps
export const emptyDemoProps = () => {
  return {
    type: "SET_EMPTY_DEMO_PROPS"
  }
}

export const setupdateQueryRes = (updateQueryOptionRep) => {
  return {
    type: "SET_UPDATE_QUERY_REP",
    updateQueryOptionRep

  }
}

export const editDemo = (item) => {
  return {
    type: "SET_EDIT_DEMO",
    item
  }
}

export const setAllOptionData = ( response) => {
  return {
    type: "SET_OPTION_LIST",
    response
  }
}

export const CreateDemoCompleted = () => {
  return {
    type: "CREATE_DEMO_COMPLETED"
  }
}

export const initilizeReducer = () => {
  return {
    type: "SET_REDUCER_TO_ZERO",

  }
}

export const setupdateDemoRes = (updateDemoRes) => {
  return {
    type: "SET_UPDATE_DEMO_RES",
    updateDemoRes

  }
}

export const setQueryAndOption = (queryAndOption) => {
  return {
    type: "SET_QUERY_AND_OPTION",
    queryAndOption

  }
}

export const setQueryAndRange = (queryAndRange) => {
  return {
    type: "SET_QUERY_AND_RANGE",
    queryAndRange

  }
}

export const updateDemoAgeRes = (updateDemoAgeRes) => {
  return {
    type: "SET_DEMO_AGE_RES",
    updateDemoAgeRes

  }
}

export const setParticipantCount = (thirdPartyTotalCount) => {
  return {
    type: "SET_PARTICIPANT_COUNT",
    thirdPartyTotalCount

  }
}

//emptyOptionsProps
export const emptyOptionsProps = () => {
  return {
    type: "SET_EMPTY_OPTIONS_PROPS"
  }
}

export const setQuestionByDemo = ( response)=>{
  return{
    type : "SET_DEMO_QUESTION",
    response
  }
}

export const setDeleteQuestionResponse = ( response)=>{
  return{
    type : "SET_DELETE_QUES_RESPONSE",
    response
  }
}

//setDeleteOptionResponse
export const setDeleteOptionResponse = ( response)=>{
  return{
    type : "SET_DELETE_OPTION_RESPONSE",
    response
  }
}

//setDeleteDemoResponse
export const setDeleteDemoResponse = ( response)=>{
  return{
    type : "SET_DELETE_DEMO_RESPONSE",
    response
  }
}

export const removeAge = (data) => {
  return dispatch => {

    removeAgeApi(data).then((result) => {

      dispatch(setQueryAndRange(result));

    });
  }
}

export const addQuery = (data) => {
  return dispatch => {
    dispatch(startSpinner())
    queryApi(data).then((result) => {
      dispatch(endSpinner());
        dispatch(setQuery(result));     
    });
  }
}

export const updateQuery = (data) => {
  return dispatch => {
    //dispatch(startSpinner())
    updateQueryApi(data).then((result) => {
      //dispatch(endSpinner())
      dispatch(setQueryUpdate(result));
    });
  }
}
export const queryTextList = (id) => {
  return dispatch => {

    queryListApi(id).then((result) => {

      dispatch(setQueryList(result));

    });
  }
}


export const addQueryOption = (data) => {
  return dispatch => {
    //dispatch(startSpinner())
    queryOptionApi(data).then((result) => {
      //dispatch(endSpinner())
      if (result.success) {
        dispatch(setQueryOption(result));
        //ispatch(setMessage(result));
      }
      else {
        //ispatch(setMessage(result));
        dispatch(setQueryOptionErrorResponse(result));

      }
    });
  }
}

export const addNewDemo = (data) => {
  return dispatch => {
    dispatch(startSpinner());
    newDemoApi(data).then((result) => {
      dispatch(endSpinner());
        dispatch(setNewDemo(result));

    });
  }
}

export const newDemoList = ( currentPage) => {
  return dispatch => {
    dispatch(startSpinner())
    newDemoListApi( currentPage).then((result) => {
      dispatch(endSpinner())
      dispatch(setNewDemoList(result));

    });
  }
}


export const updateOption = (item) => {
  return dispatch => {
    //dispatch(startSpinner())
    updateOptionApi(item).then((result) => {
      //dispatch(endSpinner())
      //ispatch(setMessage(result));
      dispatch(setupdateQueryRes(result));

    });
  }
}

export const updateDemo = (item, id) => {
  return dispatch => {
    dispatch(startSpinner())
    updateDemoApi(item, id).then((result) => {
      dispatch(endSpinner())
      dispatch(setupdateDemoRes(result));

    });
  }
}


export const getQueryOption = (id) => {
  return dispatch => {
    //dispatch(startSpinner())
    getQueryOptionApi(id).then((result) => {
      //dispatch(endSpinner())
      dispatch(setQueryAndOption(result));

    });
  }
}

export const queryAndRange = (id) => {
  return dispatch => {
    //dispatch(startSpinner())
    getQueryAndRangeApi(id).then((result) => {
      //dispatch(endSpinner())
      dispatch(setQueryAndRange(result));

    });
  }
}

export const addDemoAgeMapping = (data) => {
  return dispatch => {

    addDemoAgeMappingApi(data).then((result) => {

      dispatch(setQueryAndRange(result));

    });
  }
}

export const updateDemoAge = (data) => {
  return dispatch => {

    updateDemoAgeApi(data).then((result) => {

      dispatch(updateDemoAgeRes(result));

    });
  }
}

export const thirdPartyParticipantCount = (item) => {
  return dispatch => {

    participantCountApi(item).then((result) => {

      dispatch(setParticipantCount(result));

    });
  }
}


//getQuestionByDemo

export const getQuestionByDemo = ( id) => {
  return dispatch => {

    getQuestionByDemoApi( id).then((result) => {

      dispatch(setQuestionByDemo(result));
    });
  }
}

//getOptionList
export const getOptionList = ( id) => {
  return dispatch => {

    getOptionListApi( id).then((result) => {

     dispatch(setAllOptionData(result));
    });
  }
}

//deleteQuestion
export const deleteQuestion = ( id) => {
  return dispatch => {

    deleteQuestionApi( id).then((result) => {

     dispatch(setDeleteQuestionResponse(result));
    });
  }
}

//deleteOption
export const deleteOption = ( id) => {
  return dispatch => {

    deleteOptionApi( id).then((result) => {

     dispatch(setDeleteOptionResponse(result));
    });
  }
}

//deleteDemographics
export const deleteDemographics = ( id) => {
  return dispatch => {

    deleteDemographicsApi( id).then((result) => {
      dispatch(setDeleteDemoResponse(result));
    });
  }
}
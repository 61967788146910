import React, { Component }  from 'react';
import { connect } from 'react-redux';
import UserHeader from '../../components/User/userHeader.jsx';
import AddUserDiv from '../../components/User/addUser.jsx';
import goback from '../../images/goback.png';
import { getAllRolesByAdmin } from '../../actions/actionsBag/roles';
import { getOPmanager, addUsers, blankResUser } from '../../actions/actionsBag/users';
import {Link} from 'react-router-dom';
import { alertMessage }  from '../../alertsDialog/Action';

    class CreateUserView extends Component {

        constructor(props) {
            super(props)
            this.state = {

            }
            this.getOPmanager = this.getOPmanager.bind( this);
            this.addUser = this.addUser.bind( this);
          }

    componentWillMount() {

        this.props.dispatch(getAllRolesByAdmin());
      }

    componentWillReceiveProps( nextProps){
        if( nextProps.userAddSuccess === true && nextProps.userMessage){
            this.props.dispatch(blankResUser());
            let alertData ={};
            alertData.type ="alert";
            alertData.message = nextProps.userMessage;
          this.props.dispatch(alertMessage(alertData));
            this.props.history.push('/user-list');
        }else if(nextProps.userMessage){
            this.props.dispatch(blankResUser());
            let alertData ={};
            alertData.type ="alert";
            alertData.message = nextProps.userMessage;
          this.props.dispatch(alertMessage(alertData));
        }
    }

      getOPmanager(){
        let userId = localStorage.getItem('auth_user_id');
        this.props.dispatch(getOPmanager(userId));
      }

      addUser(data){
        this.props.dispatch(addUsers(data));
      }

    render(){

        const header = 'Create User';
        const headerText = 'Here you can new user ';
        const headerButton = <Link to="/user-list"> <button className="back-btn back-btn-color" ><img src={goback} height="13px" width="13px" /><span> Back</span></button></Link>


        return(
            <div style={{ width:"100%"}}>  
                <UserHeader 
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                />
                <AddUserDiv 
                    allRoleSuccess = {this.props.allRoleSuccess}
                    allRoleData = {this.props.allRoleData}
                    getOPmanager = {this.getOPmanager}
                    opManagerList = {this.props.opManagerList}
                    opManagerSuccess = {this.props.opManagerSuccess}
                    addUser = {this.addUser}
                />
            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        allRoleSuccess : state.roles.allRoleSuccess,
        allRoleData    : state.roles.allRoleData,

        opManagerList : state.users.opManagerList,
        opManagerSuccess : state.users.opManagerSuccess,

        userMessage : state.users.userMessage,
        userAddSuccess : state.users.userAddSuccess,
    }
  }
  
export default connect(mapStateToProps)(CreateUserView);
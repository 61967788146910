import React, { Component }  from 'react';
import { connect } from 'react-redux';
import ClientHeader from '../../components/Client/clientHeader.jsx';
import AddClientDiv from '../../components/Client/addClient.jsx';
import { getAllCountryList, addClient , emptyClientProps,} from '../../actions/actionsBag/clientAction';
import goback from '../../images/goback.png';
import {Link} from 'react-router-dom';
import { alertMessage }  from '../../alertsDialog/Action';

class CreateClientView extends Component {

        constructor(props) {
            super(props)
            this.state = {

            }
             this.addClient = this.addClient.bind(this);
             this.emptyClientProps = this.emptyClientProps.bind(this);
          }

    componentWillMount() {

        this.props.dispatch(getAllCountryList());
      }

      addClient(data){
          this.props.dispatch( addClient(data));
      }

      emptyClientProps(){
        this.props.dispatch(emptyClientProps());
      }

      componentWillReceiveProps(nextProps){
   
        if(nextProps.clientAddSuccess === true && nextProps.clientAddMessage){
            this.props.dispatch(emptyClientProps());
            let alertData ={};
            alertData.type ="alert";
            alertData.message = nextProps.clientAddMessage;
          this.props.dispatch(alertMessage(alertData));
            this.props.history.push('/client-list');
        }else if( nextProps.clientAddSuccess === false && nextProps.clientAddMessage){
            this.props.dispatch(emptyClientProps());
            let alertData ={};
            alertData.type ="alert";
            alertData.message = nextProps.clientAddMessage;
          this.props.dispatch(alertMessage(alertData));
            
        }
      }
      

    render(){

        const header = 'Create Client';
        const headerText = 'Here you can create new client ';
        const headerButton = <Link to="/client-list"> <button className="back-btn back-btn-color" ><img src={goback} height="13px" width="13px" /><span> Back</span></button></Link>


        return(
            <div style={{ width:"100%"}}>  
                <ClientHeader 
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                />
                <AddClientDiv
                    countryList = {this.props.countryList}
                    addClient = {this.addClient}
                    emptyClientProps = {this.emptyClientProps}
                />
            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        countryList : state.client.countryList,
        clientAddSuccess : state.client.clientAddSuccess,
        clientAddMessage : state.client.clientAddMessage
    }
  }
  
export default connect(mapStateToProps)(CreateClientView);
const initialState = {
	
}

const demoMapped = (state = initialState, action) => {
	//state.memberSponsees=[];
	switch (action.type) {

	case 'SET_DEMO_MAPPED_LIST':
	return {
		...state,
		demoMappedListSuccess : action.response.success,
		demoMappedListData : action.response.demoMappingData,
	}

	case 'SET_CONSTRAINTS_LIST_ON_PROJECT':
	return {
		...state,
		constraintsResponse : action.result,
	}

	

	case 'SET_PREVIEW_QUESTIONS':
	return {
		...state,
		questionsList : action.result,
	}
	

	case 'SET_DEMO_NOT_MAPPED_LIST':
	return {
		...state,
		demoNotMappedListSuccess : action.response.success,
		demoNotMappedListData : action.response.demoNotMapData,
	}

	case 'SET_DEMO_QUERY_DATA_BY_ID_RESPONSE':
		return{
			...state,
			demoQueryData : action.queryData,
		}

	case 'SET_ADD_MAPPING_DEMOGRAPHICS_RESPONSE':
		return{
			...state,
			demoMappingSuccess : action.response.success,
			demoMappingMessage : action.response.message
		}

	case 'SET_EMPTY_DEMO_MAP_RESPONSE':
		return{
			...state,
			demoMappingSuccess : null,
			demoMappingMessage : null,
			demoDeleteRangeSuccess : null,
			demoDeleteRangeMessage : null
		}

		case 'SET_QUOTA_AND_QUERY_OPTIONS' :
			return {
			  ...state,
			  quotaAndQueryOptions : action.quotaAndQueryOptions,
			  quota : action.quotaAndQueryOptions.quota
			}

		case 'SET_CONSTRAINTS_TO_PROJECT_BY_SCREENER' :
			return {
				...state,
				quota : action.result.quota,
				quotaMessage : action.result.message
			}
		
		case 'SET_CONSTRAINTS_FAIL' :
			return{
			...state,
			quotaMessage : action.result.message
			}

		case 'SET_EMPTY_QUOTA_PROPS':
			return{
				...state,
				quotaMessage : null
			}

				
		case 'SET_MAP_DEMO_DELETE_SUCCESS':
			return{
				...state,
				demoDeleteSuccess : action.demoResult.success,
				demoDeleteMessage : action.demoResult.message
			}
				
		case 'SET_MAP_DEMO_DELETE_FAIL':
			return{
				...state,
				demoDeleteSuccess : action.demoResult.success,
				demoDeleteMessage : action.demoResult.message
			}

			
		case 'EMPTY_MAP_DEMO_PROPS':
		return{
			...state,
			demoDeleteSuccess : null,
			demoDeleteMessage : null,
			demoNotMappedListSuccess : null,
			demoNotMappedListData : null,
			demoMappedListSuccess : null,
			demoMappedListData : null,
		}

		case 'SET_RANGE_OPTION_DELETE_SUCCESS':
		return{
			...state,
			demoDeleteRangeSuccess : action.response.success,
			demoDeleteRangeMessage : action.response.message
		}

		case 'SET_PARTICIPANT_REPLY_RESPONSE':
		return{
			...state,
			participantRepliesList : action.response
		}
		

		default:
			return state;
	}

};
export default demoMapped;
import React, { Component }  from 'react';
import StudyUpdate from '../../components/Study/studyUpdate';
import UpdateStudyHeader from '../../components/Study/updateStudyHeader';
import RedirectsUrl from '../../components/Study/studyRedirects';
import StudyProgress from '../../components/Study/studyProgress';
import { getProgressReportDataByStudy, getDefaultVendor} from '../../actions/actionsBag/study';
import $ from 'jquery';
import './tempstyle.css';
import {connect} from 'react-redux';
import appConfig from '../../api/apiConfig';


class StudyListUpdate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editstudyData : "",
        }
        this.selectActivity = this.selectActivity.bind(this);
        this.gotoBack = this.gotoBack.bind(this);
        this.participantAsExcelBystudy = this.participantAsExcelBystudy.bind(this);
    }

    componentWillMount(){
        if( this.props.location.state === undefined){
            this.props.history.push("error");
        }else{
    
        const { editstudyData } = this.props.location.state;
                   
        this.setState({
            editstudyData : editstudyData
          });
          localStorage.setItem("edit_study_id", editstudyData._id );
          localStorage.setItem("edit_study_name", editstudyData.studyName );
          localStorage.setItem("is_clone_main_study", editstudyData.is_clone_main_study );
          localStorage.setItem("is_unique_link", editstudyData.uniqueLink );
          this.props.dispatch( getProgressReportDataByStudy( editstudyData._id));
          this.props.dispatch( getDefaultVendor(  editstudyData._id) );
         
          if(editstudyData.status === "completed"){
            localStorage.setItem("edit_study_data", "stop" );
          }else{
            localStorage.removeItem("edit_study_data");
          }
        }
    }

    componentDidMount(){
       

        $(document).ready(function(){
            $("#tg-btn-1").click(function(){
            $("#show-form-1").toggleClass("show-full-div");
            $("#rotate-icon-1").toggleClass("rotate-tgl");
            });

            $("#tg-btn-2").click(function(){

             $("#show-form-2").toggleClass("show-full-div");
             $("#hide-me").toggleClass("tg-hide");
             $("#rotate-icon-2").toggleClass("rotate-tgl");
            });

            $("#tg-btn-3").click(function(){
                 $("#show-form-3").toggleClass("show-full-div");
                 $("#pro-tbl-hide-me").toggleClass("pro-tbl-hide");
                 $("#rotate-icon-3").toggleClass("rotate-tgl");
                 
                });
        });
    }
    
    selectActivity(activityName){
        this.props.history.push(activityName);
    }

    gotoBack(){
        window.history.back();
    }

    participantAsExcelBystudy(status, type){
        if(type === true){
            window.open(appConfig.siteURL + 'participantAsExcelByStudy/'+ this.state.editstudyData._id+"/"+status+"/test");
  
        } else{
            window.open(appConfig.siteURL + 'participantAsExcelByStudy/'+ this.state.editstudyData._id+"/"+status+"/live");
        }
    }

    render(){
        return(
            <div>
                  <UpdateStudyHeader
                    selectActivity = {this.selectActivity}
                    editstudyData = {this.state.editstudyData}
                    gotoBack = {this.gotoBack}
                  />
                 <div className="mr-top-20">
                    <div className="create-form-container">
                        <div className="forms-rd-shadow toggle-form show-me" id="show-form-1">
                            <StudyUpdate
                                editstudyData = {this.state.editstudyData}
                            />
                        </div>
                    </div>
                </div>

                <div className="create-form ">
                    <div className="create-form-container ">
                        <div className="forms-rd-shadow toggle-form show-me"  id="show-form-2">
                            <RedirectsUrl
                                editstudyData = {this.state.editstudyData}
                                defaultVendorSuccess = {this.props.defaultVendorSuccess}
                                defaultVendorId = {this.props.defaultVendorId}
                            />
                        </div>
                    </div>
                </div>

                <div className="create-form  ">
                    <div className="create-form-container ">
                        <div className="forms-rd-shadow toggle-form show-me"  id="show-form-3">
                            <StudyProgress
                                updateStudyProgressData = { this.props.updateStudyProgressData}
                                participantAsExcelBystudy = {this.participantAsExcelBystudy}
                            />
                        </div>
                    </div>
                </div>
            </div>
    )}
}
const mapStateToProps=(state)=>{
    return{
        clientsList    : state.studies.clientsList,
        bdeUsersList : state.studies.bdeUsersList,
        studySuccess : state.studies.studySuccess,
        updateStudyProgressData : state.studies.updateStudyProgressData,
        defaultVendorSuccess : state.studies.defaultVendorSuccess,
        defaultVendorId : state.studies.defaultVendorId,
    }
  }
  
  export default connect(mapStateToProps)(StudyListUpdate);
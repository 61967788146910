import React, { Component }  from 'react';
import  '../../commonStyle/common.css';

import './style.css';
export default class UpdateClientHeader extends Component {

    render(){
        return(
            <div className="router-header">
                <div className="router-header1 all-header">
                    <div>
                        <h2 className="head-two-st">{this.props.header}</h2>
                        <p className="paragraph-st color-secondary para-st-font">{ this.props.headerText}</p>
                     </div>

                    <div>
                        {this.props.headerButtonTwo}
                        {this.props.headerButton}
                        
                     </div>
                </div>
            </div>
    )}
}
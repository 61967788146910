import React, { Component } from 'react';
import '../../commonStyle/common.css';
import './style.css';

import deletIcon from '../../images/delete.png';


import add from '../../images/add.png';
import $ from 'jquery';


export default class AddOptionDiv extends Component {
  constructor(props) {
    super(props)
    this.state = {
      queryId: "",
      querytext: "",
      querylanguage: "",
      optionText: "",
      validationMsg: {},
      optionList: [],
      showloader: true,
    }
    this.addQueryOption = this.addQueryOption.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    this.setState({
      queryId: this.props.questionData._id, querytext: this.props.questionData.question,
      querylanguage: this.props.questionData.language
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.queryOptionList && nextProps.queryOptionList.length > 0) {
      this.setState({
        optionList: nextProps.queryOptionList,
        showloader: false
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleValidation() {
    let valid = true;
    let error = {}

    if (this.state.optionText === "") {
      valid = false;
      $("#error77").addClass("showtemp");
      this.setState({
        optionTexterror: "Enter option text"
      });
    }


    this.setState({ validationMsg: error });
    return valid;
  }

  addQueryOption() {
    let optionData = {};
    optionData.queryId = this.state.queryId
    optionData.optionName = this.state.optionText;
    optionData.language = this.state.querylanguage;

    if (this.handleValidation()) {
      this.props.addQueryOption(optionData);
    }
  }

  tableZoom() {
    $(".alltablestruct").toggleClass("zoom-me");
  }

  softDeleteOption(optionId) {
    this.props.softDeleteOption(optionId);
  }

  onTextChange(index, e) {
    let temp = [...this.state.optionList]
    temp[index].optionText = e.target.value;

    this.setState({
      optionList: temp
    });
    this.forceUpdate();
  }

  updateOption(item, e) {
    if (e.key == "Enter") {
      this.props.updateOption(item);
    }
  }


  render() {


    let questionItems = "";


    if (this.state.optionList && this.state.optionList.length > 0) {


      questionItems = this.state.optionList.map((list, i) => {
        return <tbody> <tr>
          <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom">{i + 1}</td>
          {/* <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.optionText}</td> */}
          <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">
            <input type="text" className="form-controll" style={{ width: "100%", padding: "0px 5px 0px 5px" }} value={list.optionText} onKeyPress={this.updateOption.bind(this, list)} onChange={this.onTextChange.bind(this, i)} />
          </td>
          <td className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom" >{list.language}</td>
          <td className=" tbl-padd-top tbl-padd-left tbl-padd-bottom">
            <div className="flex-show">
              <div className="icons" >
                <div className="delete" onClick={this.softDeleteOption.bind(this, list._id)}>
                  <img src={deletIcon} />
                </div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      });
    } else {
      questionItems = "";
    }


    let showTable = "";
    if (this.state.optionList && this.state.optionList.length > 0) {
      showTable = <table className="tbl-new-est" >
        <thead>
          <tr>
            <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">S.No.</th>
            <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Option</th>
            <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Language</th>
            <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Action</th>
          </tr>
        </thead>
        {questionItems}
      </table>
    } else if (this.state.showloader === true) {
      showTable = <div className="datanotfound"> No record found</div>
    } else {
      showTable = <div className="spiner-data"><div className="tblspinner"></div></div>
    }

    return (
      <div style={{ marginTop: "2%" }}>
        <div className="table-st ">
          <div className="forms-rd-shadow" style={{ width: "100%", paddingBottom: "50px", backgroundColor: "#ffffff", borderRadius: "4px" }}>
            <div className="addVendor-main-div">
              <div className="form-title">
                <h4 className="rm-margin" style={{ textAlign: "center" }}>Add Option</h4>
              </div>

              <div className="formgroup">
                <div class="formgroup-col-1" >
                  <label>Question : </label>&nbsp;  <label>{this.state.querytext}</label>

                </div>
              </div>

              <div className="formgroup">
                <div class="formgroup-col-1 rltv-pos" >
                  <label>Option text</label>
                  <input className="form-controll " name="optionText" placeholder="Enter option text" value={this.state.optionText} onChange={this.onChange} />
                  <div style={{ width: "98%" }} className="errordiv" id="error77">{this.state.optionTexterror}</div>
                </div>
              </div>
              <div className="formgroup">
                <div class="formgroup-col-1" >
                  <label>Language*</label>
                  <select disabled={true} className="form-controll" style={{ width: "100%", padding: "2px" }} name="querylanguage" value={this.state.querylanguage}>
                    <option value="">Select question language</option>
                    <option value="english"> English </option>
                  </select>

                </div>
              </div>

              <div style={{ textAlign: "center" }}>
                <button onClick={this.addQueryOption} style={{ marginTop: "27px", textAlign: "center" }} className="list-btn list-btn-color">
                  <img src={add} height="12px" width="12px" /><span> Add Option </span></button>
              </div>
            </div>
          </div>
        </div>

        {/* //.......................................... */}

        <div className="table-st" style={{ marginTop: "2%" }}>
          <div className="alltablestruct forms-rd-shadow">
            {/* serachbox and stausbtn */}
            <div id="search-staus">

              <div className="flex-show  tbl-h-font">
                Options's list
                            </div>

              <div className="flex-show tbl-zoom" >
                <span onClick={this.tableZoom} className="full-screen mr-rhs-20 mr-top-10"> </span>
              </div>
            </div>
            {/* serachbox and stausbtn */}

            {/* table */}
            <div style={{ marginTop: "20px", overflowX: "auto" }}>
              {showTable}
            </div>
            {/* table */}


          </div>
        </div>


      </div>
    )
  }
}

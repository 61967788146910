import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { getParticipantCount, getParticipantData, thirdPartyParticipant, studyParticipant, statusParticipant,
    getCountDataByMonth, getCountDataByYear, getUserListByAdmin, getLiveStudiesByUser} from '../../actions/actionsBag/dashboard';

import DashboardHeaderDiv from '../../components/Dashboard/dashboardHeaderDiv.jsx';
import DashboardCountDiv from '../../components/Dashboard/dashboardCountDiv.jsx';
import DashboardGraphDiv from '../../components/Dashboard/dashboardGraphDiv.jsx';
import DashboardStudyListDiv from '../../components/Dashboard/dashboardStudyListDiv.jsx';
import '../../commonStyle/common.css';
import $ from 'jquery';

    class DashboardView extends Component {

        constructor(props) {
            super(props)
            this.state = {
                adminID:"",
                countYear:"",
                countMonth:"",
                dataUserID : "",
                studyListCurrentPage : 1,
                graphDataBy : "month"
              
            }
           this.getParticipantCount = this.getParticipantCount.bind(this);
           this.getParticipantCountByUser = this.getParticipantCountByUser.bind(this);
           this.getParticipantCountByYear = this.getParticipantCountByYear.bind(this);
           this.getParticipantCountByMonth = this.getParticipantCountByMonth.bind(this);
           this.getLiveStudies = this.getLiveStudies.bind(this);
           this.setGraphBy = this.setGraphBy.bind(this);
          }

    componentWillMount() {
        
        if(localStorage.getItem("reload_component") == "true"){
            localStorage.setItem('reload_component' , false);
            window.location.reload();
        }

        let adminID = localStorage.getItem('auth_user_id');
        let adminType = localStorage.getItem('auth_user_type');
        this.setState({adminID : adminID});
        
        this.props.dispatch(getLiveStudiesByUser( this.state.studyListCurrentPage, adminID ));

    
        let currentYear = new Date().getFullYear();
        let currentMonth = new Date().getMonth();
        currentMonth = +currentMonth + 1;
       
        if(currentMonth<4){
          let lastYear = parseInt(currentYear)-1;
          let countYear1= lastYear+"-"+currentYear
          this.setState({countYear:countYear1, countMonth : currentMonth , dataUserID : adminID});
          this.props.dispatch(getParticipantCount(countYear1, currentMonth, adminID));
          this.props.dispatch(getCountDataByMonth(countYear1, currentMonth, adminID));
        }else{
          let comingYear = parseInt(currentYear)+1;
          let countYear1= currentYear+"-"+comingYear
          this.setState({countYear:countYear1, countMonth : currentMonth, dataUserID : adminID});
          this.props.dispatch(getParticipantCount(countYear1, currentMonth, adminID));
          this.props.dispatch(getCountDataByMonth(countYear1, currentMonth, adminID));
        }
        // this.props.dispatch(getParticipantData());
        // this.props.dispatch(getUserListByAdmin(adminID, adminType));
        this.props.dispatch(getUserListByAdmin(adminID, adminType));
      }

    componentDidMount(){

         {/*  years-list-div  */}
        var buttonYearsClicked = false;  
        $(document).ready(function()
        {   
            $(".dashboard-btn-danger").click(function(){
                if( buttonYearsClicked ==false ) {
                    buttonYearsClicked= true; 
                    $('.showYear').toggle();
                    $('.showUsers').hide();
                    $(".showMonth").hide();
                }else{
                    buttonYearsClicked= false;
                    $(".showYear").hide(); 
                }
            });

            $(document).mouseup(function(e){ 
                if(e.currentTarget.activeElement.id ==="dashboard-btn-years"){
                    $(".showYear").show();
                  }else{
                    buttonYearsClicked = false;
                    $('.showUsers').hide();
                    $(".showMonth").hide();
                    $(".showYear").hide(); 
                }
            });
        });

        {/*  months-list-div  */}
        var buttonMonthsClicked = false;  
        $(document).ready(function()
            {   
                $(".dashboard-btn-success").click(function(){
                    if( buttonMonthsClicked ==false ) {
                        buttonMonthsClicked= true; 
                        $('.showMonth').toggle();
                        $('.showUsers').hide();
                        $(".showYear").hide(); 
                    }else{
                        buttonMonthsClicked= false;
                        $(".showMonth").hide();
                    }
                });
                $(document).mouseup(function(e){ 
                    if(e.currentTarget.activeElement.id ==="dashboard-btn-months"){
                      $(".showMonth").hide();
                    }else{
                        buttonMonthsClicked = false;
                        $('.showUsers').hide();
                        $(".showMonth").hide();
                        $(".showYear").hide(); 
                    }
                });
            });
            {/*  users-list-div  */}
            var buttonUsersclicked = false;   
            $(document).ready(function()
            {   
                $(".dashboard-btn-dark").click(function(){
                    if( buttonUsersclicked ==false ) {
                        buttonUsersclicked= true; 
                        $('.showUsers').toggle();
                        $(".showMonth").hide();
                        $(".showYear").hide(); 
                    }else{
                        buttonUsersclicked= false;
                        $(".showUsers").hide();
                    }
                });

                $(document).mouseup(function(e){ 
                    if(e.currentTarget.activeElement.id ==="dashboard-btn-users"){
                        $('.showUsers').hide();
                    }else{
                        buttonUsersclicked = false;
                        $('.showUsers').hide();
                        $(".showMonth").hide();
                        $(".showYear").hide(); 
                    }
                });
            });
    }



    getParticipantCount( year){
        this.props.dispatch(getParticipantCount( year, this.state.dataUserID));
    }

    getParticipantCountByUser( year, month, userid){
        if(userid =="" || userid == "0"){
            this.setState({dataUserID : this.state.adminID , studyListCurrentPage : 1});
            this.props.dispatch(getParticipantCount(year, month, this.state.adminID));
            this.props.dispatch(getLiveStudiesByUser( 1, this.state.adminID ));
            if(this.state.graphDataBy === "year")
            this.props.dispatch(getCountDataByYear(year, this.state.adminID));
            if(this.state.graphDataBy === "month")
            this.props.dispatch(getCountDataByMonth(year, month, this.state.adminID));

        }else{
            this.setState({dataUserID : userid , studyListCurrentPage : 1});
            this.props.dispatch(getParticipantCount(year, month, userid));
            this.props.dispatch(getLiveStudiesByUser( 1, userid ));
            if(this.state.graphDataBy === "year")
            this.props.dispatch(getCountDataByYear(year, userid));
            if(this.state.graphDataBy === "month")
            this.props.dispatch(getCountDataByMonth(year, month, userid));
        }
        
    }

    getParticipantCountByYear( year, month, userid){
        this.setState({ countYear : year});
        if(userid =="" || userid == "0"){
            this.setState({dataUserID : this.state.adminID});
            this.props.dispatch(getParticipantCount(year, month, this.state.adminID));
            if(this.state.graphDataBy === "year")
            this.props.dispatch(getCountDataByYear(year, this.state.adminID));
            if(this.state.graphDataBy === "month")
            this.props.dispatch(getCountDataByMonth(year, month, this.state.adminID));
        }else{
            this.setState({dataUserID : userid});
            this.props.dispatch(getParticipantCount(year, month, userid));
            this.props.dispatch(getCountDataByYear(year, userid));
            if(this.state.graphDataBy === "year")
            this.props.dispatch(getCountDataByYear(year, userid));
            if(this.state.graphDataBy === "month")
            this.props.dispatch(getCountDataByMonth(year, month, userid));
        }
    }

    getParticipantCountByMonth( year, month, userid){
        this.setState({ countMonth : month});
        if(userid =="" || userid == "0"){
            this.setState({dataUserID : this.state.adminID});
            this.props.dispatch(getParticipantCount(year, month, this.state.adminID));
            if(this.state.graphDataBy === "month")
            this.props.dispatch(getCountDataByMonth(year, month, this.state.adminID));
        }else{
            this.setState({dataUserID : userid});
            this.props.dispatch(getParticipantCount(year, month, userid));
            if(this.state.graphDataBy === "month")
            this.props.dispatch(getCountDataByMonth(year, month, userid));
        }
    }

    getLiveStudies( pagenumber){
        this.setState({ studyListCurrentPage : pagenumber});
        this.props.dispatch(getLiveStudiesByUser( pagenumber, this.state.dataUserID ));
    }

    setGraphBy( data){
        this.setState({ graphDataBy : data});
        if( data === "year")
        this.props.dispatch(getCountDataByYear( this.state.countYear, this.state.dataUserID));
        if( data === "month")
        this.props.dispatch(getCountDataByMonth( this.state.countYear, this.state.countMonth, this.state.dataUserID));
    }

    render(){
        return(
            <div style={{ width:"100%"}}>  
            <DashboardHeaderDiv
                countYear = { this.state.countYear}
                countMonth = { this.state.countMonth}
                dataUserID  = { this.state.dataUserID }
                userListSuccess = {this.props.userListSuccess}
                usersListData   = {this.props.usersListData}
                getParticipantCount = {this.getParticipantCount}
                getCountDataByMonth = {this.getCountDataByMonth}

                getParticipantCount = {this.getParticipantCount}
                getParticipantCountByUser = {this.getParticipantCountByUser}
                getParticipantCountByYear = {this.getParticipantCountByYear}
                getParticipantCountByMonth = {this.getParticipantCountByMonth}

            />
            <DashboardCountDiv
                participantCount ={this.props.participantCount}
            />
            <DashboardGraphDiv
                showParticipantYearCountData = { this.props.showParticipantYearCountData}
                showParticipantMonthCountData = { this.props.showParticipantMonthCountData}
                participantYearCountData = { this.props.participantYearCountData}
                participantMonthCountData = { this.props.participantMonthCountData}
                setGraphBy = { this.setGraphBy}
            />
            <DashboardStudyListDiv
                getLiveStudies = {this.getLiveStudies}
                studyListCurrentPage = {this.state.studyListCurrentPage}
            />
            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
      participantCount: state.dashboard.participantCount,
      participantData: state.dashboard.participantData,
      thirdPartyData: state.dashboard.thirdPartyData,
      studyListData: state.dashboard.studyListData,
      showParticipantYearCountData : state.dashboard.showParticipantYearCountData,
      showParticipantMonthCountData : state.dashboard.showParticipantMonthCountData,
      participantYearCountData : state.dashboard.participantYearCountData,
      participantMonthCountData : state.dashboard.participantMonthCountData,
      userListSuccess : state.dashboard.userListSuccess,
      usersListData : state.dashboard.usersListData
    }
  }
  
  export default connect(mapStateToProps)(DashboardView);
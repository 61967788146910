import React, { Component } from 'react';
import '../../commonStyle/common.css';
import $ from 'jquery';
import { getUserListByAdmin } from '../../actions/actionsBag/dashboard';
import appConfig from "../../api/apiConfig";
import goback from '../../images/goback.png';
import { connect } from 'react-redux';

class APIProjectsListHeader extends Component {

    constructor(props) {
        super(props)
        this.state = {}
        this.sendInvitationToVendors = this.sendInvitationToVendors.bind(this);
    }


    componentWillMount() {

        if (window.location.href && window.location.href.split('/').length > 3 && window.location.href.split('/')[3] === "Api-Study-List-Update") {
            localStorage.setItem("header-title", "Study-Update");
            localStorage.setItem("header-text", "Here you can update study");
        }
        if (window.location.href && window.location.href.split('/').length > 3 && window.location.href.split('/')[3] === "api-add-mapping") {
            localStorage.setItem("header-title", "Add-Mapping");
            localStorage.setItem("header-text", "Here you can map the third party");
        }
        if (window.location.href && window.location.href.split('/').length > 3 && window.location.href.split('/')[3] === "demo-mapping-list") {
            localStorage.setItem("header-title", "Add-Demographics-Mapping");
            localStorage.setItem("header-text", "Here you can mapped demographics, update and set quota on demographics");
        }
    }

    componentDidMount() {

        $(document).ready(function () {

            $('#flt-btn').click(function (e) {

                e.preventDefault(); // stops link from making page jump to the top
                e.stopPropagation(); // when you click the button, it stops the page from seeing it as clicking the body too
                $('.dropdownitem').toggle();

            });

            $('.dropdownitem').click(function (e) {

                // e.stopPropagation(); // when you click within the content area, it stops the page from seeing it as clicking the body too

            });

            $('body').click(function () {

                $('.dropdownitem').hide();

            });

        });

    }


    selectActivity(activityName) {
        if (activityName === "api-add-mapping") {
            localStorage.setItem("header-title", "Add-Mapping");
            localStorage.setItem("header-text", "Here you can map the third party");
        } else if (activityName === "participant-list") {
            localStorage.setItem("header-title", "Participant-List");
            localStorage.setItem("header-text", "Here you can see all participants");
        } else if (activityName === "api-send-invitation") {
            localStorage.setItem("header-title", "Send Invitation");
            localStorage.setItem("header-text", "Here you can send invitation to your panelist");
        } else if (activityName === "demo-mapping-list") {
            localStorage.setItem("header-title", "Add-Demographics-Mapping");
            localStorage.setItem("header-text", "Here you can mapped demographics, update and set quota on demographics");
        } else if (activityName === "create-clone") {
            localStorage.setItem("header-title", "Create Study Clone");
            localStorage.setItem("header-text", "Here you can create clone of your study");
        }

        this.props.selectActivity(activityName);
    }

    showInvitationBox() {
        document.getElementById("invitaionbox").style.display = "flex";
    }

    sendInvitationToVendors() {
        this.props.sendInvitationToVendors();
    }

    render() {
        let showAddMapping = "";
        let showSendInvitation = "";
        let showCreateDemoMap = "";
        //let showCreateClone = <li onClick={() => this.selectActivity("create-clone")}>Create clone</li>
        let showCreateClone = "";
        if (localStorage.getItem("is_ShowAddMapping") == 1)
            showAddMapping = <li onClick={() => this.selectActivity("api-add-mapping")}>Add mapping</li>
        if (localStorage.getItem("is_SendInvitation") == 1)
            // showSendInvitation = <li onClick={() => this.selectActivity("api-send-invitation")}>Send invitation</li>
          //  showSendInvitation = <li onClick={() => this.sendInvitationToVendors()}>Send invitation</li>
        if (localStorage.getItem("is_CreateDemoMapping") == 1)
         //   showCreateDemoMap = <li onClick={() => this.selectActivity("demo-mapping-list")}>Create demo mapping</li>
        return (
            <div className="router-header">
                <div className="router-header1 all-header">
                    <div>
                        <h2 className="head-two-st">{localStorage.getItem("header-title")}</h2>
                        <p className="paragraph-st color-secondary para-st-font">{localStorage.getItem("header-text")}</p>
                    </div>

                    <div className="flex-show">
                        <div><button onClick={this.props.gotoBack} className="list-btn list-btn-color margin-right"><img src={goback} height="13px" width="13px" /><span> Back</span></button></div>
                        <div><button className="list-btn list-btn-color" id="flt-btn"><span >Project Activities<div className="flter-btn-downarrow" style={{ paddingRight: "20px" }}></div></span></button>
                            <div className="dropdownitem dropdown-shadow">
                                <ul>
                                    {showAddMapping}
                                    {/* <li onClick={() => this.selectActivity("participant-list")}>Participant list</li> */}
                                    {showSendInvitation}
                                    {showCreateClone}
                                    {/* {showCreateDemoMap} */}
                                    {/* <li >Show add constraints</li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    // render(){
    //     return(

    // <div className="router-header">
    //     <div className="router-header1 all-header">
    //         <div>
    //             <h2 className="head-two-st">API Project List</h2>
    //             <p className="paragraph-st color-secondary para-st-font">Here you can see all API project list and you can search by Survey id</p>
    //          </div>
    //     </div>
    // </div>
    // )}
}
const mapStateToProps = (state) => {
    return {}
}

export default connect(mapStateToProps)(APIProjectsListHeader);

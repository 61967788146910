import { thirdPartyApi, thirdPartyListApi, removeThirdpartyApi, getThirdPartyApi } from "../../api/apiBag/vendorApi";
import {startSpinner, endSpinner} from "../../loadingSpinner/Action";
//import {setMessage} from '../../popupAlert/action'

export const setThirdParty = (result) => {
  return {
    type: "SET_THIRD_PARTY",
    result

  }
}


export const setThirdPartyForStudyList = (result) => {
  return {
    type: "SET_THIRD_PARTY_FOR_STUDY_LIST",
    result

  }
}

export const setThirdPartyList = (thirdPartyList) => {
  return {
    type: "SET_THIRD_PARTY_DATA",
    thirdPartyList

  }
}

export const editThirdParty = (item) => {
  return {
    type: "EDIT_THIRD_PARTY_DATA",
    item

  }
}

export const initialize = () => {
  return {
    type: "INITIALIZE_DATA",
  }
}

export const setRemoveResponse = (response) => {
  return {
    type: "REMOVE_THIRD_PARTY",
    response
  }
}

// emptyVendorProps
export const emptyVendorProps = () => {
  return {
    type: "EMPTY_VENDOR_PROPS",
    
  }
}

export const addThirdParty = (data) => {
  return dispatch => {
    dispatch(startSpinner());
    thirdPartyApi(data).then((result) => {
      dispatch(endSpinner());
      //dispatch(setMessage(result));
      dispatch(setThirdParty(result));
    });
  }
}


export const getThirdParty = () => {
  return dispatch => {
    dispatch(startSpinner());
    getThirdPartyApi().then((result) => {
      dispatch(endSpinner());
      //dispatch(setMessage(result));
      dispatch(setThirdPartyForStudyList(result));
    });
  }
}
export const ThirdPartyList = (currentpage) => {
  return dispatch => {
    dispatch(startSpinner());
    thirdPartyListApi( currentpage).then((result) => {
      dispatch(endSpinner());
      dispatch(setThirdPartyList(result));
    });
  }
}

export const remove = (id) => {

  return dispatch => {
    dispatch(startSpinner());
    removeThirdpartyApi(id).then((result) => {
      dispatch(endSpinner());
      dispatch(setRemoveResponse(result));
    });
  }
}
import appConfig from "../apiConfig";

//
export const addRoleApi =(cloneData)=> {
	let study =  JSON.stringify(cloneData);
	const headers = Object.assign({'Content-Type': 'application/json'});
	const request= new Request(appConfig.siteURL + 'createRoleUsers', {
	  method : "POST",
	  headers : headers,
	  body : study
	});
  
  return  fetch(request).then(response => {
	return  response.json().then(study => {
		return study;
	});
	 }).catch(error => {return error;});
	}

//updateRoleApi
export const updateRoleApi =(data)=> {
	let study =  JSON.stringify(data);
	const headers = Object.assign({'Content-Type': 'application/json'});
	const request= new Request(appConfig.siteURL + 'updateRole', {
	  method : "POST",
	  headers : headers,
	  body : study
	});
  
  return  fetch(request).then(response => {
	return  response.json().then(study => {
		return study;
	});
	 }).catch(error => {return error;});
	}
	
//getOPmanagerApi
export const getOPmanagerApi =(id)=> {

	const headers = Object.assign({'Content-Type': 'application/json'});
	const request= new Request(appConfig.siteURL + 'getOPmanagerDataByAdmin/'+id, {
		method : "GET",
		headers : headers
	});
	
	return  fetch(request).then(response => {
	return  response.json().then(study => {
		return study;
	});
		}).catch(error => {return error;});
	
	}


	//getAllRolesByAdminApi

	export const getAllRolesByAdminApi =()=> {

	const headers = Object.assign({'Content-Type': 'application/json'});
	const request= new Request(appConfig.siteURL + 'getAllRoles', {
			method : "GET",
			headers : headers
	});

	return  fetch(request).then(response => {
	return  response.json().then(study => {
			return study;
	});
			}).catch(error => {return error;});
	}
	
	//getPermissionByRoleApi

	export const getPermissionByRoleApi =(id)=> {
	
		const headers = Object.assign({'Content-Type': 'application/json'});
		const request= new Request(appConfig.siteURL + 'getRolePermission/'+id, {
				method : "GET",
				headers : headers
		});

		return  fetch(request).then(response => {
		return  response.json().then(rolePermission => {
				return rolePermission;
			});
		}).catch(error => {return error;});
		}

//assingPermissionRoleApi

export const assingPermissionRoleApi =(data)=> {
	let study =  JSON.stringify(data);
	const headers = Object.assign({'Content-Type': 'application/json'});
	const request= new Request(appConfig.siteURL + 'assignUsersPermissions', {
	  method : "POST",
	  headers : headers,
	  body : study
	});
  
  return  fetch(request).then(response => {
	return  response.json().then(study => {
		return study;
	});
	 }).catch(error => {return error;});
	}

	//getAllRolesByAdminByPageApi
	export const getAllRolesByAdminByPageApi =( pageNumber)=> {

		const headers = Object.assign({'Content-Type': 'application/json'});
		const request= new Request(appConfig.siteURL + 'getAllRolesWithPagination'+'/'+ pageNumber, {
				method : "GET",
				headers : headers
		});
	
		return  fetch(request).then(response => {
			return  response.json().then(study => {
					return study;
			});
		}).catch(error => {return error;});
	}

	//softRoleDeleteApi
	export const softRoleDeleteApi =(data)=> {
		let study =  JSON.stringify(data);
		const headers = Object.assign({'Content-Type': 'application/json'});
		const request= new Request(appConfig.siteURL + 'disableRole', {
		  method : "POST",
		  headers : headers,
		  body : study
		});
	  
	  return  fetch(request).then(response => {
		return  response.json().then(study => {
			return study;
		});
		 }).catch(error => {return error;});
		}
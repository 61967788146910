import React, { Component }  from 'react';
import { getProgressReportDataByStudy} from '../../../actions/actionsBag/study';
import {connect} from 'react-redux';
import Pagination from "react-js-pagination";
import $ from 'jquery';


class ParticipantList extends Component {
    constructor(props){
        super(props)
        this.state = {
            search :""
        }
    }


    componentWillMount(){
        let studyId = localStorage.getItem("edit_study_id");
       
        this.props.dispatch( getProgressReportDataByStudy( studyId));
    }

    tableZoom(){
        $(".alltablestruct").toggleClass("zoom-me");
      }
    
    render(){

    
    let participantItems = "";
    let progressData = this.props.updateStudyProgressData;

    if (progressData && progressData.participantData && progressData.participantData.length > 0) {
        participantItems = progressData.participantData.filter(
        (participants, index) => {
            if (this.state.search === "") {
            return true;
            }
            else if (participants.status.indexOf(this.state.search) !== -1) {
            return true;
            }
        }).map((item, index) => {
            let loi = "";
            let status = "";

            let date ="";
            let time="";
            
            if(item.createdAt.includes("T")){
                date = item.createdAt.split("T")[0];
                time = item.createdAt.split("T")[1].split(".")[0];
            }else{
                date = item.createdAt.split(" ")[0];
                time = item.createdAt.split(" ")[1].split(".")[0];
            }         
            
            if (item.loi) {
            if (typeof item.loi == "number") {

                item.loi.toFixed(2)

            } else {

                loi = parseFloat(item.loi).toFixed(2)
            }
            }
            switch (item.status) {

            case "1":
                status = "Completed";
                break;
            case "2":
                status = "Over Quota";
                break;
            case "3":
                status = "Terminated";
                break;
            case "0":
                status = "Not Completed";
                break;
            case "4":
                status = "Internal Terminated";
                break;
            case "5":
                status = "Internal Over Quota";
                break;
            case "6":
                status = "Failed In Loi";
                break;
            case "7":
                status = "Failed In Ip"
            }
            return <tr className="s" key={index}>
            <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{index + 1}</td>
            <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{item._id}</td>
            <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{item.uid}</td>
            <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{date}</td>
            <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{time}</td>
            <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{item.participantIp}</td>
            {/* <td className="tdd">{loi}</td> */}
            <td className="tdd">{status}</td>
            </tr>
        })
    }


    let showTable = "";
    if(progressData && progressData.participantData && progressData.participantData.length > 0){
      showTable =  <table className="tbllayout " >
                <thead >
                    <tr>
                        <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-blue tbl-th-fix">S.no.</th>
                        <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-blue tbl-th-fix">ID</th>
                        <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-blue tbl-th-fix">Respondent ID</th>
                        <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-blue tbl-th-fix">Started Date</th>
                        <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-blue tbl-th-fix">Started Time</th>
                        <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-blue tbl-th-fix">IP Address</th>
                        <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-blue tbl-th-fix">Status</th>
                    </tr>
                </thead>
                <tbody>
                        {participantItems}
                        </tbody>
                </table>  
    }else if(progressData && progressData.participantData && progressData.participantData.length === 0){
            showTable = <div className="datanotfound"> No record found</div>
    }else{
        showTable = <div className="spiner-data"><div className="tblspinner"></div></div>
    }


        return(
            <div className="list-style">
            <div className="table-st">
                <div className="alltablestruct">
                     {/* serachbox and stausbtn */}
                    <div id="search-staus">

                        <div className="flex-show  tbl-h-font">
                            Participants List
                        </div>
                        
                        <div className="flex-show tbl-zoom" >
                        <span onClick={this.tableZoom} className="full-screen mr-rhs-20 mr-top-10"></span>
                        </div>
                    </div>
                    {/* serachbox and stausbtn */}

                        {/* table */}
                        <div style={{marginTop: "20px", overflowX: "auto",height: "500px"}}>
                        {showTable} 
                        </div>
                        {/* table */}
               
                  {/* <div id="pageinationstyle">
                   
                  <Pagination
                    //   activePage={this.state.currentPage}
                    //   itemsCountPerPage={5}
                    //   totalItemsCount={this.state.totalPage}
                    //   pageRangeDisplayed={5}
                    //   onChange={this.handlePageChange}
                    />
                    </div> */}
                
                </div>
            </div>
        </div>
     
    )}
}

const mapStateToProps=(state)=>{
    return{
        updateStudyProgressData : state.studies.updateStudyProgressData
    }
  }
  
  export default connect(mapStateToProps)(ParticipantList);
import React, { Component } from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import goback from '../../images/goback.png';

class AssignUserPermission extends Component {
  constructor(props) {
    super(props)
    this.state = {
      role: "",
      pageChange: "",
      roleStatus: "",
      currentUser: "",
      roleId: "",
      checked: "",
      isDashboard: 0,
      isStudy: 0,
      isStudyCreate: 0,
      isStudyList: 0,
      isStudyUpdate: 0,
      isStudyDelete: 0,
      isAllStudy: 0,

      isVendor: 0,
      isVendorCreate: 0,
      isVendorList: 0,
      isVendorUpdate: 0,
      isDemographic: 0,
      isDemographicCreate: 0,
      isDemographicList: 0,
      isDemographicUpdate: 0,
      isUser: 0,
      isUserCreate: 0,
      isUserList: 0,
      isUserUpdate: 0,
      isRole: 0,
      isRolecreate: 0,
      isRolelist: 0,
      isRoleupdate: 0,

      isShowAddMapping: 0,
      isShowUpdateMapping: 0,
      isShowParticipantList: 0,
      isSendInvitation: 0,
      isCreateDemoMapping: 0,
      isShowAddConstraint: 0,

      isClient: 0,
      isClientCreate: 0,
      isClientList: 0,
      isClientUpdate: 0,

      isInvoice: 0,
      isInvoiceCreate: 0,
      isInvoiceList: 0,
      isSendEmail: 0,
      isApiProjectList: 0,
      isArchiving: 0
    };

    this.submitAssignPermission = this.submitAssignPermission.bind(this);

    this.onDashbaordChange = this.onDashbaordChange.bind(this);
    this.onStudyChange = this.onStudyChange.bind(this);
    this.onStudyCreateChange = this.onStudyCreateChange.bind(this);
    this.onStudyListChange = this.onStudyListChange.bind(this);
    this.onStudyUpdateChange = this.onStudyUpdateChange.bind(this);
    this.onAllStudyChange = this.onAllStudyChange.bind(this);

    this.onVendorChange = this.onVendorChange.bind(this);
    this.onVendorCreateChange = this.onVendorCreateChange.bind(this);
    this.onVendorListChange = this.onVendorListChange.bind(this);
    this.onVendorUpdateChange = this.onVendorUpdateChange.bind(this);
    this.onDemographicChange = this.onDemographicChange.bind(this);
    this.onDemographicCreateChange = this.onDemographicCreateChange.bind(this);
    this.onDemographicListChange = this.onDemographicListChange.bind(this);
    this.onDemographicUpdateChange = this.onDemographicUpdateChange.bind(this);
    this.onUserChange = this.onUserChange.bind(this);
    this.onUserCreateChange = this.onUserCreateChange.bind(this);
    this.onUserListChange = this.onUserListChange.bind(this);
    this.onUserUpdateChange = this.onUserUpdateChange.bind(this);
    this.onRoleChangePermission = this.onRoleChangePermission.bind(this);
    this.onRolecreateChange = this.onRolecreateChange.bind(this);
    this.onRolelistChange = this.onRolelistChange.bind(this);
    this.onRoleupdateChange = this.onRoleupdateChange.bind(this);
    this.showRoleList = this.showRoleList.bind(this);

    this.onShowAddMappingChange = this.onShowAddMappingChange.bind(this);
    this.onShowUpdateMappingChange = this.onShowUpdateMappingChange.bind(this);
    this.onShowParticipantListChange = this.onShowParticipantListChange.bind(this);
    this.onSendInvitationChange = this.onSendInvitationChange.bind(this);
    this.onCreateDemoMappingChange = this.onCreateDemoMappingChange.bind(this);
    this.onShowAddConstraintChange = this.onShowAddConstraintChange.bind(this);
    this.onStudyDelete = this.onStudyDelete.bind(this);

    this.onClientChange = this.onClientChange.bind(this);
    this.onClientCreateChange = this.onClientCreateChange.bind(this);
    this.onClientListChange = this.onClientListChange.bind(this);
    this.onClientUpdateChange = this.onClientUpdateChange.bind(this);
    this.onInvoiceChange = this.onInvoiceChange.bind(this);
    this.onInvoiceCreateChange = this.onInvoiceCreateChange.bind(this);
    this.onInvoiceListChange = this.onInvoiceListChange.bind(this);

    this.onSendEmailChange = this.onSendEmailChange.bind(this);
    this.onApiProjectListChange = this.onApiProjectListChange.bind(this);
    this.onArchivingChange = this.onArchivingChange.bind(this);
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.rolePermissionUpdate) {
      //  alert(nextProps.rolePermissionUpdateMsg);
      this.props.showAlertMessage(nextProps.rolePermissionUpdateMsg);
      this.props.blankResRoles();
      this.props.showRoleList();

    } else if (nextProps.rolePermissionUpdateMsg) {
      // alert(nextProps.rolePermissionUpdateMsg);
      this.props.showAlertMessage(nextProps.rolePermissionUpdateMsg);
      this.props.blankResRoles();
    }
  }

  componentWillMount() {
    if (this.props.rolePermissionSuccess && this.props.rolePermissionData && this.props.rolePermissionData.length > 0) {
      this.setState({
        roleId: this.props.rolePermissionData[0].role_id,
        isDashboard: this.props.rolePermissionData[0].is_Dashboard,
        isStudy: this.props.rolePermissionData[0].is_Study,
        isStudyCreate: this.props.rolePermissionData[0].is_Study_create,
        isAllStudy: this.props.rolePermissionData[0].isAllStudy,
        isStudyList: this.props.rolePermissionData[0].is_Study_list,
        isStudyUpdate: this.props.rolePermissionData[0].is_Study_update,
        isStudyDelete: this.props.rolePermissionData[0].is_StudyDelete,
        isVendor: this.props.rolePermissionData[0].is_Vendor,
        isVendorCreate: this.props.rolePermissionData[0].is_Vendor_create,
        isVendorList: this.props.rolePermissionData[0].is_Vendor_list,
        isVendorUpdate: this.props.rolePermissionData[0].is_Vendor_update,
        isDemographic: this.props.rolePermissionData[0].is_Demographics,
        isDemographicCreate: this.props.rolePermissionData[0].is_Demographics_create,
        isDemographicList: this.props.rolePermissionData[0].is_Demographics_list,
        isDemographicUpdate: this.props.rolePermissionData[0].is_Demographics_update,
        isUser: this.props.rolePermissionData[0].is_user,
        isUserCreate: this.props.rolePermissionData[0].is_user_create,
        isUserList: this.props.rolePermissionData[0].is_user_list,
        isUserUpdate: this.props.rolePermissionData[0].is_user_update,
        isRole: this.props.rolePermissionData[0].is_Role,
        isRolecreate: this.props.rolePermissionData[0].is_Role_create,
        isRolelist: this.props.rolePermissionData[0].is_Role_list,
        isRoleupdate: this.props.rolePermissionData[0].is_Role_update,



        isShowAddMapping: this.props.rolePermissionData[0].is_ShowAddMapping,
        isShowUpdateMapping: this.props.rolePermissionData[0].is_ShowUpdateMapping,
        isShowParticipantList: this.props.rolePermissionData[0].is_ShowParticipantList,
        isSendInvitation: this.props.rolePermissionData[0].is_SendInvitation,
        isCreateDemoMapping: this.props.rolePermissionData[0].is_CreateDemoMapping,
        isShowAddConstraint: this.props.rolePermissionData[0].is_ShowAddConstraint,


        isClient: this.props.rolePermissionData[0].is_client,
        isClientCreate: this.props.rolePermissionData[0].is_client_create,
        isClientList: this.props.rolePermissionData[0].is_client_list,
        isClientUpdate: this.props.rolePermissionData[0].is_client_update,

        isInvoice: this.props.rolePermissionData[0].is_invoice,
        isInvoiceCreate: this.props.rolePermissionData[0].is_invoice_create,
        isInvoiceList: this.props.rolePermissionData[0].is_invoice_list,

        isSendEmail: this.props.rolePermissionData[0].is_send_email,
        isApiProjectList: this.props.rolePermissionData[0].is_api_project_list,
        isArchiving : this.props.rolePermissionData[0].is_archiving
      });
    }
  }


  onDashbaordChange() {
    if (this.state.isDashboard == 0) {
      this.setState({ isDashboard: 1 });
    } else if (this.state.isDashboard == 1) {
      this.setState({ isDashboard: 0 });
    }
  }

  onStudyChange() {
    if (this.state.isStudy == 0) {
      this.setState({ isStudy: 1, isStudyCreate: 1, isStudyList: 1, isStudyUpdate: 1, isStudyDelete: 1, isShowAddMapping: 1, isShowUpdateMapping: 1, isAllStudy: 1 });
    } else if (this.state.isStudy == 1) {
      this.setState({ isStudy: 0, isStudyCreate: 0, isStudyList: 0, isStudyUpdate: 0, isStudyDelete: 0, isShowAddMapping: 0, isShowUpdateMapping: 0, isAllStudy: 0 });
    }
  }

  onStudyCreateChange() {
    if (this.state.isStudyCreate == 0) {
      this.setState({ isStudyCreate: 1, isStudy: 1 });
    } else if (this.state.isStudyCreate == 1) {
      this.setState({ isStudyCreate: 0 });

      if (this.state.isAllStudy === 0 && this.state.isStudyList === 0 && this.state.isStudyUpdate === 0 && this.state.isStudyDelete === 0 && this.state.isShowAddMapping === 0 && this.state.isShowUpdateMapping === 0) {
        this.setState({ isStudy: 0 });
      }
    }
  }

  onAllStudyChange() {
    if (this.state.isAllStudy == 0) {
      this.setState({ isAllStudy: 1, isStudy: 1, isStudyList: 1 });
    } else if (this.state.isAllStudy == 1) {
      this.setState({ isAllStudy: 0 });

      if (this.state.isStudyCreate === 0 && this.state.isStudyList === 0 && this.state.isStudyUpdate === 0 && this.state.isStudyDelete === 0 && this.state.isShowAddMapping === 0 && this.state.isShowUpdateMapping === 0) {
        this.setState({ isStudy: 0, isStudyList: 0 });
      }
    }
  }

  onStudyListChange() {
    if (this.state.isStudyList == 0) {
      this.setState({ isStudyList: 1, isStudy: 1 });
    } else if (this.state.isStudyList == 1) {
      this.setState({ isStudyList: 0 });

      if (this.state.isStudyCreate === 0 && this.state.isStudyUpdate === 0 && this.state.isStudyDelete === 0 && this.state.isShowAddMapping === 0 && this.state.isShowUpdateMapping === 0) {
        this.setState({ isStudy: 0, isAllStudy: 0 });
      }
    }
  }

  onStudyUpdateChange() {
    if (this.state.isStudyUpdate == 0) {
      this.setState({ isStudyUpdate: 1, isStudy: 1 });
    } else if (this.state.isStudyUpdate == 1) {
      this.setState({ isStudyUpdate: 0 });

      if (this.state.isAllStudy === 0 && this.state.isStudyCreate === 0 && this.state.isStudyList === 0 && this.state.isStudyDelete === 0 && this.state.isShowAddMapping === 0 && this.state.isShowUpdateMapping === 0) {
        this.setState({ isStudy: 0 });
      }
    }
  }

  onStudyDelete() {
    if (this.state.isStudyDelete == 0) {
      this.setState({ isStudyDelete: 1, isStudy: 1 });
    } else if (this.state.isStudyDelete == 1) {
      this.setState({ isStudyDelete: 0 });

      if (this.state.isAllStudy === 0 && this.state.isStudyCreate === 0 && this.state.isStudyList === 0 && this.state.isStudyUpdate === 0 && this.state.isShowAddMapping === 0 && this.state.isShowUpdateMapping === 0) {
        this.setState({ isStudy: 0 });
      }
    }
  }

  onShowAddMappingChange() {
    if (this.state.isShowAddMapping == 0) {
      this.setState({ isShowAddMapping: 1, isStudy: 1 });
    } else if (this.state.isShowAddMapping == 1) {
      this.setState({ isShowAddMapping: 0 });
      if (this.state.isAllStudy === 0 && this.state.isStudyCreate === 0 && this.state.isStudyList === 0 && this.state.isStudyUpdate === 0 && this.state.isStudyDelete === 0 && this.state.isShowUpdateMapping === 0) {
        this.setState({ isStudy: 0 });
      }
    }
  }

  onShowUpdateMappingChange() {
    if (this.state.isShowUpdateMapping == 0) {
      this.setState({ isShowUpdateMapping: 1, isStudy: 1 });
    } else if (this.state.isShowUpdateMapping == 1) {
      this.setState({ isShowUpdateMapping: 0 });
      if (this.state.isAllStudy === 0 && this.state.isStudyCreate === 0 && this.state.isStudyList === 0 && this.state.isStudyUpdate === 0 && this.state.isStudyDelete === 0 && this.state.isShowAddMapping === 0) {
        this.setState({ isStudy: 0 });
      }
    }
  }

  onVendorChange() {
    if (this.state.isVendor == 0) {
      this.setState({ isVendor: 1, isVendorCreate: 1, isVendorList: 1, isVendorUpdate: 1 });
    } else if (this.state.isVendor == 1) {
      this.setState({ isVendor: 0, isVendorCreate: 0, isVendorList: 0, isVendorUpdate: 0 });
    }
  }

  onVendorCreateChange() {
    if (this.state.isVendorCreate == 0) {
      this.setState({ isVendorCreate: 1, isVendor: 1 });
    } else if (this.state.isVendorCreate == 1) {
      this.setState({ isVendorCreate: 0 });

      if (this.state.isVendorList === 0 && this.state.isVendorUpdate === 0) {
        this.setState({ isVendor: 0 });
      }
    }
  }

  onVendorListChange() {
    if (this.state.isVendorList == 0) {
      this.setState({ isVendorList: 1, isVendor: 1 });
    } else if (this.state.isVendorList == 1) {
      this.setState({ isVendorList: 0 });

      if (this.state.isVendorCreate === 0 && this.state.isVendorUpdate === 0) {
        this.setState({ isVendor: 0 });
      }
    }
  }

  onVendorUpdateChange() {
    if (this.state.isVendorUpdate == 0) {
      this.setState({ isVendorUpdate: 1, isVendor: 1 });
    } else if (this.state.isVendorUpdate == 1) {
      this.setState({ isVendorUpdate: 0 });

      if (this.state.isVendorCreate === 0 && this.state.isVendorList === 0) {
        this.setState({ isVendor: 0 });
      }
    }
  }

  onDemographicChange() {
    if (this.state.isDemographic == 0) {
      this.setState({ isDemographic: 1, isDemographicCreate: 1, isDemographicList: 1, isDemographicUpdate: 1 });
    } else if (this.state.isDemographic == 1) {
      this.setState({ isDemographic: 0, isDemographicCreate: 0, isDemographicList: 0, isDemographicUpdate: 0 });
    }
  }

  onDemographicCreateChange() {
    if (this.state.isDemographicCreate == 0) {
      this.setState({ isDemographicCreate: 1, isDemographic: 1 });
    } else if (this.state.isDemographicCreate == 1) {
      this.setState({ isDemographicCreate: 0 });

      if (this.state.isDemographicList === 0 && this.state.isDemographicUpdate === 0) {
        this.setState({ isDemographic: 0 });
      }
    }
  }

  onDemographicListChange() {
    if (this.state.isDemographicList == 0) {
      this.setState({ isDemographicList: 1, isDemographic: 1 });
    } else if (this.state.isDemographicList == 1) {
      this.setState({ isDemographicList: 0 });

      if (this.state.isDemographicCreate === 0 && this.state.isDemographicUpdate === 0) {
        this.setState({ isDemographic: 0 });
      }
    }
  }

  onDemographicUpdateChange() {
    if (this.state.isDemographicUpdate == 0) {
      this.setState({ isDemographicUpdate: 1, isDemographic: 1 });
    } else if (this.state.isDemographicUpdate == 1) {
      this.setState({ isDemographicUpdate: 0 });

      if (this.state.isDemographicCreate === 0 && this.state.isDemographicList === 0) {
        this.setState({ isDemographic: 0 });
      }
    }
  }

  onUserChange() {
    if (this.state.isUser == 0) {
      this.setState({ isUser: 1, isUserCreate: 1, isUserList: 1, isUserUpdate: 1 });
    } else if (this.state.isUser == 1) {
      this.setState({ isUser: 0, isUserCreate: 0, isUserList: 0, isUserUpdate: 0 });
    }
  }

  onUserCreateChange() {
    if (this.state.isUserCreate == 0) {
      this.setState({ isUserCreate: 1, isUser: 1 });
    } else if (this.state.isUserCreate == 1) {
      this.setState({ isUserCreate: 0 });

      if (this.state.isUserList === 0 && this.state.isUserUpdate === 0) {
        this.setState({ isUser: 0 });
      }
    }
  }

  onUserListChange() {
    if (this.state.isUserList == 0) {
      this.setState({ isUserList: 1, isUser: 1 });
    } else if (this.state.isUserList == 1) {
      this.setState({ isUserList: 0 });

      if (this.state.isUserCreate === 0 && this.state.isUserUpdate === 0) {
        this.setState({ isUser: 0 });
      }
    }
  }

  onUserUpdateChange() {
    if (this.state.isUserUpdate == 0) {
      this.setState({ isUserUpdate: 1, isUser: 1 });
    } else if (this.state.isUserUpdate == 1) {
      this.setState({ isUserUpdate: 0 });

      if (this.state.isUserCreate === 0 && this.state.isUserList === 0) {
        this.setState({ isUser: 0 });
      }
    }
  }

  onRoleChangePermission() {
    if (this.state.isRole == 0) {
      this.setState({ isRole: 1, isRolecreate: 1, isRolelist: 1, isRoleupdate: 1 });
    } else if (this.state.isRole == 1) {
      this.setState({ isRole: 0, isRolecreate: 0, isRolelist: 0, isRoleupdate: 0 });
    }
  }

  onRolecreateChange() {
    if (this.state.isRolecreate == 0) {
      this.setState({ isRolecreate: 1, isRole: 1 });
    } else if (this.state.isRolecreate == 1) {
      this.setState({ isRolecreate: 0 });

      if (this.state.isRoleupdate === 0 && this.state.isRolelist === 0) {
        this.setState({ isRole: 0 });
      }
    }
  }

  onRolelistChange() {
    if (this.state.isRolelist == 0) {
      this.setState({ isRolelist: 1, isRole: 1 });
    } else if (this.state.isRolelist == 1) {
      this.setState({ isRolelist: 0 });

      if (this.state.isRoleupdate === 0 && this.state.isRolecreate === 0) {
        this.setState({ isRole: 0 });
      }
    }
  }

  onRoleupdateChange() {
    if (this.state.isRoleupdate == 0) {
      this.setState({ isRoleupdate: 1, isRole: 1 });
    } else if (this.state.isRoleupdate == 1) {
      this.setState({ isRoleupdate: 0 });

      if (this.state.isRolelist === 0 && this.state.isRolecreate === 0) {
        this.setState({ isRole: 0 });
      }
    }
  }

  onShowParticipantListChange() {
    if (this.state.isShowParticipantList == 0) {
      this.setState({ isShowParticipantList: 1 });
    } else if (this.state.isShowParticipantList == 1) {
      this.setState({ isShowParticipantList: 0 });
    }
  }

  onSendInvitationChange() {
    if (this.state.isSendInvitation == 0) {
      this.setState({ isSendInvitation: 1 });
    } else if (this.state.isSendInvitation == 1) {
      this.setState({ isSendInvitation: 0 });
    }
  }

  onCreateDemoMappingChange() {
    if (this.state.isCreateDemoMapping == 0) {
      this.setState({ isCreateDemoMapping: 1 });
    } else if (this.state.isCreateDemoMapping == 1) {
      this.setState({ isCreateDemoMapping: 0 });
    }
  }

  onShowAddConstraintChange() {
    if (this.state.isShowAddConstraint == 0) {
      this.setState({ isShowAddConstraint: 1 });
    } else if (this.state.isShowAddConstraint == 1) {
      this.setState({ isShowAddConstraint: 0 });
    }
  }

  onClientChange() {
    if (this.state.isClient == 0) {
      this.setState({ isClient: 1, isClientCreate: 1, isClientList: 1, isClientUpdate: 1 });
    } else if (this.state.isClient == 1) {
      this.setState({ isClient: 0, isClientCreate: 0, isClientList: 0, isClientUpdate: 0 });
    }
  }

  onClientCreateChange() {
    if (this.state.isClientCreate == 0) {
      this.setState({ isClientCreate: 1, isClient: 1 });
    } else if (this.state.isClientCreate == 1) {
      this.setState({ isClientCreate: 0 });

      if (this.state.isClientList === 0 && this.state.isClientUpdate === 0) {
        this.setState({ isClient: 0 });
      }
    }
  }

  onClientListChange() {
    if (this.state.isClientList == 0) {
      this.setState({ isClientList: 1, isClient: 1 });
    } else if (this.state.isClientList == 1) {
      this.setState({ isClientList: 0 });

      if (this.state.isClientCreate === 0 && this.state.isClientUpdate === 0) {
        this.setState({ isClient: 0 });
      }
    }
  }

  onClientUpdateChange() {
    if (this.state.isClientUpdate == 0) {
      this.setState({ isClientUpdate: 1, isClient: 1 });
    } else if (this.state.isClientUpdate == 1) {
      this.setState({ isClientUpdate: 0 });

      if (this.state.isClientCreate === 0 && this.state.isClientList === 0) {
        this.setState({ isClient: 0 });
      }
    }
  }


  onInvoiceChange(e) {
    if (e.target.value == 0) {
      this.setState({ isInvoice: 1, isInvoiceCreate: 1, isInvoiceList: 1 });
    } else {
      this.setState({ isInvoice: 0, isInvoiceCreate: 0, isInvoiceList: 0 });
    }
  }

  onInvoiceCreateChange() {
    if (this.state.isInvoiceCreate == 0) {
      this.setState({ isInvoiceCreate: 1, isInvoice: 1, });
    } else if (this.state.isInvoiceCreate == 1) {
      this.setState({ isInvoiceCreate: 0 });

      if (this.state.isInvoiceList === 0) {
        this.setState({ isInvoice: 0 });
      }
    }
  }

  onInvoiceListChange() {
    if (this.state.isInvoiceList == 0) {
      this.setState({ isInvoiceList: 1, isInvoice: 1, });
    } else if (this.state.isInvoiceList == 1) {
      this.setState({ isInvoiceList: 0 });

      if (this.state.isInvoiceCreate === 0) {
        this.setState({ isInvoice: 0 });
      }
    }
  }

  onSendEmailChange() {
    if (this.state.isSendEmail == 0) {
      this.setState({ isSendEmail: 1 });
    } else if (this.state.isSendEmail == 1) {
      this.setState({ isSendEmail: 0 });
    }
  }

  onApiProjectListChange() {
    if (this.state.isApiProjectList == 0) {
      this.setState({ isApiProjectList: 1 });
    } else if (this.state.isApiProjectList == 1) {
      this.setState({ isApiProjectList: 0 });
    }
  }

  
  onArchivingChange() {
    if (this.state.isArchiving == 0) {
      this.setState({ isArchiving: 1 });
    } else if (this.state.isArchiving == 1) {
      this.setState({ isArchiving: 0 });
    }
  }

  submitAssignPermission() {
    let data = {};
    data.role_id = this.state.roleId;
    data.assign_by_id = localStorage.getItem("auth_user_id");
    data.is_Dashboard = this.state.isDashboard;
    data.is_Study = this.state.isStudy;
    data.is_Study_create = this.state.isStudyCreate;
    data.is_Study_list = this.state.isStudyList;
    data.is_Study_update = this.state.isStudyUpdate;
    data.is_StudyDelete = this.state.isStudyDelete;
    data.isAllStudy = this.state.isAllStudy;
    data.is_Vendor = this.state.isVendor;
    data.is_Vendor_create = this.state.isVendorCreate;
    data.is_Vendor_list = this.state.isVendorList;
    data.is_Vendor_update = this.state.isVendorUpdate;
    data.is_Demographics = this.state.isDemographic;
    data.is_Demographics_create = this.state.isDemographicCreate;
    data.is_Demographics_list = this.state.isDemographicList;
    data.is_Demographics_update = this.state.isDemographicUpdate;
    data.is_Role = this.state.isRole;
    data.is_Role_create = this.state.isRolecreate;
    data.is_Role_list = this.state.isRolelist;
    data.is_Role_update = this.state.isRoleupdate;
    data.is_user = this.state.isUser;
    data.is_user_create = this.state.isUserCreate;
    data.is_user_list = this.state.isUserList;
    data.is_user_update = this.state.isUserUpdate;

    data.is_ShowAddMapping = this.state.isShowAddMapping;
    data.is_ShowUpdateMapping = this.state.isShowUpdateMapping;
    data.is_ShowParticipantList = this.state.isShowParticipantList;
    data.is_SendInvitation = this.state.isSendInvitation;
    data.is_CreateDemoMapping = this.state.isCreateDemoMapping;
    data.is_ShowAddConstraint = this.state.isShowAddConstraint;

    data.is_client = this.state.isClient;
    data.is_client_create = this.state.isClientCreate;
    data.is_client_list = this.state.isClientList;
    data.is_client_update = this.state.isClientUpdate;

    data.is_invoice = this.state.isInvoice;
    data.is_invoice_create = this.state.isInvoiceCreate;
    data.is_invoice_list = this.state.isInvoiceList;

    data.isSendEmail = this.state.isSendEmail;
    data.isApiProjectList = this.state.isApiProjectList;
    data.isArchiving = this.state.isArchiving;

    this.props.assingPermissionRole(data);
  }

  showRoleList() {
    this.props.showRoleList();
  }



  render() {

    return (
      <div>

        <div style={{ marginTop: "2%" }}>
          <div className="table-st">
            <div style={{ width: "100%", paddingBottom: "50px", backgroundColor: "#ffffff", borderRadius: "4px" }}>
              <div className="addVendor-main-div">
                <div className="addVendor-main-div-2 formgroup-pdding">
                  <h5>Assign Permission </h5>
                  <p>assign permission of users</p>
                </div>
                <hr />

                <div class="form-group  formgroup-mar-2">
                  <input type="checkbox" id="dashboard" checked={this.state.isDashboard} value={this.state.isDashboard} onChange={this.onDashbaordChange} />
                  <label for="dashboard">Dashboard</label>
                </div>

                <div class="form-group flex-show formgroup-mar-2">

                  <div className="col-lg-2">
                    <input type="checkbox" id="Study" checked={this.state.isStudy} value={this.state.isStudy} onChange={this.onStudyChange} />
                    <label for="Study">Study</label>
                  </div>

                  <div className="col-lg-3">
                    <input type="checkbox" id="isStudyCreate" checked={this.state.isStudyCreate} value={this.state.isStudyCreate} onChange={this.onStudyCreateChange} />
                    <label for="isStudyCreate">Study Create</label>
                  </div>

                  <div className="col-lg-2">
                    <input type="checkbox" id="isStudyList" checked={this.state.isStudyList} value={this.state.isStudyList} onChange={this.onStudyListChange} />
                    <label for="isStudyList">Study List</label>
                  </div>

                  <div className="col-lg-3">
                    <input type="checkbox" id="isStudyUpdate" checked={this.state.isStudyUpdate} value={this.state.isStudyUpdate} onChange={this.onStudyUpdateChange} />
                    <label for="isStudyUpdate">Study Update</label>
                  </div>

                  <div className="col-lg-3">
                    <input type="checkbox" id="isStudyDelete" checked={this.state.isStudyDelete} value={this.state.isStudyDelete} onChange={this.onStudyDelete} />
                    <label for="isStudyDelete">Study Delete</label>
                  </div>

                </div>

                <div class="form-group flex-show formgroup-mar-2">
                  <div className="col-lg-3">
                    <input type="checkbox" id="isAllStudy" checked={this.state.isAllStudy} value={this.state.isAllStudy} onChange={this.onAllStudyChange} />
                    <label for="isAllStudy">Show All Study</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isShowAddMapping" checked={this.state.isShowAddMapping} value={this.state.isShowAddMapping} onChange={this.onShowAddMappingChange} />
                    <label for="isShowAddMapping">Show Add Mapping</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isShowUpdateMapping" checked={this.state.isShowUpdateMapping} value={this.state.isShowUpdateMapping} onChange={this.onShowUpdateMappingChange} />
                    <label for="isShowUpdateMapping">Show Update Mapping</label>
                  </div>
                </div>

                <div class="form-group flex-show formgroup-mar-2">
                  <div className="col-lg-3">
                    <input type="checkbox" id="isShowParticipantList" checked={this.state.isShowParticipantList} value={this.state.isShowParticipantList} onChange={this.onShowParticipantListChange} />
                    <label for="isShowParticipantList">Show Participant List</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isSendInvitation" checked={this.state.isSendInvitation} value={this.state.isSendInvitation} onChange={this.onSendInvitationChange} />
                    <label for="isSendInvitation">Send Invitation</label>
                  </div>
                  {/* <div className="col-lg-3">
                            <input type="checkbox" id="isCreateDemoMapping" checked={this.state.isCreateDemoMapping} value={this.state.isCreateDemoMapping} onChange={this.onCreateDemoMappingChange}/>
                            <label for="isCreateDemoMapping">Create Demo Mapping</label>
                          </div>
                          <div className="col-lg-3">
                            <input type="checkbox" id="isShowAddConstraint" checked={this.state.isShowAddConstraint} value={this.state.isShowAddConstraint} onChange={this.onShowAddConstraintChange}/>
                            <label for="isShowAddConstraint">Show Add Constraint</label>
                          </div> */}
                </div>

                <div class="form-group flex-show formgroup-mar-2">
                  <div className="col-lg-3">
                    <input type="checkbox" id="isVendor" checked={this.state.isVendor} value={this.state.isVendor} onChange={this.onVendorChange} />
                    <label for="isVendor">Vendor</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isVendorCreate" checked={this.state.isVendorCreate} value={this.state.isVendorCreate} onChange={this.onVendorCreateChange} />
                    <label for="isVendorCreate">Vendor Create</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isVendorList" checked={this.state.isVendorList} value={this.state.isVendorList} onChange={this.onVendorListChange} />
                    <label for="isVendorList">Vendor List</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isVendorUpdate" checked={this.state.isVendorUpdate} value={this.state.isVendorUpdate} onChange={this.onVendorUpdateChange} />
                    <label for="isVendorUpdate">Vendor Update</label>
                  </div>
                </div>

                <div class="form-group flex-show formgroup-mar-2">
                  <div className="col-lg-3">
                    <input type="checkbox" id="isDemographic" checked={this.state.isDemographic} value={this.state.isDemographic} onChange={this.onDemographicChange} />
                    <label for="isDemographic">Demographic</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isDemographicCreate" checked={this.state.isDemographicCreate} value={this.state.isDemographicCreate} onChange={this.onDemographicCreateChange} />
                    <label for="isDemographicCreate">Demographic Create</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isDemographicList" checked={this.state.isDemographicList} value={this.state.isDemographicList} onChange={this.onDemographicListChange} />
                    <label for="isDemographicList">Demographic List</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isDemographicUpdate" checked={this.state.isDemographicUpdate} value={this.state.isDemographicUpdate} onChange={this.onDemographicUpdateChange} />
                    <label for="isDemographicUpdate">Demographic Update</label>
                  </div>
                </div>

                <div class="form-group flex-show formgroup-mar-2">
                  <div className="col-lg-3">
                    <input type="checkbox" id="isRole" checked={this.state.isRole} value={this.state.isRole} onChange={this.onRoleChangePermission} />
                    <label for="isRole">Role</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isRolecreate" checked={this.state.isRolecreate} value={this.state.isRolecreate} onChange={this.onRolecreateChange} />
                    <label for="isRolecreate">Role Create</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isRolelist" checked={this.state.isRolelist} value={this.state.isRolelist} onChange={this.onRolelistChange} />
                    <label for="isRolelist">Role List</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isRoleupdate" checked={this.state.isRoleupdate} value={this.state.isRoleupdate} onChange={this.onRoleupdateChange} />
                    <label for="isRoleupdate">Role Update</label>
                  </div>
                </div>

                <div class="form-group flex-show formgroup-mar-2">
                  <div className="col-lg-3">
                    <input type="checkbox" id="isUser" checked={this.state.isUser} value={this.state.isUser} onChange={this.onUserChange} />
                    <label for="isUser">User</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isUserCreate" checked={this.state.isUserCreate} value={this.state.isUserCreate} onChange={this.onUserCreateChange} />
                    <label for="isUserCreate">User Create</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isUserList" checked={this.state.isUserList} value={this.state.isUserList} onChange={this.onUserListChange} />
                    <label for="isUserList">User List</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isUserUpdate" checked={this.state.isUserUpdate} value={this.state.isUserUpdate} onChange={this.onUserUpdateChange} />
                    <label for="isUserUpdate">User Update</label>
                  </div>
                </div>

                <div class="form-group flex-show formgroup-mar-2">
                  <div className="col-lg-3">
                    <input type="checkbox" id="isClient" checked={this.state.isClient} value={this.state.isClient} onChange={this.onClientChange} />
                    <label for="isClient">Client</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isClientCreate" checked={this.state.isClientCreate} value={this.state.isClientCreate} onChange={this.onClientCreateChange} />
                    <label for="isClientCreate">Client Create</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isClientList" checked={this.state.isClientList} value={this.state.isClientList} onChange={this.onClientListChange} />
                    <label for="isClientList">Client List</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isClientUpdate" checked={this.state.isClientUpdate} value={this.state.isClientUpdate} onChange={this.onClientUpdateChange} />
                    <label for="isClientUpdate">Client Update</label>
                  </div>
                </div>

                <div class="form-group flex-show formgroup-mar-2">
                  <div className="col-lg-3">
                    <input type="checkbox" id="isInvoice" checked={this.state.isInvoice} value={this.state.isInvoice} onChange={this.onInvoiceChange} />
                    <label for="isInvoice">Invoice</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isInvoiceCreate" checked={this.state.isInvoiceCreate} value={this.state.isInvoiceCreate} onChange={this.onInvoiceCreateChange} />
                    <label for="isInvoiceCreate">Create Invoice</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isInvoiceList" checked={this.state.isInvoiceList} value={this.state.isInvoiceList} onChange={this.onInvoiceListChange} />
                    <label for="isInvoiceList">Invoiced List</label>
                  </div>   </div>



                <div class="form-group flex-show formgroup-mar-2">
                  <div className="col-lg-3">
                    <input type="checkbox" id="isSendEmail" checked={this.state.isSendEmail} value={this.state.isSendEmail} onChange={this.onSendEmailChange} />
                    <label for="isSendEmail"> Send E-mail</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isApiProjectList" checked={this.state.isApiProjectList} value={this.state.isApiProjectList} onChange={this.onApiProjectListChange} />
                    <label for="isApiProjectList">Api Project List</label>
                  </div>
                  <div className="col-lg-3">
                    <input type="checkbox" id="isArchiving" checked={this.state.isArchiving} value={this.state.isArchiving} onChange={this.onArchivingChange} />
                    <label for="isArchiving">Archiving</label>
                  </div>
                </div>
              </div><br />
              <div className="addVendorCheckbox">
                <button className="list-btn " onClick={this.submitAssignPermission} >Assign Permission</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AssignUserPermission;

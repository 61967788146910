import React, { Component } from 'react';

import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
// import { confirmAlert } from 'react-confirm-alert';
// import 'react-confirm-alert/src/react-confirm-alert.css';
// import deletebutton from '../../Images/delete-button.png';
// import goBack from '../../Images/go_back.png';
import { alertMessage }  from '../../../alertsDialog/Action';

class ApplyConstraintsView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            screenerData : {},
            projectID :  "",
            selectedMultiselectIds : [],
            titleerror  : "",
            requirementerror : "",
            quotaTitle : "",
            quotaRequirement : "",
            rangeFrom : "",
            rangeTo : "",
            toerror : "",
            fromerror : "",
            languageCode : "",
            quotaRequirementArray : [],
            reqPosition : "",
            allowUploadLink : false,
            allowScreener : false
            
        }
        // this.onChangeTitle = this.onChangeTitle.bind(this);
        // this.onChangeRequirement = this.onChangeRequirement.bind(this);
        // this.onChangeFrom = this.onChangeFrom.bind(this);
        // this.onChangeTo = this.onChangeTo.bind(this);
        this.onChangeMultiselect = this.onChangeMultiselect.bind(this);
        // this.createGroup = this.createGroup.bind(this);
        this.onChangeTextData = this.onChangeTextData.bind(this);
        this.removeQuotaFromConstraints = this.removeQuotaFromConstraints.bind(this);

        this.onBackPressed = this.onBackPressed.bind(this);

        // this.onKeyEnter = this.onKeyEnter.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onRequirementChange = this.onRequirementChange.bind(this);
        


      }

      onKeyUp(e){
      

        var pattern = new RegExp(/^[0-9]+$/);
        let quota = e.target.value;
        if(e.keyCode === 13){
            let validate = 1;
            if(e.target.value === ""){
              validate =0;
            // this.invitationAlert("Please enter quota.");
              // alert("Please enter quota.");
              let alertData ={};
              alertData.type ="alert";
              alertData.message = "Please enter quota.";
              this.props.dispatch(alertMessage(alertData));
              return validate;
            }else if(!pattern.test(quota)){
              validate = 0;
            //   this.invitationAlert("Please enter only number");
            let alertData ={};
            alertData.type ="alert";
            alertData.message = "Please enter only number";
            this.props.dispatch(alertMessage(alertData));
              return validate;
            }else if(e.target.value > 100){
                validate = 0;
                // this.invitationAlert("Sorry ! 0 to 100 only.");
                let alertData ={};
                alertData.type ="alert";
                alertData.message = "Sorry ! 0 to 100 only.";
                this.props.dispatch(alertMessage(alertData));
                return validate;
            }else{
          let data={};
          if(sessionStorage.getItem("queryTypeForConstraints")==='range'){

            var reqPosition = "";
            for(let index =0 ; index < this.props.quotaAndQueryOptions.ageList.length; index++ ){
              if(this.props.quotaAndQueryOptions.ageList[index].ageFrom  +"#"+ this.props.quotaAndQueryOptions.ageList[index].ageTo === e.target.name){
                  // this.setState({
                  //   reqPosition : index
                  // });
                  reqPosition = index
                break;
              }
            }
              data.ageFrom  = "";
              data.ageTo  =  "" ;
              data.demographicId   = this.props.demographicData._id;
              data.requirement  = this.state.quotaRequirementArray[reqPosition];
              data.selectedOptions  = e.target.name;
              data.studyId  = this.props.studyId;
              data.text = "";
              data.title  = this.state.quotaTitle;
              data.isRange  = true;
             }
             else  if(sessionStorage.getItem("queryTypeForConstraints")==='text'){

              data.ageFrom  = "";
              data.ageTo  = "" ;
              data.demographicId   = this.props.demographicData._id ;
              data.requirement  = this.state.quotaRequirement;
              data.selectedOptions  = "";
              data.studyId  = this.props.studyId;
              data.text = this.state.allTextData;
              data.title  = this.state.quotaTitle;
             }
             else{
              var reqPosition = "";
              for(let index = 0 ; index < this.props.quotaAndQueryOptions.options.length; index++ ){
                if(this.props.quotaAndQueryOptions.options[index]._id  === e.target.name){
                  reqPosition = index
                  break;
                }
              }
              data.ageFrom  = "";
              data.ageTo  = "" ;
              data.demographicId   = this.props.demographicData._id;
              data.requirement  = this.state.quotaRequirementArray[reqPosition];
              data.selectedOptions  = e.target.name;
              data.studyId  = this.props.studyId;
              data.text = "";
              data.title  = this.state.quotaTitle;
             }
             data.lang_code = this.state.languageCode;
             if(validate === 1){
            this.props.addConstrainsts(data);
            
             }
        }
      }
    }
     
    componentWillMount(){
      

//   if(sessionStorage.getItem("uploadlink") === "upload"){
//     this.setState({
//       allowUploadLink : true
//     });
//   }

//   if(sessionStorage.getItem("addscreener") === "screener"){
//         this.setState({
//         allowScreener : true
//         });
//         }
    }

    //   componentDidMount(){
    //     if( this.props.location.state === undefined){
    //             this.props.history.push("error");
    //           }else{
    //           const { screenerData } = this.props.location.state;
    //           const { projectID }  = this.props.location.state;
    //           this.setState({
    //             screenerData : screenerData, 
    //             projectID  : projectID
    //         });
    //         // alert(screenerData.demographicId +"  ++ "+ projectID);
    //         this.props.dispatch(getQueryAndOption(screenerData.demographicId, projectID));
    //       }
    //    }

      onBackPressed(){
         window.history.back();
      }

      componentWillReceiveProps(nextProps){
       
        if(nextProps.quotaMessage){
            this.props.emptyQuotaProps();
            let alertData ={};
            alertData.type ="alert";
            alertData.message = nextProps.quotaMessage;
            this.props.dispatch(alertMessage(alertData));
            //  this.invitationAlert(nextProps.quotaMessage);
           }
   

        if(nextProps.quotaAndQueryOptions && nextProps.quotaAndQueryOptions.success === true){
    

          let tempQuotaAppliedOptions = [];
          let tempQuotaAppliedOptions1 = [];
          let tempRequirement = [];



               if(nextProps.quotaAndQueryOptions.query && nextProps.quotaAndQueryOptions.query.queryType === 'range'){
                for (let i = 0; i < nextProps.quotaAndQueryOptions.ageList.length; i++){
                  tempQuotaAppliedOptions.push("")
                  if(nextProps.quota && nextProps.quota.length > 0 ){
                    for(let q = 0 ; q < nextProps.quota.length; q++ ){
                      if(nextProps.quota[q].ageFrom === nextProps.quotaAndQueryOptions.ageList[i].ageFrom &&
                        nextProps.quota[q].ageTo === nextProps.quotaAndQueryOptions.ageList[i].ageTo  ){
                         // tempQuotaAppliedOptions.splice( i, 0, nextProps.quota[q].requirement );
                          tempQuotaAppliedOptions1.push(i);
                          tempRequirement.push(nextProps.quota[q].requirement);
                          tempQuotaAppliedOptions.splice(i,1,nextProps.quota[q].requirement)
                          break;
                        }
                    }
                  }
                }
              }
              else {

                if(nextProps.quotaAndQueryOptions.options){
                for (let i = 0; i < nextProps.quotaAndQueryOptions.options.length; i++){
                  tempQuotaAppliedOptions.push("")
                  if(nextProps.quota && nextProps.quota.length > 0 ){
                    for(let q = 0 ; q < nextProps.quota.length; q++ ){
                      if(nextProps.quota[q].queryOptionId === nextProps.quotaAndQueryOptions.options[i]._id){
                         // tempQuotaAppliedOptions.splice( i, 0, nextProps.quota[q].requirement );
                          tempQuotaAppliedOptions1.push(i);
                          tempRequirement.push(nextProps.quota[q].requirement);
                          tempQuotaAppliedOptions.splice(i,1,nextProps.quota[q].requirement)
                          break;
                        }
                    }
                  }
                }  
              }
            }
            this.setState({
              quotaRequirementArray : tempQuotaAppliedOptions
            });
        }
      }


    //   invitationAlert(msg) {
    //     confirmAlert({
    //       customUI: ({ onClose }) => {
    //         return (
    //           <div className='custom-ui'>
    //             <div className="alertimg"></div>
    //             <p className="alertmsg">{msg}</p>
    //             <button className="alertbutton" onClick={() => {
    //               onClose()
    //             }}>Ok</button>
    //           </div>
    //         )
    //       }
    //     });
    //   }

      
      // onChangeTitle(e) {

      //   if(e.target.value === ""){
      //       this.setState({titleerror: "Can't leave empty !" });
      //     document.getElementById("titleerror").style.display="block";
      //     }else if(!e.target.value.match(/^[a-zA-Z ]*$/)){
      //       this.setState({ titleerror: "Enter only alphabets !" });
      //       document.getElementById("titleerror").style.display="block";
      //     }else{
      //       document.getElementById("titleerror").style.display="none";
      //     }
      //       this.setState({ quotaTitle: e.target.value });
      // }


      onChangeTextData(e) {

        if(e.target.value === ""){
            this.setState({texterror: "Can't leave empty !" });
          document.getElementById("texterror").style.display="block";
          }else if(!e.target.value.match(/^[a-zA-Z ]*$/)){
            this.setState({ texterror: "Enter only alphabets !" });
            document.getElementById("texterror").style.display="block";
          }else{
            document.getElementById("texterror").style.display="none";
          }
            this.setState({ allTextData: e.target.value });
      }

      onRequirementChange(e){
       

        if(sessionStorage.getItem("queryTypeForConstraints")==='range'){
        


           let myPostion = -1;

           if(this.props.quotaAndQueryOptions.ageList && this.props.quotaAndQueryOptions.ageList.length > 0){
            for(let q = 0 ; q < this.props.quotaAndQueryOptions.ageList.length; q++ ){
              if(this.props.quotaAndQueryOptions.ageList[q].ageFrom  +"#"+ this.props.quotaAndQueryOptions.ageList[q].ageTo === e.target.name  ){
                 // tempQuotaAppliedOptions.splice( i, 0, nextProps.quota[q].requirement );
             
                 myPostion = q;
                }
            }
           }

           let localArray = this.state.quotaRequirementArray;
           let myDataToShow = [];
           for(let i = 0; i < localArray.length ; i++){
             if(myPostion === i){
              myDataToShow.push(e.target.value);
             }
             else{
              myDataToShow.push(localArray[i]);
             }
           }




           this.setState({
            quotaRequirementArray : myDataToShow
          });



         }
         else  if(sessionStorage.getItem("queryTypeForConstraints")==='text'){
          
         }
         else{
            // from options

            let myPostion = -1;

            if(this.props.quotaAndQueryOptions.options && this.props.quotaAndQueryOptions.options.length > 0){
             for(let q = 0 ; q < this.props.quotaAndQueryOptions.options.length; q++ ){
               if(this.props.quotaAndQueryOptions.options[q]._id === e.target.name  ){
                  // tempQuotaAppliedOptions.splice( i, 0, nextProps.quota[q].requirement );
              
                  myPostion = q;
                 }
             }
            }
            
            let localArray = this.state.quotaRequirementArray;
            let myDataToShow = [];
            for(let i = 0; i < localArray.length ; i++){
              if(myPostion === i){
               myDataToShow.push(e.target.value);
              }
              else{
               myDataToShow.push(localArray[i]);
              }
            }
            this.setState({
             quotaRequirementArray : myDataToShow
           });
 



         }
        // // alert("lwet!");
        // if(e.target.value === ""){
        //   this.setState({ quotaRequirement: "" });
        // }else if(isNaN(e.target.value)){
        //   this.setState({ quotaRequirement: "454" });
         
        // }else if(parseInt(e.target.value) > 100){
        //   alert("Sorry ! 0 to 100.");
        //   this.setState({ quotaRequirement: ""+100 });
        // }
        // else{
        //   this.setState({ quotaRequirement: e.target.value });
        // }



        // this.setState({ quotaRequirement: e.target.value });
      }

      // onChangeRequirement(e) {

      //   if(e.target.value === ""){
      //       this.setState({requirementerror: "Can't leave empty !" });
      //     document.getElementById("requirementerror").style.display="block";
      //     }else if(!e.target.value.match(/^[0-9]+$/)){
      //       this.setState({ requirementerror: "Enter only numeric number !" });
      //       document.getElementById("requirementerror").style.display="block";
      //     }else{
      //       document.getElementById("requirementerror").style.display="none";
      //     }
      //       this.setState({ quotaRequirement: e.target.value });
      // }

      onChangeFrom(e) {

        if(e.target.value === ""){
            this.setState({fromerror: "Can't leave empty !" });
          document.getElementById("fromerror").style.display="block";
          }else if(!e.target.value.match(/^[0-9]+$/)){
            this.setState({ fromerror: "Enter only numeric number !" });
            document.getElementById("fromerror").style.display="block";
          }else{
            document.getElementById("fromerror").style.display="none";
          }
            this.setState({ rangeFrom: e.target.value });
      }

      onChangeTo(e) {

        if(e.target.value === ""){
            this.setState({toerror: "Can't leave empty !" });
          document.getElementById("toerror").style.display="block";
          }else if(!e.target.value.match(/^[0-9]+$/)){
            this.setState({ toerror: "Enter only numeric number !" });
            document.getElementById("toerror").style.display="block";
          }else{
            document.getElementById("toerror").style.display="none";
          }
            this.setState({ rangeTo: e.target.value });
      }



      handleValidation() {
        let valid = true;


        //  if(this.state.quotaTitle === ""){
        //     valid=false;
        //     this.setState({titleerror: "Can't leave empty !" });
        //    document.getElementById("titleerror").style.display="block";
        //   return valid;
        //   }else if(!this.state.quotaTitle.match(/^[a-zA-Z ]*$/)){
        //     valid=false;
        //     this.setState({ titleerror: "Enter only alphabets !" });
        //     document.getElementById("titleerror").style.display="block";
        //     return valid;
        //   }else{
        //     document.getElementById("titleerror").style.display="none";
        //   }
        


        //   if(this.state.quotaRequirement === ""){
        //     valid=false;
        //     this.setState({requirementerror: "Can't leave empty !" });
        //   document.getElementById("requirementerror").style.display="block";
        //   return valid;
        //   }else if(!this.state.quotaRequirement.match(/^[0-9]+$/)){
        //     valid=false;
        //     this.setState({ requirementerror: "Enter only numeric number !" });
        //     document.getElementById("requirementerror").style.display="block";
        //     return valid;
        //   }else if(parseInt(this.state.quotaRequirement) > 100){
        //     valid=false;
        //     this.setState({ requirementerror: "The requirement is in %. Please enter accordingly. you entered more than 100. !" });
        //     document.getElementById("requirementerror").style.display="block";
        //     return valid;
        //   }
          
          
        //   else{
        //     document.getElementById("requirementerror").style.display="none";
        //   }

        if(sessionStorage.getItem("queryTypeForConstraints")==='text'){
        


            if(this.state.allTextData === ""){
                valid=false;
                this.setState({texterror: "Can't leave empty !" });
                return valid;
              document.getElementById("texterror").style.display="block";
              }else if(!this.state.allTextData.match(/^[a-zA-Z ]*$/)){
                valid=false;
                this.setState({ texterror: "Enter only alphabets !" });
                document.getElementById("texterror").style.display="block";
                return valid;
              }else{
                document.getElementById("texterror").style.display="none";
              }
    

        }else{

            if(this.state.quotaRequirementArray.length >0){

            }else{
              alert("Please enter atleast one requirement.");
            }



            // if(this.state.selectedMultiselectIds.length > 0){
                
                
            //   }else{
            //     valid=false;
            //     alert("Please select at least one query options to add constraints");
            //     return valid;
            //   }


        }
        return valid;
      }

      // createGroup(e){
      //   let data={};
      // //  let userID = sessionStorage.getItem("userId");
      //  // data.groupName = this.state.groupName;
      // //  data.userID = userID;

      // if(sessionStorage.getItem("queryTypeForConstraints")==='range'){
      //   data.ageFrom  = "";
      //   data.ageTo  =  "" ;
      //   data.demographicId   = this.state.screenerData._id ;
      //   data.requirement  = this.state.quotaRequirement;
      //   data.selectedOptions  = this.state.selectedMultiselectIds.toString();
      //   data.studyId  = this.state.projectID;
      //   data.text = "";
      //   data.title  = this.state.quotaTitle;
      //   data.isRange  = true;
      //  }
      //  else  if(sessionStorage.getItem("queryTypeForConstraints")==='text'){
      //   data.ageFrom  = "";
      //   data.ageTo  = "" ;
      //   data.demographicId   = this.state.screenerData._id ;
      //   data.requirement  = this.state.quotaRequirement;
      //   data.selectedOptions  = "";
      //   data.studyId  = this.state.projectID;
      //   data.text = this.state.allTextData;
      //   data.title  = this.state.quotaTitle;
      //  }
      //  else{
      //   data.ageFrom  = "";
      //   data.ageTo  = "" ;
      //   data.demographicId   = this.state.screenerData._id ;
      //   data.requirement  = this.state.quotaRequirement;
      //   data.selectedOptions  = this.state.selectedMultiselectIds.toString();
      //   data.studyId  = this.state.projectID;
      //   data.text = "";
      //   data.title  = this.state.quotaTitle;
      //  }
      //  data.lang_code = this.state.languageCode;
       

      //   e.preventDefault();
      //   if( this.handleValidation() ){
      //    this.props.dispatch(addConstrainsts(data));
      //   }
      // }

      
     


    removeQuotaFromConstraints(rowIndexData){

    //     confirmAlert({
    //       customUI: ({ onClose }) => {
    //         return (
    //           <div className='custom-ui'>
    //              <div className = "alertimg"> </div>
    //              <div className = "alert">Are you sure?</div>
    //              <p className = "alertmsg">You want to remove this quota?</p>
    //             <button className = "alertbtn" onClick={onClose}>No</button>
    //             <button className = "alertbutton" onClick={() => {
    //             //   this.props.dispatch(removeQuotaFromScreenerOnProject(rowIndexData.id));
    //                 onClose()
    //             }}>Yes, Delete it!</button>
    //           </div>
    //         );
    //       }
    //     });

       
      
      }




      onChangeMultiselect(e){
           //selectedMultiselectIds
  
  let found = this.state.selectedMultiselectIds.find(element => element  === e.target.value);

  if(found){
    // remove here
    let currentIds = this.state.selectedMultiselectIds.filter(data => data != e.target.value);
    this.setState(
     {
      selectedMultiselectIds : currentIds
    }
   );
  
  }
  else{
        let currentIds = this.state.selectedMultiselectIds;
        currentIds.push(e.target.value);
        this.setState(
          {
           selectedMultiselectIds : currentIds
         }
        );
    }
      }
    



      render() {
             
        // alert("Props is "+ this.props.demographicData.demographicId +" && "+this.props.studyId)
        //options
        let showQuotaList = "";
        let showOptionsList = "";
        let queryAndOptionsView = "";
        if(this.props.quotaAndQueryOptions && this.props.quotaAndQueryOptions.success === true){
           // if(this.props.quotaAndQueryOptions.options && this.props.quotaAndQueryOptions.options.length > 0 ){

                if(this.props.quotaAndQueryOptions.query && this.props.quotaAndQueryOptions.query.queryType === 'range'){

                    sessionStorage.setItem("queryTypeForConstraints","range");


                   // sessionStorage.setItem("queryTypeForConstraints","options");

                    showOptionsList = this.props.quotaAndQueryOptions.ageList.map((item , i) =>{
            
                        let found = this.state.selectedMultiselectIds.find(element => element  === item.ageFrom + "#"+  item.ageTo);
                        if(found){
                         return <div style={{display: "flex", marginTop: "10px"}}><div style={{width:"200px",fontSize: "20px"}}>{i+1}.From {item.ageFrom } to {item.ageTo}</div>
                         <div  style={{marginTop: "0px"}} class="formgroup-col-6 formgroup" >
                                    <input className="form-controll " name ={item.ageFrom +"#"+ item.ageTo} value= {this.state.quotaRequirementArray[i]} onKeyUp={this.onKeyUp} onChange={this.onRequirementChange}/>
                                </div>
                         </div>
                         
                        //  <div id="showscreenerlist"> <div id="textarea">{i+1}.&nbsp;&nbsp; From {item.ageFrom } to {item.ageTo} </div>
                        //  <div id="inputarea"><input type="text" name ={item.ageFrom +"#"+ item.ageTo} value= {this.state.quotaRequirementArray[i]} onKeyUp={this.onKeyUp} onChange={this.onRequirementChange}/></div>
                        // </div>
                        }
                        else{
                          return <div style={{display: "flex", marginTop: "10px"}}><div style={{width:"200px",fontSize: "20px"}}>{i+1}.From {item.ageFrom } to {item.ageTo}</div>
                                <div  style={{marginTop: "0px"}} class="formgroup-col-6 formgroup" >
                                     <input className="form-controll " name ={item.ageFrom +"#"+ item.ageTo} value= {this.state.quotaRequirementArray[i]} onKeyUp={this.onKeyUp} onChange={this.onRequirementChange}/>
                                  </div>
                              </div>
                          
                          
                          // <div id="showscreenerlist"> <div id="textarea">{i+1}.&nbsp;&nbsp; From {item.ageFrom } to {item.ageTo} </div>
                          //  <div id="inputarea"> <input type="text" name={item.ageFrom +"#"+ item.ageTo} value= {this.state.quotaRequirementArray[i]} onKeyUp={this.onKeyUp} onChange={this.onRequirementChange}/></div>
                          //  </div>
         
                        }
                      });

                } else if(this.props.quotaAndQueryOptions.query && this.props.quotaAndQueryOptions.query.queryType === 'text'){
                    sessionStorage.setItem("queryTypeForConstraints","text");

                    showOptionsList = <div>  <ul>
                    <li>
                        <label for="text">Enter Text *</label>
                            <input type="text" name="texterror" maxlength="100" placeholder="From" value={this.state.allTextData} onChange={this.onChangeTextData} />
                            <span id="texterror">{this.state.texterror}</span>
                      </li>
                    </ul>
                    </div>
                }
                else{

                    sessionStorage.setItem("queryTypeForConstraints","options");
                    if( this.props.quotaAndQueryOptions.options){
                    showOptionsList = this.props.quotaAndQueryOptions.options.map((item , i) =>{
                        let found = this.state.selectedMultiselectIds.find(element => element  === item._id);
                        if(found){
                         return  <div style={{display: "flex", marginTop: "10px"}}><div style={{width:"200px",fontSize: "20px"}}>{i+1}.&nbsp;&nbsp; {item.optionText} </div>
                         <div  style={{marginTop: "0px"}} class="formgroup-col-6 formgroup" >
                                    <input className="form-controll " name  = {item._id} value= {this.state.quotaRequirementArray[i]} onKeyUp={this.onKeyUp} onChange={this.onRequirementChange}/>
                                </div>
                         </div>
                        //  <div><input type="text" name  = {item._id} value= {this.state.quotaRequirementArray[i]} onKeyUp={this.onKeyUp} onChange={this.onRequirementChange}/></div>
                          
                        //  <div id="showscreenerlist"><div id="textarea"><span>{i+1}.&nbsp;&nbsp; {item.optionText} </span></div>
                        //   <div id="inputarea"> <input type="text" name  = {item._id} value= {this.state.quotaRequirementArray[i]} onKeyUp={this.onKeyUp} onChange={this.onRequirementChange}/>
                        //   </div></div>
                        }
                        else{
                          return   <div style={{display: "flex", marginTop: "10px"}}><div style={{width:"200px",fontSize: "20px"}}>{i+1}.&nbsp;&nbsp;{item.optionText} </div>
                           <div  style={{marginTop: "0px"}} class="formgroup-col-6 formgroup">
                                  <input className="form-controll "  name  = {item._id} value= {this.state.quotaRequirementArray[i]} onKeyUp={this.onKeyUp} onChange={this.onRequirementChange}/>
                                </div>
                          </div>
                        //  <div><input type="text" name  = {item._id} value= {this.state.quotaRequirementArray[i]} onKeyUp={this.onKeyUp} onChange={this.onRequirementChange}/></div>
                        }
                    });
                  }
                }

           
  
  
            queryAndOptionsView = <div id="contraints"> 
                
                <h3>Query : { this.props.quotaAndQueryOptions.query.question } </h3>
                
            <h5>Input requirement in % and hit enter to save it. </h5>
                
                   {showOptionsList}
              
            </div>

            }

            if(this.props.quota && this.props.quota.length > 0 ){
                showQuotaList =  this.props.quota.map((grouplist , i) =>{


                    let text = "";
                if(grouplist.optionText[0].text !== null){
                    text = grouplist.optionText[0].text;
                }else if (grouplist.ageFrom !== null && grouplist.ageFrom !== "" && grouplist.ageTo !== null && grouplist.ageTo !=="" ){
                    text =  "From:" + grouplist.ageFrom  + ","+ "To:"+ grouplist.ageTo
                }else if(grouplist.pincode !== null, grouplist.pincode !== ""){
                    text = grouplist.pincode
                }


                    //if(grouplist.is_active===1){
                      return  <tr>
                      <td>{i+1}</td>
      
                      <td className=""><div className="tooltipFirstParty">{grouplist.title.substring(0, 40)}<span class="tooltiptextFirstParty">{grouplist.title}</span></div></td>
                      <td>{text}</td>
                      <td>{grouplist.requirement}%</td>
                      <td style={{cursor: "pointer"}} onClick={() => this.removeQuotaFromConstraints(grouplist)}></td>
                   
                     </tr>
                  }); 
            
            }


     // }


     if( this.props.quotaAndQueryOptions && this.props.quotaAndQueryOptions.success === true && this.props.quotaAndQueryOptions.query.queryType === 'text'){
     




      return (
        <div>
         
            <div  style={{marginTop: "2%"}}>
            <div className="table-st">
            <div className="forms-rd-shadow" style={{width: "100%", padding: "5% 5% 5% 5%", backgroundColor: "#FFFFFF",borderRadius: "4px"}}>
                
                <h1 style={{fontSize: "25px;"}}>This is text type screener. You can't add quotas on this.</h1>
            
          </div>
               </div>
          </div>   
          </div>   
    );



      }
      else{

      
    
        return (
            <div >
                 
                <div style={{marginTop: "2%"}}>
                <div className="table-st">
                <div className="forms-rd-shadow" style={{width: "100%",padding: "5% 10% 5% 10%", backgroundColor: "#FFFFFF",borderRadius: "4px"}}>

                    <h2>Apply Quotas on {this.props.demographicData.demoName}</h2>
                    <hr/>
             

                      {queryAndOptionsView}

              </div>
                   </div>
              </div>    
              </div>  
        );
      }
      }
}

const mapStateToProps=(state)=>{
    return{
     
        // quotaAndQueryOptions : state.demoMapped.quotaAndQueryOptions,
        // quota : state.demoMapped.quota,
        // quotaMessage : state.screenerMapping.quotaMessage,
        // quotaRemovedSuccess : state.screenerMapping.quotaRemovedSuccess
    }
  }
  
  export default connect(mapStateToProps)(ApplyConstraintsView);


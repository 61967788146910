import React, { Component }  from 'react';
import  '../../commonStyle/common.css';
import './style.css';
import $ from 'jquery';
import {connect} from 'react-redux';
import Pagination from "react-js-pagination";
import deletIcon from '../../images/delete.png';
import editpencil from '../../images/edit-pencil.png';
import {getAllRolesByAdminByPage, softRoleDelete, blankResRoles} from '../../actions/actionsBag/roles';
import {Link} from 'react-router-dom';
import { alertMessage,emptyDialogdata  }  from '../../alertsDialog/Action';

  class RoleListTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            search: "",
            currentPage: 1,
            userId : "",
            userType : "",
            rolesData : [],
            totalPage : 0,
            showloader : false
        }
        this.handlePageChange = this.handlePageChange.bind(this);
        this.softDeleteRole = this.softDeleteRole.bind(this);
      }


  componentWillReceiveProps(nextProps){

    if(nextProps.confirmDialog === true){
      // alert(nextProps.confirmData);
      this.props.dispatch(emptyDialogdata());
      let userId = localStorage.getItem("auth_user_id");
      let data= {}
      data.role_id = nextProps.confirmData;
      data.userId = userId;
      this.props.dispatch(softRoleDelete(data));
    }

    if(nextProps.roleDeleteSuccess === true && nextProps.roleDeleteMessage){
      let alertData ={};
      alertData.type ="alert";
      alertData.message =  nextProps.roleDeleteMessage;
      this.props.dispatch(alertMessage(alertData));
      this.props.dispatch(getAllRolesByAdminByPage(1));
      this.setState({currentPage : 1});
      this.props.dispatch(blankResRoles());
    }else if(nextProps.roleDeleteMessage){
        let alertData ={};
        alertData.type ="alert";
        alertData.message =  nextProps.roleDeleteMessage;
        this.props.dispatch(alertMessage(alertData));
        this.props.dispatch(blankResRoles());
    }

    if( nextProps.totalRolesCount &&  nextProps.totalRolesCount >0){
      this.setState({
        totalPage : nextProps.totalRolesCount,
        rolesData : nextProps.rolesData,
        showloader: true,
      });
    }else{
      this.setState({
        totalPage : 0,
        showloader: true,
      });
    }
  }
  


  componentWillMount(){
    this.props.dispatch(getAllRolesByAdminByPage(1));

  $(".dropdown").on('click',function() { 
      var t = (this.id); 
    if(t === "all-rm-shadow"){
      var all= document.getElementsByClassName("dropdown");
    
      for(let a=0; a< all.length; a++){
          if ( $( all[a] ).removeClass( "active" ) ) {
        
        }
      
        $(all[0]).addClass("active");
      }
    }else if(t === "live-rm-shadow"){
      
      var live= document.getElementsByClassName("dropdown");
    
      for(let a=0; a< live.length; a++){
          if ( $( live[a] ).removeClass( "active" ) ) {
        }
        $(live[1]).addClass("active");
      }
    }else if(t === "pause-rm-shadow"){
      var pause= document.getElementsByClassName("dropdown");
    
      for(var a=0; a< pause.length; a++){
          if ( $( pause[a] ).removeClass( "active" ) ) {
        
        }
      
        $(pause[2]).addClass("active");
      }
  }else if(t === "com-rm-shadow"){
      var com= document.getElementsByClassName("dropdown");
    
      for(let a=0; a< com.length; a++){
          if ( $( com[a] ).removeClass( "active" ) ) {
        
        }
      
        $(com[3]).addClass("active");
      }
    }
  });  
}

  handlePageChange(pageNumber){

    this.setState({ currentPage : pageNumber, rolesData: []});
    
    this.props.dispatch(getAllRolesByAdminByPage(pageNumber));
  }

  
  softDeleteRole(id){
    let alertData ={};
      alertData.type ="confirmalert";
      alertData.message = "Are you sure to delete this item ?";
      alertData.deleteId = id;
      this.props.dispatch(alertMessage(alertData));
}

  tableZoom(){
    $(".alltablestruct").toggleClass("zoom-me");
    // $("#routercontainer").toggleClass("router-containter-pd");
    // document.getElementById("routercontainer").style.padding="0px";

  }

    render(){
        
        let roleItems = "";

        let sr = (this.state.currentPage * 10)-10;
        if (this.state.rolesData && this.state.rolesData.length> 0 ) {
         
          
            roleItems = this.state.rolesData.map((list, i) => {

            return <tbody> <tr>  
                <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom">{sr+i+1}</td>
                  <td id="rolename" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom"><span >{list.role_type.toLowerCase().replace(/_/g," ")}</span></td>

                  <td className=" tbl-padd-top tbl-padd-left tbl-padd-bottom">
                    <div className="flex-show">    
                      <div className="icons" >
                            <div className="delete" onClick={this.softDeleteRole.bind(this,list.role_id)}>
                              <img src={deletIcon}/>
                            </div>
                          </div>

                          <div className="icons">
                            <Link to = {{      
                                  pathname: '/update-role',
                                  state: {
                                    editRoleData : list,
                                  }
                                  
                                }}> <div className="pencil"><img src={editpencil}  height="20px" width="20px"/> </div></Link>
                          </div>  
                    </div>
                  </td>
                      </tr>
                  </tbody>
             });
          }else{
            roleItems = "";
          }


          let showTable = "";
          if (this.state.rolesData && this.state.rolesData.length> 0 ) {
            showTable =  <table className="tbl-new-est" >
            <thead>
              <tr>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">S.No.</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Role Name</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Action</th>
                </tr>
                </thead>
              {roleItems}
          </table>
          }else if(this.state.showloader === true){
            showTable = <div className="datanotfound"> No record found</div>
          }else{
            showTable = <div className="spiner-data"><div className="tblspinner"></div></div>
          }
         


        return(
            <div className="list-style">
                <div className="table-st">
                    <div className="alltablestruct forms-rd-shadow">
                         {/* serachbox and stausbtn */}
                        <div id="search-staus">

                            <div className="flex-show  tbl-h-font">
                            Users Role List
                            </div>
                            
                            <div className="flex-show tbl-zoom" >
                            <span onClick={this.tableZoom} className="full-screen mr-rhs-20 mr-top-10"> </span>
                            </div>
                        </div>
                        {/* serachbox and stausbtn */}

                            {/* table */}
                            <div style={{marginTop: "20px", overflowX: "auto"}}>
                                {showTable}
                            </div>
                            {/* table */}
                   
                      <div id="pageinationstyle">
                       
                      <Pagination
                          activePage={this.state.currentPage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalPage}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                        />
                        </div>
                    </div>
                </div>
            </div>
    )}
}

const mapStateToProps = (state) => {
  return {
    totalRolesCount    : state.roles.totalRolesCount,
    rolesData          : state.roles.rolesData,
    roleDeleteSuccess : state.roles.roleDeleteSuccess,
    roleDeleteMessage : state.roles.roleDeleteMessage,
    confirmDialog :  state.alertsDialog.confirmDialog,
    confirmData : state.alertsDialog.confirmData
  }
}

export default connect(mapStateToProps)(RoleListTable);

import React, { Component }  from 'react';

import {connect} from 'react-redux';
import $ from 'jquery';
import expandbutton from '../../../images/expand-button.png';


class UpdateMappingProgress extends Component {
    constructor(props){
        super(props)
        this.state = {
             search: "all",
             isTestParticipantsShown : false,
             updateStudyProgressData : {},
        }
        this.onChange = this.onChange.bind(this);
        this.participantAsExcel = this.participantAsExcel.bind(this);
        this.showTestParticipants = this.showTestParticipants.bind(this);
    }
    
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    participantAsExcel(){
        this.props.participantAsExcel(this.state.search, this.state.isTestParticipantsShown);
    }

    componentWillReceiveProps(nextProps){
      this.setState({
       updateStudyProgressData : nextProps.progressData
      }); 
   }

   showTestParticipants(){
    this.setState({
      isTestParticipantsShown : true
    });
}
    
    render(){
       

        let totalIpFails = 0;
    let participantItems = "";
    let totalCompletes = 0;
    let totalOverQuota = 0;
    let totalTerminates = 0;
    let totalLoiFails = 0;
    let loi = 0;
    let ir = 0;
    let avgLoi = 0;
    //let progressData = this.props.progressData;

    let progressData = {};
    
   
    if(this.state.isTestParticipantsShown){
        let studySummaryData = this.state.updateStudyProgressData;

        if(this.state.updateStudyProgressData  && this.state.updateStudyProgressData.participantData ){
            studySummaryData.participantData = this.state.updateStudyProgressData.participantData.filter(data=>data._id.includes("GNNTEST"));
        }
        
        progressData = studySummaryData;
    } else {
        progressData = this.state.updateStudyProgressData;
    }


    if (progressData) {

      // if (progressData.totalCompletes) {
      //   totalCompletes = progressData.totalCompletes;
      // }

      // if (progressData.totalOverQuota) {
      //   totalOverQuota = progressData.totalOverQuota;
      // }

      // if (progressData.totalTerminates) {
      //   totalTerminates = progressData.totalTerminates;
      // }

      // if (progressData.totalIpFails) {
      //   totalIpFails = progressData.totalIpFails;
      // }

      // if (progressData.loi && progressData.loi.loi) {
      if (progressData.loi) {
        loi = progressData.loi.loi;
        if(progressData.loi.completed === null){
          totalCompletes = 0;
          totalOverQuota = 0;
          totalTerminates = 0;
          totalIpFails = 0;
          totalLoiFails = 0;
        } else {
          totalCompletes = progressData.loi.completed;
          totalOverQuota = progressData.loi.overQuota;
          totalTerminates = progressData.loi.terminate;
          totalIpFails = progressData.loi.ipFailed;
          totalLoiFails = progressData.loi.loiFailed;
        }
      }
      if (progressData.irCount) {
        ir = progressData.irCount
      }
      if (progressData.avgLoi) {
        avgLoi = progressData.avgLoi.average
      }
      // if (progressData.totalLoiFails) {
      //   totalLoiFails = progressData.totalLoiFails
      // }
    }
    if (progressData && progressData.participantData && progressData.participantData.length > 0) {
      participantItems = progressData.participantData.filter(
        (participants, index) => {
          if (this.state.search === "all") {
            return true;
          }
          else if (participants.status.indexOf(this.state.search) !== -1) {
            return true;
          }
        }).map((item, index) => {
          let loi = "";
          let status = "";

          let date ="";
          let time="";
          let endTime= "";
            if(item.createdAt.includes("T")){
              date = item.createdAt.split("T")[0];
              time = item.createdAt.split("T")[1].split(".")[0];
            }else{
              date = item.createdAt.split(" ")[0];
              time = item.createdAt.split(" ")[1].split(".")[0];
            }         
          
            if(item.endLoi === null || item.endLoi === "null"){
              if(item.updatedAt.includes("T")){
                  endTime = item.updatedAt.split("T")[1].split(".")[0];
              }else{
                  endTime = item.updatedAt.split(" ")[1].split(".")[0];
              }
          } else {
              if(item.endLoi.includes("T")){
                  endTime = item.endLoi.split("T")[1].split(".")[0];
              }else{
                  endTime = item.endLoi.split(" ")[1].split(".")[0];
              } 
               
          }

          if (item.loi) {
            if (typeof item.loi == "number") {

              item.loi.toFixed(2)

            } else {

              loi = parseFloat(item.loi).toFixed(2)
            }
          }
          switch (item.status) {

            case "1":
              status = "Completed";
              break;
            case "2":
              status = "Over Quota";
              break;
            case "3":
              status = "Terminated";
              break;
            case "0":
              status = "Not Completed";
              break;
            case "4":
              status = "Internal Terminated";
              break;
            case "5":
              status = "Internal Over Quota";
              break;
            case "6":
              status = "Failed In Loi";
              break;
            case "7":
              status = "Failed In Ip";
              break;
            case "12":
            status = "Vendor Over Quota"
          }
          return <tr className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom" key={index}>
            {/* <td className="tdd">{index + 1}</td> */}
            <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{item._id}</td>
            <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{item.uid}</td>
            <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{date}</td>
            <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{time}</td>
            <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{endTime}</td>
            <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{item.participantIp}</td>
            <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{Math.round(item.loi)}</td>
            {/* <td className="tdd">{loi}</td> */}
            <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{status}</td>
          </tr>
        })
    }

    let showTable="";
    if(progressData && progressData.participantData && progressData.participantData.length > 0){
      showTable = <table className="mappingtable" id="pro-tbl">
      <thead  className="gradienthead" >
        <tr>
              <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-fix">ID</th>
              <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-fix">Respondent ID</th>
              <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-fix">Started Date</th>
              <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-fix">Started Time	</th>
              <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-fix">End Time	</th>
              <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-fix">IP Address</th>
              <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-fix">LOI</th>
              <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom tbl-th-fix">Status</th>
          </tr>
          </thead>
          <tbody>
              {participantItems}
          </tbody>
         
    </table>
    }else if( this.props.progressData && this.props.progressData.participantData && this.props.progressData.participantData.length == 0){
      showTable = <div className="datanotfound"> No record found</div>
    }else{
      showTable = <div className="spiner-data"><div className="tblspinner"></div></div>
    }

        return(
            <div className="form-title">
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                        <h4 className="rm-margin">Progress Report</h4>
                        </div>
                    <div className="pro-tgl-btn pro-tgl-btn-shdw" id="tg-btn-7">
                        <div id="rotate-icon-7"><img src={expandbutton} height="15px" width="15px"/></div>
                    </div>
                </div>
                <div style={{marginTop: "20px"}}  className="box-hide-4" id="map-hide-4">
               
                        <div  className="map-card-top">
                            <div className="map-card-midd">
                                <div className="map-card-bottom map-box-1 map-box">
                                    <p>Complete</p><h3>{totalCompletes}</h3>
                                </div>
                                <div className="map-card-bottom map-box-2 map-box">
                                    <p>Quota full</p><h3>{totalOverQuota}</h3>
                                </div>
                            </div>

                            <div className="map-card-midd">
                                <div className="map-card-bottom map-box-3 map-box">
                                    <p>Terminates</p><h3>{totalTerminates}</h3>
                                </div>
                                <div className="map-card-bottom map-box-4 map-box">
                                    <p>LOI Fail</p><h3>{totalLoiFails}</h3>
                                </div>
                            </div>
                        </div>

                        <div  className="map-card-top">
                            <div className="map-card-midd">
                                <div className="map-card-bottom map-box-1 map-box">
                                    <p>Length Of Interview</p><h3>{loi}</h3>
                                </div>
                                <div className="map-card-bottom map-box-2 map-box">
                                    <p>Average Loi</p><h3>{Math.round(avgLoi)}</h3>
                                </div>
                            </div>

                            <div className="map-card-midd">
                                <div className="map-card-bottom map-box-3 map-box">
                                    <p>IR Count</p><h3>{ir +" %"}</h3>
                                </div>
                                <div className="map-card-bottom map-box-4 map-box">
                                    <p>IP Fail</p><h3>{totalIpFails}</h3>
                                </div>
                            </div>
                        </div>


                        <div style={{display: "flex",justifyContent: "space-between", marginTop: "20px"}}>
                            <button className="list-btn list-btn-color"  onClick={this.participantAsExcel}>Export To Excel</button>
                            <button  onClick={this.showTestParticipants} className="list-btn list-btn-color ">Test Participants</button>
                           
                            {/* <button className="list-btn list-btn-color" id="flt-btn">
                                        <span >Project Activities
                                        <div className="flter-btn-downarrow" style={{paddingRight:"20px"}}></div>
                                        </span>
                                    </button> */}
                            <select id="selectstatus"  className="updateMappingSearchByStatus" name="search" value={this.state.search} onChange={this.onChange}>
                                <option value="all">Filter By Status</option>
                                <option value="1">Completed</option>
                                <option value="2">Over Quota</option>
                                <option value="3">Terminated</option>
                                <option value="0">Not Completed</option>
                                <option value="7">Failed In Ip</option>
                                <option value="6">Failed In Loi</option>
                                <option value="12">Vendor Over Quota</option>
                            </select>
                           
                        </div>


                        <div style={{marginTop: "20px",overflowX: "auto",height: "500px", overflowY: "auto"}}>
                              {showTable}
                        </div>

                    </div>
               
                </div>

     
    )}
}

const mapStateToProps=(state)=>{
    return{
      
    }
  }
  
  export default connect(mapStateToProps)(UpdateMappingProgress);
import React, { Component }  from 'react';
import InvitationHeader from '../../components/ProjectActivities/createClone/invitationHeader';
import CreateCloneForm from '../../components/ProjectActivities/createClone/createClone';
import $ from 'jquery';
import { alertMessage }  from '../../alertsDialog/Action';
import { connect } from 'react-redux';


export class CreateClone extends Component {
    constructor(props) {
        super(props)
        this.state = {
            NoOfClone : ""
        }
        this.selectActivity = this.selectActivity.bind(this);
        this.moveToClonePage = this.moveToClonePage.bind(this);
    }

    

    // componentDidMount(){
    //     $(document).ready(function(){
    //         $("#tg-btn-4").click(function(){
    //         $("#show-form-4").toggleClass("show-full-div");
    //         $("#map-hide-1").toggleClass("box-hide-1");
    //         $("#rotate-icon-4").toggleClass("rotate-tgl");
    //         });

    //         $("#tg-btn-5").click(function(){

    //          $("#show-form-5").toggleClass("show-full-div");
    //          $("#map-hide-2").toggleClass("box-hide-2");
    //          $("#rotate-icon-5").toggleClass("rotate-tgl");
    //         });

    //     });
    // }



    selectActivity(activityName){
        this.props.history.push(activityName);
    }

    gotoBack(){
        window.history.back();
    }

    moveToClonePage(studyId){
    this.props.history.push({
        pathname: '/Clone-Study',
        state: {
             cloneMainStudyID : studyId ,
             cloneMainStudyName : localStorage.getItem("study_Name")
            }
      });    
    }
    

    render(){
        return(
            <div>
                
              
                <InvitationHeader
                />

                <CreateCloneForm
                moveToClonePage = {this.moveToClonePage}
                />

            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        
    }
  }
  
  export default connect(mapStateToProps)( CreateClone);
import React, { Component }  from 'react';
import '../../src/commonStyle/common.css';
import userImg from '../../src/userimage/user1.png';
import logout from '../../src/images/logout.png';
import { Link } from 'react-router-dom';

export default class UserProfilePopup extends Component {

    logoutUser(){
        const url = '/logout';
    window.open(url, '_self');
    }
    
    render(){
        return(
            <div className="profile-poup" id="userpopup">  
                <div id="logoutpart1">
                    <div id="userimg">
                        <div id="userimg1">
                            <div style={{borderRadius: "50%",overflow: "hidden",height:"120px", width: "120px"}}>
                                <img src={userImg} />
                                </div>
                        </div>
                    </div>
                        <div id="username">{localStorage.getItem('admin_name')}</div></div>
                <div id="logoutpart2" onClick={this.logoutUser}>
                        
                        <span>Logout</span>
                        <span ><img src={logout} /></span>

                </div>
            </div>
          
    )}
}
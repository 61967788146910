import React, { Component } from 'react';
import { thirdPartyParticipantCount, archivingThirdpartyParticipantCount,   updateMapping, emptyResUpdateMapping, getAllCloneStudiesByStudy } from '../../actions/actionsBag/study';
import { getProgress  , getArchivingProgress } from '../../actions/actionsBag/dashboard';
import ArchivingUpdateStudyHeader from '../../components/archivingStudies/updateStudyHeader';
import UpdateMapping from '../../components/archivingStudies/projectActivities/UpdateMapping';
import UpdateMappingProgress from '../../components/archivingStudies/projectActivities/UpdateMappingProgress';
import CloneMapVendorsUrl from '../../components/ProjectActivities/updateMapping/cloneMappingUrlList';
import { connect } from 'react-redux';
import $ from 'jquery';
import appConfig from '../../api/apiConfig';
import { alertMessage } from '../../alertsDialog/Action';

export class ArchivingUpdateProjectMapping extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editVendorMappingData: "",
            studyid: ""
        }
        this.gotoBack = this.gotoBack.bind(this);
        this.updateMapping = this.updateMapping.bind(this);
        this.participantAsExcel = this.participantAsExcel.bind(this);
        this.selectActivity = this.selectActivity.bind(this);
    }

    componentWillMount() {
       
        if (this.props.location.state === undefined) {
            this.props.history.push("error");
        } else {
            let studyid = localStorage.getItem("edit_study_id");
            let studyName = localStorage.getItem("edit_study_name");
            if(localStorage.getItem("is_clone_main_study") == 1){
               this.props.dispatch( getAllCloneStudiesByStudy( studyid ));
            }

            const { editVendorMappingData } = this.props.location.state;
            this.setState({ editVendorMappingData: editVendorMappingData, studyid: studyid });

            this.props.dispatch(archivingThirdpartyParticipantCount(editVendorMappingData));



            let id = {};
            id.thirdPartyId = editVendorMappingData.thirdPartyId;
            id.studyId = studyid;
            this.props.dispatch(getArchivingProgress(id));

        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $("#tg-btn-6").click(function () {
                $("#show-form-6").toggleClass("show-full-div");
                $("#map-hide-3").toggleClass("box-hide-3");
                $("#rotate-icon-6").toggleClass("rotate-tgl");
            });

            $("#tg-btn-7").click(function () {

                $("#show-form-7").toggleClass("show-full-div");
                $("#map-hide-4").toggleClass("box-hide-4");
                $("#rotate-icon-7").toggleClass("rotate-tgl");
            });

            $("#tg-btn-8").click(function () {

                $("#show-form-8").toggleClass("show-full-div");
                $("#map-hide-5").toggleClass("box-hide-5");
                $("#rotate-icon-8").toggleClass("rotate-tgl");
            });

        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.updateMappingSuccess && nextProps.updateMappingMessage) {
            // alert(nextProps.updateMappingMessage);
            let alertData = {};
            alertData.type = "alert";
            alertData.message = nextProps.updateMappingMessage;
            this.props.dispatch(alertMessage(alertData));
            this.props.dispatch(emptyResUpdateMapping());
        } else if (nextProps.updateMappingMessage) {
            // alert(nextProps.updateMappingMessage);
            let alertData = {};
            alertData.type = "alert";
            alertData.message = nextProps.updateMappingMessage;
            this.props.dispatch(alertMessage(alertData));
            this.props.dispatch(emptyResUpdateMapping());
        }
    }

    gotoBack() {
        window.history.back();
    }

    updateMapping(mappingData) {
        this.props.dispatch(updateMapping(mappingData));
    }

    participantAsExcel(status,type) {
        //window.open(appConfig.siteURL + 'participantAsExcel/' + this.state.studyid + '/' + this.state.editVendorMappingData.thirdPartyId + "/" + status);
        if(type === true){
            window.open(appConfig.siteURL + 'archivingParticipantAsExcel/'+ this.state.studyid+"/"+ this.state.editVendorMappingData.thirdPartyId+'/'+status+"/test");
  
        } else{
            window.open(appConfig.siteURL + 'archivingParticipantAsExcel/'+ this.state.studyid+"/"+this.state.editVendorMappingData.thirdPartyId+'/'+status+"/live");
        }
    }

    selectActivity(activityName) {
        this.props.history.push(activityName);
    }


    render() {
        return (
            <div>
                <ArchivingUpdateStudyHeader
                    selectActivity={this.selectActivity}
                    editstudyData={this.state.editstudyData}
                    gotoBack={this.gotoBack}
                />
                <div className="create-form ">
                    <div className="create-form-container all-header">
                        <div className="forms-rd-shadow toggle-form show-me" id="show-form-6"  >
                            <UpdateMapping
                                editVendorMappingData={this.state.editVendorMappingData}
                                thirdPartyTotalCount={this.props.thirdPartyTotalCount}
                                updateMapping={this.updateMapping}
                            />
                        </div>
                    </div>
                </div>

                <div className="create-form ">
                    <div className="create-form-container all-header">
                        <div className="forms-rd-shadow toggle-form show-me" id="show-form-7" >
                            <UpdateMappingProgress
                                progressData={this.props.progressData}
                                participantAsExcel={this.participantAsExcel}
                            />
                        </div>
                    </div>
                </div>

                {localStorage.getItem("is_clone_main_study") == 1 ? (

                    <div className="create-form ">
                        <div className="create-form-container all-header">
                            <div className="forms-rd-shadow toggle-form show-me" id="show-form-8" >
                                <CloneMapVendorsUrl
                                    editVendorMappingData={this.state.editVendorMappingData}
                                />
                            </div>
                        </div>
                    </div>
                ):(<div></div>)}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        thirdPartyTotalCount: state.studies.thirdPartyTotalCount,
        updateMappingSuccess: state.studies.updateMappingSuccess,
        updateMappingMessage: state.studies.updateMappingMessage,
        progressData: state.dashboard.progressData
    }
}

export default connect(mapStateToProps)(ArchivingUpdateProjectMapping);
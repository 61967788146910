import React from 'react';
import {render} from 'react-dom';

import { Provider } from 'react-redux';
import {store}  from './lib/store';
import {appInit} from './actions';

import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
store.dispatch(appInit(store));
render(
<Provider store={store}>
  <App />
  </Provider>, document.getElementById('root')

);
registerServiceWorker();

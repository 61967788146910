import React, { Component }  from 'react';
import { connect } from 'react-redux';
import RoleHeader from '../../components/Role/roleHeader.jsx';
import AddRoleDiv from '../../components/Role/addRole';
import {addRole, blankResRoles} from '../../actions/actionsBag/roles';
import goback from '../../images/goback.png';
import {Link} from 'react-router-dom';
import { alertMessage }  from '../../alertsDialog/Action';

    class CreateRoleView extends Component {

        constructor(props) {
            super(props)
            this.state = {
                roleId : ""
            }
            this.addRole = this.addRole.bind(this);
        }

    componentWillMount() {

       
      }

    componentWillReceiveProps( nextProps){
        if (nextProps.addRoleSuccess === true && nextProps.addRoleSuccess != undefined && nextProps.addRoleMessage != undefined ) {
            if(nextProps.addRoleMessage){
                let alertData ={};
                alertData.type ="alert";
                alertData.message = nextProps.addRoleMessage;
                this.props.dispatch(alertMessage(alertData));
              this.setState({ role_type  : "" });
              if(nextProps.addRoleResponse.data){
                let roleId = nextProps.addRoleResponse.data.role_id;
                this.setState({roleID : roleId });
                this.props.history.push('/assign-permission/'+ roleId);
              }
              this.props.dispatch(blankResRoles());
            }
        } else if(nextProps.addRoleMessage){
            let alertData ={};
            alertData.type ="alert";
            alertData.message = nextProps.addRoleMessage;
            this.props.dispatch(alertMessage(alertData));
            this.props.dispatch(blankResRoles());
            
        }
    }

    addRole(data){
        this.props.dispatch(addRole(data));
    }

    render(){

        const header = 'Create Role';
        const headerText = 'Here you can add new role for user';
        const headerButton = <Link to="/role-list"> <button className="back-btn back-btn-color" ><img src={goback} height="13px" width="13px" /><span> Back</span></button></Link>


        return(
            <div style={{ width:"100%"}}>  
                <RoleHeader 
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                />
                <AddRoleDiv
                    addRole = {this.addRole}
                />
            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        addRoleSuccess : state.roles.addRoleSuccess,
        addRoleMessage : state.roles.addRoleMessage,
        addRoleResponse : state.roles.addRoleResponse,
    }
  }
  
export default connect(mapStateToProps)(CreateRoleView);
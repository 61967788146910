import React, { Component } from 'react';
import appConfig from '../../../api/apiConfig'
import { connect } from 'react-redux';
import expandbutton from '../../../images/expand-button.png';


import $ from 'jquery';



class CloneMapVendorsUrl extends Component {
  constructor(props) {
    super(props)
    this.state = {
      studyUrl: "",
      testStudyUrl: "",
      stopEditingData: true,
      cloneUrlArr : [],
    }
    this.CopyUrl = this.CopyUrl.bind( this);
  }

  componentWillMount() {
    //this.getProgress()
    if (this.props.editVendorMappingData) {
      this.setState({
        studyUrl: appConfig.url.participantUrl + "?SID=" + this.props.editVendorMappingData.studyId + "&TID=" + this.props.editVendorMappingData.thirdPartyId + "&UID={uid}",
        // testStudyUrl: appConfig.url.testUrl + "?SID=" + this.props.editVendorMappingData.studyId + "&TID=" + this.props.editVendorMappingData.thirdPartyId + "&UID={uid}"
      });
    }
    if (localStorage.getItem("edit_study_data") === "stop") {
      this.setState({
        stopEditingData: true
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cloneStudesIdSuccess && nextProps.cloneStudesIdData && nextProps.cloneStudesIdData.result.length > 0) {
      let cloneStudes = nextProps.cloneStudesIdData.result;
      let cloneStudesUrl = [];
      for( let i = cloneStudes.length-1 ; i >=0 ; i--){
        let studyUrl = ( cloneStudes[i].countryName ? (cloneStudes[i].countryName + ' '): '') +  ( cloneStudes[i].lang_code ? cloneStudes[i].lang_code + ' - ': '') + appConfig.url.participantUrl + "?SID=" + cloneStudes[i]._id + "&TID=" + this.props.editVendorMappingData.thirdPartyId + "&UID={uid}";
        cloneStudesUrl.push( studyUrl);
        if( (cloneStudes.length-1) === i){
          this.setState({ cloneUrlArr : cloneStudesUrl });
        }
      }
    }   
  }





  onChange(e) { this.setState({ [e.target.name]: e.target.value }); }

  CopyUrl() {
    let str = '';

    for(let i=0; i< this.state.cloneUrlArr.length ; i++){
      str = this.state.cloneUrlArr[i]+' \n'+ str;
    }
    var el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
 
  }



  render() {
    let cloneStudyLiveUrl = "";
    if (this.props.cloneStudesIdSuccess && this.props.cloneStudesIdData && this.props.cloneStudesIdData.result.length > 0) {
      cloneStudyLiveUrl = this.props.cloneStudesIdData.result.map((list, i) => {
        // let accessAllowed = (age > 18) ? true : false;
        let studyUrl =  (list.countryName ? list.countryName + ' ': '')+  (list.lang_code ? list.lang_code + ' - ': '') + appConfig.url.participantUrl + "?SID=" + list._id + "&TID=" + this.props.editVendorMappingData.thirdPartyId + "&UID={uid}";
        return <div className="formgroup">
                <div className="url-text-1" style={{ display: "flex" }}>
                  <label style={{ paddingTop: "6px" }}>{i + 1}.</label>&nbsp;&nbsp;
                  <input className="form-controll" id="CopyUrl" disabled={this.state.stopEditingData} value={studyUrl} ></input>
                </div>
              </div>
      });
    }

    return (
      <div className="form-title">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div style={{ display: "flex" }}><h4 className="rm-margin">clone study redirect link</h4></div>

          </div>
          <div className="pro-tgl-btn pro-tgl-btn-shdw" id="tg-btn-8">
            <div id="rotate-icon-8"><img src={expandbutton} height="15px" width="15px" /></div>
          </div>
        </div>
        <div style={{ marginTop: "20px" }} className="box-hide-5" id="map-hide-5">
          <div style={{ marginTop: "20px" }} >
            <button className="list-btn" style={{ width: "150px" }} onClick={this.CopyUrl}>Copy All Urls</button></div>


          {cloneStudyLiveUrl}


        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    cloneStudesIdSuccess: state.studies.cloneStudesIdSuccess,
    cloneStudesIdData: state.studies.cloneStudesIdData
  }
}

export default connect(mapStateToProps)(CloneMapVendorsUrl);
import React, { Component }  from 'react';
import '../../commonStyle/common.css';
import { addClient, emptyVendorProps } from '../../actions/actionsBag/vendor';
import { connect } from 'react-redux';
import { thirdPartyApi } from '../../api/apiBag/vendorApi';
import { getDisplayName } from 'recompose';
import $ from 'jquery';

export default class UpdateUserDiv extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userID : "",
            firstName: "",
            lastName: "",
            userName:"",
            password:"",
            role_id : "",
            disableOperationManager: "none",
            showOpManager : false,
            op_managerList : [],
            op_manager : ""
        }
        this.onFirstNameChange = this.onFirstNameChange.bind(this);
        this.onLastNameChange = this.onLastNameChange.bind(this);
        this.onUserNameChange = this.onUserNameChange.bind(this);
        this.onPasswordNameChange = this.onPasswordNameChange.bind(this);
        this.onRoleChange = this.onRoleChange.bind(this);
        this.OnManagerChange = this.OnManagerChange.bind(this);
        this.updateUser = this.updateUser.bind(this);
      }

      componentWillMount(){
        if(this.props.editUserData ){
            this.setState({ 
                userID      :   this.props.editUserData.user_id,
                firstName   :   this.props.editUserData.first_name,
                lastName    :   this.props.editUserData.last_name,
                userName    :   this.props.editUserData.user_name,
                password    :   this.props.editUserData.password,
                role_id     :   this.props.editUserData.user_role_id,
                });
                
          }
          if(this.props.editUserData.op_manager){
           
            this.props.getOPmanager();
            this.setState({
              op_manager  :   this.props.editUserData.op_manager,
              showOpManager: true,
              disableOperationManager : "block"
            });
           }else{
          
            this.setState({
                op_manager  :  [],
                showOpManager: false,
                disableOperationManager : "none"
              });
           }
      }

      componentWillReceiveProps(nextProps){

        if(nextProps.result && nextProps.result.success === true ){
            this.setState({
        
            });
        }
        if(nextProps.opManagerSuccess){
            if(nextProps.opManagerList.length>0){
              this.setState({op_managerList : nextProps.opManagerList});
            }else{
             alert("Please create Opration Manager role_type user first !");
            }
        }
      }


      onFirstNameChange(e){
        if(e.target.value !== "")
            $("#error1").removeClass("showerror");
        
        this.setState({
            firstName : e.target.value
        });
    }
    onLastNameChange(e){
        if(e.target.value !== "")
        $("#error2").removeClass("showerror");
    
    this.setState({
        lastName : e.target.value
    });
    }
    onUserNameChange(e){
        if(e.target.value !== "")
        $("#error3").removeClass("showerror");
    
        this.setState({
            userName : e.target.value
        });
    }
    onPasswordNameChange(e){
        
        if(e.target.value !== "")
        $("#error4").removeClass("showerror");
    
        this.setState({
            password : e.target.value
        });
    }


    
    onRoleChange(e){
        if( e.target.value.includes("bde") ){
            this.props.getOPmanager();
            this.setState({ [e.target.name]: e.target.value, showOpManager: true, disableOperationManager : "block" });
        }else{
            this.setState({ [e.target.name]: e.target.value ,
            op_managerList : [], disableOperationManager : "none", showOpManager : false});
        }
    }

    OnManagerChange(e){
        if(e.target.value !== ""){
            $("#error6").removeClass("showerror");
        }
        this.setState({
            op_manager : e.target.value
        });
    }

    handleValidation() {
        let valid = true;
        let error = {};  
    
        if (this.state.firstName === "") {
            valid = false;
            $("#error1").addClass("showerror");
            this.setState({
               userfirstnameerror: "Enter user first name"
            });
        }
        if(this.state.lastName === ""){
            valid = false;
            $("#error2").addClass("showerror");
            this.setState({
               userlastnameerror: "Enter user last name"
            });
        }
        if(this.state.userName === ""){
            valid = false;
            $("#error3").addClass("showerror");
            this.setState({
                usernameerror: "Enter user name"
            });
        }
        
        if(this.state.password === ""){
            valid = false;
            $("#error4").addClass("showerror");
            this.setState({
                passworderror: "Enter password"
            });
        }

        if(this.state.role_id === "" ){
            valid = false;
            $("#error5").addClass("showerror");
            this.setState({
                roleerror: "Select role"
            });
        }

        if(this.state.role_id.includes("bde") && this.state.op_manager ===""){
            valid = false;
            $("#error6").addClass("showerror");
            this.setState({
                managererror: "Select manager"
            });
        }

        this.setState({ validationMsg: error });
        return valid;
      }

    updateUser(){
        let studyData = {};
        if(this.state.role_id.includes("bde")){
            studyData.op_manager = this.state.op_manager;
        }else{
            studyData.op_manager = "";
        }
        
        studyData.id = this.state.userID;
        studyData.first_name = this.state.firstName;
        studyData.last_name = this.state.lastName;
        studyData.user_name = this.state.userName;
        studyData.password = this.state.password;
        studyData.user_role_id = this.state.role_id;
        
        
        studyData.updated_by = localStorage.getItem('auth_user_id');
        if (this.handleValidation()) {
          this.props.updateUser(studyData);
        }
    }

    render(){

        let roleItem = "";
        if(this.props.allRoleSuccess && this.props.allRoleData  && this.props.allRoleData.length>0 ){
            roleItem = this.props.allRoleData.map((role) =>       
            <option key={role.role_id} value ={role.role_id}>{role.role_type}</option>
              );
        }
        let opManagerItem="";
        if( this.state.showOpManager && this.state.op_managerList.length>0){
            opManagerItem = this.state.op_managerList.map((op) =>       
          <option key={op.user_id} value ={op.user_id}>{op.user_name}</option>
            );
        }


        return(
            <div style={{marginTop: "2%"}}>
                <div className="table-st">
                    <div className="forms-rd-shadow" style={{ width: "100%",paddingBottom:"20px", backgroundColor:"#ffffff", borderRadius:"4px" }}>
                        <div className="addVendor-main-div">
                        <div className="form-title">
                            <h4 className="rm-margin">Update user form</h4>
                            <p className="form-title-text"> Update Gnn user  </p>
                        </div>
                            <hr/>
                        
                            <div className = "formgroup">
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>First Name*</label>
                                    <input className="form-controll " value={this.state.firstName} name="firstName" placeholder="First Name" onChange={this.onFirstNameChange}></input>
                                    <div className="errordiv cus-width-2" id="error1">{this.state.userfirstnameerror }</div>
                                </div>
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>Last Name*</label>
                                    <input className="form-controll " value={this.state.lastName} name="lastName" placeholder="Last Name" onChange={this.onLastNameChange}></input>
                                    <div className="errordiv cus-width-2" id="error2">{this.state.userlastnameerror }</div>
                                </div>
                            </div>

                            <div className = "formgroup">
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>User Name*</label>
                                    <input className="form-controll " value={this.state.userName} name="userName" placeholder="User Name" onChange={this.onUserNameChange}></input>
                                    <div className="errordiv cus-width-2" id="error3">{this.state.usernameerror }</div>
                                </div>
                                
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>Password*</label>
                                    <input className="form-controll " value={this.state.password} name="password" placeholder="Password" onChange={this.onPasswordNameChange}></input>
                                    <div className="errordiv cus-width-2" id="error4">{this.state.passworderror }</div>
                                </div>
                            </div>     

                            <div className = "formgroup">

                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>Role*</label>
                                    <select className="form-controll" style={{width:"100%", padding: "2px"}}  name="role_id" value={this.state.role_id} onChange={this.onRoleChange}>
                                        <option value="">Select Role</option>
                                        {roleItem}
                                    </select>
                                    <div className="errordiv cus-width-2" id="error5">{this.state.roleerror}</div>
                                </div>
                                
                                <div class="formgroup-col-6 rltv-pos" style={{ display: this.state.disableOperationManager }} >
                                    <label>OprationManager*</label>
                                    <select className="form-controll" style={{width:"100%", padding: "2px"}}  name="op_manager"value={this.state.op_manager} onChange={this.OnManagerChange}>
                                        <option value="">Select Op_manager</option>
                                        {opManagerItem}
                                    </select>
                                    <div className="errordiv cus-width-2" id="error6">{this.state.managererror}</div>
                                </div>
                            </div>                        
                            
                            <div className = "formgroup addVendorCheckbox">
                                <button onClick={this.updateUser} className="list-btn" style={{marginTop:"20px"}}>Update User</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )}
}



import appConfig from "../../api/apiConfig";

export const uniqueLinkDataApi = (id) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getLinkData/' + id, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Get unique link Data", error)
    return error;
  });
}

export const searchApi = (data) => {
  let data1 = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'search/', {
    method: "POST",
    headers: headers,
    body: data1
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Get Participant List", error)
    return error;
  });
}


export const searchInArchiveApi = (data) => {
  let data1 = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'searchInArchive/', {
    method: "POST",
    headers: headers,
    body: data1
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Get Participant List", error)
    return error;
  });
}

export const invitationToPanellistApi = (invitationData) => {

  invitationData.url = appConfig.url.participantUrl;
  let data = JSON.stringify(invitationData);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'createMapping', {
    method: "POST",
    headers: headers,
    body: data
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      if (result.success === false) {
        return result;
      } else {

        result.study_name = invitationData.study_name;
        result.description = invitationData.description;
        result.cpi = invitationData.cpi;
        result.is_live = invitationData.is_live;

        let value = JSON.stringify(result);

        //const request = new Request("https://apimy.panelreward.com/api/createStudy", {
        //const request = new Request("https://testmyapi.panelreward.com/api/createStudy", { // test
        const request = new Request(appConfig.panelrewardSiteURL + 'createStudyBySp', { // test local host
          method: "POST",
          headers: headers,
          body: value
        });
        return fetch(request).then(response => {
          return response.json().then(result => {
            return result;
          })
        })
      }
    });
  }).catch(error => {
    console.log("invitation", error)
    return error;
  });
}

export const uploadDocApi = (doc, studyId) => {

  const request = new Request(appConfig.siteURL + 'file/' + studyId, {
    method: "POST",
    body: doc
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create participant", error)
    return error;
  });
}


export const getParticipantListApi = (id) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'participantList/' + id, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Get Participant List", error)
    return error;
  });
}

export const updateParticipantApi = (data) => {
  //let participantData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  //const request = new Request(appConfig.siteURL + 'participantsUpdate/' + data.uid + "/" + data.checkLoi + "/" + data.sid + "/" + data.status + "/"+data.localTime, {
  const request = new Request(appConfig.siteURL + 'participantsUpdate/' + data.uid + "/" + data.checkLoi + "/" + data.sid + "/" + data.status, {
    method: "GET",
    headers: headers,
    //body: participantData
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("update participant", error)
    return error;
  });
}

export const participantApi = (data) => {
  let participantData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'participants/', {
    method: "POST",
    headers: headers,
    body: participantData
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create participant", error)
    return error;
  });
}


//participantsPost....

export const addnewParticipantApi = (data) => {
  //let participantData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  //const request = new Request(appConfig.siteURL + 'participantsnew/' + data.sid + '/' + data.tid + '/' + data.uid + '/' + data.localDate, {
  const request = new Request(appConfig.siteURL + 'participantsnew/' + data.sid + '/' + data.tid + '/' + data.uid, {
    method: "GET",
    headers: headers,
    //body: participantData
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create participant", error)
    return error;
  });
}

export const studiesApi = (data) => {
  let studyData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });

  let request
  if (data.id) {
    request = new Request(appConfig.siteURL + 'studiesUpdated/' + data.id, {
      method: "POST",
      headers: headers,
      body: studyData
    });
  } else {
    request = new Request(appConfig.siteURL + 'studies/', {
      method: "POST",
      headers: headers,
      body: studyData
    });
  }

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

export const studiesListApi = (page, user, role, status) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getAllStudiesBy/' + page + '/' + user + '/' + role + '/' + status, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}



export const archivingStudiesListApi = (page, user, role, status) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getAllArchivedStudiesBy/' + page + '/' + user + '/' + role + '/' + status, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

//studiesListByFilterApi
export const studiesListByFilterApi = (page,  filterType,id,user, role, status) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'filterStudiesBy/' + page + '/' + filterType + '/' + id + '/' + user + '/' + role + '/' + status, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

//cloneStudiesListApi
export const cloneStudiesListApi = (page, user, role, status, sid) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getAllCloneStudiesByStudy/' + page + '/' + user + '/' + role + '/' + status + '/' + sid, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

export const addMappingApi = (data) => {
  let mappingData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'studyThirdPartyMapping/', {
    method: "POST",
    headers: headers,
    body: mappingData
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

export const updateMappingApi = (data) => {
  let updateData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'studyThirdPartyMappingUpdate/' + data.id, {
    method: "POST",
    headers: headers,
    body: updateData
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

export const dataByStudyIdApi = (id) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'studyThirdPartyMapping/' + id, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

//archivingDataByStudyId
export const archivingDataByStudyIdApi = (id) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'archivingStudyThirdPartyMapping/' + id, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}


export const demoMappingApi = (data) => {
  let DemoMappingData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'studyDemoMapping/', {
    method: "POST",
    headers: headers,
    body: DemoMappingData
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

export const demoListMappingApi = (id) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getDemoAndMapping/' + id, {
    method: "GET",
    headers: headers,

  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

export const participantDemoDataApi = (id) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'participantReply/' + id, {
    method: "GET",
    headers: headers,

  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}


export const addParticipantReplyApi = (data) => {
  let DemoMappingData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'participantReply/', {
    method: "POST",
    headers: headers,
    body: DemoMappingData

  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

export const DemoMappingListApi = (id) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getDemoAndQuota/' + id, {
    method: "GET",
    headers: headers,

  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

export const getOptionsApi = (id) => {
  //let idData = JSON.stringify(id);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getQueryAndOption/' + id.studyId + "/" + id.demoId, {
    method: "GET",
    headers: headers,
    //body: idData

  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

export const addConstraintApi = (data) => {
  let constraintData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'addConstrainsts/', {
    method: "POST",
    headers: headers,
    body: constraintData

  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

export const updateParticipantDataApi = (data) => {
  let participantData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'updateParticipant/' + data.id, {
    method: "PUT",
    headers: headers,
    body: participantData
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

//Soft Delete Study Api
export const softDeleteApi = (id, currentPage) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'studiesSoftDel/' + id + "/" + currentPage, {
    method: "POST",
    headers: headers,
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    return error;
  });
}


//getAllBDEbyAdminApi
export const getAllBDEbyAdminApi = (id) => {

  let userType = localStorage.getItem("auth_user_type");

  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getAllBDEusersbyAdmin/' + id + '/' + userType, {
    method: "GET",
    headers: headers,
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    return error;
  });
}

//getStudiesExcelListApi
export const getStudiesExcelListApi = (userBy, userType) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'studyInExcel/' + userBy + '/' + userType, {
    method: "GET",
    headers: headers,
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    return error;
  });
}

//getThirdPartyListByStudiesAPI 
export const getThirdPartyListByStudiesAPI = (studyId) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'thirdPartyListByStudies/' + studyId, {
    method: "GET",
    headers: headers,
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    return error;
  });
}

//updateStudyUniqueFieldApi
export const updateStudyUniqueFieldApi = (data) => {
  let uniqueFieldData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'updateUniqueFieldStudy/', {
    method: "POST",
    headers: headers,
    body: uniqueFieldData

  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

//getActivitylogByStudyApi
export const getActivitylogByStudyApi = (currentPage, studyId, field) => {

  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getActivitylogByStudy/' + currentPage + '/' + studyId + '/' + field, {
    method: "GET",
    headers: headers

  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

//getDataByStudyIDApi
export const getDataByStudyIDApi = (studyId) => {

  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'studies/' + studyId, {
    method: "GET",
    headers: headers

  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

// getAllclientsApi
export const getAllclientsApi = (userID, roleType) => {

  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getClientByAllUser/' + userID + '/' + roleType, {
    method: "GET",
    headers: headers

  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

//UpdateStudiesDataApi
export const UpdateStudiesDataApi = (data) => {
  let studyData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });

  let request = new Request(appConfig.siteURL + 'studiesUpdated/' + data.id, {
    method: "POST",
    headers: headers,
    body: studyData
  });


  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create third party", error)
    return error;
  });
}

//updateInMarket
export const updateInMarketApi = (data) => {
  let studyData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });

  let request = new Request(appConfig.siteURL + 'updateInMarket' ,{
    method: "POST",
    headers: headers,
    body: studyData
  });


  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("updateInMarket error", error)
    return error;
  });
}


export const participantCountApi = (item) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'ThirdpartyParticipantCount/' + item.thirdPartyId + '/' + item.studyId, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    return error;
  });
}

// archivingThirdpartyParticipantCountApi
export const archivingThirdpartyParticipantCountApi = (item) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'archivingThirdpartyParticipantCount/' + item.thirdPartyId + '/' + item.studyId, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    return error;
  });
}

export const getProgressReportDataByStudyApi = (studyId) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getProgressByStudy/' + studyId, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    return error;
  });
}

//getArchivedProgressReportDataByStudyApi
export const getArchivedProgressReportDataByStudyApi = (studyId) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getArchivedProgressByStudy/' + studyId, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    return error;
  });
}


export const getProgressReportDataByApiStudyApi = (studyId) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getProgressBySurveyStudy/' + studyId, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    return error;
  });
}

export const getQuotaDataOfApiStudyApi = (studyId) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getQuotasOnStudy/' + studyId, {
    method: "GET",
    headers: headers
  });
  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    return error;
  });
}


//getProgressReportDataByStudyMapApi
export const getProgressReportDataByStudyMapApi = (studyId) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getProgressByStudyMapping/' + studyId, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    return error;
  });
}



export const getArchivedProgressReportDataByStudyMapApi = (studyId) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getArchivedProgressByStudyMapping/' + studyId, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    return error;
  });
}

//getDefaultVendorApi
export const getDefaultVendorApi = (sid) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getDefaultVendor/' + sid, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    return error;
  });
}


export const apiProjectsListApi = (page) => {

  const headers = Object.assign({
    'Content-Type': 'application/json',
  });

  const request = new Request(appConfig.siteURL + 'getAllProjectListAPI/' + page, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Something went wrong!", error)
    return error;
  });
}

export const searchAPIProjectApi = (data) => {
  let searchData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });

  const request = new Request(appConfig.siteURL + 'searchAPIProject', {
    method: "POST",
    headers: headers,
    body : searchData
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Something went wrong!", error)
    return error;
  });
}

//sortAPIProject
export const sortAPIProjectApi = (data) => {
  let searchData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });

  const request = new Request(appConfig.siteURL + 'sortAPIProject', {
    method: "POST",
    headers: headers,
    body : searchData
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Something went wrong!", error)
    return error;
  });
}

export const sendInvitationToVendorsApi = (studie_id) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'sendInvitationToVendors/' + studie_id, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {

    return error;
  });
}

//createCloneStudyApi
export const createCloneStudyApi = (data) => {
  let studyData = JSON.stringify(data);
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });

  let request = new Request(appConfig.siteURL + 'cloneStudies/', {
    method: "POST",
    headers: headers,
    body: studyData
  });


  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {
    console.log("Create clone", error)
    return error;
  });
}

//getAllCloneStudiesByStudyApi
export const getAllCloneStudiesByStudyApi = (studie_id) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getAllCloneStudiesIdByStudy/' + studie_id, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {

    return error;
  });
}

//getAllCountryLangCodeApi
export const getAllCountryLangCodeApi = () => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getAllCountryLangCode/', {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {

    return error;
  });
}

//getUniqueLinkListApi
export const getUniqueLinkListApi = (sid) => {
  const headers = Object.assign({
    'Content-Type': 'application/json',
  });
  const request = new Request(appConfig.siteURL + 'getLinkData/' + sid, {
    method: "GET",
    headers: headers
  });

  return fetch(request).then(response => {
    return response.json().then(result => {
      return result;
    });
  }).catch(error => {

    return error;
  });
}
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Login from './pages/Login';
import { connect } from 'react-redux';
import Logout from './containers/Logout';
import { compose } from 'recompose';
import TopHeader from './containers/headerMenu';
import LeftMenu from './containers/leftMenu/leftMenu';
import DashboardView from './pages/Dashboard/index.jsx'

import Layout from './layout';

import StudyList from './pages/Study/studyList';
import CreateClientView from './pages/Client/createClient';
import ClientListView from './pages/Client/clientList';
import UpdateClientDiv from './components/Client/updateClient';
import UploadClientLogo from './components/Client/uploadClientLogo.jsx';
import VendorListView from './pages/Vendor/VendorList.jsx';
import UpdateVendorDiv from './components/Vendor/updateVendor.jsx';
import CreateVendorView from './pages/Vendor/createVendor.jsx';
import CreateStudy from './pages/Study/createStudy';
import StudyListUpdate from './pages/Study/updateStudy';
import ArchivingStudyListUpdate from './pages/Study/archivingUpdateStudy';
import CreateUserView from './pages/User/createUser.jsx';
import UserListView from './pages/User/userList.jsx';
import UpdateUserView from './pages/User/updateUser.jsx';
import CreateRoleView from './pages/Role/createRole.jsx';
import RoleListView from './pages/Role/RoleList.jsx';
import ProjectMapping from './pages/projectActivities/addMapping';
import ParticipantReplies from './pages/projectActivities/participantReplies';
import ArchivedProjectMapping from './pages/archivingProjectActivities/archivingAddMapping';
import CloneProjectMapping from './pages/projectActivities/cloneAddMapping';
import UpdateProjectMapping from './pages/projectActivities/updateMapping';
import ArchivingUpdateProjectMapping from './pages/archivingProjectActivities/ArchivingUpdateProjectMapping';
import UpdateCloneProjectMapping from './pages/projectActivities/cloneUpdateMapping';
import AssignUserPermission from './pages/Role/assignPermission';
import CreateDemographicView from './pages/Demographic/createDemographic';
import DemographicsListView from './pages/Demographic/DemographicsList';
import UpdateDemographicView from './pages/Demographic/updateDemographics';
import { Loader } from 'react-overlay-loader';
import AlertDialogBox from './components/Dialogbox/alertDialogBox';
import Participants from './pages/projectActivities/participantList';
import Invoice from './pages/Invoice/invoiceList';
import { emptyStudyResponse } from './actions/actionsBag/study';
import CreateInvoiceView from './pages/Invoice/createInvoice';
import RecieptForm from './components/Invoice/recieptForm';
import UpdateRoleView from './pages/Role/updateRole';
import ActivityLog from './components/ActivityLog/activityLog';
import SendInvitation from './pages/projectActivities/sendInvitation';
import CreateSendMailView from './pages/SendMail/sendmail';
import AddQuestionView from './pages/Demographic/addQuestion';
import AddOptionView from './pages/Demographic/addOption';
import UpdateQueryAndOptionsView from './pages/Demographic/updateQueryAndOptions';
import DemoMappedList from './pages/projectActivities/DemoMapping/demoMappingList';
import ArchivingDemoMappingList from './pages/archivingProjectActivities/demoMapping/archivingDemoMappingList';

import AddDemoMapped from './pages/projectActivities/DemoMapping/addDemoMap';
import ApplyDemographics from './pages/projectActivities/DemoMapping/applyDemographics';
import PreviewQuestionsAdded from './pages/projectActivities/DemoMapping/previewQuestions';
import ConstraintsListMapped from './pages/projectActivities/DemoMapping/constraintsListMapped';
import ArchivingConstraintsListMapped from './pages/archivingProjectActivities/demoMapping/archivingConstraintsListMapped';

import ApplyConstraints from './pages/projectActivities/DemoMapping/applyConstraints';

import APIProjectView from './pages/Study/apiProjectList';
import ArchivingProjectView from './pages/Study/archivingProjectList';
import ApiProjectListUpdate from './pages/Study/updateApiProjectList';
import ApiProjectMapping from './pages/projectActivitiesSurvey/addMapping';
import ApiUpdateProjectMapping from './pages/projectActivitiesSurvey/apiupdateMapping';

import CreateClone from './pages/projectActivities/createClone';
import CloneStudyList from './pages/StudyClone/cloneStudyList';
import CloneStudyListUpdate from './pages/StudyClone/updateCloneStudy';

import UniqueLinkListView from './pages/StudyUniqueLink/uniqueLinkList';
import ArchivingUniqueLinkList from './pages/archivingProjectActivities/archivingUniqueLinkList';

import UploadUniqueLink from './pages/StudyUniqueLink/uploadUniqueLink';

const styles = {
  root: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    width: "50px",
    marginRight: 20,
  },
};

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      left: false,
      showDialog: false,
      showMessage: "",
      alertType: "",
      alertPushRoute : "",
      alertActionName: "",
      alertActionData: ""
    }

    // this.alertMessage = this.alertMessage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.showAlert === true) {
      this.setState({
        showDialog: true,
        showMessage: nextProps.alertMessage,
        alertType: nextProps.alertType,
        alertData: nextProps.alertData,
        alertPushRoute : nextProps.alertPushRoute
      });
    } else {
      this.setState({
        showDialog: false,
      });
    }

    // alert(""+nextProps.alertMessage);
    if (nextProps.alertMessage && nextProps.alertMessage.success === true) {

      // this.alertMessage(nextProps.alertMessage.message)
    } else if (nextProps.alertMessage && nextProps.alertMessage.success === false) {

      // this.alertMessage(nextProps.alertMessage.message)
    }
  }

  // alertMessage(msg) {
  //   confirmAlert({
  //     customUI: ({ onClose }) => {
  //       return (
  //         <div className='custom-ui'>
  //           <div className="alertimg"> <img src={require("./pages/ThirdParty/alert.png")} className="panellogo" /></div>
  //           <p className="alertmsg">{msg}</p>
  //           <button className="alertbutton" onClick={() => {
  //             this.props.dispatch(removeMessage())
  //             onClose()
  //           }}>Ok</button>
  //         </div>
  //       )
  //     }
  //   })
  // }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };
  onDialogClose() {
    document.getElementById("alert-dialog").style.display = "none";
    // this.props.dispatch(emptyStudyResponse());
  }


  render() {

    let showDialogBox = "";
    if (this.state.showDialog === true) {
      // alert(this.state.showMessage);
      showDialogBox = <div id="alert-dialog">
        {/* <div className="alert-box-container">
            <div className="alert-box" style={{borderRadius: "5px"}}>
                 <div style={{borderTopLeftRadius: "5px",borderTopRightRadius: "5px",backgroundColor: "blue",color: "white",height:"20%",textAlign: "center",display: "flex",alignItems: "center",justifyContent: "center"}}>
                     Alert !!!
                 </div>
                 <div style={{height:"60%",textAlign: "center",color: "black"}}>
                         {this.state.showMessage}
                     </div>
                     <div style={{borderTop: "1px solid gray",height:"20%",textAlign: "center",display: "flex",alignItems: "center",justifyContent: "center"}}>
                         <button className="list-btn" onClick={this.onDialogClose}>Ok</button>
                     </div>
                 </div>
            </div> */}
        <AlertDialogBox
          displayShow={true}
          alertType={this.state.alertType}
          displayMessage={this.state.showMessage}
          alertData={this.state.alertData}
          alertPushRoute = {this.state.alertPushRoute}
        />

      </div>
      // showDialogBox= <AlertDialogBox 
      //   displayShow = {true}
      //   displayMessage = {this.state.showMessage}
      // />
    } else {
      showDialogBox = "";
    }


    let defaultPageRoute = "";
    if (localStorage.getItem('is_Dashboard') == 1) {
      defaultPageRoute = <Route component={DashboardView} exact path="/" />
    } else if ((localStorage.getItem('is_Study_list') == 1)) {
      defaultPageRoute = <Route component={StudyList} exact path="/" />
    }


    const { classes, authenticated } = this.props;
    let authenticate = ""
    let viewHtml = ""
    if (this.props.authenticated === undefined || this.props.authenticated === false) {
      authenticate = localStorage.getItem('authenticate');
      if (authenticate) {

        viewHtml = <Router><Layout>
          <div>
            <div>
              <div>


                <Switch>
                  <Route component={Logout} path="/logout" />
                  <Route component={DashboardView} path="/dashboard" />
                  <Route component={StudyList} path="/studylist" />
                  <Route component={APIProjectView} path="/api-project-list" />
                  <Route component={ArchivingProjectView} path="/archiving-project-list" />
                  <Route component={VendorListView} path="/thirdpartylist" />
                  <Route component={CreateVendorView} path="/addthirdparty" />
                  <Route component={UpdateVendorDiv} path="/update-vendor" />
                  <Route component={CreateStudy} path="/createstudy" />
                  <Route component={CreateClientView} path="/create-client" />
                  <Route component={ClientListView} path="/client-list" />
                  <Route component={UpdateClientDiv} path="/update-client" />
                  <Route component={UploadClientLogo} path="/update-client-logo" />
                  <Route component={StudyListUpdate} path="/Study-List-Update" />
                  <Route component={ArchivingStudyListUpdate} path="/Archiving-Study-List-Update" />
                  
                  <Route component={ApiProjectListUpdate} path="/Api-Study-List-Update" />
                  <Route component={UserListView} path="/user-list" />
                  <Route component={CreateUserView} path="/create-user" />
                  <Route component={RoleListView} path="/role-list" />
                  <Route component={CreateRoleView} path="/create-role" />
                  <Route component={ProjectMapping} path="/add-mapping" />
                  
                  <Route component={ParticipantReplies} path="/participant-replies" />
                  <Route component={ArchivedProjectMapping} path="/Archiving-add-mapping" />
                  
                  <Route component={CloneProjectMapping} path="/clone-add-mapping" />
                  
                  <Route component={UpdateProjectMapping} path="/update-mapping" />
                  <Route component={ArchivingUpdateProjectMapping} path="/archiving-update-mapping" />
                  
                  <Route component={UpdateCloneProjectMapping} path="/clone-update-mapping" />
                  
                  <Route component={AssignUserPermission} path="/assign-permission/:key?" />
                  <Route component={CreateDemographicView} path="/create-demographic" />
                  <Route component={DemographicsListView} path="/demographics-list" />
                  <Route component={UpdateDemographicView} path="/update-demographic" />
                  <Route component={AlertDialogBox} path="/alert-box" />
                  <Route component={UpdateUserView} path="/update-user" />
                  <Route component={Participants} path="/participant-list" />
                  <Route component={Invoice} path="/invoice-list" />
                  <Route component={CreateInvoiceView} path="/create-invoice" />
                  <Route component={RecieptForm} path="/reciept" />
                  <Route component={UpdateRoleView} path="/update-role" />
                  <Route component={ActivityLog} path="/activity-log" />
                  <Route component={SendInvitation} path="/send-invitation" />
                  <Route component={CreateClone} path="/create-clone" />
                  <Route component={CreateSendMailView} exact path="/send-mail" />
                  <Route component={AddQuestionView} exact path="/add-question" />
                  <Route component={AddOptionView} exact path="/add-option" />
                  <Route component={UpdateQueryAndOptionsView} exact path="/Update-query-option" />
                  <Route component={DemoMappedList} exact path="/demo-mapping-list" />
                  <Route component={ArchivingDemoMappingList} exact path="/archiving-demo-mapping-list" />
                  
                  <Route component={AddDemoMapped} exact path="/add-demo-map" />


                  <Route component={ConstraintsListMapped} exact path="/constraintslist" />
                  <Route component={ArchivingConstraintsListMapped} exact path="/archiving-constraintslist" />
                  
                  <Route component={PreviewQuestionsAdded} exact path="/previewquestions" />
                  <Route component={ApplyDemographics} exact path="/apply-demographics" />
                  <Route component={ApplyConstraints} exact path="/applyconstraints" />

                  <Route component={ApiProjectMapping} path="/api-add-mapping" />
                  <Route component={ApiUpdateProjectMapping} path="/api-update-mapping" />
                  <Route component={CloneStudyList} path="/Clone-Study" />
                  <Route component={CloneStudyListUpdate} path="/Clone-Study-List-Update" />

                  <Route component={UniqueLinkListView} path="/unique-link" />
                  <Route component={ArchivingUniqueLinkList} path="/archiving-unique-link" />
                  
                  <Route component={UploadUniqueLink} path="/unique-link-upload" />
                  {/* <Route component={DashboardView} path="/" /> */}
                  {defaultPageRoute}

                  {/* <Route component={Home} exact path="/Home" />
                  <Route component={thirdParty} exact path="/third-Party-List" />
                  <Route component={UpdateQueryAndOptionsView} exact path="/Create-third-party" />
                  <Route component={createStudies} exact path="/Create-study" />
                  <Route component={Studies} exact path="/Study-list" />
                  <Route component={Demo} exact path="/Demo-list" />
                  <Route component={CreateDemo} exact path="/Create-demo" />
                  <Route component={CreateQueryText} exact path="/Create-demo-query" />
                  <Route component={queryTextList} exact path="/demo-query-list" />
                  <Route component={CreateOptionsText} exact path="/Create-options" />
                  <Route component={Demographics} exact path="/demographics" /> */}
                  {/* <Route component={Users} exact path="/users" />
                  <Route component={Roles} exact path="/roles" />
                  <Route component={AssignPermission}  exact path="/assignRolePermission/:key?" />
                  <Route component={StudyFieldUpdatePopUp} exact path="/StudyUniqueFieldUpdate" /> */}
                  {/* <Route component={CreateClient} exact path="/create-client" />
                  <Route component={ClientList} exact path="/client-list" />
                  <Route component={ClientUpdate} exact path="/client-update" />
                  <Route component={InvoicedList} exact path="/invoiced-list" />
                  <Route component={CreateInvoice} exact path="/create-invoice" />
                  <Route component={InvoiceBill} exact path="/invoice-bill" />
                  
                  <Route component={UpdateStudies} exact path="/Update-study" /> */}

                </Switch>
              </div>
            </div>
          </div> </Layout>
        </Router>

      } else {
        viewHtml = <Router>
          <div>
            <div>
              <Switch>
                <Route component={Login} exact path="/" />
                <Route component={Login} exact path="/login" />

                {/* <Route component={Participants} exact path="/participant/:key?" /> */}
                {/* <Route component={NewParticipants} exact path="/participant/:key?" />
                <Route component={StudyOverQuota} exact path="/study-overQuota/:key?" />
                <Route component={StudySuccess} exact path="/study-success/:key?" />
                <Route component={StudyTerminate} exact path="/study-terminate/:key?" /> */}
              </Switch>
            </div>
          </div>
        </Router>
      }
    } else {

      viewHtml = <Router><Layout>
        <div>
          <div className="column">


            <Switch>

              <Route component={Logout} path="/logout" />
              <Route component={DashboardView} path="/dashboard" />
              <Route component={StudyList} path="/studylist" />
              <Route component={APIProjectView} path="/api-project-list" />
              <Route component={ArchivingProjectView} path="/archiving-project-list" />
              <Route component={VendorListView} path="/thirdpartylist" />
              <Route component={CreateVendorView} path="/addthirdparty" />
              <Route component={UpdateVendorDiv} path="/update-vendor" />
              <Route component={CreateStudy} path="/createstudy" />
              <Route component={CreateClientView} path="/create-client" />
              <Route component={ClientListView} path="/client-list" />
              <Route component={UpdateClientDiv} path="/update-client" />
              <Route component={UploadClientLogo} path="/update-client-logo" />
              <Route component={StudyListUpdate} path="/Study-List-Update" />
              <Route component={ArchivingStudyListUpdate} path="/Archiving-Study-List-Update" />
              <Route component={ApiProjectListUpdate} path="/Api-Study-List-Update" />
              <Route component={UserListView} path="/user-list" />
              <Route component={CreateUserView} path="/create-user" />
              <Route component={RoleListView} path="/role-list" />
              <Route component={CreateRoleView} path="/create-role" />
              <Route component={ProjectMapping} path="/add-mapping" />
              <Route component={ParticipantReplies} path="/participant-replies" />
              <Route component={ArchivedProjectMapping} path="/Archiving-add-mapping" />
              <Route component={CloneProjectMapping} path="/clone-add-mapping" />
              <Route component={UpdateProjectMapping} path="/update-mapping" />
              <Route component={ArchivingUpdateProjectMapping} path="/archiving-update-mapping" />
              <Route component={UpdateCloneProjectMapping} path="/clone-update-mapping" />
              <Route component={AssignUserPermission} path="/assign-permission/:key?" />
              <Route component={CreateDemographicView} path="/create-demographic" />
              <Route component={DemographicsListView} path="/demographics-list" />
              <Route component={UpdateDemographicView} path="/update-demographic" />
              <Route component={AlertDialogBox} path="/alert-box" />
              <Route component={UpdateUserView} path="/update-user" />
              <Route component={Participants} path="/participant-list" />
              <Route component={Invoice} path="/invoice-list" />
              <Route component={CreateInvoiceView} path="/create-invoice" />
              <Route component={RecieptForm} path="/reciept" />
              <Route component={UpdateRoleView} path="/update-role" />
              <Route component={ActivityLog} path="/activity-log" />
              <Route component={SendInvitation} path="/send-invitation" />
              <Route component={CreateClone} path="/create-clone" />
              <Route component={CreateSendMailView} exact path="/send-mail" />
              <Route component={AddQuestionView} exact path="/add-question" />
              <Route component={AddOptionView} exact path="/add-option" />
              <Route component={UpdateQueryAndOptionsView} exact path="/Update-query-option" />
              <Route component={DemoMappedList} exact path="/demo-mapping-list" />
              <Route component={ArchivingDemoMappingList} exact path="/archiving-demo-mapping-list" />
              <Route component={AddDemoMapped} exact path="/add-demo-map" />
              <Route component={ConstraintsListMapped} exact path="/constraintslist" />
              <Route component={ArchivingConstraintsListMapped} exact path="/archiving-constraintslist" />
              <Route component={PreviewQuestionsAdded} exact path="/previewquestions" />

              <Route component={ApplyDemographics} exact path="/apply-demographics" />
              <Route component={ApplyConstraints} exact path="/applyconstraints" />

              <Route component={ApiProjectMapping} path="/api-add-mapping" />
              <Route component={ApiUpdateProjectMapping} path="/api-update-mapping" />
              <Route component={CloneStudyList} path="/Clone-Study" />
              <Route component={CloneStudyListUpdate} path="/Clone-Study-List-Update" />
              <Route component={UniqueLinkListView} path="/unique-link" />
              <Route component={ArchivingUniqueLinkList} path="/archiving-unique-link" />
              <Route component={UploadUniqueLink} path="/unique-link-upload" />
              {defaultPageRoute}
              {/* <Route component={DashboardView} path="/" /> */}

              {/* <Route component={Home} exact path="/Home" />
              <Route component={thirdParty} exact path="/third-Party-List" />
              <Route component={CreateThirdParty} exact path="/Create-third-party" />
              <Route component={createStudies} exact path="/Create-study" />
              <Route component={Studies} exact path="/Study-list" />
              <Route component={Demo} exact path="/Demo-list" />
              <Route component={CreateDemo} exact path="/Create-demo" />
              <Route component={CreateQueryText} exact path="/Create-demo-query" />
              <Route component={queryTextList} exact path="/demo-query-list" />
              <Route component={CreateOptionsText} exact path="/Create-options" /> */}
              {/* <Route component={Users} exact path="/users" />
              <Route component={Roles} exact path="/roles" />
              <Route component={AssignPermission}  exact path="/assignRolePermission/:key?" />
              <Route component={StudyFieldUpdatePopUp} exact path="/StudyUniqueFieldUpdate" /> */}
              {/* <Route component={CreateClient} exact path="/create-client" />
              <Route component={ClientList} exact path="/client-list" />
              <Route component={ClientUpdate} exact path="/client-update" />
              <Route component={InvoicedList} exact path="/invoiced-list" />
              <Route component={CreateInvoice} exact path="/create-invoice" />
              <Route component={InvoiceBill} exact path="/invoice-bill" />

              <Route component={UpdateStudies} exact path="/Update-study" /> */}
            </Switch>
          </div>
        </div> </Layout>
      </Router>

    }
    // #fbf8f8
    let showSpinner = "";
    if (this.props.loading) {
      showSpinner = <div style={{ zIndex: "20", display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", position: "fixed", backgroundColor: "#fbf8f8", top: "0", bottom: "0", left: "0", right: "0" }} className="lds-ring">
        <div></div><div></div><div></div><div></div></div>
    } else {
      showSpinner = "";
    }

    return (
      <div >
        {/* <AppBar position="fixed">
          <Toolbar>

            <div
             className  = {this.props.classes.root}
            style={authenticated || authenticate ? {} : { display: 'none' }}>
              <IconButton
                className={classes.menuButton}
                color="inherit" aria-label="Menu"
                onClick={this.toggleDrawer('left', true)}>
                <MenuIcon />
              </IconButton>
            </div>
            <img src={require("./pages/StudyComplete/panelreward-logo.png")} className="menu-Logo" />
          </Toolbar>
        </AppBar> */}
        {showDialogBox}

        <div className="">
          {viewHtml}
        </div>
        {showSpinner}
        {/* <Loader fullPage={true}
          loading={this.props.loading} /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.login.authenticated,
    loginFailError: state.login.error,
    loading: state.spinner.loading,
    showAlert: state.alertsDialog.showAlert,
    alertType: state.alertsDialog.alertType,
    alertMessage: state.alertsDialog.alertMessage,
    alertData: state.alertsDialog.alertData,
    alertPushRoute : state.alertsDialog.alertPushRoute,
  }
}

export default compose(
  connect(mapStateToProps)
)(App);
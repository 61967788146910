import React, { Component }  from 'react';

import goback from '../../../images/goback.png';

export default class InvitationHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
    }
    goBack(){
        window.history.back();
    }
    
    render(){
        return(
            <div className="router-header">
            <div className="router-header1 all-header">
                <div>
                    <h2 className="head-two-st">Create Clone Study</h2>
                    <p className="paragraph-st color-secondary para-st-font">Here you can create clone your study</p>
                 </div>

                <div>
                    <button className="back-btn back-btn-color" onClick={this.goBack}><img src={goback} height="13px" width="13px" /><span> Back</span></button>
                 </div>
            </div>
        </div>
    )}
}
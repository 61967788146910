import React, { Component }  from 'react';
import ConstraintsList from '../../../components/ProjectActivities/DemoMapping/constraintsList';
import $ from 'jquery';
import { alertMessage }  from '../../../alertsDialog/Action';
import { connect } from 'react-redux';
import UpdateStudyHeader from '../../../components/archivingStudies/updateStudyHeader';


export class ArchivingConstraintsListMapped extends Component {
    constructor(props) {
        super(props)
        this.state = {
            study_id : ""
        }
        this.selectActivity = this.selectActivity.bind(this);
     
    }

    selectActivity(activityName){
        this.props.history.push(activityName);
    }

    gotoBack(){
        window.history.back();
    }

    

    render(){
        return(
            <div>
                
              
                <UpdateStudyHeader
                    selectActivity = {this.selectActivity}
                    editstudyData = {this.state.editstudyData}
                    gotoBack = {this.gotoBack}
                />

                <ConstraintsList />

            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        
    }
  }
  
  export default connect(mapStateToProps)(ArchivingConstraintsListMapped);
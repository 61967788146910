import React, { Component }  from 'react';
import { connect } from 'react-redux';
import UserHeader from '../../components/User/userHeader.jsx';
import UserListTable from '../../components/User/userList.jsx';
import add from '../../images/add.png';
import {Link} from 'react-router-dom';

    class UserListView extends Component {

        constructor(props) {
            super(props)
            this.state = {

            }
            // this.gotoCreateVendor = this.gotoCreateVendor.bind(this);
        }

    componentWillMount() {
        let currentpage = 1;
        //this.props.dispatch(ThirdPartyList( currentpage));
      }

    //   gotoCreateVendor(){
    //    alert("gotoCreateVendor");

    //   }

    

    render(){
        const header = 'Users Information';
        const headerText = 'Here you can see all the members of Gnn Research Group';
        const headerButton = <Link to="/create-user"><button className="create-btn create-btn-color" onClick={this.gotoCreateVendor}><img src={add} height="12px" width="12px"/><span> Add User</span></button></Link>

        return(
            <div style={{ width:"100%"}}>  
                <UserHeader
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                />
                <UserListTable 
                       
                />

            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
      
         
    }
  }
  
  export default connect(mapStateToProps)(UserListView);
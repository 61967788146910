import React, { Component }  from 'react';
import  '../../commonStyle/common.css';
import $ from 'jquery';
import {connect} from 'react-redux';

class CreateStudyHeader extends Component {

componentDidMount(){

}

    render(){
        return(
            <div className="router-header">
            <div className="router-header1">
                <div>
                    <h2 className="head-two-st">Create Study</h2>
                    <p className="paragraph-st color-secondary para-st-font">Here you can create new study and redirects</p>
                 </div>
            </div>
        </div>

    )}
}
const mapStateToProps=(state)=>{
    return{
        
    }
  }
  
  export default connect(mapStateToProps)(CreateStudyHeader);
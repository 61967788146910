import React, { Component }  from 'react';
import  '../../commonStyle/common.css';
import $ from 'jquery';
import {connect} from 'react-redux';
import Pagination from "react-js-pagination";
import deletIcon from '../../images/delete.png';
import editpencil from '../../images/edit-pencil.png';
import {Link} from 'react-router-dom';
import { softDeleteLiveStudies, emptyResponseDashboard} from '../../actions/actionsBag/dashboard';
import { alertMessage, emptyDialogdata }  from '../../alertsDialog/Action';

class DashboardStudyListDiv extends Component {

    constructor(props) {
        super(props)
        this.state = {
            search: "",
            currentPage: 1,
            userId : "",
            userType : "",
            panrentProjectList : [],
            totalPage : 0,
        }
        this.handlePageChange = this.handlePageChange.bind(this);
      }

    


  componentWillReceiveProps(nextProps){

    if(nextProps.studyListCurrentPage){
      this.setState({ currentPage : nextProps.studyListCurrentPage});
    }

    if( nextProps.studyListData && nextProps.studyListData.totalPages &&  nextProps.studyListData.totalPages >0){
      this.setState({
        totalPage : nextProps.studyListData.totalPages
      });
    }else{
      this.setState({
        totalPage : 0
      });
    }

    if(nextProps.confirmDialog === true){
      this.props.dispatch(emptyDialogdata());
      let data = {};
      data.study_id = nextProps.confirmData;
      this.props.dispatch(softDeleteLiveStudies(data));
    }

    if( nextProps.removeStudySuccess && nextProps.removeStudyMessage){
      this.props.dispatch( emptyResponseDashboard());
      this.props.getLiveStudies( 1 );
      let alertData ={};
      alertData.type ="alert";
      alertData.message = nextProps.removeStudyMessage;
      this.props.dispatch(alertMessage(alertData));
      this.setState({ currentPage : 1});
    }else if(nextProps.removeStudyMessage){
      this.props.dispatch( emptyResponseDashboard());
      let alertData ={};
      alertData.type ="alert";
      alertData.message = nextProps.removeStudyMessage;
      this.props.dispatch(alertMessage(alertData));
      
    }
  }
  


    componentDidMount(){

$(".dropdown").on('click',function() { 
    var t = (this.id); 
  if(t === "all-rm-shadow"){
    var all= document.getElementsByClassName("dropdown");
   
    for(let a=0; a< all.length; a++){
        if ( $( all[a] ).removeClass( "active" ) ) {
       
      }
     
      $(all[0]).addClass("active");
    }
  }else if(t === "live-rm-shadow"){
    
    var live= document.getElementsByClassName("dropdown");
   
    for(let a=0; a< live.length; a++){
        if ( $( live[a] ).removeClass( "active" ) ) {
      }
      $(live[1]).addClass("active");
    }
  }else if(t === "pause-rm-shadow"){
    var pause= document.getElementsByClassName("dropdown");
   
    for(var a=0; a< pause.length; a++){
        if ( $( pause[a] ).removeClass( "active" ) ) {
       
      }
     
      $(pause[2]).addClass("active");
    }
 }else if(t === "com-rm-shadow"){
    var com= document.getElementsByClassName("dropdown");
   
    for(let a=0; a< com.length; a++){
        if ( $( com[a] ).removeClass( "active" ) ) {
       
      }
     
      $(com[3]).addClass("active");
    }
  }
});  

}

callMe(){
    $(function() {
        $("#demo").find("p").hide();

        $(".demo").find("table").hide();
        
        $(".tbl").click(function(event) {
            event.stopPropagation();
            var $target = $(event.target);
            if ( $target.closest("td").attr(".demo") > 1 ) {
                $target.slideUp();
                $(".listexpandicon").removeClass("minus");
                } else {
                $target.closest("tr").next().find("table").toggle();
                $target.closest("tr").find(".listexpandicon").toggleClass("minus");
            }              
        });
    });
}
    

handlePageChange(pageNumber){

  this.setState({ currentPage : pageNumber});
  this.props.getLiveStudies( pageNumber );
}



tableZoom(){
    $(".alltablestruct").toggleClass("zoom-me");
    // $("#routercontainer").toggleClass("router-containter-pd");
    // document.getElementById("routercontainer").style.padding="0px";
  
}

setHeaderData(){
  localStorage.setItem("header-title","Study-Update");
  localStorage.setItem("header-text","Here you can update study");
}

deletProject(deleteId){
  let alertData ={};
  alertData.type ="confirmalert";
  alertData.message = "Are you sure to delete this item ?";
  alertData.deleteId = deleteId;
  this.props.dispatch(alertMessage(alertData));
  // alert("Delete project");
}

    render(){
        this.callMe();
        let profileItems = "";
       

        let studies = this.props.studyListData
        //&& studies.values && studies.values.length > 0
        if (studies && studies.study  && studies.study.length > 0) {
          let i, j;
       // //   totalPage = studies.totalPages;
          // for (i = 0; i < studies.study.length; i++) {
          //   for (j = 0; j < studies.values.length; j++) {
          //     if (studies.study[i]._id == studies.values[j].studyId) {
          //       studies.study[i].totalCount = studies.values[j].totalCount;
          //       studies.study[i].totalCompletes = studies.values[j].totalCompletes;
          //       studies.study[i].totalTerminates = studies.values[j].totalTerminates;
          //       studies.study[i].totalVendor = studies.values[j].totalVendor;
          //       studies.study[i].totalCloneStudies = studies.values[j].totalCloneStudies;
          //       studies.study[i].totalOverQuota = studies.values[j].totalOverQuota; 
          //     }
          //   }
          // }
            profileItems = studies.study.map((list, i) => {
                
                let statusBtnshow = ""
                if(list.status === "completed"){
                    statusBtnshow =<button className="cmpbtn">Complete</button>
                }else if(list.status === "live"){
                    statusBtnshow = <button className="livebtn">Live</button>
                }else if(list.status === "pause"){
                    statusBtnshow = <button className="pause">Pause</button>
                }else{
                    statusBtnshow = "Error!"
                }


                let paymentStatus = "";
                if(list.payment_received === 1){
                  paymentStatus = "Paid"
                }else{
                  paymentStatus = "Not Paid"
                }
                
                    return <tbody> <tr>  
                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom"><span className="plus listexpandicon"></span>{list._id}</td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom"><div className="ToolTip">{list.studyName.substring(0,12)}<div className="ToolTipText" style={{width: (list.studyName.length*8)+"px"}}>{list.studyName}</div></div></td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.name}</td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.fees}</td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.completed}</td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.orignalRequirment}</td>
                                          <td className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">
                                          {statusBtnshow}
                                          </td>
                                          <td className="tbl-padd-top tbl-padd-left tbl-padd-bottom">
                                              <div className="bedg-1" >{list.allVendors}</div>
                                          </td>   
                                          <td className="tbl  tbl-padd-top tbl-padd-left tbl-padd-bottom">
                                          <div className="bedg-2" >{list.allClonned}</div>
                                          </td>
                                          <td className=" tbl-padd-top tbl-padd-left tbl-padd-bottom">
                                          <div className="flex-show">
                                              
                                          <div className="icons">
                                                <div className="delete" onClick={this.deletProject.bind(this,list._id)} >
                                                  <img src={deletIcon}/>
                                                </div>
                                              </div>

                                          <div className="icons">
                                          <Link to = {{
                                                  pathname: "/Study-List-Update",
                                                  state: {
                                                      editstudyData : list,
                                                    }
                                                }} 

                                          onClick={this.setHeaderData}>
                                            <div className="pencil"><img src={editpencil}  height="20px" width="20px"/>
                                            </div></Link>
                                          </div>

                                              
                                            </div>
                                            </td>
                                </tr>
                                <tr><td colSpan="10" className="demo">
                                            <table className="expand-tbl"  border="0" cellspacing="0" cellpadding="0">
                                            <tbody>
                                                   
                                            <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}} >First Party Url</th>
                                                        <td className="tbl-td" >
                                                            <ul style={{ listStyleType: "disc",listStylePosition:"inside"}}>
                                                                <li style={{color: "black"}}><b>Live Link &nbsp;:&nbsp;</b>{list.firstPartyUrl}</li>
                                                                <li style={{color: "black"}}><b>Test Link &nbsp;:&nbsp;</b>{list.firstPartyUrlTest}</li>
                                                            </ul></td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>LOI</th>
                                                        <td className="tbl-td" >{list.loi}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>IR</th>
                                                        <td className="tbl-td">{list.IR} %</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Terminates</th>
                                                        <td className="tbl-td">{list.terminate}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Quotafulls</th>
                                                        <td className="tbl-td">{list.overQuota}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Hits</th>
                                                        <td className="tbl-td">{list.allParticipants}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Invoice No</th>
                                                        <td className="tbl-td">{list.receipt_no}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Invoice Date</th>
                                                        <td className="tbl-td"></td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Payment Status</th>
                                                        <td className="tbl-td">{paymentStatus}</td>
                                                    </tr>
                                                      {/* <tr>
                                                          <th className="tbl-th">Complete</th>
                                                          <td className="tbl-td">{list.totalCompletes}</td>
                                                      </tr> */}
                                                    
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Payment Amount</th>
                                                        <td className="tbl-td">{list.payment_received}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Payment Date</th>
                                                        <td className="tbl-td">{list.receipt_no}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td></tr>
                                </tbody>
             });
          }else{
            profileItems = "";
          }

          let showTable = "";
          //&& studies.values && studies.values.length > 0
          if(studies && studies.study  && studies.study.length > 0){
            showTable = <table className="tbllayout " >
            <thead >
              <tr>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Study Id</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Study Name</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Client</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Fee</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Complete</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">T.Req.</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Status</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Vendor</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Clone</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom" style={{textAlign: "center"}}>Action</th>
                </tr>
                </thead>
              {profileItems}
          </table>
          }else{
            showTable = <div className="spiner-data"><div className="tblspinner"></div></div>
          }



        return(
            <div className="list-style " style={{marginTop: "20px"}}>
                <div className="table-st" id="all-tbl">
                    <div className="alltablestruct forms-rd-shadow">
                         {/* serachbox and stausbtn */}
                        <div id="search-staus">

                            <div className="flex-show tbl-h-font">
                                Studies overviews
                            </div>
                            
                            <div className="flex-show tbl-zoom" >
                            <span onClick={this.tableZoom} className="full-screen mr-rhs-20 mr-top-10"></span>
                            </div>
                        </div>
                        {/* serachbox and stausbtn */}
                            {/* table */}
                            <div style={{marginTop: "20px", overflowX: "auto"}}>
                                  {showTable}
                            </div>
                            {/* table */}
                   
                      <div id="pageinationstyle">
                       
                      <Pagination
                          activePage={this.state.currentPage}
                          itemsCountPerPage={5}
                          totalItemsCount={this.state.totalPage}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                        </div>
                    
                    </div>
                </div>
            </div>
    )}
}
const mapStateToProps = (state) => {
  return {
   
    studyListData: state.dashboard.studyListData,
    confirmDialog :  state.alertsDialog.confirmDialog,
    confirmData : state.alertsDialog.confirmData,
    removeStudySuccess : state.dashboard.removeStudySuccess,
    removeStudyMessage : state.dashboard.removeStudyMessage
  }
}

export default connect(mapStateToProps)(DashboardStudyListDiv);
import React, { Component }  from 'react';
import { connect } from 'react-redux';
import DemographicHeader from '../../components/Demographics/demographicHeader.jsx';
import UpdateQuestionDiv from '../../components/Demographics/UpdateQuestion.jsx';
import goback from '../../images/goback.png';
import { alertMessage , emptyDialogdata}  from '../../alertsDialog/Action';
import AddOptionDiv from '../../components/Demographics/addOptions.jsx';
import { getQuestionByDemo, updateQuery, emptyQuestionUpdateRes, deleteQuestion , getOptionList, 
    addQueryOption, updateOption, emptyOptionsProps, deleteOption } from '../../actions/actionsBag/Demographic';
import {Link} from 'react-router-dom';

    class UpdateQueryAndOptionsView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            demoId : "",
            demoName : "",
            demoQuestionData :""
        }            
        this.goBack = this.goBack.bind( this);
        this.alertMessage = this.alertMessage.bind(this);
        this.updateQuery = this.updateQuery.bind( this);
        this.updateQueryByQueryType = this.updateQueryByQueryType.bind( this);
        this.deleteQuestion = this.deleteQuestion.bind( this);

        this.addQueryOption = this.addQueryOption.bind( this);
        this.updateOption = this.updateOption.bind( this);
        this.softDeleteOption = this.softDeleteOption.bind( this);
    }

    componentWillMount(){
        if( this.props.location.state === undefined){
            this.props.history.push("error");
        }else{
            
        const { updateDemoID, updateDemoName } = this.props.location.state;
            
        this.setState({
            demoId : updateDemoID,
            demoName : updateDemoName
            });
            this.props.dispatch(getQuestionByDemo( updateDemoID));
        }
    }

    componentWillReceiveProps( nextProps){

        if(nextProps.confirmDialog === true){
            
            this.props.dispatch(emptyDialogdata());

            if( nextProps.confirmData && nextProps.confirmData.alertby === "queryTypeUpdate" )
                this.props.dispatch(updateQuery(nextProps.confirmData));
            
            if( nextProps.confirmData && nextProps.confirmData.alertby === "queryDelete" )
                this.props.dispatch(deleteQuestion(nextProps.confirmData.quesId));
            
            if( nextProps.confirmData && nextProps.confirmData.alertby === "optionDelete" )
                this.props.dispatch(deleteOption(nextProps.confirmData.optionId));
        }

        if(nextProps.demoQuestionSuccess && nextProps.demoQuestionData){
            this.props.dispatch(emptyQuestionUpdateRes());
            this.setState({ demoQuestionData : nextProps.demoQuestionData});
            this.props.dispatch(getOptionList( nextProps.demoQuestionData._id));
        }else if(nextProps.demoQuestionSuccess === false && nextProps.demoQuestionData && nextProps.demoQuestionData.length === 0){
            this.alertMessage("Please add Question first for question update !");
            let demoData = {};
            demoData._id = this.state.demoId;
            demoData.name = this.state.demoName;
            this.props.history.push({
                pathname : '/add-question',
                state:{ demographicsData : demoData }
            });
        }

        if(nextProps.queryUpdateSuccess && nextProps.queryUpdateMessage ){
           
            this.alertMessage(nextProps.queryUpdateMessage);
            window.history.back();
       // this.props.history.push('/demographics-list');
        }else if( nextProps.queryUpdateSuccess  === false && nextProps.queryUpdateMessage ){
          
            this.alertMessage( nextProps.queryUpdateMessage );
        }


        //for options......//
        if( nextProps.queryOptionSuccess && nextProps.queryOptionSuccess.success  && nextProps.queryOptionSuccess.message ){
            this.alertDialog( nextProps.queryOptionSuccess.message);
        }else if(nextProps.queryOptionError && (nextProps.queryOptionError.success === false)  && nextProps.queryOptionError.message){
            this.alertDialog( nextProps.queryOptionError.message);
        }
        
        if( nextProps.updateQueryOptionRep && nextProps.updateQueryOptionRep.success  && nextProps.updateQueryOptionRep.message ){
            this.alertDialog( nextProps.updateQueryOptionRep.message);
        }else if(nextProps.updateQueryOptionRep && (nextProps.updateQueryOptionRep.success === false)  && nextProps.updateQueryOptionRep.message){
            this.alertDialog( nextProps.updateQueryOptionRep.message);
        }

        if( nextProps.optionDeleteSuccess && nextProps.optionDeleteMessage ){
            this.alertDialog( nextProps.optionDeleteMessage);
            this.props.dispatch(getOptionList( this.state.demoQuestionData._id));
        }else if( (nextProps.optionDeleteSuccess === false) && nextProps.optionDeleteMessage){
            this.alertDialog( nextProps.optionDeleteMessage);
        }
    }

    alertDialog( message){
        let alertData ={};
        alertData.type ="alert";
        alertData.message = message;
        this.props.dispatch(alertMessage(alertData));
        this.props.dispatch(emptyOptionsProps());
      }

    alertMessage( message){
        this.props.dispatch(emptyQuestionUpdateRes());
        let alertData ={};
        alertData.type ="alert";
        alertData.message = message;
        this.props.dispatch(alertMessage(alertData));
    }

    goBack(){
       
        window.history.back();
    }

    updateQuery( data){
        this.props.dispatch(updateQuery(data));
    }

    updateQueryByQueryType( data){
        let alertData ={};
        alertData.type ="confirmalert";
        alertData.message = "Are you sure to change ?";
        data.alertby = "queryTypeUpdate";
        alertData.deleteId = data;
        
        this.props.dispatch(alertMessage(alertData));
        //this.props.dispatch(remove(id));
    }

    deleteQuestion( quesId){
        let alertData ={};
        let data = {};
        alertData.type ="confirmalert";
        alertData.message = "Are you sure to delete this item ?";
        data.quesId = quesId;
        data.alertby = "queryDelete";
        alertData.deleteId = data;
        this.props.dispatch(alertMessage(alertData));
        // this.props.dispatch(deleteQuestion(quesId));
    }

    addQueryOption( data){
        this.props.dispatch( addQueryOption(data));
      }

      updateOption( data){
        this.props.dispatch( updateOption( data));
      }

      softDeleteOption( optionId){
        let alertData ={};
        let data = {};
        alertData.type ="confirmalert";
        alertData.message = "Are you sure to delete this item ?";
        data.optionId = optionId;
        data.alertby = "optionDelete";
        alertData.deleteId = data;
        this.props.dispatch(alertMessage(alertData));
      }

    render(){
        const header = 'Update Question and options';
        const headerText = 'Here you can update demographic questions and their options.';
        const headerButton =  <button onClick={this.goBack} className="back-btn back-btn-color" ><img src={goback} height="13px" width="13px" /><span> Back</span></button>

        let updateQuestion = "";
        let updateOption = "";
        if( this.state.demoQuestionData){
            updateQuestion = <UpdateQuestionDiv 
                    demoId = { this.state.demoId}
                    demoName = { this.state.demoName}
                    demoQuestionSuccess = {this.props.demoQuestionSuccess}
                    demoQuestionData  = {this.state.demoQuestionData}
                    updateQuery = { this.updateQuery}
                    updateQueryByQueryType = { this.updateQueryByQueryType}
                    deleteQuestion = {this.deleteQuestion}
                />

            updateOption =<AddOptionDiv
                    questionData = {this.state.demoQuestionData}
                    addQueryOption = {this.addQueryOption}
                    queryOptionList = {this.props.queryOptionList}
                    updateOption= {this.updateOption}
                    softDeleteOption = {this.softDeleteOption}
                />
        }

        return(
            <div style={{ width:"100%"}}>  
                <DemographicHeader 
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                />
                {updateQuestion}
                { updateOption}
                
            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        demoQuestionSuccess : state.demographics.demoQuestionSuccess,
        demoQuestionData    : state.demographics.demoQuestionData,
        queryUpdateSuccess  : state.demographics.queryUpdateSuccess,
        queryUpdateMessage  : state.demographics.queryUpdateMessage,
        confirmDialog :  state.alertsDialog.confirmDialog,
        confirmData : state.alertsDialog.confirmData,


        queryOptionSuccess: state.demographics.queryOptionSuccess,
        queryOptionError: state.demographics.queryOptionError,
        queryOptionList: state.demographics.queryOptionList,
        updateQueryOptionRep : state.demographics.updateQueryOptionRep,
        optionDeleteSuccess  : state.demographics.optionDeleteSuccess,
        optionDeleteMessage : state.demographics.optionDeleteMessage,
    }
  }
  
export default connect(mapStateToProps)(UpdateQueryAndOptionsView);
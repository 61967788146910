import React, { Component } from 'react';
import { getDemoMappedList, getParticipantsReplyData } from '../../../actions/actionsBag/demoMapping';
import { alertMessage } from '../../../alertsDialog/Action';
import { connect, } from 'react-redux';
import { Link } from 'react-router-dom';
// import Pagination from "react-js-pagination";
import deletIcon from '../../../images/delete.png';
import editpencil from '../../../images/edit-pencil.png';
import add from '../../../images/add.png';
import Sortable from 'react-sortablejs';
import $ from 'jquery';
import './style.css';
import Pagination from "react-js-pagination";
import appConfig from "../../../api/apiConfig";

class DemoMappingList extends Component {
  constructor(props) {
    super(props)
    this.state = {
    currentPage: 1,
    totalPage : 0,
    replyList : [],
    showloader: false,
    studyId: "",
     
    }
    this.handlePageChange = this.handlePageChange.bind(this);
    this.participantAsExcel = this.participantAsExcel.bind(this);
  }

  participantAsExcel(){
    window.open(appConfig.siteURL + "exportParticipantsReplyData/"+ this.state.studyId);
 }

  componentWillReceiveProps(nextProps) {

    if(nextProps.participantReplyResponse && 
        nextProps.participantReplyResponse.success &&
        nextProps.participantReplyResponse.participantReplies.length >0){
        this.setState({
            totalPage : nextProps.participantReplyResponse.totalCount,
            replyList : nextProps.participantReplyResponse.participantReplies,
            showloader: true,
        });
    } else if(nextProps.participantReplyResponse && 
      nextProps.participantReplyResponse.success === false){
        this.setState({
          totalPage : 0,
          replyList : [],
          showloader: true,
      });
    }
  }



  componentWillMount() {
    let studyId = localStorage.getItem("study_Id");
    this.setState({ studyId: studyId });
    this.props.dispatch(getParticipantsReplyData(studyId, 1));
  }

  handlePageChange(pageNumber) {

    this.setState({ currentPage: pageNumber, replyList: [],  showloader: false });

    this.props.dispatch(getParticipantsReplyData(this.state.studyId, pageNumber));
  }

  tableZoom() {
    $(".alltablestruct").toggleClass("zoom-me");
    // $("#routercontainer").toggleClass("router-containter-pd");
    // document.getElementById("routercontainer").style.padding="0px";

  }

  render() {

    let pReplyList = "";

    if(this.state.replyList && this.state.replyList.length >0){
         
        let sr = (this.state.currentPage * 10)-10;
        pReplyList= this.state.replyList.map((list, i) => {  
          
              
                  return <tbody> <tr>  
                                    <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom">{sr+i+1}</td>
                                    <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.tid}</td>
                                    <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.participantId}</td>
                                    <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.query_text}</td>
                                    <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.option_text}</td>
                                    <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.is_correct ? "Yes" : "No"}</td>
                                </tr>
                        </tbody>
           });
        }else{
            pReplyList = "";
        }

    let showTable = "";
    if (this.state.replyList && this.state.replyList.length > 0) {
      showTable = <table className="tbl-new-est">
      <thead>
        <tr>
              <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">S.no.</th>
              <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Vendor Id</th>
              <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">PID</th>
              <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Query</th>
              <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Answer</th>
              <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Correct</th>
          </tr>
          </thead>
        {pReplyList}
    </table>

    } else if (this.state.showloader === true) {
      showTable = <div className="datanotfound"> No record found</div>
    } else {
      showTable = <div className="spiner-data"><div className="tblspinner"></div></div>
    }



    return (
      <div className="list-style">
        <div className="table-st">
          <div className="alltablestruct forms-rd-shadow">
            {/* serachbox and stausbtn */}
            <div id="search-staus">

              <div className="flex-show  tbl-h-font">
                Participant Replies List
                            </div>
              <div style={{ marginLeft: "auto", marginRight : "30px"}}>
              <button  onClick={this.participantAsExcel} className="pro-btn ">Export To Excel</button></div>
              <div className="flex-show tbl-zoom" >
                <span onClick={this.tableZoom} className="full-screen mr-rhs-20 mr-top-10"> </span>
              </div>
            </div>
            {/* serachbox and stausbtn */}

            {/* table */}
            <div style={{ marginTop: "20px", overflowX: "auto" }}>
              {showTable}
            </div>
            {/* table */}

             <div id="pageinationstyle">
                       
                <Pagination
                    activePage={this.state.currentPage}
                    itemsCountPerPage={10}
                    totalItemsCount={this.state.totalPage}
                    pageRangeDisplayed={4}
                    onChange={this.handlePageChange}
                />
            </div> 
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    participantReplyResponse : state.demoMapped.participantRepliesList,
  }
}

export default connect(mapStateToProps)(DemoMappingList);


import React, { Component }  from 'react';
import AddProjectMapping from '../../components/ProjectActivities/addProjectMapping';
import UpdateStudyHeader from '../../components/Study/updateStudyHeader';
import ParticipantList from '../../components/ProjectActivities/participants/participantList';


export default class Participants extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
        this.selectActivity = this.selectActivity.bind(this);
    }
   


    selectActivity(activityName){
        this.props.history.push(activityName);
    }

 

    gotoBack(){
        window.history.back();
    }

    render(){

       
    

        return(
            <div>
                
              
                <UpdateStudyHeader
                    selectActivity = {this.selectActivity}
                    editstudyData = {this.state.editstudyData}
                    gotoBack = {this.gotoBack}
                />
                <ParticipantList />


            </div>
    )}
}

import { addRoleApi, getAllRolesByAdminApi, updateRoleApi, getPermissionByRoleApi, assingPermissionRoleApi,

	getAllRolesByAdminByPageApi, softRoleDeleteApi } from "../../api/apiBag/roleApi";
	import {startSpinner, endSpinner} from "../../loadingSpinner/Action";

//viewCreateRole
export const viewCreateRole = () => {
	return {
        type: "SET_SHOW_CREATE_ROLE_PAGE"
	}
}

//setAddRoleRes
export const setAddRoleRes = (response) => {
	return {
        type: "SET_ADD_ROLE_RES",
        response
	}
}

//blankResRoles
export const blankResRoles = () => {
    return { 
        type : "SET_EMPTY_RESPONSE"
    }
}

//setAllRoles
export const setAllRoles = (response) => {
	return {
        type: "SET_ALL_ROLE_RES",
        response
	}
}

//editRoleUser
export const editRoleUser = (data) =>{
	return{
		type : "SET_EDIT_ROLE",
		data
	}
} 

//setUpdateRolesRes
export const setUpdateRolesRes = (response) => {
	return {
        type: "SET_ROLE_UPDATE_RES",
        response
	}
}

//setRolePermissionData
export const setRolePermissionData = (response) => {
	return {
        type: "SET_ROLE_PERMISSION_DATA_RES",
        response
	}
}

//setUpdatedRolePermission
export const setUpdatedRolePermission = (response) => {
	return {
        type: "SET_ROLE_PERMISSION_UPDATE_RES",
        response
	}
}

//setAllRolesPage
export const setAllRolesPage = (response) => {
	return {
        type: "SET_ALL_ROLE_WITH_PAGE",
        response
	}
}

export const setRoleDelete = ( response) => {
	return{
	  type : "SET_ROLE_DELETE",
	  response
	}
  }

export const addRole = (data) => {
	return dispatch => {
			dispatch(startSpinner());
			addRoleApi(data).then((result) => {
			dispatch(endSpinner());
			dispatch(setAddRoleRes(result));
			});
	}
}

//updateRole
export const updateRole = (data) => {
	return dispatch => {
				dispatch(startSpinner());
			updateRoleApi(data).then((result) => {
				dispatch(endSpinner());
				dispatch(setUpdateRolesRes(result));
			});
	}
}


//getAllRolesByAdmin

export const getAllRolesByAdmin = () => {
	return dispatch => {
			    dispatch(startSpinner());
			getAllRolesByAdminApi().then((result) => {
			    dispatch(endSpinner());
			if(result.success){
			    dispatch(setAllRoles(result));
			}else{
				alert(result.message);
			}   
		});
	}
}

//getPermissionByRole
export const getPermissionByRole = (id) => {
	return dispatch => {
			    dispatch(startSpinner());
				getPermissionByRoleApi(id).then((result) => {
			    dispatch(endSpinner());
			if(result.success){
			    dispatch(setRolePermissionData(result));
			}else{
				alert(result.message);
			}   
		});
	}
}


//assingPermissionRole
export const assingPermissionRole = (data) => {
	return dispatch => {
			    dispatch(startSpinner());
				assingPermissionRoleApi(data).then((result) => {
			    dispatch(endSpinner());	
			    dispatch(setUpdatedRolePermission(result));
		});
	}
}


export const getAllRolesByAdminByPage = ( pageNumber) => {
	return dispatch => {
			    dispatch(startSpinner());
				getAllRolesByAdminByPageApi( pageNumber).then((result) => {
			   dispatch(endSpinner());
			if(result.success){
			    dispatch(setAllRolesPage(result));
			}else{
				alert(result.message);
			}   
		});
	}
}

//softRoleDelete
export const softRoleDelete = (roleData) => {

	return dispatch => {
		dispatch(startSpinner());
		softRoleDeleteApi(roleData).then((result) => {
			dispatch(endSpinner());
			dispatch(setRoleDelete(result));
	  });
	}
  }
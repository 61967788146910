import {getDemoMappedListApi, getAllNotMapDemographicsApi, 
  getDemoQueryDataForApplyApi,getPreviewQuestionsApi , 
  studyDemoMappingApi , getConstraintsListApi,getQueryAndOptionApi, 
  addConstrainstsApi  ,studyDemoAgeMappingApi, removeDemoFromProjectApi, removeRangeOptionsApi, updateQuestionOrderApi,
  getParticipantsReplyDataApi} from "../../api/apiBag/demoMappingApi.js";
  import {startSpinner, endSpinner} from "../../loadingSpinner/Action";
  //import {setMessage} from '../../popupAlert/action';
 
  export const setDemoMappedList = ( response) => {
    return {
      type: "SET_DEMO_MAPPED_LIST",
      response
    }
  }


  export const setConstraintsList = ( result) => {
    return {
      type: "SET_CONSTRAINTS_LIST_ON_PROJECT",
      result
    }
  }

  export const setPreviewQuestions = ( result) => {
    return {
      type: "SET_PREVIEW_QUESTIONS",
      result
    }
  }
  

  
  export const setDemoNotMappedList = ( response) => {
    return {
      type: "SET_DEMO_NOT_MAPPED_LIST",
      response
    }
  }

  export const setDemographicsResultData =(queryData) => {
    return {
      type : "SET_DEMO_QUERY_DATA_BY_ID_RESPONSE",
      queryData
    }
  }

  export const setAddMappingDemographicsResult =( response) =>{
    return{
      type : "SET_ADD_MAPPING_DEMOGRAPHICS_RESPONSE",
      response
    }
  }

  export const blankResDemoMap =( response) =>{
    return{
      type : "SET_EMPTY_DEMO_MAP_RESPONSE",
    }
  }

  export const setQuotaAndQueryOptions =(quotaAndQueryOptions) => {
    return {
      type : "SET_QUOTA_AND_QUERY_OPTIONS",
      quotaAndQueryOptions
      
    }
  }



  
  export const setConstraintsToProject =(result) => {
    return {
      type : "SET_CONSTRAINTS_TO_PROJECT_BY_SCREENER",
      result
      
    }
  }

  // setContraintsFail
  export const setContraintsFail =(result) => {
    return {
      type : "SET_CONSTRAINTS_FAIL",
      result
      
    }
  }

  export const emptyQuotaProps =() => {
    return {
      type : "SET_EMPTY_QUOTA_PROPS"
    }
  }


  export const setPropsEmptyScreenerAddByCategory =() => {
    return {
      type : "SET_PROPS_EMPTY_ADD_BY_CATEGORY_SCREENER",
      
      
    }
  }

  export const getScreenerSuccess =(screenerList) => {
    return {
      type : "SET_SCREENER_LIST",
      screenerList
    }
  }

 
  export const setDemoDelete =(demoResult) => {
    return {
      type : "SET_MAP_DEMO_DELETE_SUCCESS",
      demoResult
    }
  }

  
  export const setDemoDeleteFail =(demoResult) => {
    return {
      type : "SET_MAP_DEMO_DELETE_FAIL",
      demoResult
    }
  }

  
  export const emptyMapDemoProps =() => {
    return {
      type : "EMPTY_MAP_DEMO_PROPS",
      
    }
  }

  export const setRangeOptionDeleteSuccess =(response) => {
    return {
      type : "SET_RANGE_OPTION_DELETE_SUCCESS",
      response
    }
  }
  export const setParticipantReplyList =(response) => {
    return {
      type : "SET_PARTICIPANT_REPLY_RESPONSE",
      response
    }
  }
  
  // 
  
// getPreviewQuestions
export const getPreviewQuestions = ( sid) => {
  return dispatch => {

    getPreviewQuestionsApi(sid).then((result) => {

      dispatch(setPreviewQuestions(result));

    });
  }
}


// getConstraintsList

export const getConstraintsList = ( sid, page) => {
  return dispatch => {

      getConstraintsListApi( sid, page).then((result) => {

      dispatch(setConstraintsList(result));

    });
  }
}

  export const getDemoMappedList = ( sid, page) => {
    return dispatch => {
  
        getDemoMappedListApi( sid, page).then((result) => {
  
        dispatch(setDemoMappedList(result));
  
      });
    }
  }
  
  
  export const getParticipantsReplyData = ( sid, page) => {
    return dispatch => {
  
      getParticipantsReplyDataApi( sid, page).then((result) => {
        dispatch(setParticipantReplyList(result));
      });
    }
  }

  //getAllDemographics
  export const getAllNotMapDemographics = ( sid) => {
    return dispatch => {
  
      getAllNotMapDemographicsApi( sid).then((result) => {
  
        dispatch(setDemoNotMappedList(result));
  
      });
    }
  }

  //getDemoQueryDataForApply
  export const getDemoQueryDataForApply = ( demoId ,sid) => {
    return dispatch => {
  
      getDemoQueryDataForApplyApi( demoId, sid).then((result) => {
  
        dispatch(setDemographicsResultData(result));
  
      });
    }
  }

  export const studyDemoMapping = (data) => {
    return dispatch => {
      dispatch(startSpinner());
      studyDemoMappingApi(data).then((result) => {
        dispatch(endSpinner());
          dispatch(setAddMappingDemographicsResult(result));
      });
    }
  }

  export const studyDemoAgeMapping = (data) => {
    return dispatch => {
      dispatch(startSpinner());
      studyDemoAgeMappingApi(data).then((result) => {
        dispatch(endSpinner());
          dispatch(setAddMappingDemographicsResult(result));
      });
    }
  }
  


  export const getQueryAndOption = (screenerId,projectID) => {

    return dispatch => {
      dispatch(startSpinner());
      getQueryAndOptionApi(screenerId,projectID).then((result) => {
        dispatch(endSpinner());
              if(result.success){
                  dispatch(setQuotaAndQueryOptions(result));
              }
      });
    }
  }


  export const addConstrainsts = (projectID) => {
    return dispatch => {
      dispatch(startSpinner());
      addConstrainstsApi(projectID).then((result) => {
        dispatch(endSpinner());
              if(result.success){
                  dispatch(setConstraintsToProject(result));
              }
              else{
                 dispatch(setContraintsFail(result));
              
              }
      });
    }
  }

  export const removeDemoFromProject = (data) => {
    return dispatch => {
      dispatch(startSpinner());
      removeDemoFromProjectApi(data).then((result) => {
        dispatch(endSpinner());
              if(result.success){
                  //  dispatch(setPropsEmptyScreenerAddByCategory());
                  // dispatch(getScreenerSuccess(result));
                   dispatch(setDemoDelete(result));
                 
              }
              else{
                dispatch(setDemoDeleteFail(result));
                // alert(result.message);
              }
      });
    }
  }

  export const removeRangeOptions = (data) => {
    return dispatch => {
      dispatch(startSpinner());
      removeRangeOptionsApi(data).then((result) => {
        dispatch(endSpinner());              
          dispatch(setRangeOptionDeleteSuccess(result));      
      });
    }
  }


  //updateQuestionOrder
  export const updateQuestionOrder = (data) => {
    return dispatch => {
      dispatch(startSpinner());
      updateQuestionOrderApi(data).then((result) => {
        dispatch(endSpinner());              
        if(result.success){
          dispatch(setDemoMappedList(result));
      }
      });
    }
  }







import React, { Component }  from 'react';
import { connect } from 'react-redux';
import UserHeader from '../../components/User/userHeader.jsx';
import UploadedUniqueLinkList from '../../components/StudyUniqueLink/uploadedUniqueLinks.jsx';
import add from '../../images/add.png';
import {Link} from 'react-router-dom';
import goback from '../../images/goback.png';

class ArchivingUniqueLinkList extends Component {

        constructor(props) {
            super(props)
            this.state = {

            }
            // this.gotoCreateVendor = this.gotoCreateVendor.bind(this);
        }

    componentWillMount() {
        let currentpage = 1;
        //this.props.dispatch(ThirdPartyList( currentpage));
      }
    goBack(){
        window.history.back();
    }

    //   gotoCreateVendor(){
    //    alert("gotoCreateVendor");

    //   }

    

    render(){
        const header = 'Archived Unique Link Information';
        const headerText = 'Here you can see all file of uniquelink';
        const headerButton = <div>
        <button className="back-btn back-btn-color" onClick={ this.goBack} ><img src={goback} height="13px" width="13px" /><span> Back</span></button>&nbsp;
        {/* <Link to="/unique-link-upload"><button style={{ padding: "12px 15px 7px 15px"}} className="create-btn create-btn-color" onClick={this.gotoCreateVendor}><img src={add} height="12px" width="12px"/><span> Upload Unique Link</span></button></Link> */}
        </div>
        return(
            <div style={{ width:"100%"}}>  
                <UserHeader
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                />
                <UploadedUniqueLinkList 
                       
                />

            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
      
         
    }
  }
  
  export default connect(mapStateToProps)(ArchivingUniqueLinkList);
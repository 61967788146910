import React, {Component} from 'react';
import {connect} from 'react-redux';
import {submitLogin, emptyLoginProps} from './action';
// import {ThirdPartyList} from '../../components/Club/Action';
// import DocumentTitle from 'react-document-title';
// import 'react-overlay-loader/styles.css';
import './newstyle.css';
import logo from './panelreward-logo.png';

class Login extends Component {
  constructor(props){
      super(props)
        this.state={
          uname:"",
          psw:"",
          validationMsg: {},
          logerror : ""
        }
      //bind the function context
      this.onUserNameChange=this.onUserNameChange.bind(this);
      this.onPasswordeChange = this.onPasswordeChange.bind(this);
      this.login = this.login.bind(this);
  }

  componentWillMount(){
    if(this.props.authenticated){
      this.props.history.push("/Login");
    }
  }
  componentWillReceiveProps(nextProps){
   
   if(nextProps.loginFailError === false){
    document.getElementById("note").style.display ="block";
    var x = document.getElementById("note")
    setTimeout(function(){ x.style.display ="none"}, 1000);
     this.setState({
      logerror : " User name and password incorrect !"
     });
     this.props.dispatch(emptyLoginProps());
   }
  }

  onUserNameChange(e){
    this.setState({uname:e.target.value})
  }

  onPasswordeChange(e){
    this.setState({psw:e.target.value})
  }

  handleValidation(){
		let valid = true;
			if(this.state.uname === ""){
        valid = false;
        document.getElementById("note").style.display ="block";
        var x = document.getElementById("note")
        setTimeout(function(){ x.style.display ="none"}, 1000);
        this.setState({
          logerror : "Please enter user name"
        });
    
      return valid;
    }
    
		if(this.state.psw === ""){
      valid = false;
      document.getElementById("note").style.display ="block";
      var x = document.getElementById("note")
      setTimeout(function(){ x.style.display ="none"}, 1000);
      this.setState({
        logerror : "Please enter password"
      });
     
      return valid;
    }
		return valid;
  }

 

login(e){
  e.preventDefault();
  if(this.handleValidation()){
  let loginData={}
  loginData.userName=this.state.uname;
  loginData.password=this.state.psw;
    this.props.dispatch(submitLogin(loginData));
  //this.props.dispatch(ThirdPartyList());
  }
}

  render(){
//     let loginFailErrorMessage="";
// if(this.props.loginFailError === false){
//   document.getElementById("note").style.display ="block";
//       var x = document.getElementById("note")
//       setTimeout(function(){ x.style.display ="none"}, 1000);
//   loginFailErrorMessage= "Please check your username and password";
// }
    return (
      <div id="logmain">
            <div id="note">
                {this.state.logerror}
               {/* {loginFailErrorMessage} */}
            </div>
            <div id="logform">
              <div>
              <form onSubmit ={this.login} >
                <div className="logo-st"><img src={logo} /></div>
                <h4 >Welcome to Sp.Panel Reward</h4>
                  <label>
                  <input type ="text" name="uname"  placeholder="User Name" className="inputtext mb mt" autoFocus={true} value={this.state.uname}  onChange={this.onUserNameChange}/></label><br/>
                 <label><input type ="password" name="psw" placeholder="Password" className="inputtext mb mt" value={this.state.psw} onChange={this.onPasswordeChange} /></label>
                 <label><button id="signbtn" className="mb mt" type = "submit">Sign In</button></label>
                 </form>
             </div>
            </div>
      </div>





      // <DocumentTitle title='Login To Panelreward'>
      // <div className="loginbg">
      //  <div className = "companyname"><img src={require("../../pages/StudyComplete/panelreward-logo.png")}  className = "menu-Logo"/></div>
      // <div className="container" >
      //     <div className = "userimg"> <img src={require("./user4.png")}  className = "panellogo"/></div>
      //     <form onSubmit ={this.login} >
      //         <div className = "loginform">
      //           <div className="loginhead">User Login</div>

      //             <input type="text" className="w"
      //                   placeholder="Enter Username"
      //                   name="uname"
      //                   onChange={this.onUserNameChange}
      //                   value={this.state.uname}
      //                 required
      //                 align="center"/><br/>
      //                   <div style={{color: "red"}} >{this.state.validationMsg.uname}</div>
      //             <input type="password" className="w"
      //             placeholder="Enter Password"
      //             name="psw"
      //             onChange={this.onPasswordeChange}
      //             value={this.state.psw}
      //               required/><br/>
      //             <div style={{color: "red"}} >{this.state.validationMsg.psw}</div>
      //             <button className="w1" type = "submit" >Login</button><br/>
      //             <div>{loginFailErrorMessage}</div>
      //           </div>
      //         </form>
      //     </div>
      // </div>
      // </DocumentTitle>
     );
  }
}


const mapStateToProps=(state)=>{
  return{
    authenticated : state.login.authenticated,
    loginFailError: state.login.error,

  }
}

export default connect(mapStateToProps)(Login);

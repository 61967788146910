import React, { Component }  from 'react';
import  '../../commonStyle/common.css';
import $ from 'jquery';
import {connect} from 'react-redux';
import Pagination from "react-js-pagination";
import deletIcon from '../../images/delete.png';
import editpencil from '../../images/edit-pencil.png';
import {ThirdPartyList, remove, emptyVendorProps} from '../../actions/actionsBag/vendor';
import { Link } from 'react-router-dom';
import { alertMessage, emptyDialogdata }  from '../../alertsDialog/Action';
//export default class VendorListTable extends Component {
  class VendorListTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            search: "",
            currentPage: 1,
            userId : "",
            userType : "",
            thirdPartyList : [],
            totalPage : 0,
            showloader: false,
        }
        this.handlePageChange = this.handlePageChange.bind(this);
      }

   
  // componentWillMount(){
   
  // }

  componentWillReceiveProps(nextProps){

    if(nextProps.confirmDialog === true){
     
      this.props.dispatch(emptyDialogdata());
      
     this.props.dispatch(remove(nextProps.confirmData));
    
  }


    if( nextProps.totalpages &&  nextProps.totalpages >0){
      this.setState({thirdPartyList: nextProps.thirdPartyList});
      this.setState({
        totalPage : nextProps.totalpages,
        showloader: true,
      });
    }else{
      this.setState({
        totalPage : 0,
        showloader: true,
      });
    }

   

    if(nextProps.removeSuccess && nextProps.removeMessage){
      this.props.dispatch(ThirdPartyList(1));
      this.setState({ currentPage : 1});
      let alertData ={};
      alertData.type ="alert";
      alertData.message = nextProps.removeMessage;
      this.props.dispatch(alertMessage(alertData));
      this.props.dispatch(emptyVendorProps());
      this.callMe();
    }else if(nextProps.removeMessage){
      let alertData ={};
      alertData.type ="alert";
      alertData.message = nextProps.removeMessage;
      this.props.dispatch(alertMessage(alertData));
      this.props.dispatch(emptyVendorProps());
      this.callMe();
    }
  }
  


  componentWillMount(){
    this.props.dispatch(ThirdPartyList(1));

  $(".dropdown").on('click',function() { 
      var t = (this.id); 
    if(t === "all-rm-shadow"){
      var all= document.getElementsByClassName("dropdown");
    
      for(let a=0; a< all.length; a++){
          if ( $( all[a] ).removeClass( "active" ) ) {
        
        }
      
        $(all[0]).addClass("active");
      }
    }else if(t === "live-rm-shadow"){
      
      var live= document.getElementsByClassName("dropdown");
    
      for(let a=0; a< live.length; a++){
          if ( $( live[a] ).removeClass( "active" ) ) {
        }
        $(live[1]).addClass("active");
      }
    }else if(t === "pause-rm-shadow"){
      var pause= document.getElementsByClassName("dropdown");
    
      for(var a=0; a< pause.length; a++){
          if ( $( pause[a] ).removeClass( "active" ) ) {
        
        }
      
        $(pause[2]).addClass("active");
      }
  }else if(t === "com-rm-shadow"){
      var com= document.getElementsByClassName("dropdown");
    
      for(let a=0; a< com.length; a++){
          if ( $( com[a] ).removeClass( "active" ) ) {
        
        }
      
        $(com[3]).addClass("active");
      }
    }
  });  
}

  callMe(){
      $(function() {
          $("#demo").find("p").hide();

          $(".demo").find("table").hide();
          
          $(".tbl").click(function(event) {
              event.stopPropagation();
              var $target = $(event.target);
              if ( $target.closest("td").attr(".demo") > 1 ) {
                  $target.slideUp();
                  $(".listexpandicon").removeClass("minus");
                  } else {
                  $target.closest("tr").next().find("table").toggle();
                  $target.closest("tr").find(".listexpandicon").toggleClass("minus");
              }              
          });
      });
  }
    

  handlePageChange(pageNumber){
    this.setState({thirdPartyList: []});
    this.props.dispatch(ThirdPartyList(pageNumber));
    this.setState({ currentPage : pageNumber});
  }

  deleteVendor(id){

    let alertData ={};
    alertData.type ="confirmalert";
    alertData.message = "Are you sure to delete this item ?";
    alertData.deleteId = id;
    this.props.dispatch(alertMessage(alertData));
    // this.props.dispatch(remove(id));
}

  tableZoom(){
    $(".alltablestruct").toggleClass("zoom-me");
    // $("#routercontainer").toggleClass("router-containter-pd");
    // document.getElementById("routercontainer").style.padding="0px";

  }

//   static getDerivedStateFromProps(nextProps, prevState) {
//     if(prevState.thirdPartyList !== nextProps.thirdPartyList){
//       alert("change props");
//     }
   
// }

    render(){
        this.callMe();
        let vendorItems = "";
       

        let vendors = this.state.thirdPartyList;
        
        let sr = (this.state.currentPage * 10)-10;
        if (vendors && vendors.length > 0 ) {
         
        
            vendorItems = vendors.map((list, i) => {
                    return <tbody> <tr>  
                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom"><span className="plus listexpandicon"></span>{sr+i+1}</td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.vendorName}</td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.contactName}</td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.contactEmail}</td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.contactNumber}</td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.variable1}</td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.variable2}</td>
                                          
                                        
                                          <td className=" tbl-padd-top tbl-padd-left tbl-padd-bottom">
                                          <div className="flex-show">
                                              
                                          <div className="icons" >
                                                <div className="delete" onClick={this.deleteVendor.bind(this, list._id)}>
                                                  <img src={deletIcon}/>
                                                </div>
                                              </div>

                                          <div className="icons">
                                            <Link to = {{      
                                                  pathname: '/update-vendor',
                                                  state: {
                                                      editVendorData : list,
                                                    }
                                                }}> <div className="pencil"> <img src={editpencil}  height="20px" width="20px"/> </div></Link>
                                          </div>

                                              
                                            </div>
                                            </td>
                                </tr>
                                <tr><td colSpan="10" className="demo">
                                            <table className="expand-tbl"  border="0" cellspacing="0" cellpadding="0">
                                                <tbody >
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Complete</th>
                                                        <td className="tbl-td" >{list.redirectUrlSuccess}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Terminate</th>
                                                        <td className="tbl-td" >{list.redirectUrlTerminated}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Quotafull</th>
                                                        <td className="tbl-td">{list.redirectUrlOverQuota}</td>
                                                    </tr>
                                                  
                                                </tbody>
                                            </table>
                                        </td></tr>
                                </tbody>
             });
          }else{
            vendorItems = "";
          }

          let showTable ="";
          if (vendors && vendors.length > 0 ) {
            showTable =  <table className="tbllayout " >
            <thead >
              <tr>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">S.no.</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Vendor Name</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Contact Name</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Email-id</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Contact Number</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Variable-1</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Variable-2.</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Action</th>
                </tr>
                </thead>
              {vendorItems}
          </table>
          }else if( this.state.showloader ===  true){
            showTable = <div className="datanotfound"> No record found</div>
          }else{
            showTable = <div className="spiner-data"><div className="tblspinner"></div></div>
          }
         
          


        return(
            <div className="list-style">
                <div className="table-st">
                    <div className="alltablestruct forms-rd-shadow">
                         {/* serachbox and stausbtn */}
                        <div id="search-staus">

                            <div className="flex-show  tbl-h-font"> Vendor List </div>
                            
                            <div className="flex-show tbl-zoom" >
                            <span onClick={this.tableZoom} className="full-screen mr-rhs-20 mr-top-10"> </span>
                            </div>
                        </div>
                        {/* serachbox and stausbtn */}

                            {/* table */}
                            <div style={{marginTop: "20px", overflowX: "auto"}}>
                                {showTable}
                            </div>
                            {/* table */}
                   
                      <div id="pageinationstyle">
                       
                      <Pagination
                          activePage={this.state.currentPage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalPage}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                        />
                        </div>
                    
                    </div>
                </div>
            </div>
    )}
}

const mapStateToProps = (state) => {
  return {
      thirdPartyList : state.thirdParty.thirdPartyList,
      totalpages : state.thirdParty.totalpages,
      removeSuccess : state.thirdParty.removeSuccess,
      removeMessage : state.thirdParty.removeMessage,
      confirmDialog :  state.alertsDialog.confirmDialog,
      confirmData : state.alertsDialog.confirmData
  }
}

export default connect(mapStateToProps)(VendorListTable);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../pages/Study/tempstyle.css';
import '../../commonStyle/common.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import './style.css';
import expandbutton from '../../images/expand-button.png';

class ArchivingMappingList extends Component {

  componentDidMount() {


  }

  setThirdPartyName() {
    if (this.props.mapping && this.props.thirdPartyList) {
      let i, j;
      for (i = 0; i < this.props.mapping.result.length; i++) {
        for (j = 0; j < this.props.thirdPartyList.length; j++) {
          if (this.props.mapping.result[i].thirdPartyId == this.props.thirdPartyList[j]._id) {
            this.props.mapping.result[i].thirdPartyName = this.props.thirdPartyList[j].vendorName;
          }
        }
      }
    }
  }

  mapingEditItem(data) {
  }

  setHeaderData() {
    localStorage.setItem("header-title", "Update Mapping");
    localStorage.setItem("header-text", "Here you can update mapping of study");
  }

  render() {
    let mappingList = "";

    if (this.props.mapping && this.props.mapping.result && this.props.mapping.result.length > 0) {
      this.setThirdPartyName();

      mappingList = this.props.mapping.result.map((item, index) => {
        return <tr onClick={this.mapingEditItem.bind(this, item)} key={index}>

          <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{item.thirdPartyName}</td>
          <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{item.orderId}</td>
          {/* <td className=""><div class="tooltipMap">{item.successUrl.slice(0,20)}
                    <span class="tooltipMapText">{item.successUrl}</span>
                  </div></td>
                  <td className=""><div class="tooltipMap">{item.terminateUrl.slice(0,20)}
                    <span class="tooltipMapText">{item.terminateUrl}</span>
                  </div></td>
                  <td className=""><div class="tooltipMap">{item.overQuotaUrl.slice(0,20)}
                    <span class="tooltipMapText">{item.overQuotaUrl}</span>
                  </div></td> */}
          <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{item.successUrl.slice(0, 20)}</td>
          <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{item.terminateUrl.slice(0, 20)}</td>
          <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{item.overQuotaUrl.slice(0, 20)}</td>
          <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{item.totalVendorQuota}</td>
          <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{item.TotalCount}</td>
          <td id="mapp-unique" className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom"> <Link to={{
            pathname: '/archiving-update-mapping',
            state: {
              editVendorMappingData: item,
            }
          }} onClick={this.setHeaderData}> <div className="eye-st"><div className="eye"></div></div> </Link></td>
        </tr>
      }
      )
    }

    return (
      <div className="form-title" >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h4 className="rm-margin card-text-h4">Mapping List</h4>
          </div>
          <div className="pro-tgl-btn pro-tgl-btn-shdw" id="tg-btn-5">
            <div id="rotate-icon-5"><img src={expandbutton} height="15px" width="15px" /></div>
          </div>
        </div>
        <div style={{ marginTop: "20px" }} className="box-hide-2 " id="map-hide-2">
          <table className="mappingtable" id="pro-tbl">
            <thead className="gradienthead">
              <tr>
                <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Vendor</th>
                <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Order ID</th>
                <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Success</th>
                <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Terminated</th>
                <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Quotafull</th>
                <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Quota</th>
                <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Achieve</th>
                <th id="mapp-unique" className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Action</th>
              </tr>
            </thead>
            <tbody>
              {mappingList}
            </tbody>
          </table>
        </div>

      </div>


    )
  }
}

const mapStateToProps = (state) => {
  return {

  }
}

export default connect(mapStateToProps)(ArchivingMappingList);
import React, { Component }  from 'react';
import appConfig from '../../../api/apiConfig'
import {connect} from 'react-redux';
import expandbutton from '../../../images/expand-button.png';


import $ from 'jquery';



class UpdateMapping extends Component {
    constructor( props){
        super(props)
        this.state = {
            successUrl: "",
            terminateUrl: "",
            overQuotaUrl: "",
            studyUrl: "",
            testStudyUrl: "",
            totalCount: 0,
            vendorQuota : '',
            oldVendorQuota : '',
            vendorName : '',
            stopEditingData : false,
            thirdPartyId : ''
        }
        this.onChange = this.onChange.bind(this);
        this.onChangeVendorQuota = this.onChangeVendorQuota.bind(this);
    }

    componentWillMount() {
        //this.getProgress()
        if (this.props.editVendorMappingData) {
          this.setState({
            vendorName : this.props.editVendorMappingData.thirdPartyName,
            successUrl: this.props.editVendorMappingData.successUrl,
            terminateUrl: this.props.editVendorMappingData.terminateUrl,
            overQuotaUrl: this.props.editVendorMappingData.overQuotaUrl,
            vendorQuota : this.props.editVendorMappingData.totalVendorQuota,
            oldVendorQuota : this.props.editVendorMappingData.totalVendorQuota,
            thirdPartyId : this.props.editVendorMappingData.thirdPartyId,
            studyUrl: appConfig.url.participantUrl + "?SID=" + this.props.editVendorMappingData.studyId + "&TID=" + this.props.editVendorMappingData.thirdPartyId + "&UID={uid}",
            testStudyUrl: appConfig.url.participantUrl + "?SID=" + this.props.editVendorMappingData.studyId + "&TID=" + this.props.editVendorMappingData.thirdPartyId + "&UID={uid}&isTest=true"
          });
        }
          if(localStorage.getItem("edit_study_data") === "stop"){
            this.setState({
                stopEditingData : true
            });
          }
      }

      componentWillReceiveProps(nextProps) {
        if (nextProps.thirdPartyTotalCount && nextProps.thirdPartyTotalCount.success) {
          this.setState({
            totalCount: nextProps.thirdPartyTotalCount.counts
          });
        }
      }

      addPlaceholder() {
          if( this.state.successUrl.includes("<uid>") && this.state.terminateUrl.includes("<uid>") && this.state.overQuotaUrl.includes("<uid>") ){
            // alert("Placeholder( <uid> ) already exixts in url !");
          }

          if( !this.state.successUrl.includes("<uid>")){
            this.setState({ successUrl: this.state.successUrl + "<uid>" })
          }

          if( !this.state.terminateUrl.includes("<uid>")){
            this.setState({ terminateUrl: this.state.terminateUrl + "<uid>" })
          }

          if( !this.state.overQuotaUrl.includes("<uid>")){
            this.setState({ overQuotaUrl: this.state.overQuotaUrl + "<uid>" })
          }
      }

      onChange(e) { this.setState({ [e.target.name]: e.target.value }); }

      CopyUrl() {
        let copyText = document.getElementById("CopyUrl");
        copyText.select();
        document.execCommand("Copy");
      }

      CopyTestUrl(){
        let copyText = document.getElementById("CopyTestUrl");
        copyText.select();
        document.execCommand("Copy");
      }

      onChangeVendorQuota( e){  
       
        if(Number.isInteger(parseInt(e.target.value))){
          if(parseInt(e.target.value) > 0 ){
            this.setState({ vendorQuota: parseInt(e.target.value )});
          }
        }else if( e.target.value === ''){
          this.setState({ vendorQuota: ''});
        }else{
          $("#error1").removeClass("showerror");
          // alert("Please only enter numeric characters in vendor quota!");
        }
      
    }

    handleValidation(){
        let valid = true;
        if(this.state.vendorQuota === ""){
          valid = false;
          $("#error1").addClass("showerror");
          this.setState({
            quotaerror: "Enter requirement."
          });
        }
        return valid;
    }

      updateMapping() {
        
        if(this.handleValidation()){
          let MappingData = {}
          MappingData.studyUrl = this.state.studyUrl;
          MappingData.studyTestUrl = this.state.testStudyUrl;
          MappingData.successUrl = this.state.successUrl;
          MappingData.terminateUrl = this.state.terminateUrl;
          MappingData.overQuotaUrl = this.state.overQuotaUrl;
          MappingData.vendorQuota = this.state.vendorQuota;
          MappingData.id = this.state.thirdPartyId;
          MappingData.oldVendorQuota = this.state.oldVendorQuota;
          MappingData.studyid = localStorage.getItem("edit_study_id");         
          this.props.updateMapping(MappingData);   
        }
      }
    
    render(){
        return(
            <div className="form-title">
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                            <div style={{display: "flex"}}><h4 className="rm-margin">Vendor Name :</h4><span>&nbsp;{this.state.vendorName}</span></div>
                            <div style={{display: "flex",marginTop: "10px"}}><h4 className="rm-margin">Study Name :</h4>&nbsp; <span style={{marginTop: "2px"}}>{localStorage.getItem("edit_study_name")}</span></div>
                        </div>
                    <div className="pro-tgl-btn pro-tgl-btn-shdw" id="tg-btn-6">
                        <div id="rotate-icon-6"><img src={expandbutton} height="15px" width="15px"/></div>
                    </div>
                </div>
                <div style={{marginTop: "20px"}} className="box-hide-3" id="map-hide-3">
                                
                         <div className = "formgroup">
                                <div class="formgroup-col-1 ">
                                    <label>Complete</label>
                                    <input className="form-controll" disabled={this.state.stopEditingData} name="successUrl" placeholder="Success Url" value={this.state.successUrl} onChange={this.onChange}></input>
                                </div>
                            </div>

                            <div className = "formgroup">
                                <div class="formgroup-col-1 ">
                                    <label>Terminated</label>
                                    <input className="form-controll" disabled={this.state.stopEditingData}  name="terminateUrl" placeholder="Terminate Url" value={this.state.terminateUrl} onChange={this.onChange}></input>
                                </div>
                            </div>

                            <div className = "formgroup">
                                <div className="url-text-1">
                                    <label>Quotafull</label>
                                    <input className="form-controll" disabled={this.state.stopEditingData}  name="overQuotaUrl" placeholder="Over Quota Url" value={this.state.overQuotaUrl} onChange={this.onChange}></input>
                                </div>
                                <div  style={{marginTop: "30px"}} ><button  className="list-btn" disabled={this.state.stopEditingData}  onClick={this.addPlaceholder.bind(this)}  style={{width: "150px"}}>Add Placeholder</button></div>
                            </div>

                            <div className = "formgroup">
                                <div className="url-text-1">
                                    <label></label>
                                    <input className="form-controll" disabled={this.state.stopEditingData}  id="CopyUrl" value={this.state.studyUrl} onChange={this.onChange} ></input>
                                </div>
                                <div  style={{marginTop: "20px"}} >
                                    <button  className="list-btn" style={{width: "150px"}}  onClick={this.CopyUrl}>Copy Live Url</button></div>
                            </div>

                            <div className = "formgroup">
                                <div className="url-text-1">
                                    <label></label>
                                    <input className="form-controll" disabled={this.state.stopEditingData} edi id="CopyTestUrl" value={this.state.testStudyUrl} onChange={this.onChange} ></input>
                                </div>
                                <div  style={{marginTop: "20px"}} ><button  className="list-btn "   style={{width: "150px"}} onClick={this.CopyTestUrl}>Copy Test Url</button></div>
                            </div>

                            <div style={{display: "flex",width: "100%",height: "50px"}}>
                                <div className = "formgroup" style={{width: "50%"}}>
                                    <div className="url-text-1">
                                        <label>Complete</label>
                                        <input className="form-controll " value={this.state.totalCount} disabled={this.state.stopEditingData} ></input>
                                    </div>
                                </div>
                                <div className = "formgroup" style={{width: "50%"}}>
                                    <div className="url-text-1  rltv-pos">
                                        <label> Requirement</label>
                                        <input className="form-controll " disabled={this.state.stopEditingData}  name="vendorQuota"  value={this.state.vendorQuota} onChange={this.onChangeVendorQuota} />
                                        <div className="errordiv cus-width-6" id="error1" >{this.state.quotaerror}</div>
                                    </div>
                                </div>
                            </div>
                           <div style={{marginTop: "100px", textAlign: "center"}}>
                                <button  onClick={ this.updateMapping.bind(this)} disabled={this.state.stopEditingData}   className="list-btn ">Update Mapping</button>
                           </div>
                    </div>
               
                </div>

     
    )}
}

const mapStateToProps=(state)=>{
    return{
      
    }
  }
  
  export default connect(mapStateToProps)(UpdateMapping);
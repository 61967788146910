const initialState = {
    show: "",
    userListSuccess : "",
    usersListData : ""
    }
    
  
  
  const dashboard = (state = initialState , action) =>{
      switch (action.type) {
    
        case "SET_PARTICIPANT_COUNT" :
          return {
            ...state,
            participantCount: action.participantCount,
          }    

          case "SET_PARTICIPANT_DATA" :
          return {
            ...state,
            participantData: action.participant.participantData,
            thirdPartyData: action.participant.thirdPartyData,
            studyData : null
          } 

          case "SET_THIRDPARTY_PARTICIPANT_DATA" :
          return {
            ...state,
            participantData: action.participant.participantData,
            studyData: action.participant.studyData
          } 

          case "SET_STUDY_PARTICIPANT_DATA" :
          return {
            ...state,
            participantData: action.participant.participantData,
            
          } 

          case "SET_PROGRESS_DATA" :
          return {
            ...state,
            progressData: action.progressData,
            
          } 
          
          case "SET_PARTICIPANT_MONTH_COUNT" :
          return {
            ...state,
            participantMonthCountData: action.participantMonthCount.dataPerDayCount,
            showParticipantMonthCountData: true,
            showParticipantYearCountData: false,
            participantYearCountData: false,
            studyData : null
            
          } 
          case "SET_PARTICIPANT_YEAR_COUNT" :
          return {
            ...state,
            participantYearCountData: action.participantYearCount.dataPerMonthCount,
            showParticipantYearCountData: true,
            showParticipantMonthCountData: false,
            participantMonthCountData: false,
            studyData : null
          } 

          case "SET_USERS_LIST" : 
          return{
            ...state,
            userListSuccess : action.users.success,
            usersListData : action.users.usersList,
          }

          case 'SET_DASHBOARD_STUDIES_LIST':
            return{
              ...state,
              studyListData : action.studies,
            }

          case 'SET_DASHBOARD_STUDIES_LIST_EMPTY':
            return{
              ...state,
              studyListData : null
            }

          case 'SET_REMOVE_STUDY_RESPONSE':
            return{
              ...state,
              removeStudySuccess : action.response.success,
              removeStudyMessage : action.response.message,
            }
          
          case 'SET_EMPTY_DASHBOARD_RES':
            return{
              ...state,
              removeStudySuccess : null,
              removeStudyMessage : null,
            }

          case 'SET_SERVER_TIME':
            return{
              ...state,
              serverTimeData : action.response,
            }

          default:
              return state;
            }
    
    };
    export default dashboard;
import React, { Component }  from 'react';
import StudyUpdate from '../../components/Study/studyUpdate';
import UpdateStudyHeader from '../../components/Study/updateStudyHeader';
import {connect} from 'react-redux';
import '../../pages/Study/tempstyle.css';
import '../../commonStyle/common.css';
import $ from 'jquery';

import download from '../../images/downloadimg.png';
import './style.css';

import expandbutton from '../../images/expand-button.png';
class ThirdPartyOverallReport extends Component {

    componentDidMount(){

       
    }
    
    render(){
    
    let totalIpFails = 0;
    let participantItems = "";
    let totalCompletes = 0;
    let totalOverQuota = 0;
    let totalTerminates = 0;
    let totalLoiFails = 0;
    let loi = 0;
    let ir = 0;
    let avgLoi = 0;
    let progressData = this.props.progressDataStudyMap;

    if (progressData) {

        // if (progressData.totalCompletes) {
        // totalCompletes = progressData.totalCompletes;
        // }

        // if (progressData.totalOverQuota) {
        // totalOverQuota = progressData.totalOverQuota;
        // }

        // if (progressData.totalTerminates) {
        // totalTerminates = progressData.totalTerminates;
        // }

        // if (progressData.totalIpFails) {
        // totalIpFails = progressData.totalIpFails;
        // }

        if (progressData.loi ) {
        loi = progressData.loi.loi;
        totalCompletes = progressData.loi.completed;
        totalOverQuota = progressData.loi.overQuota;
        totalTerminates = progressData.loi.terminate;
        totalIpFails = progressData.loi.ipFailed;
        totalLoiFails = progressData.loi.loiFailed
         
        }


        if (progressData.irCount) {
        ir = progressData.irCount
        }
        if (progressData.avgLoi && progressData.avgLoi.average != null) {
           
        avgLoi = progressData.avgLoi.average
        }
        // if (progressData.totalLoiFails) {
        // totalLoiFails = progressData.totalLoiFails
        // }
    }

        return(
            <div className="form-title">
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                        <h4 className="rm-margin card-text-h4">Third Party Overall Report</h4>
                        </div>
                    <div className="pro-tgl-btn pro-tgl-btn-shdw" id="tg-btn-4">
                        <div id="rotate-icon-4"><img src={expandbutton} height="15px" width="15px"/></div>
                    </div>
                </div>
                <div className="box-hide-1"  id="map-hide-1">
                <div className="map-card-top ">
                    <div className="map-card-midd" >
                        <div className="map-card-bottom map-box-1 map-box " >
                            <p>Complete</p><h3>{totalCompletes}</h3>
                        </div>
                        <div className="map-card-bottom map-box-2 map-box">
                            <p>Quota full</p><h3>{totalOverQuota}</h3>
                        </div>
                    </div>

                    <div  className="map-card-midd" >
                        <div className="map-card-bottom map-box-3 map-box">
                            <p>Terminates</p><h3>{totalTerminates}</h3>
                        </div>
                        <div className="map-card-bottom map-box-4 map-box">
                            <p>LOI Fail</p><h3>{totalLoiFails}</h3>
                        </div>
                    </div>
                </div>


                <div  className="map-card-top">
                    <div className="map-card-midd">
                        <div className="map-card-bottom map-box-1 map-box">
                            <p>Length Of Interview</p><h3>{loi}</h3>
                        </div>
                        <div className="map-card-bottom map-box-2 map-box">
                            <p>Average Loi</p><h3>{avgLoi}</h3>
                        </div>
                    </div>

                    <div className="map-card-midd">
                        <div className="map-card-bottom map-box-3 map-box">
                            <p>IR Count</p><h3>{ir +" %"}</h3>
                        </div>
                        <div className="map-card-bottom map-box-4 map-box">
                            <p>IP Fail</p><h3>{totalIpFails}</h3>
                        </div>
                    </div>
                </div>
                </div>
        </div>
    )}
}

const mapStateToProps=(state)=>{
    return{
      
    }
  }
  
  export default connect(mapStateToProps)(ThirdPartyOverallReport);
import React, { Component }  from 'react';
import '../../commonStyle/common.css';
import { addThirdParty, emptyVendorProps } from '../../actions/actionsBag/vendor';
import { connect } from 'react-redux';
import add from '../../images/add.png';
import $ from 'jquery';

class  AddRoleDiv  extends Component {
  constructor(props) {
    super(props)
    this.state = {
      role_type: "",
      validationMsg: {},
    }
      
    this.addRole = this.addRole.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleValidation() {
    let valid = true;
    let error = {};  

    if (this.state.role_type === "") {
        valid = false;
        $("#error1").addClass("showerror");
        this.setState({
          rolenameerror: "Enter role name"
        });
    }
    
    this.setState({ validationMsg: error });
    return valid;
  }

  onChange(e){
    if(e.target.value !== "")
    $("#error1").removeClass("showerror");
    this.setState({ [e.target.name]: e.target.value });
  }
    
  addRole(){
    let roleData = {};
    roleData.role_type = this.state.role_type.toLowerCase().replace(/ /g,"_");
    roleData.created_by_id = localStorage.getItem('auth_user_id');
    if (this.handleValidation()) {
      this.props.addRole(roleData);
    }
  }


    render(){

        return(
            <div style={{marginTop: "2%"}}>
                <div className="table-st">
                    <div className="forms-rd-shadow" style={{ width: "100%",paddingBottom:"50px", backgroundColor:"#ffffff", borderRadius:"4px" }}>
                        <div className="addVendor-main-div">
                        <div className="form-title">
                            <h4 className="rm-margin">Create new role</h4>
                        </div>
                        <hr/>
                        <div className = "formgroup">
                                <div class="formgroup-col-7 rltv-pos" >
                                    <label>Role name*</label>
                                    <input className="form-controll " name="role_type" placeholder="role name" value={this.state.role_type} onChange={this.onChange}></input>
                                    <div className="errordiv cus-width" id="error1" >{this.state.rolenameerror}</div>
                                </div>
                                <div class="formgroup-col-2" >
                                    <button  onClick={this.addRole}  style={{marginTop: "27px", width: "-webkit-fill-available"}} className="list-btn list-btn-color">
                                    <img src={add} height="12px" width="12px"/><span> Create Role </span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )}
}
const mapStateToProps=(state)=>{
    return{
       
    }
  }
  export default connect(mapStateToProps)(AddRoleDiv);


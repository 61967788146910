import appConfig from "../apiConfig";


export const removeAgeApi =(data)=> {
  let ageData = JSON.stringify(data);
  const headers = Object.assign({'Content-Type': 'application/json',
                                  });
  const request= new Request(appConfig.siteURL+'removeAgeMapping/', {
    method : "POST",
    headers : headers,
    body : ageData
  });

return  fetch(request).then(response => {
  return  response.json().then(result => {
      return result;
  });
   }).catch(error => {
     return error;
   }); 
}


export const queryApi =(data)=> {
    let queryData = JSON.stringify(data);
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL + 'newQuery/', {
      method : "POST",
      headers : headers,
      body : queryData
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }

  export const queryListApi =(id)=> {
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL+'queryDemoMapping/'+ id.studyId +'/'+ id.demoId, {
      method : "GET",
      headers : headers
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }


  export const queryOptionApi =(data)=> {
    let queryOptionData = JSON.stringify(data);
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL + 'newOption/', {
      method : "POST",
      headers : headers,
      body : queryOptionData
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }

  export const newDemoApi =(data)=> {
    let demoQueryData = JSON.stringify(data);
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'newDemo/', {
      method : "POST",
      headers : headers,
      body : demoQueryData
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }

  export const newDemoListApi =(currentPage)=> {
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL + 'getAllDemographics/'+currentPage, {
      method : "GET",
      headers : headers
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }

  export const updateOptionApi =(item)=> {
    let queryOption = JSON.stringify(item);
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL + 'updateOption/'+item._id, {
      method : "POST",
      headers : headers,
      body : queryOption
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }

  export const updateDemoApi =(item, id)=> {
    let demoData = JSON.stringify(item);
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'updateDemo/'+id, {
      method : "POST",
      headers : headers,
      body : demoData
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }

  export const getQueryOptionApi =(id)=> {
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'queryAndOption/'+ id, {
      method : "GET",
      headers : headers
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }

  export const updateQueryApi =(item)=> {
    let queryData = JSON.stringify(item);
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL + 'updateQuery/'+item.id, {
      method : "POST",
      headers : headers,
      body : queryData
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }

  export const getQueryAndRangeApi =(data)=> {
    //let id = JSON.stringify(data);
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'getDemoAgeMapping/'+data.studyId+'/'+data.demoId, {
      method : "GET",
      headers : headers,
     // body : id
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }

  export const addDemoAgeMappingApi =(data)=> {
    let demoAgeMappingData = JSON.stringify(data);
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'studyDemoAgeMapping/', {
      method : "POST",
      headers : headers,
      body : demoAgeMappingData
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }

  export const updateDemoAgeApi =(item)=> {
    let DemoAgeData = JSON.stringify(item);
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL + 'updateDemoAgeMapping/', {
      method : "POST",
      headers : headers,
      body : DemoAgeData
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }

  export const participantCountApi =(item)=> {
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'ThirdpartyParticipantCount/' + item.thirdPartyId + '/' + item.studyId, {
      method : "GET",
      headers : headers
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }
  
 //getQuestionByDemoApi
 export const getQuestionByDemoApi =(id)=> {
  const headers = Object.assign({'Content-Type': 'application/json',
                                  });
  const request= new Request(appConfig.siteURL +'getQueryBydemo/' + id, {
    method : "GET",
    headers : headers
  });

return  fetch(request).then(response => {
  return  response.json().then(result => {
      return result;
  });
   }).catch(error => {
     return error;
   }); 
}

//getOptionListApi
export const getOptionListApi =(id)=> {
  const headers = Object.assign({'Content-Type': 'application/json',
                                  });
  const request= new Request(appConfig.siteURL +'getOptionList/' + id, {
    method : "GET",
    headers : headers
  });

return  fetch(request).then(response => {
  return  response.json().then(result => {
      return result;
  });
   }).catch(error => {
     return error;
   }); 
}

//deleteQuestionApi
export const deleteQuestionApi =(id)=> {

  const headers = Object.assign({'Content-Type': 'application/json',
                                  });
  const request= new Request(appConfig.siteURL + 'deleteQuery/'+id, {
    method : "POST",
    headers : headers,
  });

return  fetch(request).then(response => {
  return  response.json().then(result => {
      return result;
  });
   }).catch(error => {
     return error;
   }); 
}

//deleteOptionApi

export const deleteOptionApi =(id)=> {

  const headers = Object.assign({'Content-Type': 'application/json',
                                  });
  const request= new Request(appConfig.siteURL + 'deleteOption/'+id, {
    method : "POST",
    headers : headers,
  });

return  fetch(request).then(response => {
  return  response.json().then(result => {
      return result;
  });
   }).catch(error => {
     return error;
   }); 
}
//deleteDemographicsApi

export const deleteDemographicsApi =(id)=> {

  const headers = Object.assign({'Content-Type': 'application/json',
                                  });
  const request= new Request(appConfig.siteURL + 'deleteDemo/'+id, {
    method : "POST",
    headers : headers,
  });

return  fetch(request).then(response => {
  return  response.json().then(result => {
      return result;
  });
   }).catch(error => {
     return error;
   });
}
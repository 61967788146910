import React, { Component }  from 'react';
import { connect } from 'react-redux';
import VendorHeader from '../../components/Vendor/vendorHeader.jsx';
import AddVendorDiv from '../../components/Vendor/addVendor.jsx';
import goback from '../../images/goback.png';
import {Link} from 'react-router-dom';


    class CreateVendorView extends Component {

        constructor(props) {
            super(props)
            this.state = {

            }
             this.gotoThirdPartyList = this.gotoThirdPartyList.bind(this);
          }

    componentWillMount() {

       
      }

    //   gotoListVendor(){
    //       alert("gotoListVendor");
    //   }

    gotoThirdPartyList(){
        this.props.history.push("/thirdpartylist");
    }


    render(){

        const header = 'Add Third Party';
        const headerText = 'Here you can add you vendor information and his redirect';
        const headerButton = <Link to="/thirdpartylist"> <button className="back-btn back-btn-color" ><img src={goback} height="13px" width="13px" /><span> Back</span></button></Link>


        return(
            <div style={{ width:"100%"}}>
                <VendorHeader 
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                />
                <AddVendorDiv 
                gotoThirdPartyList = {this.gotoThirdPartyList}
                />
            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
 
    }
  }
  
export default connect(mapStateToProps)(CreateVendorView);
import React, { Component }  from 'react';
import '../../commonStyle/common.css';
import './dashboardStyle.css';
import './commonStyle.css';

import ReactChartkick, { LineChart, PieChart } from 'react-chartkick';
import Chart from 'chart.js'

export default class DashboardGraphDiv extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      graphSlider : false,
      graphDataType : "day" 
    }

    this.onGraphSliderChange = this.onGraphSliderChange.bind(this);
  }

  componentWillMount(){
    
  }

    onGraphSliderChange(e){     
       if(e.target.value == false || e.target.value == "false"){
        this.setState({
          graphSlider : true,
          graphDataType : "month"
        });
        //alert("Year"); 
        this.props.setGraphBy("year");
       }else{
        this.setState({
          graphSlider : false,
          graphDataType : "day"
        });
        
        //alert("month");
        this.props.setGraphBy("month");
        
       }
    }

    render(){
        

        let participantCountArray = {};

        if(this.props.participantMonthCountData){
            let dataArrayCount = "";
            dataArrayCount = this.props.participantMonthCountData;
        
            for(let i=0; i< dataArrayCount.length; i++){
              let participantCountObj = {};
              //  participantCountObj.x =dataArrayCount[i].count_date.split("T")[0] ;
              //  participantCountObj.y = dataArrayCount[i].counted_leads ;
              // participantCountArray.push(participantCountObj);
        
               participantCountArray['"'+dataArrayCount[i].count_date.split("T")[0]+'"'] = dataArrayCount[i].counted_leads;
            }
        
          }else if(this.props.participantYearCountData){
            const monthNames = ["January", "February", "March", "April", "May", "June",
                                "July", "August", "September", "October", "November", "December"
                              ];
            let dataArrayCount = "";
            dataArrayCount = this.props.participantYearCountData;
        
            for(let i=0; i< dataArrayCount.length; i++){
              let participantCountObj = {};
              //  participantCountObj.x =dataArrayCount[i].count_date.split("T")[0] ;
              //  participantCountObj.y = dataArrayCount[i].counted_leads ;
              // participantCountArray.push(participantCountObj);
              if(i==0){
                participantCountArray['Start'+dataArrayCount[i].count_date.split("-")[0]+"-"+ 
                monthNames[parseInt(dataArrayCount[i].count_date.split("-")[1])-1]] = dataArrayCount[i].counted_leads;
              }else{
                participantCountArray[dataArrayCount[i].count_date.split("-")[0]+"-"+ 
                monthNames[parseInt(dataArrayCount[i].count_date.split("-")[1])-1]] = dataArrayCount[i].counted_leads;
              }
            }
          }
          let showChart = "";
          if((this.props.participantMonthCountData !== undefined && this.props.participantMonthCountData && this.props.participantMonthCountData.length > 0 )
          || (this.props.participantYearCountData !== undefined && this.props.participantYearCountData && this.props.participantYearCountData.length > 0) ){
           showChart = <div><LineChart className="dashboard-graph" data={participantCountArray}  /></div>
          }else{
           showChart = <div className="spiner-data"><div className="tblspinner"></div></div>
          }


        return(
            <div style={{marginTop: "20px"}}>
                <div className="table-st ">
                    <div style={{ width: "100%", height: "350px", paddingTop:"20px",paddingLeft: "10px",paddingRight: "10px",paddingBottom: "10px", backgroundColor:"#ffffff" }} className="forms-rd-shadow">
                    <div id="participantgraphead " style={{display: "flex", justifyContent: "space-between",paddingLeft: "10px"}}>
                                  <div><span className="tbl-h-font">Complete </span>Here you can see {this.state.graphDataType} wise completes</div>
                                  <div id="participantgraphslider">
                                         <label class="switch"><br/>
                                          <input type="checkbox" value={this.state.graphSlider} checked={this.state.graphSlider} onChange={this.onGraphSliderChange}/>
                                            <span class="slider round"></span>
                                             </label>
                                     </div>
                            </div>
                        {showChart}
                        {/* <LineChart className="dashboard-graph" data={participantCountArray}  /> */}
                    </div>
                </div>
            </div>
    )}
}
const initialState = {
	logout: '',
	error: "",
}


const login = (state = initialState, action) => {

	switch (action.type) {

		case 'LOGOUT' :
        return {
          ...state,
          authClient: null,
          authenticated : false
        }

		case 'SET_AUTH_CLIENT':
			return {
				...state,
                authenticated: action.result.success,
                error: ""
			}

		case 'SET_AUTH_Fail':
			return {
				...state,
				error: action.result.success,
			}
		case 'SET_EMPTY_LOGIN_PROPS':
			return{
				...state,
				error: null,
			}

		default:
			return state;
	}

};
export default login;
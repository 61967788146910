import appConfig from "../../api/apiConfig";

//
export const addClientApi =(clientData)=> {
	let study =  JSON.stringify(clientData);
	const headers = Object.assign({'Content-Type': 'application/json'});
	const request= new Request(appConfig.siteURL + 'createClient', {
	  method : "POST",
	  headers : headers,
	  body : study
	});
  
  return  fetch(request).then(response => {
	return  response.json().then(study => {
		return study;
	});
	 }).catch(error => {return error;});
	}


	
	export const uploadClientLogoApi =(clientData,clientId)=> {
		
		
		const request= new Request(appConfig.siteURL + 'uploadClientLogo/'+clientId, {
		  method : "POST",
		  body : clientData
		});
	  
	  return  fetch(request).then(response => {
		return  response.json().then(study => {
			return study;
		});
		 }).catch(error => {return error;});
		}

	// getClientListApi
	export const getClientListApi =(page)=> {
		let userRoleType = localStorage.getItem("auth_user_type");
		let userId = localStorage.getItem("auth_user_id");
		
		const headers = Object.assign({'Content-Type': 'application/json'});
		const request= new Request( appConfig.siteURL+'getClientByUser/'+userId+'/'+page+'/'+userRoleType,  {
		  method : "GET",
		  headers : headers,
		 		});
	  
	  return  fetch(request).then(response => {
		return  response.json().then(client => {
			return client;
		});
		 }).catch(error => {return error;});
		}
	

// getClientByUserApi
export const getClientByUserApi =(userId)=> {
			
	const headers = Object.assign({'Content-Type': 'application/json'});
	const request= new Request( appConfig.siteURL+'getClientByUserid/'+userId,  {
		method : "GET",
		headers : headers,
	});
	
	return  fetch(request).then(response => {
	return  response.json().then(client => {
		return client;
	});
		}).catch(error => {return error;});
	}

// updateClientApi
export const updateClientApi =(clientData)=> {
	let client =  JSON.stringify(clientData);
	const headers = Object.assign({'Content-Type': 'application/json'});
	const request= new Request( appConfig.siteURL+'updateClient/',  {
		method : "POST",
		headers : headers,
		body : client
	});
	
	return  fetch(request).then(response => {
	return  response.json().then(client => {
		return client;
	});
		}).catch(error => {return error;});
	}



	// softClientDeleteApi
	export const softClientDeleteApi =(clientData)=> {
		let client =  JSON.stringify(clientData);
		const headers = Object.assign({'Content-Type': 'application/json'});
		const request= new Request( appConfig.siteURL+'disabledClient/',  {
			method : "POST",
			headers : headers,
			body : client
		});
		
		return  fetch(request).then(response => {
		return  response.json().then(client => {
			return client;
		});
			}).catch(error => {return error;});
		}

	//getAllCountryListApi
	export const getAllCountryListApi =(userId)=> {
			
		const headers = Object.assign({'Content-Type': 'application/json'});
		const request= new Request( appConfig.siteURL+'getAllCountries/',  {
			method : "GET",
			headers : headers,
		});
		
		return  fetch(request).then(response => {
		return  response.json().then(client => {
			return client;
		});
			}).catch(error => {return error;});
		}

//getStudyDataForInvoicedApi

export const getStudyDataForInvoicedApi =(study_id)=> {
			
	const headers = Object.assign({'Content-Type': 'application/json'});
	const request= new Request( appConfig.siteURL+'getInvoicedDataByStudy/'+ study_id,  {
		method : "GET",
		headers : headers,
	});
	
	return  fetch(request).then(response => {
	return  response.json().then(client => {
		return client;
	});
		}).catch(error => {return error;});
	}
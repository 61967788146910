import React, { Component } from 'react';
import '../../commonStyle/common.css';
import $ from 'jquery';
import { connect } from 'react-redux';


export default class CreateInvoice extends Component {
    constructor(props) {
        super(props)
        this.state = {
            studyId: "",
            client_id: "",
            studyName: "",
            startDate: "",
            clientName: "",
            companyName: "",
            countryName: "",
            clientEmail: "",
            clientMobile: "",
            clientAlternativeContact: "",
            totalComplete: "",
            cpc: "",
            currency: "",
            finelTotalJobCost: "",
            finelTotalComplete: "",
            invoiceNumber: "",
            invoiceDate: "",
            invoicePaid: "",
            paymentDate: "",
            refNumber: "",
            allStudyListData: [],
            isHaveCloneStudy: false,
        }

        this.addInvoice = this.addInvoice.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onCpcChange = this.onCpcChange.bind(this);
        this.onInvoicePaidChange = this.onInvoicePaidChange.bind(this);
        this.ontotalCompleteChange = this.ontotalCompleteChange.bind(this);
        this.handleChangeNoOfComplete = this.handleChangeNoOfComplete.bind(this);
        this.handleChangeCPC = this.handleChangeCPC.bind(this);
        this.onCurrencyChange = this.onCurrencyChange.bind(this);
    }


    onCurrencyChange(e){
        if(e.target.value === ""){
          
        }
        this.setState({
            currency : e.target.value
        });
    }

    componentWillMount() {

        if (this.props.studyData.length > 0) {
            let newArr = this.props.studyData;
            let finelTotalParticipants = 0;
            let finelTotalcost = 0;
            for (let i = 0; i < newArr.length > 0; i++) {
                let totalParticipant = newArr[i].totalParticipant;
                let totalCPC = newArr[i].cpc;
                newArr[i].totalJobCost = parseInt(totalParticipant) * parseInt(totalCPC);
                finelTotalParticipants = finelTotalParticipants + parseInt(totalParticipant);
                finelTotalcost = finelTotalcost + (parseInt(totalParticipant) * parseInt(totalCPC));
            }
            this.setState({ allStudyListData: newArr, finelTotalComplete: finelTotalParticipants, finelTotalJobCost: finelTotalcost });
        }

        if (this.props.studyData) {
            this.setState({

                studyId: this.props.studyData._id,
                client_id: this.props.studyData.client_id,
                studyName: this.props.studyData.studyName,
                startDate: this.props.studyData[0].createdAt.split("T")[0],
                // companyName : this.props.studyData,
                countryName: this.props.studyData[0].countryName,
                clientName: this.props.studyData[0].name,
                clientEmail: this.props.studyData[0].e_mail,
                clientMobile: this.props.studyData[0].mobile,
                clientAlternativeContact: this.props.studyData[0].alternate_mobile,
                isHaveCloneStudy: this.props.studyData[0].is_clone_main_study,
                // totalComplete : this.props.studyData.totalParticipant,
                // cpc : this.props.studyData.cpc,
                // totalJobCost : (this.props.studyData.totalParticipant * this.props.studyData.cpc),
            });
        }
    }

    handleValidation() {
        let valid = true;
        if (this.state.studyId === "") {
            valid = false;
            $("#error1").addClass("showerror");
            this.setState({
                studyIderror: "Study id is missing. Try again"
            });
        }

        if (this.state.studyName === "") {
            valid = false;
            $("#error2").addClass("showerror");
            this.setState({
                studyNameerror: "Study name error."
            });

        }

        if (this.state.startDate === "") {
            valid = false;
            $("#error3").addClass("showerror");
            this.setState({
                startDateerror: "Start date error"
            });
        }

        if (this.state.clientAlternativeContact === "") {
            valid = false;
            $("#error4").addClass("showerror");
            this.setState({
                altConterror: "Contact error"
            });
        }

        if (this.state.countryName === "") {
            valid = false;
            $("#error5").addClass("showerror");
            this.setState({
                countryerror: "Country error"
            });
        }

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        let tempemail = this.state.clientEmail;

        if (this.state.clientEmail === "") {
            valid = false;
            $("#error6").addClass("showerror");
            this.setState({
                emailerror: "Email error"
            });
        } else if (!pattern.test(tempemail)) {
            valid = false;
            $("#error6").addClass("showerror");
            this.setState({
                emailerror: "Incorrect email"
            });
        }

        if (this.state.clientMobile === "") {
            valid = false;
            $("#error7").addClass("showerror");
            this.setState({
                clientconterror: "Client contact error"
            });
        }

        if (this.state.refNumber === "") {
            valid = false;
            $("#error8").addClass("showerror");
            this.setState({
                refnoerror: "Refrence number error"
            });
        }

        // if(this.state.totalComplete === ""){
        //     valid = false;
        //     $("#error9").addClass("showerror");
        //         this.setState({
        //             completeerror: "No. of complete error"
        //         });
        //     } 

        // if(this.state.cpc === ""){
        //     valid = false;
        //     $("#error10").addClass("showerror");
        //         this.setState({
        //             cpcerror: "CPC error"
        //         });
        //     }

        // if(this.state.currency === ""){
        //     valid = false;
        //     $("#error11").addClass("showerror");
        //         this.setState({
        //             currencyerror: "Currency error"
        //         });
        //     }

        // if(this.state.totalJobCost === ""){
        //     valid = false;
        //     $("#error12").addClass("showerror");
        //         this.setState({
        //             joberror: "Job cost error"
        //         });
        //     }


        if (this.state.invoiceNumber === "") {
            valid = false;
            $("#error13").addClass("showerror");
            this.setState({
                invoicenoerror: "Invoice number error"
            });
        }

        if (this.state.invoiceDate === "") {
            valid = false;
            $("#error14").addClass("showerror");
            this.setState({
                invoicedateerror: "Invoice date error"
            });
        }

        if (this.state.invoicePaid === "") {
            valid = false;
            $("#error15").addClass("showerror");
            this.setState({
                invoicepaiderror: "Invoice paid error"
            });
        }

        if (this.state.paymentDate === "") {
            valid = false;
            $("#error16").addClass("showerror");
            this.setState({
                paymentdateerror: "Payment date error"
            });
        }

        return valid;
    }


    addInvoice() {
        if (this.handleValidation()) {
            let data = {};
            data.userId = localStorage.getItem("auth_user_id");
            data.roleType = localStorage.getItem("auth_user_type");
            data.client_id = this.state.client_id;
            data.client_name = this.state.clientName;
            // data.studyId = this.state.studyId;
            // data.studyName = this.state.studyName;
            // data.cpc = this.state.cpc;
            data.refNumber = this.state.refNumber;
            // data.totalParticipant = this.state.totalComplete;
            data.currency = this.state.currency;
            // data.totalJobCost = this.state.totalJobCost;
            data.invoiceNumber = this.state.invoiceNumber;
            data.invoiceDate = this.state.invoiceDate;
            data.invoicePaid = this.state.invoicePaid;
            data.paymentDate = this.state.paymentDate;
            data.clientEmail = this.state.clientEmail;
            data.finelTotalJobCost = this.state.finelTotalJobCost;
            data.finelTotalComplete = this.state.finelTotalComplete;
            data.isHaveCloneStudy = this.state.isHaveCloneStudy;
            data.allStudyListData = this.state.allStudyListData;
            this.props.invoiceCreate(data);
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
        if (e.target.name === "clientEmail")
            $("#error6").removeClass("showerror");
        if (e.target.name === "refNumber")
            $("#error8").removeClass("showerror");
        if (e.target.name === "invoiceNumber")
            $("#error13").removeClass("showerror");
        if (e.target.name === "invoiceDate")
            $("#error14").removeClass("showerror");
        if (e.target.name === "paymentDate")
            $("#error16").removeClass("showerror");

    }

    onCpcChange(e) {
        let validNum = /^[0-9]+$/;
        if (e.target.value.match(validNum)) {
            let totalJobCost = (parseInt(this.state.totalComplete) * parseInt(e.target.value));
            this.setState({ cpc: e.target.value, totalJobCost: totalJobCost });
        } else if (e.target.value === "") {
            this.setState({ cpc: "", totalJobCost: "" });
        }
    }

    ontotalCompleteChange(e) {
        let validNum = /^[0-9]+$/;
        if (e.target.value.match(validNum)) {
            let totalJobCost = (parseInt(this.state.cpc) * parseInt(e.target.value));
            this.setState({ totalComplete: e.target.value, totalJobCost: totalJobCost });
        } else if (e.target.value === "") {
            this.setState({ totalComplete: "", totalJobCost: "" });
        }
        $("#error9").removeClass("showerror");
        $("#error12").removeClass("showerror");

    }

    onInvoicePaidChange(e) {
        let validNum = /^[0-9]+$/;
        if (e.target.value.match(validNum)) {
            this.setState({ invoicePaid: e.target.value });
        } else if (e.target.value === "") {
            this.setState({ invoicePaid: "", totalJobCost: "" });
        }


        if (e.target.name === "invoicePaid")
            $("#error15").removeClass("showerror");
    }


    handleChangeNoOfComplete(e) {

        let newArr = this.state.allStudyListData;
        if (e.target.value == '') {

            let finelTotalParticipants = parseInt(this.state.finelTotalComplete) - parseInt(newArr[e.target.name].totalParticipant);
            let finelTotalJobCost = parseInt(this.state.finelTotalJobCost) - parseInt(newArr[e.target.name].totalJobCost)
            newArr[e.target.name].totalParticipant = 0;
            newArr[e.target.name].totalJobCost = 0;
            this.setState({ allStudyListData: newArr, finelTotalComplete: finelTotalParticipants, finelTotalJobCost: finelTotalJobCost });
        } else if (e.target.value > 0) {

            let finelTotalParticipants = parseInt(this.state.finelTotalComplete) - parseInt(newArr[e.target.name].totalParticipant);
            let finelTotalJobCost = parseInt(this.state.finelTotalJobCost) - parseInt(newArr[e.target.name].totalJobCost);
            finelTotalJobCost = finelTotalJobCost + (parseInt(e.target.value) * newArr[e.target.name].cpc);
            finelTotalParticipants = finelTotalParticipants + parseInt(e.target.value)
            newArr[e.target.name].totalParticipant = parseInt(e.target.value);
            newArr[e.target.name].totalJobCost = parseInt(e.target.value) * newArr[e.target.name].cpc;
            this.setState({ allStudyListData: newArr, finelTotalComplete: finelTotalParticipants, finelTotalJobCost: finelTotalJobCost });
        }
    }

    handleChangeCPC(e) {
        let newArr = this.state.allStudyListData;
        if (e.target.value == '') {
            let finelTotalJobCost = parseInt(this.state.finelTotalJobCost) - parseInt(newArr[e.target.name].totalJobCost)
            newArr[e.target.name].cpc = 0;
            newArr[e.target.name].totalJobCost = 0;
            this.setState({ allStudyListData: newArr, finelTotalJobCost: finelTotalJobCost });
        } else if (e.target.value > 0) {
            let finelTotalJobCost = parseInt(this.state.finelTotalJobCost) - parseInt(newArr[e.target.name].totalJobCost);
            finelTotalJobCost = finelTotalJobCost + (parseInt(e.target.value) * newArr[e.target.name].totalParticipant);
            newArr[e.target.name].cpc = parseInt(e.target.value);
            newArr[e.target.name].totalJobCost = parseInt(e.target.value) * newArr[e.target.name].totalParticipant;
            this.setState({ allStudyListData: newArr, finelTotalJobCost: finelTotalJobCost });
        }
    }

    render() {

        var date = new Date();

        //let minDate = "2020-04-12";
        let yearOMonth = date.getFullYear();
        let monthOf = date.getMonth() + 1;


        let dayOf = date.getDate();

        if(monthOf<10)
        monthOf = "0"+monthOf;

        if(dayOf<10)
        dayOf = "0"+dayOf;
        let minDate = yearOMonth + "-"+monthOf+"-"+dayOf;


        let displayCountryListItem = "";
        if (this.state.countryList) {
            displayCountryListItem = this.state.countryList.map((country) =>
                <option key={country.country_id} value={country.country_id}>{country.countryName}</option>
            );
        }

        let showAllStudyDetails = ""
        if (this.state.allStudyListData) {
            showAllStudyDetails = this.state.allStudyListData.map((list, i) => {
                return <div className="formgroup">
                    <div class="formgroup-col-3  rltv-pos" >

                        <input className="form-controll " value={list.studyName} ></input>
                        {/* <div className="errordiv cus-width-6" id="error9" >{this.state.completeerror}</div> */}
                    </div>
                    <div class="formgroup-col-3  rltv-pos" >

                        <input className="form-controll " name={i} onChange={this.handleChangeNoOfComplete} value={list.totalParticipant}  ></input>
                        {/* <div className="errordiv cus-width-6" id="error10" >{this.state.cpcerror}</div> */}
                    </div>
                    <div class="formgroup-col-3  rltv-pos" >

                        <input className="form-controll " name={i} onChange={this.handleChangeCPC} value={list.cpc}  ></input>
                        {/* <div className="errordiv cus-width-3" id="error11" >{this.state.currencyerror}</div> */}
                    </div>
                    <div class="formgroup-col-3  rltv-pos" >

                        <input className="form-controll " onChange={this.handleChangeTotalJobCost} disabled={true} value={list.totalJobCost} ></input>
                        {/* <div className="errordiv cus-width-6" id="error12" >{this.state.joberror}</div> */}
                    </div>
                </div>
            });
        }

        return (
            <div style={{ marginTop: "2%" }}>
                <div className="table-st">
                    <div className="forms-rd-shadow" style={{ width: "100%", paddingBottom: "50px", backgroundColor: "#ffffff", borderRadius: "4px" }}>
                        <div className="addVendor-main-div">
                            <div className="form-title" style={{ textAlign: "center" }}>
                                <h4 className="rm-margin"> Create Invoice form </h4>
                                <p className="form-title-text"> Create new invoice for client </p>
                            </div>
                            <hr />
                            <div className="formgroup">
                                <div class="formgroup-col-3  rltv-pos" >
                                    <label>Study Name</label>
                                </div>
                                <div class="formgroup-col-3  rltv-pos" >
                                    <label> No. of Completes</label>
                                </div>
                                <div class="formgroup-col-3  rltv-pos" >
                                    <label>CPC</label>
                                </div>
                                <div class="formgroup-col-3  rltv-pos" >
                                    <label> Total Job Cost</label>
                                </div>
                            </div>
                            {showAllStudyDetails}

                            {/* <div className = "formgroup">
                                <div class="formgroup-col-4 rltv-pos" >
                                    <label>Study Id</label>
                                    <input className="form-controll " disabled={true} value={this.state.studyId} ></input>
                                    <div className="errordiv cus-width-7" id="error1" >{this.state.studyIderror}</div>
                                </div>
                                <div class="formgroup-col-7 rltv-pos" >
                                    <label>Study Name</label>
                                    <input className="form-controll " disabled={true} value={this.state.studyName}  ></input>
                                    <div className="errordiv cus-width-8" id="error2" >{this.state.studyNameerror}</div>
                                </div>
                            </div> */}

                            <div className="formgroup">

                                <div class="formgroup-col-4 rltv-pos" >
                                    <label>Currency</label>
                                    <select className="form-controll" value={this.state.currency} style={{ width: "103%", padding: "2px" }}  onChange={this.onCurrencyChange} >
                                        <option value="">Select Currency</option>
                                        <option >Euro</option>
                                        <option >Japanees Yen</option>
                                        <option >Hong Kong Doller</option>
                                        <option >U.S. Dollar</option>
                                    </select>
                                    <div className="errordiv cus-width-3" id="error11" >{this.state.currencyerror}</div>
                                </div>

                                <div class="formgroup-col-4 rltv-pos" >
                                    <label>Total No. of Completes</label>
                                    <input className="form-controll " value={this.state.finelTotalComplete} ></input>
                                    {/* <div className="errordiv cus-width-6" id="error9" >{this.state.completeerror}</div> */}
                                </div>

                                <div class="formgroup-col-4 rltv-pos" >
                                    <label> Total Job Cost</label>
                                    <input className="form-controll " disabled={true} value={this.state.finelTotalJobCost} ></input>
                                    {/* <div className="errordiv cus-width-6" id="error12" >{this.state.joberror}</div> */}
                                </div>
                            </div>

                            <div className="formgroup">
                                <div class="formgroup-col-4 rltv-pos">
                                    <label>Start Date</label>
                                    <input className="form-controll " disabled={true} value={this.state.startDate}></input>
                                    <div className="errordiv cus-width-7" id="error3" >{this.state.startDateerror}</div>
                                </div>

                                <div class="formgroup-col-4 rltv-pos">
                                    <label>Alternate Contact*</label>
                                    <input className="form-controll " disabled={true} value={this.state.clientAlternativeContact} ></input>
                                    <div className="errordiv cus-width-7" id="error4" >{this.state.altConterror}</div>
                                </div>

                                <div class="formgroup-col-4 rltv-pos " >
                                    <label>Country</label>
                                    <input className="form-controll " disabled={true} value={this.state.countryName} ></input>
                                    <div className="errordiv cus-width-7" id="error5" >{this.state.countryerror}</div>
                                </div>
                            </div>

                            <div className="formgroup">
                                <div class="formgroup-col-4  rltv-pos" >
                                    <label>Client E-mail Id</label>
                                    <input className="form-controll " name="clientEmail" onChange={this.onChange} value={this.state.clientEmail} ></input>
                                    <div className="errordiv cus-width-7" id="error6" >{this.state.emailerror}</div>
                                </div>
                                <div class="formgroup-col-4  rltv-pos" >
                                    <label> Client Phone Number </label>
                                    <input className="form-controll " disabled={true} value={this.state.clientMobile} ></input>
                                    <div className="errordiv cus-width-7" id="error7" >{this.state.clientconterror}</div>
                                </div>
                                <div class="formgroup-col-4  rltv-pos" >
                                    <label> Refrence Number </label>
                                    <input className="form-controll " name="refNumber" value={this.state.refNumber} onChange={this.onChange}></input>
                                    <div className="errordiv cus-width-7" id="error8" >{this.state.refnoerror}</div>
                                </div>
                            </div>

                            {/* <div className = "formgroup">
                                <div class="formgroup-col-3  rltv-pos" >
                                    <label>No. of Completes</label>
                                    <input className="form-controll " name="totalComplete" onChange={this.ontotalCompleteChange} value = {this.state.totalComplete} ></input>
                                    <div className="errordiv cus-width-6" id="error9" >{this.state.completeerror}</div>
                                </div>
                                <div class="formgroup-col-3  rltv-pos" >
                                    <label> CPC</label>
                                    <input className="form-controll " name="cpc" value = {this.state.cpc}  onChange={this.onCpcChange}></input>
                                    <div className="errordiv cus-width-6" id="error10" >{this.state.cpcerror}</div>
                                </div>
                                <div class="formgroup-col-3  rltv-pos" >
                                    <label>Currency</label>
                                    <select className="form-controll" value={this.state.currency} style={{width:"103%", padding: "2px"}} >
                                        <option value="">Select Currency</option>
                                        <option >Euro</option>
                                        <option >Japanees Yen</option>
                                        <option >Hong Kong Doller</option>
                                        <option >U.S. Dollar</option>
                                    </select>
                                    <div className="errordiv cus-width-3" id="error11" >{this.state.currencyerror}</div>
                                </div>
                                <div class="formgroup-col-3  rltv-pos" >
                                    <label> Total Job Cost</label>
                                    <input className="form-controll " disabled={true} value = { this.state.totalJobCost} ></input>
                                    <div className="errordiv cus-width-6" id="error12" >{this.state.joberror}</div>
                                </div>
                            </div> */}


                            <div className="formgroup">
                                <div class="formgroup-col-3  rltv-pos" >
                                    <label> Invoice Number</label>
                                    <input className="form-controll " name="invoiceNumber" value={this.state.invoiceNumber} onChange={this.onChange}></input>
                                    <div className="errordiv cus-width-6" id="error13" >{this.state.invoicenoerror}</div>
                                </div>
                                <div class="formgroup-col-3  rltv-pos" >
                                    <label>Invoice Date</label>
                                    <input type="date" min={minDate} name="invoiceDate" value={this.state.invoiceDate} className="form-controll " onChange={this.onChange} ></input>
                                    <div className="errordiv cus-width-6" id="error14" >{this.state.invoicedateerror}</div>
                                </div>
                                <div class="formgroup-col-3  rltv-pos" >
                                    <label>Invoice Paid</label>
                                    <input className="form-controll " name="invoicePaid" value={this.state.invoicePaid} onChange={this.onInvoicePaidChange} ></input>
                                    <div className="errordiv cus-width-6" id="error15" >{this.state.invoicepaiderror}</div>
                                </div>
                                <div class="formgroup-col-3  rltv-pos" >
                                    <label>Payment Date</label>
                                    <input type="date" min={minDate} name="paymentDate" value={this.state.paymentDate} className="form-controll " onChange={this.onChange} ></input>
                                    <div className="errordiv cus-width-6" id="error16" >{this.state.paymentdateerror}</div>
                                </div>
                            </div>

                            <div className="formgroup addVendorCheckbox">
                                <button onClick={this.addInvoice} className="list-btn ">Create Invoice</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



import React, { Component } from 'react';
import StudyUpdate from '../../components/Study/studyUpdate';
import APIProjectsListHeader from '../../components/StudySurvey/apiProjectsListHeader';
import RedirectsUrl from '../../components/Study/studyRedirects';
import StudyProgress from '../../components/Study/studyProgress';
import {
    getProgressReportDataByApiStudy, getDefaultVendor, sendInvitationToVendors,
    initialiseInvitationRes, getQuotaOfApiStudy
} from '../../actions/actionsBag/study';
import $ from 'jquery';
import './tempstyle.css';
import { connect } from 'react-redux';
import appConfig from '../../api/apiConfig';
import ApiStudyUpdate from '../../components/StudySurvey/ApiStudyUpdate';
import ApiRedirectUrl from '../../components/StudySurvey/apiStudyRedirects';
import ApiStudyProgress from '../../components/StudySurvey/apiStudyProgress';
import ApiStudyQuota from '../../components/StudySurvey/apiStudyQuota';
import { alertMessage } from '../../alertsDialog/Action';

class ApiProjectListUpdate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editstudyData: "",
        }
        this.selectActivity = this.selectActivity.bind(this);
        this.gotoBack = this.gotoBack.bind(this);
        this.participantAsExcelBystudy = this.participantAsExcelBystudy.bind(this);
        this.sendInvitationToVendors = this.sendInvitationToVendors.bind(this);
    }

    componentWillMount() {
        if (this.props.location.state === undefined) {
            this.props.history.push("error");
        } else {

            const { editstudyData } = this.props.location.state;
            this.setState({
                editstudyData: editstudyData
            });
            localStorage.setItem("edit_study_id", editstudyData.survey_id);
            localStorage.setItem("edit_study_name", editstudyData.survey_name);
            this.props.dispatch(getProgressReportDataByApiStudy(editstudyData.survey_id));
            this.props.dispatch(getQuotaOfApiStudy(editstudyData.survey_id));
            this.props.dispatch(getDefaultVendor(editstudyData.survey_id));

            if (editstudyData.status === "completed") {
                localStorage.setItem("edit_study_data", "stop");
            } else {
                localStorage.removeItem("edit_study_data");
            }
        }
    }

    componentDidMount() {


        $(document).ready(function () {
            $("#tg-btn-1").click(function () {
                $("#show-form-1").toggleClass("show-full-div");
                $("#rotate-icon-1").toggleClass("rotate-tgl");
            });

            $("#tg-btn-2").click(function () {

                $("#show-form-2").toggleClass("show-full-div");
                $("#hide-me").toggleClass("tg-hide");
                $("#rotate-icon-2").toggleClass("rotate-tgl");
            });

            $("#tg-btn-3").click(function () {
                $("#show-form-3").toggleClass("show-full-div");
                $("#pro-tbl-hide-me").toggleClass("pro-tbl-hide");
                $("#rotate-icon-3").toggleClass("rotate-tgl");

            });
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.invitationVendorResp && nextProps.invitationVendorResp.success === true) {
            this.props.dispatch(initialiseInvitationRes());
            let alertData = {};
            alertData.type = "alert";
            alertData.message = nextProps.invitationVendorResp.message;
            this.props.dispatch(alertMessage(alertData));
        } else {
            if (nextProps.invitationVendorResp && nextProps.invitationVendorResp.success === false && nextProps.invitationVendorResp.message) {
                this.props.dispatch(initialiseInvitationRes());
                let alertData = {};
                alertData.type = "alert";
                alertData.message = nextProps.invitationVendorResp.message;
                this.props.dispatch(alertMessage(alertData));
            }
        }

    }

    selectActivity(activityName) {
        this.props.history.push(activityName);
    }

    gotoBack() {
        window.history.back();
    }

    participantAsExcelBystudy(status, type){
        if(type === true){
            window.open(appConfig.siteURL + 'participantAsExcelByStudy/'+ this.state.editstudyData._id+"/"+status+"/test");
  
        } else{
            window.open(appConfig.siteURL + 'participantAsExcelByStudy/'+ this.state.editstudyData._id+"/"+status+"/live");
        }
    }
    sendInvitationToVendors() {

        this.props.dispatch(sendInvitationToVendors(this.state.editstudyData.survey_id));
    }

    render() {
        return (
            <div>
                <APIProjectsListHeader
                    selectActivity={this.selectActivity}
                    editstudyData={this.state.editstudyData}
                    gotoBack={this.gotoBack}
                    sendInvitationToVendors={this.sendInvitationToVendors}
                />
                <div className="mr-top-20">
                    <div className="create-form-container">
                        <div className="forms-rd-shadow toggle-form show-me" id="show-form-1">
                            <ApiStudyUpdate
                                editstudyData={this.state.editstudyData}
                            />
                        </div>
                    </div>
                </div>

                <div className="create-form ">
                    <div className="create-form-container ">
                        <div className="forms-rd-shadow toggle-form show-me" id="show-form-2">
                            <ApiRedirectUrl
                                editstudyData={this.state.editstudyData}
                                defaultVendorSuccess={this.props.defaultVendorSuccess}
                                defaultVendorId={this.props.defaultVendorId}
                            />
                        </div>
                    </div>
                </div>

                <div className="create-form  ">
                    <div className="create-form-container ">
                        <div className="forms-rd-shadow toggle-form show-me" id="show-form-3">
                            <ApiStudyProgress
                                updateStudyProgressData={this.props.updateStudyProgressData}
                                participantAsExcelBystudy={this.participantAsExcelBystudy}
                            />
                        </div>
                    </div>
                </div>

                {/* // api project quota */}
                <div className="create-form  ">
                    <div className="create-form-container ">
                        <div className="forms-rd-shadow toggle-form show-me" id="show-form-4">
                            {
                                this.props.updateQuotaApiStudy ?
                                    <ApiStudyQuota updateQuotaApiStudy={this.props.updateQuotaApiStudy}
                                        updateStudyProgressData={this.props.updateStudyProgressData} />
                                    : ''
                            }

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        updateStudyProgressData: state.studies.updateSurveyStudyProgressData,
        updateQuotaApiStudy: state.studies.updateQuotaApiStudy,
        defaultVendorSuccess: state.studies.defaultVendorSuccess,
        defaultVendorId: state.studies.defaultVendorId,
        invitationVendorResp: state.studies.invitationVendorResp
    }
}

export default connect(mapStateToProps)(ApiProjectListUpdate);
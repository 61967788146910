import React, { Component }  from 'react';
import AddDemoMapList from '../../../components/ProjectActivities/DemoMapping/addDemoMap';
import $ from 'jquery';
import { alertMessage }  from '../../../alertsDialog/Action';
import { connect } from 'react-redux';
import UpdateStudyHeader from '../../../components/Study/updateStudyHeader';


export class AddDemoMapped extends Component {
    constructor(props) {
        super(props)
        this.state = {
            study_id : ""
        }
        this.selectActivity = this.selectActivity.bind(this);
     
    }

    

    // componentDidMount(){
    //     $(document).ready(function(){
    //         $("#tg-btn-4").click(function(){
    //         $("#show-form-4").toggleClass("show-full-div");
    //         $("#map-hide-1").toggleClass("box-hide-1");
    //         $("#rotate-icon-4").toggleClass("rotate-tgl");
    //         });

    //         $("#tg-btn-5").click(function(){

    //          $("#show-form-5").toggleClass("show-full-div");
    //          $("#map-hide-2").toggleClass("box-hide-2");
    //          $("#rotate-icon-5").toggleClass("rotate-tgl");
    //         });

    //     });
    // }



    selectActivity(activityName){
        this.props.history.push(activityName);
    }

    gotoBack(){
        window.history.back();
    }

    

    render(){
        return(
            <div>
                
              
                <UpdateStudyHeader
                    selectActivity = {this.selectActivity}
                    editstudyData = {this.state.editstudyData}
                    gotoBack = {this.gotoBack}
                />

                <AddDemoMapList />

            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        
    }
  }
  
  export default connect(mapStateToProps)(AddDemoMapped);
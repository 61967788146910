import React, { Component }  from 'react';
import { connect } from 'react-redux';
import InvoiceHeader from '../../components/Invoice/invoiceHeader';
import InvoiceListTable from '../../components/Invoice/InvoiceList';
import add from '../../images/add.png';
import {Link} from 'react-router-dom';

class Invoice extends Component {

        constructor(props) {
            super(props)
            this.state = {

            }
           
        }

   
    render(){
        const header = 'Invoice List';
        const headerText = 'Here you can see all invoices';
        const headerButton = "";
        // const headerButton = <Link to="/create-invoice"><button className="create-btn create-btn-color"><img src={add} height="12px" width="12px"/><span> Create Invoice</span></button></Link>

        return(
            <div style={{ width:"100%"}}>  
                <InvoiceHeader
                   header = {header}
                   headerText = {headerText}
                   headerButton = {headerButton}
                />
                <InvoiceListTable />
                
            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        thirdPartyList : state.thirdParty.thirdPartyList,
        totalpages : state.thirdParty.totalpages
    }
  }
  
  export default connect(mapStateToProps)(Invoice);
import React, { Component }  from 'react';
import '../../commonStyle/common.css';
import ClientHeader from './clientHeader.jsx';
import UpdateClientHeader from './updateClientHeader.jsx';
import goback from '../../images/goback.png';
import { updateClient, emptyClientProps, getAllCountryList} from '../../actions/actionsBag/clientAction';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { alertMessage }  from '../../alertsDialog/Action';

export class UpdateClientDiv extends Component {
    
    constructor(props) {
    
        super(props)
        this.state = {
            name : "",
            email : "",
            mobile : "",
            alterContact : "",
            company : "",
            lineOne : "",
            lineTwo : "",
            city : "",
            stateName : "",
            country : "",
            pincode : "",
            countryList : [],
            clientId : "",
            imageUrl : "",
            editClientData : null
        }
        this.onNameChange = this.onNameChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onMobileChange = this.onMobileChange.bind(this);
        this.onAlternateContactChange = this.onAlternateContactChange.bind(this);
        this.onCompanyChange = this.onCompanyChange.bind(this);
        this.onLineOneChange = this.onLineOneChange.bind(this);
        this.onLineTwoChange = this.onLineTwoChange.bind(this);
        this.onCityChange = this.onCityChange.bind(this);
        this.onStateChange = this.onStateChange.bind(this);
        this.onCountryChange = this.onCountryChange.bind(this);
        this.onPinCodeChange = this.onPinCodeChange.bind(this);
        this.updateClient = this.updateClient.bind(this);
      }
      
      componentWillMount(){
        this.props.dispatch(getAllCountryList());
        if( this.props.location.state === undefined){
            this.props.history.push("error");
        }else{
    
        const { editClientData } = this.props.location.state;
        
        this.setState({
            name : editClientData.name,
            email : editClientData.e_mail,
            mobile : editClientData.mobile,
            company : editClientData.company_name,
            alterContact : editClientData.alternate_mobile,
            lineOne : editClientData.residence_add_1,
            lineTwo : editClientData.residence_add_2,
            city : editClientData.residence_city,
            stateName : editClientData.residence_state,
            country : editClientData.residence_country,
            pincode : editClientData.residence_pin_code,
            clientId : editClientData.client_id,
            imageUrl : editClientData.imageUrl,
            editClientData : editClientData
          });
        }
      }

      componentWillReceiveProps(nextProps){

        if(nextProps.countryList && nextProps.countryList.success && nextProps.countryList.countryList.length>0 ){
            this.setState({ countryList : nextProps.countryList.countryList });
          }else if(nextProps.countryList && nextProps.countryList.success == false && nextProps.countryList.message){
            alert(nextProps.countryList.message);
          }

        if(nextProps.clientUpdateSuccess === true && nextProps.clientUpdateMessage){
          let alertData ={};
          alertData.type ="alert";
          alertData.message =  nextProps.clientUpdateMessage;
        this.props.dispatch(alertMessage(alertData));
            this.props.history.push("/client-list");
            this.props.dispatch(emptyClientProps());
        }else if(nextProps.clientUpdateMessage){
          let alertData ={};
          alertData.type ="alert";
          alertData.message =  nextProps.clientUpdateMessage;
          this.props.dispatch(alertMessage(alertData));
          this.props.dispatch(emptyClientProps());
        }

        
      }

      onNameChange(e){
        if(e.target.value !== "")
          $("#error1").removeClass("showerror");
      
          this.setState({
              name : e.target.value
          });
      }
  
      onEmailChange(e){
        if(e.target.value !== "")
          $("#error2").removeClass("showerror");
      
          this.setState({
              email : e.target.value
          });
      }
  
      onMobileChange(e){
          if(e.target.value !== "")
          $("#error3").removeClass("showerror");
      
            let validNum = /^[0-9]+$/;
            if(e.target.value.match(validNum)){
                    this.setState({ mobile: e.target.value});
            }else if(e.target.value === ""){
                this.setState({ mobile : ""});
            }
      }
  
      onAlternateContactChange(e){
          if(e.target.value !== "")
          $("#error4").removeClass("showerror");
      
            let validNum = /^[0-9]+$/;
            if(e.target.value.match(validNum)){
                    this.setState({ alterContact: e.target.value});
            }else if(e.target.value === ""){
                this.setState({ alterContact : ""});
            }
      }
  
      onCompanyChange(e){
        if(e.target.value !== "")
        $("#error5").removeClass("showerror");
           
        this.setState({
          company : e.target.value
      });
    }
      
      onLineOneChange(e){
        if(e.target.value !== "")
        $("#error6").removeClass("showerror");
          this.setState({
              lineOne : e.target.value
          });
      }
  
      onLineTwoChange(e){
        if(e.target.value !== "")
        $("#error7").removeClass("showerror");
          this.setState({
              lineTwo : e.target.value
          });
      }
  
      onCityChange(e){
        if(e.target.value !== "")
        $("#error8").removeClass("showerror");
          this.setState({
              city : e.target.value
          });
      }
  
      onStateChange(e){
        if(e.target.value !== "")
        $("#error9").removeClass("showerror");
          this.setState({
            stateName : e.target.value
          });
      }
  
      onCountryChange(e){
        if(e.target.value !== "")
        $("#error10").removeClass("showerror");
          this.setState({
              country : e.target.value
          });
      }
      
      onPinCodeChange(e){
       if(e.target.value !== "")
      $("#error11").removeClass("showerror");
  
        let validNum = /^[0-9]+$/;
        if(e.target.value.match(validNum)){
                this.setState({ pincode: e.target.value});
        }else if(e.target.value === ""){
            this.setState({ pincode : ""});
        }
      }
  
      handleValidation(){
        let valid = true;
  
        if(this.state.name === ""){
          valid = false;
          $("#error1").addClass("showerror");
            this.setState({
              clientnameerror: "Enter client name"
            });
        }
        
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        let tempemail = this.state.email;
  
        if(this.state.email === ""){  // alert("email");
          valid = false;
          $("#error2").addClass("showerror");
          this.setState({
            emailerror: "Enter correct email"
      });
    }else if(!pattern.test(tempemail)){
      valid = false;
      $("#error2").addClass("showerror");
      this.setState({
        emailerror: "Enter correct email"
      });
    }


    if(this.state.mobile === ""){
      valid = false;
       $("#error3").addClass("showerror");
      this.setState({
        mobileerror: "Enter mobile number"
      });
    }else if(isNaN(this.state.mobile)){
      valid = false;
      $("#error3").addClass("showerror");
      this.setState({
        mobileerror: "Number only"
      });
    }

    if(this.state.alterContact === ""){
      valid = false;
      $("#error4").addClass("showerror");
      this.setState({
        altmobileerror: "Enter aleternate contact"
      });
    }else if(isNaN(this.state.alterContact)){
      valid = false;
      $("#error4").addClass("showerror");
      this.setState({
        altmobileerror: "Number only"
      });
    }


    if(this.state.company === ""){
      valid = false;
      $("#error5").addClass("showerror");
      this.setState({
        companyerror: "Enter company name"
      });
    }

    if(this.state.lineOne === ""){
      valid = false;
      $("#error6").addClass("showerror");
      this.setState({
        lineoneerror: "Enter address line one"
      });
    }



      if(this.state.lineTwo === ""){
        valid = false;
        $("#error7").addClass("showerror");
        this.setState({
          linetwoerror: "Enter address line two"
        });
      }

      if(this.state.city === ""){
        valid = false;
        $("#error8").addClass("showerror");
        this.setState({
          cityerror: "Enter city name"
        });
      }

      if(this.state.stateName === ""){
        valid = false;
        $("#error9").addClass("showerror");
        this.setState({
          stateerror: "Enter state name"
        });
      }

      if(this.state.country === ""){
        valid = false;
        $("#error10").addClass("showerror");
        this.setState({
          countryerror: "Enter country name"
        });
      }

     

      if(this.state.pincode === ""){
        valid = false;
        $("#error11").addClass("showerror");
        this.setState({
          pinerror: "Enter pincode"
        });
      }else if(isNaN(this.state.pincode)){
        valid = false;
        $("#error11").addClass("showerror");
        this.setState({
          pinerror: "Enter only number"
        });
      }

     return valid;
      }
  
      updateClient(){
        if(this.handleValidation()){
          let data = {};
          data.name = this.state.name;
          data.email = this.state.email;
          data.mobile = this.state.mobile;
          data.alternateMobile = this.state.alterContact;
          data.company = this.state.company;
          data.raddone = this.state.lineOne;
          data.raddtwo = this.state.lineTwo;
          data.rcity = this.state.city;
          data.rstate = this.state.stateName;
          data.rcountry = this.state.country;
          data.rpincode = this.state.pincode;
          data.clientId = this.state.clientId;
          data.userid = localStorage.getItem("auth_user_id");
          this.props.dispatch(updateClient(data));
        }
    }


    render(){

    const header = 'Update Client';
    const headerText = 'Here you can update client ';
    const headerButton = <Link to="/client-list"> <button className="back-btn back-btn-color" ><img src={goback} height="13px" width="13px" /><span> Back</span></button></Link>
    const headerButtonTwo = <Link 
    to = {{      
      pathname: '/update-client-logo',
      state: {
        imageUrl : this.state.imageUrl,
        clientId : this.state.clientId,
        editClientData : this.state.editClientData
        }
    }}> <button className="back-btn back-btn-color" ><span> Update Logo</span></button></Link>


    let displayCountryListItem = "";
    if(this.state.countryList){
      displayCountryListItem = this.state.countryList.map((country) =>       
        <option key={country.country_id} value ={country.country_id}>{country.countryName}</option>
          );
    }

        return(

            <div style={{ width:"100%"}}>  
                <UpdateClientHeader 
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                    headerButtonTwo = {headerButtonTwo}
                />
            <div style={{marginTop: "2%",marginBottom: "50px"}}>
                <div className="table-st">
                    <div style={{ width: "100%",paddingBottom:"20px", backgroundColor:"#ffffff", borderRadius:"4px" }}>
                    <div className="addVendor-main-div">
                            <div className="addVendor-main-div-2 formgroup-pdding">
                                <h5> Update client form </h5>
                            </div>
                            <hr/>
                        
                            <div className = "formgroup">
                                <div class="formgroup-col-6 rltv-pos">
                                    <label>Name</label>
                                    <input className="form-controll "  name="Name" placeholder="Name" value={this.state.name} onChange={this.onNameChange}></input>
                                    <div className="errordiv cus-width-2" id="error1" >{this.state.clientnameerror}</div>
                                </div>
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>E-mail Id</label>
                                    <input className="form-controll " name="email" placeholder="Email" value={this.state.email} onChange={this.onEmailChange}></input>
                                    <div className="errordiv cus-width-2" id="error2" >{this.state.emailerror}</div>
                                </div>
                            </div>

                            <div className = "formgroup">
                                <div class="formgroup-col-3 rltv-pos" >
                                    <label>Mobile Number*</label>
                                    <input className="form-controll " name="mobile number" placeholder="Mobile Number" value={this.state.mobile} onChange={this.onMobileChange}></input>
                                    <div className="errordiv cus-width-1" id="error3" >{this.state.mobileerror}</div>
                                </div>
                                <div class="formgroup-col-3 rltv-pos" >
                                    <label>Alternate Contact*</label>
                                    <input className="form-controll " name="altercontact" placeholder="Alternate Contact" value={this.state.alterContact} onChange={this.onAlternateContactChange}></input>
                                    <div className="errordiv cus-width-1" id="error4" >{this.state.altmobileerror}</div>
                                </div>
                                
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>Company Name*</label>
                                    <input className="form-controll "  name="companyname" placeholder="Company name" value={this.state.company} onChange={this.onCompanyChange}></input>
                                    <div className="errordiv cus-width-2" id="error5" >{this.state.companyerror}</div>
                                </div>
                            </div>

                            <div className = "formgroup">
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>Address Line One*</label>
                                    <input className="form-controll " name="lineone" placeholder="Address line one" value={this.state.lineOne} onChange={this.onLineOneChange}></input>
                                    <div className="errordiv cus-width-2" id="error6" >{this.state.lineoneerror}</div>
                                </div>
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label> Address Line Two*</label>
                                    <input className="form-controll " name="linetwo" placeholder="Address line two" value={this.state.lineTwo} onChange={this.onLineTwoChange}></input>
                                    <div className="errordiv cus-width-2" id="error7" >{this.state.linetwoerror}</div>
                                </div>
                            </div>

                            <div className = "formgroup">
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>City*</label>
                                    <input className="form-controll " name="city" placeholder="City" value={this.state.city} onChange={this.onCityChange}></input>
                                    <div className="errordiv cus-width-2" id="error8" >{this.state.cityerror}</div>
                                </div>
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label> State*</label>
                                    <input className="form-controll " name="sate" placeholder="State" value={this.state.stateName} onChange={this.onStateChange}></input>
                                    <div className="errordiv cus-width-2" id="error9" >{this.state.stateerror}</div>
                                </div>
                            </div>


                            <div className = "formgroup">
                                <div class="formgroup-col-6 rltv-pos">
                                    <label>Country*</label>
                                    <select className="form-controll" style={{width:"100%", padding: "2px"}}   name="country"  value={this.state.country} onChange={this.onCountryChange}>
                                    <option  value = ""> Select Country</option>
                                        {displayCountryListItem}
                                    </select>
                                    <div className="errordiv cus-width-2" id="error10" >{this.state.countryerror}</div>
                                </div>

                               


                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>Pin Code*</label>
                                    <input className="form-controll " name="pincode" placeholder="Pincode" value={this.state.pincode}  onChange={this.onPinCodeChange}></input>
                                    <div className="errordiv cus-width-2" id="error11" >{this.state.pinerror}</div>
                                </div>
                            </div>

                            <div className = "formgroup addVendorCheckbox">
                                <button onClick={this.updateClient} className="list-btn" style={{marginTop:"20px"}}>Update Client</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )}
}


const mapStateToProps=(state)=>{
    return{
        clientUpdateSuccess : state.client.clientUpdateSuccess,
        clientUpdateMessage : state.client.clientUpdateMessage,
        countryList : state.client.countryList,
    }
  }
  export default connect(mapStateToProps)(UpdateClientDiv);
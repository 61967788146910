import {combineReducers} from 'redux';
// import member from './member';
 import thirdParty from '../reducers/reducerbag/vendor';
// import club from './club';
 import demographics from './reducerbag/Demographics';
import studies from '../../src/reducers/reducerbag/study';
// import demographics from '../components/Demos/Reducer';
import login from '../pages/Login/reducer';
 import dashboard from '../reducers/dashboard';
// import quota from '../components/Constraints/Reducer';
import spinner from '../loadingSpinner/Reducer';
import alertsDialog from '../alertsDialog/Reducer';
 import users from '../reducers/reducerbag/users';
 import roles from './reducerbag/roles';
 import client  from '../reducers/reducerbag/clientReducer';
 import invoice from '../reducers/reducerbag/invoiceReducer';
 import demoMapped from '../reducers/reducerbag/DemoMap';
const rootReducer = combineReducers({
    // member,
    // club,
    demographics,
    thirdParty,
    studies,
    // demographics,
    login,
     dashboard,
    // quota,
     spinner,
     alertsDialog,
     users,
     roles,
     client,
     invoice,
     demoMapped
});

export default rootReducer;

import { getAllInvoicedApi, getAllReadyInvoicesApi, invoiceCreateApi,
   approvedAllSelectedInvoicedApi, sendInvoiceEmailApi, deleteInvoiceApi,
    getInvoiceByIdApi, generateFinalBillApi, sendInvoiceApi } from "../../api/apiBag/invoiceApi";
    import {startSpinner, endSpinner} from "../../loadingSpinner/Action";


// setInvoiced
export const setInvoiced = (invoicedResult) => {
    return{
      type : "SET_INVOICED",
      invoicedResult
    }
  }

//   setCreateInvoice
export const setCreateInvoice = (invoiceResult) => {
    return{
      type : "SET_CREATE_INVOICE",
      invoiceResult
    }
  }


  //invoiceCreateShowRes
export const invoiceCreateShowRes =(response) => {
    return {
      type : "SET_RES_ALERT_CREATE_INVOICE",
      response
    }
  }

  //   setRedyInvoiceList
export const setRedyInvoiceList =(readyInvoice) => {
    return {
      type : "SET_READY_INVOICE_LIST",
      readyInvoice
    }
  }

  //emptyResponseInvoiced
  export const emptyResponseInvoiced = () => {
    return{
      type : "SET_EMPTY_INVOICE_RESPONSE"
    }
  }

 
  // setDeleteInvoice
  export const setDeleteInvoice =(invoiceDeleteResult) => {
    return {
      type : "SET_DELETE_INVOICE",
      invoiceDeleteResult
    }
  }


  // setDeleteInvoiceFail
  export const setDeleteInvoiceFail =(invoiceDeleteResult) => {
    return {
      type : "SET_DELETE_INVOICE_FAIL",
      invoiceDeleteResult
    }
  }


 // setApprovedInvoicedList
 export const setApprovedInvoicedList =(invoiced) => {
  return {
    type : "SET_APPROVED_INVOICED_LIST",
    invoiced
  }
}

 // setInvoiceReciept
 export const setInvoiceReciept =(invoiceResult) => {
  return {
    type : "SET_INVOICE_RECIEPT",
    invoiceResult
  }
}

  // setFinalInvoiced
  export const setFinalInvoiced =(invoiceUpdate) => {
    return {
      type : "SET_FINAL_INVOICED",
      invoiceUpdate
    }
  }

  export const setDeleteInvoiceFromInvoice =(invoiceDeleteResult) => {
    return {
      type : "SET_DELETE_INVOICE_FROM_INVOICE",
      invoiceDeleteResult
    }
  }


  // setDeleteInvoiceFail
  export const setDeleteInvoiceFailFromInvoice =(invoiceDeleteResult) => {
    return {
      type : "SET_DELETE_INVOICE_FAIL_FROM_INVOICE",
      invoiceDeleteResult
    }
  }


  // setInvoiceMail
export const setInvoiceMail =(invoieMail) => {
  return {
    type : "SET_INVOICE_MAIL",
    invoieMail
  }
}















// getAllInvoiced
export const getAllInvoiced = (page) => {

  return dispatch => {
    dispatch(startSpinner());
    getAllInvoicedApi(page).then((result) => {
      dispatch(endSpinner());
          dispatch(setInvoiced(result));
      // else{
      //   dispatch(setLoginFail(result));
      // }
    });
  }
}

// getAllReadyInvoices
export const getAllReadyInvoices = (page) => {
  
    return dispatch => {
      dispatch(startSpinner());
        getAllReadyInvoicesApi(page).then((result) => {
          dispatch(endSpinner());
            dispatch(setCreateInvoice(result));
        // else{
        //   dispatch(setLoginFail(result));
        // }
      });
    }
  }

// invoiceCreate
  export const invoiceCreate = (Data) => {
    return dispatch => {
      dispatch(startSpinner());
     invoiceCreateApi(Data).then((result) => {
      dispatch(endSpinner());
      dispatch(invoiceCreateShowRes(result));
      });
    }
  }

  
  //approvedAllSelectedInvoiced
  export const approvedAllSelectedInvoiced = (Data) => {
    return dispatch => {
      dispatch(startSpinner());
     approvedAllSelectedInvoicedApi(Data).then((result) => {
      dispatch(endSpinner());
       dispatch(setApprovedInvoicedList(result));
      });
    }
  }


  // sendInvoice
  export const sendInvoice = (invoiceID) => {
    return dispatch => {
      dispatch(startSpinner());
     sendInvoiceApi(invoiceID).then((result) => {
      dispatch(endSpinner());
        if(result.success){
          dispatch(setInvoiceMail(result));
        }else{
          

        }
        
      });
    }
  }

    // sendInvoiceEmail
    export const sendInvoiceEmail = (approvedInvoices) => {
      return dispatch => {
        dispatch(startSpinner());
       sendInvoiceEmailApi(approvedInvoices).then((result) => {
        dispatch(endSpinner());
          if(result.success){
          }else{
            
  
          }
          
        });
      }
    }

      // deleteInvoice
  export const deleteInvoice = (invoiceID) => {
    return dispatch => {
    
     deleteInvoiceApi(invoiceID).then((result) => {
        
        if(result.success){
          dispatch(setDeleteInvoice(result));
        }else{
          dispatch(setDeleteInvoiceFail(result));

        }
        
      });
    }
  }


   // getInvoiceById
   export const getInvoiceById = (invoiceID) => {
    return dispatch => {
      dispatch(startSpinner());
     getInvoiceByIdApi(invoiceID).then((result) => {
      dispatch(endSpinner());
        if(result.success){
          dispatch(setInvoiceReciept(result));
        }
        
      });
    }
  }


    // generateFinalBill
    export const generateFinalBill = (invoiceID) => {
      return dispatch => {
        dispatch(startSpinner());
       generateFinalBillApi(invoiceID).then((result) => {
        dispatch(endSpinner());
          //if(result.success){
            dispatch(setFinalInvoiced(result));
          //}
        });
      }
    }

    // deleteInvoice
    export const deleteInvoiceFromInvoice = (invoiceID) => {
      return dispatch => {
        dispatch(startSpinner());
       deleteInvoiceApi(invoiceID).then((result) => {
        dispatch(endSpinner());
          if(result.success){
            dispatch(setDeleteInvoiceFromInvoice(result));
          }else{
            dispatch(setDeleteInvoiceFailFromInvoice(result));
  
          }
          
        });
      }
    }
export function appInit(store) {
  return dispatch => {

    console.log(store);
  //  dispatch(startInit())
    // return EmSession.init(store)
    //   .then(response => {
    //    //dispatch(endInit(response.sessionInfo))
    //  })
    //   .catch((err) => { console.log(err) })
  }
}

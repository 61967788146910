import React, { Component }  from 'react';
import '../../commonStyle/common.css';
import './dashboardStyle.css';
import './commonStyle.css';
import orangeDobleClick  from '../../images/orange-double-click.png';
import greenDoubleClick from '../../images/green-double-click.png';
import redDoubleClick from '../../images/red-double-click.png';
import blueDoubleClick from '../../images/blue-double-click.png'
export default class DashboardCountDiv extends Component {

    constructor(props){
        super(props)
        this.state={
            dayCount : "0",
            monthCount : "0",
            yearCount : "0",
            studyCompletePerYear :"0"
            
        }
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.participantCount) {
          if(nextProps.participantCount.dataPerdata != null || nextProps.participantCount.dataPerdata != undefined){
            this.setState({
              dayCount: nextProps.participantCount.dataPerdata,
              monthCount: nextProps.participantCount.dataPerMonth,
              yearCount : nextProps.participantCount.dataPerYear,
              studyCompletePerYear : nextProps.participantCount.studyCompletePerYear,
            });
          }
        }
      }
    

    render(){
        return(
            <div style={{marginTop: "2%"}}>
                <div className="table-st " id ="all-tbl">

                <div className="parent-layer" >
                    {/* first count */}
                    <div className="middle-layer " >
                        {/* first count 1st */}
                        <div className="dashshadow bottom-layer layer-r-mr forms-rd-shadow white-back">
                            <div id="dashboard-card">
                                <div id="dashboard-card-icon" className="dashboard-card-icon-success">
                                    <img style={{ verticalAlign: "middle", width: "1.5em"}} src={greenDoubleClick}/>
                                </div>
                                <div id="dashboard-card-text" >
                                <p className="para-st-font"> Complete of the day</p>
                                    <h4>{this.state.dayCount}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="dashshadow bottom-layer white-back forms-rd-shadow" >
                            <div id="dashboard-card">
                                <div id="dashboard-card-icon" className="dashboard-card-icon-danger">
                                    <img style={{ verticalAlign: "middle", width: "1.5em"}} src={redDoubleClick}/>
                                </div>
                                <div id="dashboard-card-text" >
                                <p className="para-st-font"> Complete of the month</p>
                                    <h4>{this.state.monthCount}</h4>
                                </div>
                            </div>
                        </div>
                        {/* first count 1st */}
                    </div>
                    {/* first count */}
                    {/* Second count */}
                    <div className="middle-layer">
                        {/* second count 2nd */}
                        <div  className="dashshadow bottom-layer layer-r-mr forms-rd-shadow white-back">
                            <div id="dashboard-card">
                                <div id="dashboard-card-icon" className="dashboard-card-icon-warning">
                                <img style={{ verticalAlign: "middle", width: "1.5em"}} src={orangeDobleClick}/>
                                </div>
                                <div id="dashboard-card-text" >
                                <p className="para-st-font"> Complete of the year</p>
                                    <h4>{this.state.yearCount}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="dashshadow bottom-layer white-back forms-rd-shadow">
                            <div id="dashboard-card">
                                <div id="dashboard-card-icon" className="dashboard-card-icon-primary">
                                    <img style={{ verticalAlign: "middle", width: "1.5em"}} src={blueDoubleClick}/>
                                </div>
                                <div id="dashboard-card-text" >
                                <p className="para-st-font"> Complete Projects</p>
                                    <h4>{this.state.studyCompletePerYear}</h4>
                                </div>
                            </div>
                        </div>
                        {/* second count 2nd */}
                    </div>
                     {/* Second count */}
                </div>

                   
            </div>
        </div>
    )}
}
import React, { Component }  from 'react';
import { connect } from 'react-redux';
import DemographicHeader from '../../components/Demographics/demographicHeader.jsx';
import UpdateDemographicDiv from '../../components/Demographics/updateDemographics.jsx';
import goback from '../../images/goback.png';
import { alertMessage }  from '../../alertsDialog/Action';
import { updateDemo, emptyQuestionUpdateRes} from '../../actions/actionsBag/Demographic';
import {Link} from 'react-router-dom';

    class UpdateDemographicView extends Component {

        constructor(props) {
            super(props)
            this.state = {
                editDemographicsData : ""
            }
            this.updateDemoAndQuery = this.updateDemoAndQuery.bind(this);
            this.updateDemo= this.updateDemo.bind(this);
          }

          componentWillMount(){
            if( this.props.location.state === undefined){
                this.props.history.push("error");
            }else{
        
            const { editDemographicsData } = this.props.location.state;
               
            this.setState({
                editDemographicsData : editDemographicsData
              });
            }
          }

    componentWillReceiveProps( nextProps){

        if(nextProps.updateDemoRes && nextProps.updateDemoRes.success && nextProps.updateDemoRes.message){
            this.alertMessage( nextProps.updateDemoRes.message );
            this.props.history.push('/demographics-list');
        }else if( nextProps.updateDemoRes && (nextProps.updateDemoRes.success === false) && nextProps.updateDemoRes.message){
            this.alertMessage( nextProps.updateDemoRes.message );
        }
    }

    alertMessage( message){
        this.props.dispatch(emptyQuestionUpdateRes());
        let alertData ={};
        alertData.type ="alert";
        alertData.message = message;
        this.props.dispatch(alertMessage(alertData));
    }

    updateDemoAndQuery( demoId, demoName){
        this.props.history.push({
            pathname : '/Update-query-option',
            state : { updateDemoID : demoId, updateDemoName : demoName }
        });
    }

    updateDemo(DemoData, demo_id ){
        this.props.dispatch(updateDemo(DemoData, demo_id))
    }

    render(){
        const header = 'Update Demographic';
        const headerText = 'Here you can update demographic';
        const headerButton = <Link to="/demographics-list"> <button className="back-btn back-btn-color" ><img src={goback} height="13px" width="13px" /><span> Back</span></button></Link>

        return(
            <div style={{ width:"100%"}}>  
                <DemographicHeader 
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                />
                <UpdateDemographicDiv
                    editDemographicsData = {this.state.editDemographicsData}
                    updateDemoAndQuery = { this.updateDemoAndQuery}
                    updateDemo = { this.updateDemo}
                />
            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        updateDemoRes : state.demographics.updateDemoRes
    }
  }
  
export default connect(mapStateToProps)(UpdateDemographicView);
import React, { Component }  from 'react';
import '../../commonStyle/common.css';
import { addThirdParty, emptyVendorProps } from '../../actions/actionsBag/vendor';
import { connect } from 'react-redux';
import $ from 'jquery';
import { alertMessage }  from '../../alertsDialog/Action';


class AddVendorDiv extends Component {
    constructor(props) {
        super(props)
        this.state = {
          vendorName: "",
          contactName: "",
          contactEmail: "",
          contactNumber: "",
          redirectUrlSuccess: "",
          redirectUrlTerminated: "",
          redirectUrlOverQuota: "",
          variable1: "",
          variable2: "",
          defaultVendor : false
        }
    
        
        this.addThirdParty = this.addThirdParty.bind(this);
        this.onVendorNameChange = this.onVendorNameChange.bind(this);
        this.onAllowDefaultVendor = this.onAllowDefaultVendor.bind(this);
        this.onContactNameChange = this.onContactNameChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onContactNumberChange = this.onContactNumberChange.bind(this);
        this.SuccessUrlChange = this.SuccessUrlChange.bind(this);
        this.onTerminatedUrlChange = this.onTerminatedUrlChange.bind(this);
        this.onOverQuotaChange = this.onOverQuotaChange.bind(this);
        this.onVariableOneChange = this.onVariableOneChange.bind(this);
        this.onVariableTwoChange = this.onVariableTwoChange.bind(this);
        this.onAllowDefaultVendor = this.onAllowDefaultVendor.bind(this);

      }

      componentWillReceiveProps(nextProps){

        if(nextProps.result && nextProps.result.success === true ){
            this.setState({
                vendorName: "",
                contactName: "",
                contactEmail: "",
                contactNumber: "",
                redirectUrlSuccess: "",
                redirectUrlTerminated: "",
                redirectUrlOverQuota: "",
                variable1: "",
                variable2: "",
                defaultVendor : false
            });
        }

        if( nextProps.result && nextProps.result.message ){
            let alertData ={};
            alertData.type ="alert";
            alertData.message =nextProps.result.message;
            this.props.dispatch(alertMessage(alertData));
            this.props.dispatch(emptyVendorProps());
            if(nextProps.result.success){
               this.props.gotoThirdPartyList();
            }
        }

      }

      onVendorNameChange(e){

        if(e.target.value === ""){
            // $("#demo").addClass("showerror");
        }else{
            $("#error1").removeClass("showerror");
        }

          this.setState({
            vendorName : e.target.value   
          })
      }
    

      onContactNameChange(e){
        if(e.target.value === ""){
            // $("#demo").addClass("showerror");
        }else{
            $("#error2").removeClass("showerror");
        }
        this.setState({
            contactName : e.target.value   
          })
      }

      onEmailChange(e){
        if(e.target.value === ""){
            // $("#demo").addClass("showerror");
        }else{
            $("#error3").removeClass("showerror");
        }
        this.setState({
            contactEmail : e.target.value   
          })
      }

      onContactNumberChange(e){
        if(e.target.value === ""){
            // $("#demo").addClass("showerror");
        }else{
            $("#error4").removeClass("showerror");
        }
        this.setState({
            contactNumber : e.target.value   
          })
      }

      SuccessUrlChange(e){
        if(e.target.value === ""){
            // $("#demo").addClass("showerror");
        }else{
            $("#error5").removeClass("showerror");
        }
        this.setState({
            redirectUrlSuccess : e.target.value   
          })
      }

      onTerminatedUrlChange(e){
        if(e.target.value === ""){
            // $("#demo").addClass("showerror");
        }else{
            $("#error6").removeClass("showerror");
        }
        this.setState({
            redirectUrlTerminated : e.target.value   
          })
      }

      onOverQuotaChange(e){
        if(e.target.value === ""){
            // $("#demo").addClass("showerror");
        }else{
            $("#error7").removeClass("showerror");
        }
        this.setState({
            redirectUrlOverQuota : e.target.value   
          })
      }

      onVariableOneChange(e){
        if(e.target.value === ""){
            // $("#demo").addClass("showerror");
        }else{
            $("#error8").removeClass("showerror");
        }
        this.setState({
            variable1 : e.target.value   
          })
      }

      onVariableTwoChange(e){
        if(e.target.value === ""){
            // $("#demo").addClass("showerror");
        }else{
            $("#error9").removeClass("showerror");
        }
        this.setState({
            variable2 : e.target.value   
          })
      }


      onAllowDefaultVendor(){
          this.setState({
              defaultVendor : !this.state.defaultVendor
          });
      }


      handleValidation(){
          let valid= true;
          if(this.state.vendorName === ""){
            valid = false;
            $("#error1").addClass("showerror");
            this.setState({
                vendornameerror: "Enter vendor name"
            });
        }

        if(this.state.contactName === ""){
            valid = false;
            $("#error2").addClass("showerror");
            this.setState({
               contnameerror: "Enter contact name"
            });  
        }

        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        let tempemail = this.state.contactEmail;

        if(this.state.contactEmail === ""){
            valid = false;
            $("#error3").addClass("showerror");
            this.setState({
                emailerror: "Enter email"
            });  
        }else if(!pattern.test(tempemail)){
            this.setState({ emailerror: "Enter correct email !" });
            $("#error3").addClass("showerror");
        }

        if(this.state.contactNumber === ""){ 
            valid = false;
            $("#error4").addClass("showerror");
            this.setState({
                contnumbererror: "Enter contact number"
            });  
        }else if(isNaN(this.state.contactNumber)){
            this.setState({
                contnumbererror: "Number only"
            });
            valid = false;
            $("#error4").addClass("showerror");
        }

        if(this.state.redirectUrlSuccess === ""){ 
            valid = false;
            $("#error5").addClass("showerror");
            this.setState({
                successurlerror: "Enter success redirect url"
            });  
        }

        if(this.state.redirectUrlTerminated === ""){ 
            valid = false;
            $("#error6").addClass("showerror");
            this.setState({
                terminateurlerror: "Enter terminate redirect url"
            });  
        }

        if(this.state.redirectUrlOverQuota === ""){ 
            valid = false;
            $("#error7").addClass("showerror");
            this.setState({
               overquotaerror: "Enter overquota redirect url"
            });  
        }


        // if(this.state.variable1 === ""){ 
        //     valid = false;
        //     $("#error8").addClass("showerror");
        //     this.setState({
        //         var1error: "Enter variable 1"
        //     });  
        // }

        // if(this.state.variable2 === ""){ 
        //     valid = false;
        //     $("#error9").addClass("showerror");
        //     this.setState({
        //         var2error: "Enter variable 2"
        //     });  
        // }
        
          return valid;
      }


      addThirdParty(){

        if(this.handleValidation()){
            let thirdPartyData = {};
            thirdPartyData.vendorName = this.state.vendorName;
            thirdPartyData.contactName = this.state.contactName;
            thirdPartyData.contactEmail = this.state.contactEmail;
            thirdPartyData.contactNumber = this.state.contactNumber;
            thirdPartyData.redirectUrlSuccess = this.state.redirectUrlSuccess;
            thirdPartyData.redirectUrlTerminated = this.state.redirectUrlTerminated;
            thirdPartyData.redirectUrlOverQuota = this.state.redirectUrlOverQuota;
            thirdPartyData.variable1 = this.state.variable1;
            thirdPartyData.variable2 = this.state.variable2;
            thirdPartyData.defaultVendor = this.state.defaultVendor;
            this.props.dispatch(addThirdParty(thirdPartyData));
        }

       
      }


    render(){

        return(
            <div style={{marginTop: "2%"}}>
                <div className="table-st">
                    <div style={{ width: "100%",paddingBottom:"50px", backgroundColor:"#ffffff", borderRadius:"4px" }}>
                        <div className="addVendor-main-div">
                            <div className="addVendor-main-div-2 formgroup-pdding">
                                <h5>Add third party form </h5>
                            </div>
                            <hr/>
                        
                            <div className = "formgroup">
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>Third Party Name</label>
                                    <input className="form-controll " value={this.state.vendorName} onChange={this.onVendorNameChange}></input>
                                    <div className="errordiv cus-width-2" id="error1">{this.state.vendornameerror}</div>
                                </div>
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>Contact Name</label>
                                    <input className="form-controll " value={this.state.contactName} onChange={this.onContactNameChange}></input>
                                    <div className="errordiv cus-width-2" id="error2">{this.state.contnameerror}</div>
                                </div>
                            </div>

                            <div className = "formgroup">
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>Contact Email</label>
                                    <input className="form-controll " value={this.state.contactEmail} onChange={this.onEmailChange}></input>
                                    <div className="errordiv cus-width-2" id="error3">{this.state.emailerror}</div>
                                </div>
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>Contact Number</label>
                                    <input className="form-controll " value={this.state.contactNumber} onChange={this.onContactNumberChange}></input>
                                    <div className="errordiv cus-width-2" id="error4">{this.state.contnumbererror}</div>
                                </div>
                            </div>

                            <div className = "formgroup">
                                <div class="formgroup-col-1 rltv-pos" >
                                    <label>Redirect Url Success</label>
                                    <input className="form-controll " value={this.state.redirectUrlSuccess} onChange={this.SuccessUrlChange}></input>
                                    <div className="errordiv" id="error5">{this.state.successurlerror}</div>
                                </div>
                            </div>

                            <div className = "formgroup">
                                <div class="formgroup-col-1 rltv-pos" >
                                    <label>Redirect Url Terminated</label>
                                    <input className="form-controll " value={this.state.redirectUrlTerminated} onChange={this.onTerminatedUrlChange}></input>
                                    <div className="errordiv cus-width-2" id="error6">{this.state.terminateurlerror}</div>
                                </div>
                            </div>

                            <div className = "formgroup">
                                <div class="formgroup-col-1 rltv-pos">
                                    <label>Redirect Url OverQuota</label>
                                    <input className="form-controll " value={this.state.redirectUrlOverQuota} onChange={this.onOverQuotaChange}></input>
                                    <div className="errordiv cus-width-2" id="error7">{this.state.overquotaerror}</div>
                                </div>
                            </div>

                            <div className = "formgroup">
                                <div class="formgroup-col-6 rltv-pos">
                                    <label>Variable 1</label>
                                    <input className="form-controll " value={this.state.variable1} onChange={this.onVariableOneChange}></input>
                                    <div className="errordiv cus-width-2" id="error8">{this.state.var1error}</div>
                                </div>
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>Variable 2</label>
                                    <input className="form-controll " value={this.state.variable2}  onChange={this.onVariableTwoChange}></input>
                                    <div className="errordiv cus-width-2" id="error9">{this.state.var2error}</div>
                                </div>
                            </div>

                            <div className = "formgroup">
                                <div className="form-group addVendorCheckbox">
                                <input type="checkbox" id="allowvendor"  onChange={this.onAllowDefaultVendor} checked={this.state.defaultVendor}/>
                                <label for="allowvendor">Allow Default Vendor</label>
                                </div>
                            </div>
                            
                            <div className = "formgroup addVendorCheckbox">
                                <button onClick={this.addThirdParty} className="list-btn ">Create thirdparty</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )}
}
const mapStateToProps=(state)=>{
    return{
        result: state.thirdParty.result,
    }
  }
  export default connect(mapStateToProps)(AddVendorDiv);


import React, {Component} from 'react';
import {connect} from 'react-redux';
import './leftmenustyle.css';
// import 'react-overlay-loader/styles.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import assessme from '../../images/assessme-1.png';
import list from '../../images/list-menu.png';
import database_archived from '../../images/database_archived.png';

import addimg from '../../images/add-menu1.png';
import userimg from '../../images/person-menu.png';
import reciept from '../../images/receipt-menu.png';
import people from '../../images/people-menu.png';
import demo from '../../images/demographic.png';
import roleimg from '../../images/role.png';
import sendmail from '../../images/sendmail.png';


class LeftMenu extends Component {
  constructor(props){
      super(props)
        this.state={
            showDashboard : false,
            showStudyList : false,
            showCreateStudy : false,
            showThirdPartyList : false,
            showInvoiceList : false,
            showClientList : false,
            showDemographic : false,
            showUsers: false,
            showRoles: false,
            showSendMailMenu : false,
            showArchivingMenu : false
        }
  }

  componentWillMount(){

    if(localStorage.getItem("is_Dashboard") === "1"){
        this.setState({
          showDashboard : true
        });
    }

    if(localStorage.getItem("is_Study_list") === "1"){
      this.setState({
        showStudyList : true
      });
    }
    if(localStorage.getItem("is_Study_create") === "1"){
      this.setState({
        showCreateStudy : true
      });
      }
      if(localStorage.getItem("is_Vendor_list") === "1"){
        this.setState({
          showThirdPartyList : true
        });
    }
    if(localStorage.getItem("is_invoice_list") === "1"){
      this.setState({
        showInvoiceList : true
      });
    }
    if(localStorage.getItem("is_client_list") === "1"){
      this.setState({
        showClientList : true
      });
    }
    
    if(localStorage.getItem("is_archiving") === "1" || 
    localStorage.getItem("is_archiving") === 1){
      this.setState({
        showArchivingMenu : true
      });
    }
    if(localStorage.getItem("is_Demographics_list") === "1"){
      this.setState({
        showDemographic : true
      });
    }
    if(localStorage.getItem("is_user_list") === "1"){
      this.setState({
        showUsers: true
      });
    }
    if(localStorage.getItem("is_Role_list") === "1"){
      this.setState({
        showRoles: true
      });
    }

    
    if(localStorage.getItem("is_send_email") === "1"){
      this.setState({
        showSendMailMenu : true
      });
    }
  }




  // componentDidMount(){
  //   $(document).ready(function(){
  //       $("#dash").click(function(){
           
            // var x= document.getElementsByClassName("common");
            // for(var a=0; a<x.length; a++){
            //     $(x[a]).removeClass("backblue");
            //     sessionStorage.removeItem(x[a]);
            // }
  //         $("#dash").addClass("backblue");
  //           sessionStorage.setItem(x[0], "dash");
  //       });
        

  //       $("#stdlist").click(function(){
  //           var x= document.getElementsByClassName("common");
  //           for(var a=0; a<x.length; a++){
  //               $(x[a]).removeClass("backblue");
  //           }
  //           $("#stdlist").addClass("backblue");
  //         });

  //         $("#createstd").click(function(){
  //           var x= document.getElementsByClassName("common");
  //           for(var a=0; a<x.length; a++){
  //               $(x[a]).removeClass("backblue");
  //           }
  //           $("#createstd").addClass("backblue");
  //         });


          
  //         $("#thrdparty").click(function(){
  //           var x= document.getElementsByClassName("common");
  //           for(var a=0; a<x.length; a++){
  //               $(x[a]).removeClass("backblue");
  //           }
  //           $("#thrdparty").addClass("backblue");
  //         });
          

  //         $("#invoice").click(function(){
  //           var x= document.getElementsByClassName("common");
  //           for(var a=0; a<x.length; a++){
  //               $(x[a]).removeClass("backblue");
  //           }
  //           $("#invoice").addClass("backblue");
  //         });
          
  //         $("#clientlist").click(function(){
  //           var x= document.getElementsByClassName("common");
  //           for(var a=0; a<x.length; a++){
  //               $(x[a]).removeClass("backblue");
  //           }
  //           $("#clientlist").addClass("backblue");
  //         });
          

  //         $("#demograph").click(function(){
  //           var x= document.getElementsByClassName("common");
  //           for(var a=0; a<x.length; a++){
  //               $(x[a]).removeClass("backblue");
  //           }
  //           $("#demograph").addClass("backblue");
  //         });


  //         $("#users").click(function(){
  //           var x= document.getElementsByClassName("common");
  //           for(var a=0; a<x.length; a++){
  //               $(x[a]).removeClass("backblue");
  //           }
  //           $("#users").addClass("backblue");
  //         });
          
  //         $("#role").click(function(){
  //           var x= document.getElementsByClassName("common");
  //           for(var a=0; a<x.length; a++){
  //               $(x[a]).removeClass("backblue");
  //           }
  //           $("#role").addClass("backblue");
  //         });
  //     });
  // }


  

componentDidMount(){
  if(sessionStorage.getItem("activeMenu") !== "" || sessionStorage.getItem("activeMenu") !== undefined){
    var x= document.getElementsByClassName("common");
    for(var a=0; a<x.length; a++){
        $(x[a]).removeClass("backblue");
        sessionStorage.removeItem(x[a]);
    }
    let cId= sessionStorage.getItem("activeMenuId");
    $("#"+cId).addClass("backblue");
  }
  
}

doActive(){
  if(sessionStorage.getItem("activeMenu") !== "" || sessionStorage.getItem("activeMenu") !== undefined){
    var x= document.getElementsByClassName("common");
    for(var a=0; a<x.length; a++){
        $(x[a]).removeClass("backblue");
        sessionStorage.removeItem(x[a]);
    }
    let cId= sessionStorage.getItem("activeMenuId");
    $("#"+cId).addClass("backblue");
  }
}

activeMenu(menuid){
    sessionStorage.setItem("activeMenuId", menuid);
    this.doActive();
}

  render(){
    
      let showDashboardMenu = "";
      let showStudyListMenu = "";
      let showCreateStudyMenu = "";
      let showThirdPartyListMenu = "";
      let showInvoiceListMenu = "";
      let showClientListMenu = "";
      let showDemoListMenu = "";
      let showUserListMenu = "";
      let showRoleListMenu = "";
      let showSendMail = "";
      let showAPIProjectsList = "";
      let showArchivingMenuInNav = "";
      if(this.state.showDashboard === true || this.state.showDashboard === 1){
        showDashboardMenu =<li><Link to="/dashboard" className="common" id="dash"  onClick={() => this.activeMenu("dash")}><div className="menuicons" style={{width:"30px"}}><img src={assessme} height="24px" width="24px"/></div><div className="menuicons"><div  style={{marginLeft:"30px"}} >Dashboard</div></div></Link></li>
      }else{
        showDashboardMenu = "";
      }

      if(this.state.showStudyList === true){
          showStudyListMenu = <li><Link to="/studylist" className="common" id="stdlist" onClick={() => this.activeMenu("stdlist")}><div className="menuicons" style={{width:"30px"}}><img src={list} height="18px" width="16px" style={{marginLeft: "5px"}}/></div><div className="menuicons"><div style={{marginLeft:"30px"}} >Study List</div></div></Link></li>
      }else{
        showStudyListMenu = "";
      }

      if(localStorage.getItem('auth_user_type') === "superadmin"){
        showAPIProjectsList = <li><Link to="api-project-list" className="common" id="projAPIlist" onClick={() => this.activeMenu("projAPIlist")}><div className="menuicons" style={{width:"30px"}}><img src={list} height="18px" width="16px" style={{marginLeft: "5px"}}/></div><div className="menuicons"><div style={{marginLeft:"30px"}} >API Project List</div></div></Link></li>
      }
      else{
        showAPIProjectsList = "";
      }



      if(this.state.showCreateStudy === true){
        showCreateStudyMenu = <li><Link to="/createstudy" className="common" id="createstd" onClick={() => this.activeMenu("createstd")}><span className="menuicons" style={{width:"30px"}}><img src={addimg} height="22px" width="22px"/></span><div className="menuicons"><div  style={{marginLeft:"30px"}}>Create Study</div></div></Link></li>
      }else{
        showCreateStudyMenu = "";
      }

      if(this.state.showThirdPartyList === true){
        showThirdPartyListMenu = <li><Link to="/thirdpartylist" className="common" id="thrdparty"  onClick={() => this.activeMenu("thrdparty")}><span className="menuicons" style={{width:"30px"}}><img src={userimg} height="23px" width="23px"/></span><div className="menuicons"><div style={{marginLeft:"30px"}} >Third Party</div></div></Link></li>
      }else{
        showThirdPartyListMenu = "";
      }

      if(this.state.showInvoiceList === true){
        showInvoiceListMenu = <li><Link to="/invoice-list" className="common" id="invoice" onClick={() => this.activeMenu("invoice")}><span className="menuicons" style={{width:"30px"}}><img src={reciept} height="22px" width="20px"/></span><div className="menuicons"><div style={{marginLeft:"30px"}} >Invoiced List</div></div></Link></li>
      }else{
        showInvoiceListMenu = "";
      }

      if(this.state.showClientList === true){
        showClientListMenu = <li><Link to="/client-list" className="common" id="clientlist" onClick={() => this.activeMenu("clientlist")}><span className="menuicons" style={{width:"30px"}}><img src={people} height="20px" width="20px"/></span><div className="menuicons"><div style={{marginLeft:"30px"}} >Client List</div></div></Link></li>
      }else{
        showClientListMenu = "";
      }

      if(this.state.showDemographic === true){
          showDemoListMenu = <li><Link to="/demographics-list" className="common" id="demograph" onClick={() => this.activeMenu("demograph")}><span className="menuicons" style={{width:"30px"}}><img src={demo} height="23px" width="23px"/></span><div className="menuicons"><div style={{marginLeft:"30px"}} >Demographics</div></div></Link></li>
      }else{
        showDemoListMenu = "";
      }

      if(this.state.showUsers === true){
        showUserListMenu = <li><Link to="/user-list" className="common" id="users" onClick={() => this.activeMenu("users")}><span className="menuicons" style={{width:"30px"}}><img src={userimg} height="20px" width="20px"/></span><div className="menuicons"><div style={{marginLeft:"30px"}} >Users</div></div></Link></li>
      }else{
        showUserListMenu = "";
      }

      if(this.state.showRoles === true){
          showRoleListMenu = <li><Link to="/role-list" className="common" id="role"  onClick={() => this.activeMenu("role")}><span className="menuicons" style={{width:"30px"}}><img src={roleimg} height="23px" width="23px"/></span><div className="menuicons"><div style={{marginLeft:"30px"}} >Roles</div></div></Link></li>
      }else{
        showRoleListMenu = "";
      }

      if(this.state.showSendMailMenu === true){
        showSendMail = <li><Link to="/send-mail" className="common" id="sendmail"  onClick={() => this.activeMenu("sendmail")}><span className="menuicons" style={{width:"30px"}}><img src={sendmail} height="23px" width="23px"/></span><div className="menuicons"><div style={{marginLeft:"30px"}} >Send Mail</div></div></Link></li>
      }else{
        showSendMail = "";
      }
      

      if(this.state.showArchivingMenu === true){
        showArchivingMenuInNav = <li><Link to="archiving-project-list" className="common" id="projArchiving" onClick={() => this.activeMenu("projArchiving")}><div className="menuicons" style={{width:"30px"}}><img src={database_archived} height="18px" width="16px" style={{marginLeft: "5px"}}/></div><div className="menuicons"><div style={{marginLeft:"30px"}} >Archiving</div></div></Link></li>
      }else{
        showArchivingMenuInNav = "";
      }
      

    return (
        // <div id="leftmenubar">
            <div id="leftsubcontainer">
            <ul style={{paddingTop: "60px"}}>
            {showDashboardMenu}
            {showStudyListMenu}
            {/* {showAPIProjectsList} */}
            {showCreateStudyMenu}
            {showThirdPartyListMenu}
            {showInvoiceListMenu}
            {showClientListMenu}
            {showDemoListMenu}
            {showUserListMenu}
            {showRoleListMenu}
            {showSendMail} 
            <li><Link to="api-project-list" className="common" id="projAPIlist" onClick={() => this.activeMenu("projAPIlist")}><div className="menuicons" style={{width:"30px"}}><img src={list} height="18px" width="16px" style={{marginLeft: "5px"}}/></div><div className="menuicons"><div style={{marginLeft:"30px"}} >API Project List</div></div></Link></li>
            {showArchivingMenuInNav}
                {/* <li><Link to="/dashboard" className="common" id="dash"><div className="menuicons"><img src={assessme} height="26px" width="26px"/></div><div className="menuicons"><div className="pad-top-5">Dashboard</div></div></Link></li> */}
                {/* <li><Link to="/studylist" className="common" id="stdlist"><div className="menuicons"><img src={list} height="18px" width="18px"/></div><div className="menuicons"><div className="pad-top-5">Study List</div></div></Link></li> */}
                {/* <li><Link to="/createstudy" className="common" id="createstd"><span className="menuicons"><img src={addimg} height="22px" width="22px"/></span><div className="menuicons"><div className="pad-top-5">Create Study</div></div></Link></li> */}
                {/* <li><Link to="/thirdpartylist" className="common" id="thrdparty"><span className="menuicons"><img src={userimg} height="23px" width="23px"/></span><div className="menuicons"><div className="pad-top-5">Third Party</div></div></Link></li> */}
                {/* <li><Link to="/invoice-list" className="common" id="invoice"><span className="menuicons"><img src={reciept} height="22px" width="20px"/></span><div className="menuicons"><div className="pad-top-5">Invoiced List</div></div></Link></li> */}
                {/* <li><Link to="/client-list" className="common" id="clientlist"><span className="menuicons"><img src={people} height="20px" width="20px"/></span><div className="menuicons"><div className="pad-top-5">Client List</div></div></Link></li> */}
                {/* <li><Link to="/demographics-list" className="common" id="demograph"><span className="menuicons"><img src={demo} height="23px" width="23px"/></span><div className="menuicons"><div className="pad-top-5">Demographics</div></div></Link></li> */}
                {/* <li><Link to="/user-list" className="common" id="users"><span className="menuicons"><img src={userimg} height="20px" width="20px"/></span><div className="menuicons"><div className="pad-top-5">Users</div></div></Link></li> */}
                {/* <li><Link to="/role-list" className="common" id="role"><span className="menuicons"><img src={roleimg} height="23px" width="23px"/></span><div className="menuicons"><div className="pad-top-5">Roles</div></div></Link></li> */}
            </ul>
            </div>
            // </div>
     );
  }

}


const mapStateToProps=(state)=>{
  return{

  }
}

export default connect(mapStateToProps)(LeftMenu);

import appConfig from "../apiConfig";

//
export const addUsersApi =(cloneData)=> {
	let study =  JSON.stringify(cloneData);
	const headers = Object.assign({'Content-Type': 'application/json'});
	const request= new Request(appConfig.siteURL + 'addUsersBySuperAdmin', {
	  method : "POST",
	  headers : headers,
	  body : study
	});
  
  return  fetch(request).then(response => {
	return  response.json().then(study => {
		return study;
	});
	 }).catch(error => {return error;});
  
	}

//updateUserApi
export const updateUserApi =(data)=> {
	let study =  JSON.stringify(data);
	const headers = Object.assign({'Content-Type': 'application/json'});
	const request= new Request(appConfig.siteURL + 'updateUsersBySuperAdmin', {
	  method : "POST",
	  headers : headers,
	  body : study
	});
  
return  fetch(request).then(response => {
		return  response.json().then(study => {
			return study;
		});
	}).catch(error => {return error;});
}
	
	//getOPmanagerApi
	export const getOPmanagerApi =(id)=> {

		const headers = Object.assign({'Content-Type': 'application/json'});
		const request= new Request(appConfig.siteURL + 'getOPmanagerDataByAdmin/'+id, {
			method : "GET",
			headers : headers
		});
		
		return  fetch(request).then(response => {
		return  response.json().then(study => {
			return study;
		});
		 }).catch(error => {return error;});
		
		}


		//getAllUserByAdminApi

		export const getAllUserByAdminApi =(pagenumber, userid, userType)=> {

			const headers = Object.assign({'Content-Type': 'application/json'});
			const request= new Request(appConfig.siteURL + 'getAllUserByAdminWidthPagination/'+pagenumber+'/'+ userid+'/'+userType, {
				method : "GET",
				headers : headers
			});
			
			return  fetch(request).then(response => {
			return  response.json().then(study => {
				return study;
			});
			 }).catch(error => {return error;});
			
			}

	//deleteUserApi

	export const deleteUserApi =(id)=> {

		const headers = Object.assign({'Content-Type': 'application/json'});
		const request= new Request(appConfig.siteURL + 'softDeleteUser/'+id, {
			method : "POST",
			headers : headers
		});
		
		return  fetch(request).then(response => {
		return  response.json().then(study => {
			return study;
		});
			}).catch(error => {return error;});
		
		}

	//sendMailToUserApi
	export const sendMailToUserApi =(data)=> {
		let study =  JSON.stringify(data);
		const headers = Object.assign({'Content-Type': 'application/json'});
		const request= new Request(appConfig.siteURL + 'sendMailToUserApi', {
		method : "POST",
		headers : headers,
		body : study
		});
	
	return  fetch(request).then(response => {
			return  response.json().then(study => {
				return study;
			});
		}).catch(error => {return error;});
	}
import React, { Component }  from 'react';
import  '../../commonStyle/common.css';
import $ from 'jquery';
import { getUserListByAdmin } from '../../actions/actionsBag/dashboard';
import appConfig from "../../api/apiConfig";
import {connect} from 'react-redux';
import goback from '../../images/goback.png';

class cloneStudyListHeaderForCloneStudy extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedUserID : "",
            selectedUser   : "Filter by users project",
        }
        this.gobackPage = this.gobackPage.bind( this);
    }

componentWillMount(){
    let adminID = localStorage.getItem('auth_user_id');
    let adminType = localStorage.getItem('auth_user_type');
    this.props.dispatch(getUserListByAdmin(adminID, adminType));
}

componentDidMount(){

    $(document).ready(function(){
    
        $('#flt-btn').click( function(e) {
            
            e.preventDefault(); // stops link from making page jump to the top
            e.stopPropagation(); // when you click the button, it stops the page from seeing it as clicking the body too
            $('.dropdownitem').toggle();
            
        });
        
        $('.dropdownitem').click( function(e) {
            
            // e.stopPropagation(); // when you click within the content area, it stops the page from seeing it as clicking the body too
            
        });
        
        $('body').click( function() {
           
            $('.dropdownitem').hide();
            
        });
        
    });
}

showselectedUser(e){
       
    if(e === 0){
        this.setState({
            selectedUserID : "",
            selectedUser   : "Filter by users project",
        });
        let data = {};
        data.user_id = localStorage.getItem("auth_user_id");
        data.user_name = localStorage.getItem("auth_user_type");
        this.props.getStudiesByUser(data);
    }else{
        this.setState({
        selectedUserID : e.user_id,
        selectedUser   : e.user_name
        });
        this.props.getStudiesByUser(e);
    }
 }

 exportStudies(){
    let adminID = localStorage.getItem('auth_user_id');
    let adminType = localStorage.getItem('auth_user_type');
    window.open(appConfig.siteURL + "studyInExcel/"+ adminID+"/"+adminType);
 }

 gobackPage(){
     window.history.back();
 }


    render(){

        let userItem = "";
       
        if(this.props.userListSuccess && this.props.usersListData && this.props.usersListData.length>0){
          userItem = this.props.usersListData.map((item, i) => {
              return <li onClick={this.showselectedUser.bind(this, item)}>{item.user_name}</li>
            });
        }

        
        return(
            <div className="router-header">
                <div className="router-header1 all-header">
                    <div>
                        <h2 className="head-two-st">Clone Studies List</h2>
                        <p className="paragraph-st color-secondary para-st-font">Here you can see all projects and you can search study by id and name</p>
                     </div>

                    <div className="flex-show" >
                        <div><button onClick={this.props.gotoBack} className="list-btn list-btn-color margin-right"><img src={goback} height="13px" width="13px" /><span> Back</span></button></div>
                        </div>
                </div>
            </div>
    )}
}
const mapStateToProps=(state)=>{
    return{
        userListSuccess : state.dashboard.userListSuccess,
        usersListData : state.dashboard.usersListData
    }
  }
  
  export default connect(mapStateToProps)(cloneStudyListHeaderForCloneStudy);

import React, { Component } from 'react';
import { invitationToPanellist, initialiseInvitationRes } from '../../../actions/actionsBag/study';
import { alertMessage } from '../../../alertsDialog/Action';
import { connect } from 'react-redux';

import $ from 'jquery';

class SendInvitationForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newUpdatedValue: "",
            newUpdatedComment: ""
        }
        this.sendInvitationToPanelreward = this.sendInvitationToPanelreward.bind(this);
        this.onNewValueChange = this.onNewValueChange.bind(this);
        this.onCommentChange = this.onCommentChange.bind(this);
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.invitationResp && nextProps.invitationResp.message) {
            let alertData = {};
            alertData.type = "alert";
            alertData.message = nextProps.invitationResp.message;
            this.props.dispatch(initialiseInvitationRes());
            this.props.dispatch(alertMessage(alertData));
        }
    }


    onCommentChange(e) {
        if (e.target.value !== "")
            $("#error21").removeClass("showerror");
        this.setState({ newUpdatedComment: e.target.value });
    }

    onNewValueChange(e) {
        if (e.target.value !== "")
            $("#error22").removeClass("showerror");
        this.setState({ newUpdatedValue: e.target.value });
    }

    handleValidation1() {
        let valid = true;

        if (this.state.newUpdatedComment === "") {
            valid = false;
            $("#error21").addClass("showerror");
            this.setState({
                descerror: "Enter new value"
            });
        }

        if (this.state.newUpdatedValue === "") {
            valid = false;
            $("#error22").addClass("showerror");
            this.setState({
                redeemerror: "Enter redeem points"
            });
        }


        return valid;
    }

    sendInvitationToPanelreward() {


        let data = {};
        data.studyId = localStorage.getItem("study_Id");
        data.study_name = localStorage.getItem("study_Name");
        data.is_live = localStorage.getItem("study_Is_live");
        data.description = this.state.newUpdatedComment;
        data.cpi = this.state.newUpdatedValue;



        if (this.handleValidation1()) {
            this.props.dispatch(invitationToPanellist(data));
        }
    }


    render() {

        return (
            <div className="create-form">
                <div className="create-form-container">
                    <div className="allformtruct forms-rd-shadow">
                        <div className="form-title">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <h4 className="rm-margin">Study Name</h4>
                                    <p className="form-title-text">{localStorage.getItem("study_Name")}</p>
                                </div>
                                {/* <div className="pro-tgl-btn pro-tgl-btn-shdw" id="tg-btn-1">
                                    <div id="rotate-icon-1">&#11167;</div>
                                </div> */}
                            </div>
                            <hr />
                            <div className="formgroup">
                                <div class="formgroup-col-6 rltv-pos" >
                                    <label>Enter studie's description</label>
                                    <input className="form-controll " placeholder="Study description" value={this.state.newUpdatedComment} onChange={this.onCommentChange}></input>
                                    <div className="errordiv cus-width-2" id="error21" >{this.state.descerror}</div>
                                </div>
                                <div class="formgroup-col-6 rltv-pos">
                                    <label>Enter redeem points</label>
                                    <input className="form-controll " placeholder="Redeem points" value={this.state.newUpdatedValue} onChange={this.onNewValueChange}></input>
                                    <div className="errordiv cus-width-2" id="error22" >{this.state.redeemerror}</div>
                                </div>
                            </div>
                            <div style={{ marginTop: "20px", textAlign: "center" }}>
                                <button className="list-btn" onClick={this.sendInvitationToPanelreward}>Send Invitation</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        invitationResp: state.studies.invitationResp
    }
}
export default connect(mapStateToProps)(SendInvitationForm);


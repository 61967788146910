import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//TODO : get the language code dynamically using geolocation service.
//const lang = window.location.pathname.replace(/\//g, '')!==''?window.location.pathname.replace(/\//g, ''):'en';

export  const store = createStore(rootReducer, /* preloadedState, */
		composeEnhancers(
    		applyMiddleware(thunkMiddleware)
			));

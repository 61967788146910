import {
	addUsersApi, getOPmanagerApi, getAllUserByAdminApi, updateUserApi, deleteUserApi, sendMailToUserApi
} from "../../api/apiBag/userApi";
import {startSpinner, endSpinner} from "../../loadingSpinner/Action";


export const setAddUsersRes = (response) => {
	return {
        type: "SET_ADD_USER_RES",
        response
	}
}

//setUpdateUsersRes
export const setUpdateUsersRes = (response) => {
	return {
        type: "SET_UPDATE_USER_RES",
        response
	}
}

export const setFailAddUsersRes = (response) => {
	return {
        type: "SET_FAIL_ADD_USER_RES",
        response
	}
}

export const blankResUser = () => {
	return {
        type: "SET_EMPTY_ADD_USER_RES",
	}
}

//setOPManagerDataRes
export const setOPManagerDataRes = (response) => {
	return {
	type: "SET_OP_MANAGER_LIST_RES",
	response
	}
}
//setAllUsersByAdmin
export const setAllUsersByAdmin = (response) => {
	return {
	type: "SET_USERS_LIST_RES",
	response
	}
}

//editUserProfile
export const editUserProfile = (data) => {
	return {
	type: "SET_USERS_PROFILE_EDIT",
	data
	}
}


export const sendMailToUserResponse = (response) => {
	return {
	type: "SET_USER_MAIL_RESPONSE",
	response
	}
}

//showCreateUser
export const showCreateUser = () => {
	return {
	type: "SHOW_CREATE_USER_PAGE"
	}
}

export const removeUserResponse = (response) => {
	return{
		type : "SET_REMOVE_USER_RES",
		response
	}
}

export const addUsers = (data) => {
	return dispatch => {
			dispatch(startSpinner());
			addUsersApi(data).then((result) => {
			dispatch(endSpinner());
			dispatch(setAddUsersRes(result));
			});
	}
}

//updateUser
export const updateUser = (data) => {
	return dispatch => {
			dispatch(startSpinner());
				updateUserApi(data).then((result) => {
			dispatch(endSpinner());
				dispatch(setUpdateUsersRes(result));
			});
	}
}

//getOPmanager
export const getOPmanager = (data) => {
	return dispatch => {
			dispatch(startSpinner());
			getOPmanagerApi(data).then((result) => {
			dispatch(endSpinner());
			if(result.success){
				dispatch(setOPManagerDataRes(result));
			}else{
				alert(result.message);
			}   
           
		});
	}
}

//getAllUserByAdmin

export const getAllUserByAdmin = (pagenumber, userid, userType) => {
	return dispatch => {
			dispatch(startSpinner());
			getAllUserByAdminApi(pagenumber, userid, userType).then((result) => {
			dispatch(endSpinner());
			if(result.success){
				dispatch(setAllUsersByAdmin(result));
			}else{
				alert(result.message);
			}   
           
		});
	}
}

//deleteUser
export const deleteUser = ( id) => {
	return dispatch => {
			dispatch(startSpinner());
			deleteUserApi( id).then((result) => {
			dispatch(endSpinner());
				dispatch(removeUserResponse(result));
			});
	}
}

//sendMailToUser
export const sendMailToUser = (data) => {
	return dispatch => {
			dispatch(startSpinner());
			sendMailToUserApi(data).then((result) => {
			dispatch(endSpinner());
				dispatch(sendMailToUserResponse(result));
		});
	}
}
import {
	studiesApi, studiesListApi, addMappingApi, updateMappingApi, dataByStudyIdApi,
	participantApi, updateParticipantApi, demoMappingApi, demoListMappingApi, participantDemoDataApi,
	addParticipantReplyApi, DemoMappingListApi, getOptionsApi, addConstraintApi, updateParticipantDataApi,
	softDeleteApi, addnewParticipantApi, getParticipantListApi, uploadDocApi, invitationToPanellistApi,
	searchApi, uniqueLinkDataApi, getAllBDEbyAdminApi, getStudiesExcelListApi, getThirdPartyListByStudiesAPI,
	updateStudyUniqueFieldApi, getActivitylogByStudyApi, getDataByStudyIDApi, getAllclientsApi, UpdateStudiesDataApi,
	participantCountApi, getProgressReportDataByStudyApi, getProgressReportDataByStudyMapApi, getDefaultVendorApi, apiProjectsListApi,
	getProgressReportDataByApiStudyApi, sendInvitationToVendorsApi, createCloneStudyApi, cloneStudiesListApi, getAllCloneStudiesByStudyApi,
	getAllCountryLangCodeApi, getQuotaDataOfApiStudyApi, searchAPIProjectApi, sortAPIProjectApi,
	studiesListByFilterApi, updateInMarketApi,
	 archivingStudiesListApi , searchInArchiveApi , getArchivedProgressReportDataByStudyApi,
	 getArchivedProgressReportDataByStudyMapApi , archivingDataByStudyIdApi ,
	 archivingThirdpartyParticipantCountApi
} from "../../api/apiBag/studyApi";
import { startSpinner, endSpinner } from "../../loadingSpinner/Action";
// import {setMessage} from '../../popupAlert/action';

export const showStudyList = () => {
	return {
		type: "SET_SHOW_STUDY",
	}
}

export const emptyStudyList = () => {
	return {
		type: "SET_STUDIES_LIST_EMPTY",
	}
}

export const setStudies = (studySuccess) => {
	return {
		type: "SET_STUDIES_MESSAGE",
		studySuccess

	}
}

export const setParticipantList = (participantList) => {
	return {
		type: "SET_PARTICIPANT_LIST",
		participantList

	}
}

export const setParticipant = (participantResp) => {
	return {
		type: "SET_PARTICIPANT_RESPONSE",
		participantResp

	}
}


export const setnewParticipant = (participantNewResp) => {
	return {
		type: "SET_NEW_PARTICIPANT_RESPONSE",
		participantNewResp

	}
}

export const setUpdateParticipant = (updateParticipantResp) => {
	return {
		type: "SET_UPDATE_PARTICIPANT_RESPONSE",
		updateParticipantResp

	}
}

export const setStudiesList = (studies) => {
	return {
		type: "SET_STUDIES_LIST",
		studies

	}
}


export const setStudyDeleteResult = (data) => {
	return {
		type: "SET_STUDIES_LIST_DELETE_RESULT",
		data

	}
}

//setCloneStudiesList
export const setCloneStudiesList = (cloneStudies) => {
	return {
		type: "SET_CLONE_STUDIES_LIST",
		cloneStudies

	}
}

export const setMappingResponse = (mapping) => {
	return {
		type: "SET_MAPPING_RESPONSE",
		mapping

	}
}

export const setDefaultVendor = (response) => {
	return {
		type: "SET_DEFAULT_VENDOR",
		response
	}
}


export const setMappingResponseSuccess = (response) => {
	return {
		type: "SET_ADD_MAPPING_SUCCESS_RES",
		response
	}
}

export const setMappingResponseFail = (response) => {
	return {
		type: "SET_MAPPING_FAIL_RESPONSE",
		response
	}
}

export const setUpdateMappingResponse = (response) => {
	return {
		type: "SET_UPDATE_MAPPING_RESPONSE",
		response

	}
}

export const initialiseFileRes = () => {
	return {
		type: "INITIALISE_FILE_RES"
	}
}

export const show = () => {
	return {
		type: "SET_SHOW_TO_INITIAL",

	}
}

export const showList = () => {
	return {
		type: "SET_SHOW_STUDY_LIST",

	}
}

export const setdemoMappingResponse = (demoMappingResp) => {
	return {
		type: "SET_DEMO_MAPIING_RESP",
		demoMappingResp
	}
}

export const setDemoListMapping = (demoMappingList) => {
	return {
		type: "SET_DEMO_MAPIING_LIST",
		demoMappingList
	}
}

export const setparticipantDemoData = (DemoDataList) => {
	return {
		type: "SET_DEMO_DATA_LIST",
		DemoDataList
	}
}

export const setparticipantReplyRes = (participantReplyRes) => {
	return {
		type: "SET_PARTICIPANT_REPLY_RES",
		participantReplyRes
	}
}

//setparticipantRedirectUrl
export const setparticipantRedirectUrl = (participantRedirectUrlRes) => {
	return {
		type: "SET_PARTICIPANT_REDIRECT_URL_RES",
		participantRedirectUrlRes
	}
}

export const setDemoMapping = (demoMapping) => {
	return {
		type: "SET_LIST_OF_DEMO_MAPPING",
		demoMapping
	}
}

export const setOptions = (optionsData) => {
	return {
		type: "SET_LIST_OF_OPTIONS",
		optionsData
	}
}

export const setaddConstraintRes = (addConstraintRes) => {
	return {
		type: "SET_ADD_CONSTRAINT_RES",
		addConstraintRes
	}
}

export const updateParticipantDataRes = (updatedParticipantRes) => {
	return {
		type: "SET_PARTICIPANT_UPDATE_RES",
		updatedParticipantRes
	}
}

export const setUploadDocRes = (uploadLinkRes) => {
	return {
		type: "SET_UPLOAD_DOC_RES",
		uploadLinkRes
	}
}

export const blankResUploadUniqueLink = () => {
	return {
		type: "SET_UPLOAD_DOC_EMPTY_RES",
	}
}

export const setInvitationToPanellistARes = (invitationResp) => {
	return {
		type: "SET_INVITATION_RESP",
		invitationResp
	}
}

export const setCreateCloneRes = (cloneResp) => {
	return {
		type: "SET_CREATE_CLONE_RESP",
		cloneResp
	}
}

export const initialiseInvitationRes = () => {
	return {
		type: "SET_INITIALISE_INVITATION_RESP",
	}
}

export const setuniqueLinkData = (uniqueLinkData) => {
	return {
		type: "SET_UNIQUE_LINK_DATA",
		uniqueLinkData
	}
}
//setBDEusresList
export const setBDEusresList = (bdeData) => {
	return {
		type: "SET_BDE_USERS_LIST",
		bdeData
	}
}

//setThirdPartyListByStudy
export const setThirdPartyListByStudy = (thirdPartList) => {
	return {
		type: "SET_THIRDPARTY_LIST_BY_STUDY",
		thirdPartList
	}
}

//setMappingListSuccessFail
export const setMappingListSuccessFail = () => {
	return {
		type: "SET_MAPPING_LIST_SUCCESS_FAIL",
	}
}

//setActivityLog

export const setActivityLog = (response) => {
	return {
		type: "SET_ACTIVITY_LOG_ON_STUDY",
		response
	}
}

//setUpdateResponseUniquefield
export const setUpdateResponseUniquefield = (response) => {
	return {
		type: "SET_UPDATE_RESPONSE_UNIQUE_FIELD",
		response
	}
}

//emptyUniqueFieldResponse
export const emptyUniqueFieldResponse = () => {
	return {
		type: "SET_EMPTY_RESPONSE_UNIQUE_FIELD",
	}
}

//setEditableStudy
export const setEditableStudy = (editStudyData) => {
	return {
		type: "SET_EDIT_STUDY_DATA",
		editStudyData
	}
}

//setClientListStudy 
export const setClientListStudy = (clientList) => {
	return {
		type: "SET_CLIENT_LIST_TO_STUDY",
		clientList
	}
}

//setResponseUpdateStudies
export const setResponseUpdateStudies = (response) => {
	return {
		type: "SET_UPDATE_STUDY_RESPONSE",
		response
	}
}


//setResponseAPIUpdateStudies
export const setResponseAPIUpdateStudies = (response) => {
	return {
		type: "SET_API_UPDATE_STUDY_RESPONSE",
		response
	}
}

// emptyStudyResponse
export const emptyStudyResponse = () => {
	return {
		type: "SET_STUDY_PROPS",

	}
}

export const setParticipantCount = (thirdPartyTotalCount) => {
	return {
		type: "SET_PARTICIPANT_COUNT",
		thirdPartyTotalCount

	}
}

export const setCloneStudyId = (cloneStudiesId) => {
	return {
		type: "SET_CLONE_STUDY_LIST_ID",
		cloneStudiesId

	}
}

export const emptyResUpdateMapping = () => {
	return {
		type: "SET_EMPTY_UPDATE_MAPING_RES"
	}
}

export const setProgressDataOnUpdateStudy = (response) => {
	return {
		type: "SET_UPDATE_STUDY_PROGRESS_DATA",
		response
	}
}

export const setProgressDataOnUpdateAPIStudy = (response) => {
	return {
		type: "SET_UPDATE_API_STUDY_PROGRESS_DATA",
		response
	}
}

export const setQuotaDataOfAPIStudy = (response) => {
	return {
		type: "SET_QUOTA_DATA_API_STUDY",
		response
	}
}

export const setProgressDataByStudyMap = (response) => {
	return {
		type: "SET_PROGRESS_DATA_STUDY_MAP",
		response
	}
}

export const setAllCountryLangCode = (response) => {
	return {
		type: "SET_ALL_COUNTRY_LANG_CODE",
		response
	}
}

export const setInvitationToVendorsRes = (response) => {
	return {
		type: "SET_INVITATION_VENDORS_RESP",
		response
	}
}

export const getUniqueLinkData = (id) => {
	return dispatch => {
		uniqueLinkDataApi(id).then((result) => {

			dispatch(setuniqueLinkData(result));
		});
	}
}

export const search = (data) => {
	return dispatch => {
		dispatch(emptyStudyList())
		searchApi(data).then((result) => {
			dispatch(setStudiesList(result));
		});
	}
}


export const searchInArchive = (data) => {
	return dispatch => {
		dispatch(emptyStudyList())
		searchInArchiveApi(data).then((result) => {
			dispatch(setStudiesList(result));
		});
	}
}

export const invitationToPanellist = (invitationData) => {
	return dispatch => {
		// dispatch(startSpinner());
		invitationToPanellistApi(invitationData).then((result) => {
			// dispatch(endSpinner());
			dispatch(setInvitationToPanellistARes(result));
		});
	}
}

export const createCloneStudy = (studyData) => {
	return dispatch => {
		dispatch(startSpinner());
		createCloneStudyApi(studyData).then((result) => {
			dispatch(endSpinner());
			dispatch(setCreateCloneRes(result));
		});
	}
}

export const uploadDoc = (file, studyId) => {
	return dispatch => {
		dispatch(startSpinner());
		uploadDocApi(file, studyId).then((result) => {
			dispatch(endSpinner());
			//dispatch(setMessage(result));
			dispatch(setUploadDocRes(result));
		});
	}
}

export const getParticipantList = (id) => {
	return dispatch => {
		dispatch(startSpinner());
		getParticipantListApi(id).then((result) => {
			dispatch(endSpinner());
			dispatch(setParticipantList(result));
		});
	}
}

export const addStudies = (data) => {
	return dispatch => {
		dispatch(startSpinner())
		studiesApi(data).then((result) => {
			dispatch(endSpinner())
			//dispatch(setMessage(result));
			dispatch(emptyStudyList());
			dispatch(setStudies(result));
		});
	}
}

export const addParticipant = (data) => {
	return dispatch => {
		dispatch(startSpinner());
		participantApi(data).then((result) => {
			dispatch(endSpinner());
			dispatch(setParticipant(result));

		});
	}
}


export const addnewParticipant = (data) => {
	return dispatch => {

		addnewParticipantApi(data).then((result) => {

			dispatch(setnewParticipant(result));

		});
	}
}

export const updateParticipant = (data) => {
	return dispatch => {

		updateParticipantApi(data).then((result) => {

			dispatch(setUpdateParticipant(result));

		});
	}
}

export const studiesList = (page, userBy, role, status) => {
	return dispatch => {
		dispatch(startSpinner());
		dispatch(emptyStudyList())
		studiesListApi(page, userBy, role, status).then((result) => {
			dispatch(endSpinner());
			dispatch(setStudiesList(result));
		});
	}
}

export const archivingStudiesList = (page, userBy, role, status) => {
	return dispatch => {
		dispatch(startSpinner());
		dispatch(emptyStudyList())
		archivingStudiesListApi(page, userBy, role, status).then((result) => {
			dispatch(endSpinner());
			dispatch(setStudiesList(result));
		});
	}
}

//studiesListByFilter
export const studiesListByFilter = (page, filterType,id,userBy, role, status) => {
	return dispatch => {
		dispatch(startSpinner());
		dispatch(emptyStudyList())
		studiesListByFilterApi(page,filterType,id, userBy, role, status).then((result) => {
			dispatch(endSpinner());
			dispatch(setStudiesList(result));
		});
	}
}
//cloneStudiesList
export const cloneStudiesList = (page, userBy, role, status, studyID) => {
	return dispatch => {
		dispatch(startSpinner());
		dispatch(emptyStudyList())
		cloneStudiesListApi(page, userBy, role, status, studyID).then((result) => {
			dispatch(endSpinner());
			dispatch(setCloneStudiesList(result));
		});
	}
}

//For API project List Starts From Here.....

export const getAPIProjectList = (page) => {
	return dispatch => {
		dispatch(startSpinner());
		dispatch(emptyAPIProjectListList())
		apiProjectsListApi(page).then((result) => {
			dispatch(endSpinner());
			dispatch(setAPIProjectsList(result));
		});
	}
}

export const searchAPIProject = (data) => {
	return dispatch => {
		dispatch(startSpinner());
		dispatch(emptyAPIProjectListList())
		searchAPIProjectApi(data).then((result) => {
			dispatch(endSpinner());
			dispatch(setAPIProjectsList(result));
		});
	}
}

//sortAPIProject
export const sortAPIProject = (data) => {
	return dispatch => {
		dispatch(startSpinner());
		dispatch(emptyAPIProjectListList())
		sortAPIProjectApi(data).then((result) => {
			dispatch(endSpinner());
			dispatch(setAPIProjectsList(result));
		});
	}
}


export const emptyAPIProjectListList = () => {
	return {
		type: "SET_API_PROJECT_LIST_EMPTY",
	}
}


export const setAPIProjectsList = (apiProjectsList) => {
	return {
		type: "SET_API_PROJECT_LIST",
		apiProjectsList
	}
}





export const addMapping = (data) => {
	return dispatch => {
		dispatch(startSpinner());
		addMappingApi(data).then((result) => {
			dispatch(endSpinner());
			//dispatch(setMessage(result));
			if (result.success) {
				dispatch(setMappingResponseSuccess(result));
			} else {
				dispatch(setMappingResponseFail(result));
			}

		});
	}
}

export const updateMapping = (data) => {
	return dispatch => {
		dispatch(startSpinner());
		updateMappingApi(data).then((result) => {
			dispatch(endSpinner());
			// //dispatch(setMessage(result));
			dispatch(setUpdateMappingResponse(result));

		});
	}
}

export const dataByStudyId = (data) => {
	return dispatch => {
		dispatch(startSpinner());
		dataByStudyIdApi(data).then((result) => {
			dispatch(endSpinner());
			dispatch(setMappingResponse(result));

		});
	}
}

//
export const archivingDataByStudyId = (data) => {
	return dispatch => {
		dispatch(startSpinner());
		archivingDataByStudyIdApi(data).then((result) => {
			dispatch(endSpinner());
			dispatch(setMappingResponse(result));

		});
	}
}

export const addDemoMapping = (data) => {
	return dispatch => {
		dispatch(startSpinner());
		demoMappingApi(data).then((result) => {
			dispatch(demoListAndMapping(data.studyId));
			dispatch(endSpinner());
			//dispatch(setMessage(result));
			dispatch(setdemoMappingResponse(result));
		});
	}
}

export const demoListAndMapping = (id) => {
	return dispatch => {
		dispatch(startSpinner());
		demoListMappingApi(id).then((result) => {
			dispatch(endSpinner());
			dispatch(setDemoListMapping(result));
		});
	}
}


export const participantDemoData = (id) => {
	return dispatch => {
		participantDemoDataApi(id).then((result) => {

			dispatch(setparticipantDemoData(result));
		});
	}
}

export const addParticipantReply = (data) => {
	return dispatch => {
		addParticipantReplyApi(data).then((result) => {
			if (result.newSurveyParticipant) {

				dispatch(setparticipantRedirectUrl(result));
			} else {
				dispatch(setparticipantReplyRes(result));
			}

		});
	}
}

export const DemoMapping = (id) => {

	return dispatch => {
		dispatch(startSpinner());
		DemoMappingListApi(id).then((result) => {
			dispatch(endSpinner());
			dispatch(setDemoMapping(result));
		});
	}
}

export const getOptions = (id) => {
	return dispatch => {
		dispatch(startSpinner());
		getOptionsApi(id).then((result) => {
			dispatch(endSpinner());
			dispatch(setOptions(result));
		});
	}
}
//Add Constrainst
export const addConstraint = (data) => {
	return dispatch => {
		dispatch(startSpinner());
		addConstraintApi(data).then((result) => {
			dispatch(endSpinner());
			//dispatch(setMessage(result));
			dispatch(setaddConstraintRes(result));
		});
	}
}

export const updateParticipantData = (data) => {
	return dispatch => {
		updateParticipantDataApi(data).then((result) => {

			dispatch(updateParticipantDataRes(result));
		});
	}
}

export const softDelete = (id, currentPage) => {
	return dispatch => {
		dispatch(startSpinner());
		softDeleteApi(id, currentPage).then((result) => {
			dispatch(endSpinner());
			let data = {}
			data.id = id;
			data.currentPage = currentPage;
			dispatch(setStudyDeleteResult(data));
			//dispatch(setStudiesList(result));
		});
	}
}


//getAllBDEbyAdmin
export const getAllBDEbyAdmin = (data) => {
	return dispatch => {
		dispatch(startSpinner());
		getAllBDEbyAdminApi(data).then((result) => {
			dispatch(endSpinner());
			if (result.success) {
				dispatch(setBDEusresList(result));
			}
		});
	}
}

//getStudiesExcelList
export const getStudiesExcelList = (userBy, userType) => {
	return dispatch => {

		getStudiesExcelListApi(userBy, userType).then((result) => {

			//dispatch(setStudiesList(result));
		});
	}
}

//getThirdPartyListByStudies
export const getThirdPartyListByStudies = (studyId) => {
	return dispatch => {
		dispatch(startSpinner());
		getThirdPartyListByStudiesAPI(studyId).then((result) => {
			dispatch(endSpinner());
			if (result.success) {
				dispatch(setThirdPartyListByStudy(result));
			} else {

			}
		});
	}
}

//updateStudyUniqueField
export const updateStudyUniqueField = (data) => {
	return dispatch => {
		dispatch(startSpinner());
		updateStudyUniqueFieldApi(data).then((result) => {
			dispatch(endSpinner());
			dispatch(setUpdateResponseUniquefield(result));
		});
	}
}

//getActivitylogByStudy
export const getActivitylogByStudy = (currentPage, studyId, field) => {
	return dispatch => {
		dispatch(startSpinner());
		getActivitylogByStudyApi(currentPage, studyId, field).then((result) => {
			dispatch(endSpinner());
			if (result.success) {
				dispatch(setActivityLog(result));
			} else {
				alert(result.message);
			}
		});
	}
}

//getDataByStudyID
export const getDataByStudyID = (studyId) => {
	return dispatch => {
		dispatch(startSpinner());
		getDataByStudyIDApi(studyId).then((result) => {
			dispatch(endSpinner());
			if (result.success) {
				dispatch(setEditableStudy(result));
			} else {
				alert(result.message);
			}
		});
	}
}

//getAllclients
export const getAllclients = (userId, roleType) => {
	return dispatch => {
		dispatch(startSpinner());
		getAllclientsApi(userId, roleType).then((result) => {
			dispatch(endSpinner());
			if (result.success) {
				dispatch(setClientListStudy(result));
			} else {
				//alert( result.message);
			}
		});
	}
}

//UpdateStudiesData
export const UpdateStudiesData = (data) => {
	return dispatch => {
		dispatch(startSpinner());
		UpdateStudiesDataApi(data).then((result) => {
			dispatch(endSpinner());

			//dispatch(setMessage(result));
			if (result.success) {
				dispatch(setResponseUpdateStudies(result));
			}
		});
	}
}


//updateInMarket
export const updateInMarket = (data) => {
	return dispatch => {
		dispatch(startSpinner());
		updateInMarketApi(data).then((result) => {
			dispatch(endSpinner());

			//dispatch(setMessage(result));
			if (result.success) {
				dispatch(setResponseAPIUpdateStudies(result));
			}
		});
	}
}

export const thirdPartyParticipantCount = (item) => {
	return dispatch => {
		dispatch(startSpinner());
		participantCountApi(item).then((result) => {
			dispatch(endSpinner());
			dispatch(setParticipantCount(result));

		});
	}
}


export const archivingThirdpartyParticipantCount = (item) => {
	return dispatch => {
		dispatch(startSpinner());
		archivingThirdpartyParticipantCountApi(item).then((result) => {
			dispatch(endSpinner());
			dispatch(setParticipantCount(result));

		});
	}
}

//getProgressReportDataByStudy
export const getProgressReportDataByStudy = (studyId) => {
	return dispatch => {
		dispatch(startSpinner());
		getProgressReportDataByStudyApi(studyId).then((result) => {
			dispatch(endSpinner());
			dispatch(setProgressDataOnUpdateStudy(result));

		});
	}
}

//getArchivedProgressReportDataByStudy
export const getArchivedProgressReportDataByStudy = (studyId) => {
	return dispatch => {
		dispatch(startSpinner());
		getArchivedProgressReportDataByStudyApi(studyId).then((result) => {
			dispatch(endSpinner());
			dispatch(setProgressDataOnUpdateStudy(result));

		});
	}
}

export const getProgressReportDataByApiStudy = (studyId) => {
	return dispatch => {
		dispatch(startSpinner());
		getProgressReportDataByApiStudyApi(studyId).then((result) => {
			dispatch(endSpinner());
			dispatch(setProgressDataOnUpdateAPIStudy(result));

		});
	}
}

export const getQuotaOfApiStudy = (studyId) => {
	return dispatch => {
		getQuotaDataOfApiStudyApi(studyId).then((result) => {
			dispatch(setQuotaDataOfAPIStudy(result));
		});
	}
}

//getProgressReportDataByStudyMap
export const getProgressReportDataByStudyMap = (studyId) => {
	return dispatch => {
		dispatch(startSpinner());
		getProgressReportDataByStudyMapApi(studyId).then((result) => {
			dispatch(endSpinner());
			dispatch(setProgressDataByStudyMap(result));

		});
	}
}

//getArchivedProgressReportDataByStudyMap
export const getArchivedProgressReportDataByStudyMap = (studyId) => {
	return dispatch => {
		dispatch(startSpinner());
		getArchivedProgressReportDataByStudyMapApi(studyId).then((result) => {
			dispatch(endSpinner());
			dispatch(setProgressDataByStudyMap(result));

		});
	}
}


//getDefaultVendor
export const getDefaultVendor = (sid) => {
	return dispatch => {
		dispatch(startSpinner());
		getDefaultVendorApi(sid).then((result) => {
			dispatch(endSpinner());
			dispatch(setDefaultVendor(result));

		});
	}
}

export const sendInvitationToVendors = (study_id) => {
	return dispatch => {
		dispatch(startSpinner());
		sendInvitationToVendorsApi(study_id).then((result) => {
			dispatch(endSpinner());
			dispatch(setInvitationToVendorsRes(result));
		});
	}
}

//getAllCloneStudiesByStudy
export const getAllCloneStudiesByStudy = (sid) => {
	return dispatch => {
		dispatch(startSpinner());
		getAllCloneStudiesByStudyApi(sid).then((result) => {
			dispatch(endSpinner());
			dispatch(setCloneStudyId(result));

		});
	}
}

//getAllCountryLangCode
export const getAllCountryLangCode = () => {
	return dispatch => {
		dispatch(startSpinner());
		getAllCountryLangCodeApi().then((result) => {
			dispatch(endSpinner());
			dispatch(setAllCountryLangCode(result));
		});
	}
}

import React, { Component }  from 'react';
import { connect } from 'react-redux';
import DemographicHeader from '../../components/Demographics/demographicHeader.jsx';
import AddQuestionDiv from '../../components/Demographics/addDemoQuestion.jsx';
import { addQuery, emptyQuestionUpdateRes} from '../../actions/actionsBag/Demographic';
import goback from '../../images/goback.png';
import { alertMessage }  from '../../alertsDialog/Action';
import {Link} from 'react-router-dom';

    class AddQuestionView extends Component {

        constructor(props) {
            super(props)
            this.state = {
                demographicsData : "",
                demoId : ""
            }
            this.addQuery = this.addQuery.bind(this);
        }

    componentWillMount() {
        const { demographicsData } = this.props.location.state;
       if(demographicsData){
            this.setState({ demographicsData : demographicsData , demoId : demographicsData._id});
       }else{
           this.props.history.push('/demographics-list');
       }
      }

      

      componentWillReceiveProps(nextProps){
                
        if(nextProps.querySuccess && nextProps.querySuccess.success  && nextProps.querySuccess.message ){
            this.props.dispatch(emptyQuestionUpdateRes());
            let alertData ={};
            alertData.type ="alert";
            alertData.message = nextProps.querySuccess.message;
            this.props.dispatch(alertMessage(alertData));

            if(nextProps.querySuccess.query && (nextProps.querySuccess.query.queryType === "checkbox" || nextProps.querySuccess.query.queryType === "radio" )){
                this.props.history.push({
                    pathname : '/add-option',
                    state:{ questionData : nextProps.querySuccess.query }
                    });
            }else{
                this.props.history.push('/demographics-list');
            }

            
        }else if(nextProps.querySuccess && (nextProps.querySuccess.success === false)  && nextProps.querySuccess.message){
            this.props.dispatch(emptyQuestionUpdateRes());
            let alertData ={};
            alertData.type ="alert";
            alertData.message = nextProps.querySuccess.message;
            this.props.dispatch(alertMessage(alertData));
        }
      }

      goBack(){
          window.history.back();
      }

      addQuery( data){
        this.props.dispatch(addQuery(data));
      }

    render(){

        const header = 'Add Question';
        const headerText = 'Here you can add new question of current demographic';
        const headerButton =  <button className="back-btn back-btn-color" onClick={ this.goBack}><img src={goback} height="13px" width="13px" /><span> Back</span></button>


        return(
            <div style={{ width:"100%"}}>  
                <DemographicHeader 
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                />
                <AddQuestionDiv
                    demographicsData = { this.state.demographicsData}
                    addQuery = {this.addQuery}
                />
            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        querySuccess: state.demographics.querySuccess,
    }
  }
  
export default connect(mapStateToProps)(AddQuestionView);
import React, { Component } from 'react';
import { getDemoMappedList, updateQuestionOrder } from '../../../actions/actionsBag/demoMapping';
import { alertMessage } from '../../../alertsDialog/Action';
import { connect, } from 'react-redux';
import { Link } from 'react-router-dom';
// import Pagination from "react-js-pagination";
import deletIcon from '../../../images/delete.png';
import editpencil from '../../../images/edit-pencil.png';
import add from '../../../images/add.png';
import Sortable from 'react-sortablejs';
import $ from 'jquery';
import './style.css';

class DemoMappingList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: "",
      currentPage: 1,
      studyId: "",
      demoMappList: [],
      totalPage: 0,
      showloader: false
    }
    this.handlePageChange = this.handlePageChange.bind(this);
    this.softDeleteRole = this.softDeleteRole.bind(this);
  }


  componentWillReceiveProps(nextProps) {

    if (nextProps.confirmDialog === true) {
      // alert(nextProps.confirmData);
      //this.props.dispatch(emptyDialogdata());
      let userId = localStorage.getItem("auth_user_id");
      let data = {}
      data.role_id = nextProps.confirmData;
      data.userId = userId;
      //  this.props.dispatch(softRoleDelete(data));
    }

    if (nextProps.roleDeleteSuccess === true && nextProps.roleDeleteMessage) {
      let alertData = {};
      alertData.type = "alert";
      alertData.message = nextProps.roleDeleteMessage;
      this.props.dispatch(alertMessage(alertData));
      //this.props.dispatch(getAllRolesByAdminByPage(1));
      this.setState({ currentPage: 1 });
      //this.props.dispatch(blankResRoles());
    } else if (nextProps.roleDeleteMessage) {
      let alertData = {};
      alertData.type = "alert";
      alertData.message = nextProps.roleDeleteMessage;
      this.props.dispatch(alertMessage(alertData));
      //this.props.dispatch(blankResRoles());
    }

    if (nextProps.demoMappedListSuccess && nextProps.demoMappedListData.length > 0) {
      this.setState({
        demoMappList: nextProps.demoMappedListData,
        showloader: true,
      });
    } else {
      this.setState({
        totalPage: 0,
        showloader: true,
      });
    }



  }



  componentWillMount() {
    let studyId = localStorage.getItem("study_Id");
    this.setState({ studyId: studyId });
    this.props.dispatch(getDemoMappedList(studyId, 1));
  }

  handlePageChange(pageNumber) {

    this.setState({ currentPage: pageNumber, demoMappList: [] });

    //this.props.dispatch(getAllRolesByAdminByPage(pageNumber));
  }


  softDeleteRole(id) {
    let alertData = {};
    alertData.type = "confirmalert";
    alertData.message = "Are you sure to delete this item ?";
    alertData.deleteId = id;
    this.props.dispatch(alertMessage(alertData));
  }

  tableZoom() {
    $(".alltablestruct").toggleClass("zoom-me");
    // $("#routercontainer").toggleClass("router-containter-pd");
    // document.getElementById("routercontainer").style.padding="0px";

  }

  render() {

    let screenerListShow = "";

    let screenersRangeListShow = "";


    if (this.state.demoMappList && this.state.demoMappList.length > 0) {


      let studyDemoMapped = this.state.demoMappList[0];
      let studyRangeMapped = this.state.demoMappList[1];

      var mappedScreeners = studyDemoMapped.concat(studyRangeMapped);

      mappedScreeners.sort(function (a, b) {
        return a.order_no - b.order_no;
      });

      screenerListShow = mappedScreeners.map((item, i) => {
        return <div id="dragdata">
          <ul id="projectinlive" key={item._id}>
            <li id="counter1"><span>{i + 1}</span></li>

            <li id="screenername1"><span>{item.name}</span></li>
            <Link to={{
              pathname: '/applyconstraints',
              state: {
                screenerData: item,
                projectID: this.state.studyId
              }

            }}>
              <li style={{ marginTop: "5px" }} className="icons">
                <div className="pencil"   ><img src={editpencil} height="20px" width="20px" /> </div>
              </li></Link>


          </ul>
        </div>
      });
    }




    let showTable = "";
    if (this.state.demoMappList && this.state.demoMappList.length > 0) {
      showTable =
        <Sortable
          tag="div" // Defaults to "div"
          onChange={(order, sortable, evt) => {
            let data = {};
            data.oldIndex = evt.oldIndex;
            data.newIndex = evt.newIndex;
            data.projectId = this.state.projectID;
            data.languageCode = this.state.languageCode;

            let mySwapedData = [];


            if (this.state.demoMappList && this.state.demoMappList.length > 0) {


              let studyDemoMapped = this.state.demoMappList[0];
              let studyRangeMapped = this.state.demoMappList[1];

              var mappedScreeners1 = studyDemoMapped.concat(studyRangeMapped);

              mappedScreeners1.sort(function (a, b) {
                return a.order_no - b.order_no;
              });

              // let m =  swapArrayElements(mappedScreeners1,evt.oldIndex , evt.newIndex);

              //oldIndex , newIndex


              let firstIndex = mappedScreeners1[evt.oldIndex].order_no;
              let secondIndex = mappedScreeners1[evt.newIndex].order_no;
              mappedScreeners1[evt.newIndex].order_no = firstIndex;
              mappedScreeners1[evt.oldIndex].order_no = secondIndex;


              let mArrayToResetOrder = [];
              for (let i = 0; i < mappedScreeners1.length; i++) {
                let data = {};
                data.id = mappedScreeners1[i].id;
                data.order_no = mappedScreeners1[i].order_no;
                data._id = mappedScreeners1[i]._id;
                mArrayToResetOrder.push(data);
              }
              data.orderToChange = mArrayToResetOrder;
              data.projectId = this.state.studyId;

              this.props.dispatch(updateQuestionOrder(data))

            }
          }}
        >
          {screenerListShow}
        </Sortable>

    } else if (this.state.showloader === true) {
      showTable = <div className="datanotfound"> No record found</div>
    } else {
      showTable = <div className="spiner-data"><div className="tblspinner"></div></div>
    }



    return (
      <div className="list-style">
        <div className="table-st">
          <div className="alltablestruct forms-rd-shadow">
            {/* serachbox and stausbtn */}
            <div id="search-staus">

              <div className="flex-show  tbl-h-font">
                Demographic's Mapped List
                            </div>
              <div style={{ marginLeft: "auto" }}>
                <Link to="/add-demo-map"><button style={{ marginTop: "0px", marginRight: "10px" }} className="create-btn create-btn-color" ><img src={add} height="12px" width="12px" /><span> Add Demographic</span></button></Link>
                <Link to="/constraintslist"><button style={{ marginTop: "0px", marginRight: "30px" }} className="create-btn create-btn-color" ><span> All Constraints</span></button></Link>
              </div>
              <div className="flex-show tbl-zoom" >
                <span onClick={this.tableZoom} className="full-screen mr-rhs-20 mr-top-10"> </span>
              </div>
            </div>
            {/* serachbox and stausbtn */}

            {/* table */}
            <div style={{ marginTop: "20px", overflowX: "auto" }}>
              <div id="dragheading">
                <ul>
                  <li id="counter">S.No</li>
                  <li id="screenername">Demographic Name</li>
                  <li id="eyeicon">View</li>
                </ul>
              </div>
              {showTable}
            </div>
            {/* table */}

            {/* <div id="pageinationstyle">
                       
                      <Pagination
                          activePage={this.state.currentPage}
                          itemsCountPerPage={4}
                          totalItemsCount={this.state.totalPage}
                          pageRangeDisplayed={4}
                          onChange={this.handlePageChange}
                        />
                        </div> */}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    demoMappedListSuccess: state.demoMapped.demoMappedListSuccess,
    demoMappedListData: state.demoMapped.demoMappedListData,

    confirmDialog: state.alertsDialog.confirmDialog,
    confirmData: state.alertsDialog.confirmData
  }
}

export default connect(mapStateToProps)(DemoMappingList);


import React, { Component }  from 'react';
import AddDemoMapList from '../../../components/ProjectActivities/DemoMapping/addDemoMap';
import $ from 'jquery';
import { alertMessage }  from '../../../alertsDialog/Action';
import { connect } from 'react-redux';
import UpdateStudyHeader from '../../../components/Study/updateStudyHeader';

import ApplyConstraintsView from '../../../components/ProjectActivities/DemoMapping/applyConstraints';

import {getQueryAndOption, addConstrainsts, emptyQuotaProps } from '../../../actions/actionsBag/demoMapping';

export class ApplyConstraints extends Component {
    constructor(props) {
        super(props)
        this.state = {
            screenerData : "", 
            projectID  : ""
        }
        this.selectActivity = this.selectActivity.bind(this);
        this.addConstrainsts = this.addConstrainsts.bind(this);
        this.emptyQuotaProps = this.emptyQuotaProps.bind(this);
    }

    

    componentDidMount(){
        if( this.props.location.state === undefined){
                // this.props.history.push("error");
              }else{
              const { screenerData } = this.props.location.state;
              const { projectID }  = this.props.location.state;
              this.setState({
                screenerData : screenerData, 
                projectID  : projectID
            });
            // alert(screenerData.demographicId +"  ++ "+ projectID);
            this.props.dispatch(getQueryAndOption(screenerData._id , projectID));
          }
       }


    selectActivity(activityName){
        this.props.history.push(activityName);
    }

    gotoBack(){
        window.history.back();
    }

    addConstrainsts(data){
        this.props.dispatch(addConstrainsts(data));
    }
    
    emptyQuotaProps(){
            this.props.dispatch(emptyQuotaProps());
    }
    render(){
        return(
            <div>
                
              
                <UpdateStudyHeader
                    selectActivity = {this.selectActivity}
                    editstudyData = {this.state.editstudyData}
                    gotoBack = {this.gotoBack}
                />

                <ApplyConstraintsView 
                    demographicData = { this.state.screenerData }
                    studyId = { this.state.projectID }
                    quotaAndQueryOptions = { this.props.quotaAndQueryOptions }
                    quota = { this.props.quota }
                    addConstrainsts = { this.addConstrainsts }
                    quotaMessage = { this.props.quotaMessage }
                    emptyQuotaProps = { this.emptyQuotaProps }
                />

            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        quotaAndQueryOptions : state.demoMapped.quotaAndQueryOptions,
        quota : state.demoMapped.quota,
        quotaMessage : state.demoMapped.quotaMessage,
        quotaRemovedSuccess : state.demoMapped.quotaRemovedSuccess
    }
  }
  
  export default connect(mapStateToProps)(ApplyConstraints);
import React, { Component }  from 'react';
import ActivityLogUpdateForm from './activityLogUpdate';
import UpdateStudyHeader from '../Study/updateStudyHeader';
import ActivityLogList from './activityLogList';
import StudyProgress from '../Study/studyProgress';
import { getProgressReportDataByStudy} from '../../actions/actionsBag/study';
import $ from 'jquery';
import {connect} from 'react-redux';
import appConfig from '../../api/apiConfig';
import '../../pages/Study/tempstyle.css';

class ProjectActivity extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
       
    }

    componentDidMount(){
    $(document).ready(function(){
       

        $("#tg-btn-10").click(function(){
         $("#show-form-10").toggleClass("show-full-div");
         $("#hide-me-10").toggleClass("tg-hide-act-log-form");
         $("#rotate-icon-10").toggleClass("rotate-tgl");
        });

        
        $("#tg-btn-11").click(function(){
            $("#show-form-11").toggleClass("show-full-div");
            $("#hide-me-11").toggleClass("tg-hide-act-log-list");
            $("#rotate-icon-11").toggleClass("rotate-tgl");
           });
    

    });
}


    render(){
        return(
            <div>
                 
                 <div className="mr-top-40">
                    <div className="create-form-container">
                        <div className="forms-rd-shadow toggle-form show-me"  id="show-form-10">
                            <ActivityLogUpdateForm
                            />
                        </div>
                    </div>
                </div>

                <div className="create-form ">
                    <div className="create-form-container ">
                        <div className="forms-rd-shadow toggle-form show-me" id="show-form-11">
                            <ActivityLogList/>
                        </div>
                    </div>
                </div>

            </div>
    )}
}
const mapStateToProps=(state)=>{
    return{
       
    }
  }
  
  export default connect(mapStateToProps)(ProjectActivity);
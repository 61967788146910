import React, { Component }  from 'react';
import  '../../commonStyle/common.css';
import search from '../../images/search.png';
import liveimg from '../../images/play.png';
import marketimg from '../../images/market.png';
import pause from '../../images/pause.png';
import right from '../../images/correct.png';
import $ from 'jquery';
import {connect} from 'react-redux';
import { studiesList, softDelete } from '../../actions/actionsBag/study';
import Pagination from "react-js-pagination";
import deletIcon from '../../images/delete.png';
import down_arrow_table from '../../images/down_arrow_table.png';
import editpencil from '../../images/edit-pencil.png';
import {Link} from 'react-router-dom';
import { alertMessage, emptyDialogdata }  from '../../alertsDialog/Action';


class ApiProjectListTable extends Component {
    constructor(props) {
        super(props)

        let defaultSort = {};
        defaultSort.pageNumber = 1;
        defaultSort.orderIn = "DESC";
        defaultSort.isAscending = false;
        let defaultSortOnID = [];
        defaultSortOnID.push(defaultSort); 
        this.state = {
            search: "",
            currentPage: 1,
            userId : "",
            userType : "",
            panrentProjectList : [],
            totalPage : 0,
            projectStatus : "all",
            showDeleteBtn : false,
            showloader: false,
            sortingProjectsData : defaultSortOnID,
            sortProjectsOn : "survey_id",
            sortType : "all"
        }
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.surveyIdOnClick = this.surveyIdOnClick.bind(this);
        this.surveyNameOnClick = this.surveyNameOnClick.bind(this);
        this.geAllProject = this.geAllProject.bind(this);
        this.getLiveProject = this.getLiveProject.bind(this);
        this.gePauseProject = this.gePauseProject.bind(this);
        this.geCompleteProject = this.geCompleteProject.bind(this);
        this.getInMarketProject = this.getInMarketProject.bind(this);

      }

    componentWillMount(){
     
    }
  
    componentDidMount(){
    
     
      $(".dropdown").on('click',function() { 
          var t = (this.id); 
        if(t === "all-rm-shadow"){
          var all= document.getElementsByClassName("dropdown");
        
          for(let a=0; a< all.length; a++){
              if ( $( all[a] ).removeClass( "active" ) ) {
            
            }
          
            $(all[0]).addClass("active");
          }
        }else if(t === "market-rm-shadow"){
          var all= document.getElementsByClassName("dropdown");
        
          for(let a=0; a< all.length; a++){
              if ( $( all[a] ).removeClass( "active" ) ) {
            
            }
          
            $(all[1]).addClass("active");
          }
        }else if(t === "live-rm-shadow"){
          
          var live= document.getElementsByClassName("dropdown");
        
          for(let a=0; a< live.length; a++){
              if ( $( live[a] ).removeClass( "active" ) ) {
            }
            $(live[2]).addClass("active");
          }
        }else if(t === "pause-rm-shadow"){
          var pause= document.getElementsByClassName("dropdown");
        
          for(var a=0; a< pause.length; a++){
              if ( $( pause[a] ).removeClass( "active" ) ) {
            
            }
          
            $(pause[3]).addClass("active");
          }
      }else if(t === "com-rm-shadow"){
          var com= document.getElementsByClassName("dropdown");
        
          for(let a=0; a< com.length; a++){
              if ( $( com[a] ).removeClass( "active" ) ) {
            
            }
          
            $(com[4]).addClass("active");
          }
        }
      });  

      // alert(this.container.offsetWidth +  ':'+ this.container.offsetHeight);
}

  componentWillReceiveProps(nextProps){

    if(nextProps.apiProjectListPage>0){
        // alert(nextProps.apiProjectListPage);
      this.setState({
        totalPage : nextProps.apiProjectListPage,
        showloader: false,
      });
    }else{
      this.setState({
        totalPage : 0,
        showloader: true,
      });
    }
  }

  geAllProject(){
    let status="all";
    this.setState({
      projectStatus : status,
      currentPage : 1
    });
   
    let data = {};
    data.sortOn = "survey_id";
    data.pageNumber = "1";
    data.orderIn = "DESC";
    data.isAscending = false;
    data.sortType = status;
    let sortOn = [];
    sortOn.push(data);
    this.setState({
      sortingProjectsData : sortOn,
      sortProjectsOn : "survey_id",
      sortType : status
    });

      this.props.sortStudy(data);
  }
  

  
  getInMarketProject(){
    let status="inMarket";
    this.setState({
      projectStatus : status,
      currentPage : 1
    });

    let data = {};
    data.sortOn = "survey_id";
    data.pageNumber = "1";
    data.orderIn = "DESC";
    data.isAscending = false;
    data.sortType = status;
    let sortOn = [];
    sortOn.push(data);
    this.setState({
      sortingProjectsData : sortOn,
      sortProjectsOn : "survey_id",
      sortType : status
    });

      this.props.sortStudy(data);

   // this.props.studiesListBypage( 1, status);
  }

  getLiveProject(){
    let status="live";
    this.setState({
      projectStatus : status,
      currentPage : 1
    });
    let data = {};
    data.sortOn = "survey_id";
    data.pageNumber = "1";
    data.orderIn = "DESC";
    data.isAscending = false;
    data.sortType = status;
    let sortOn = [];
    sortOn.push(data);
    this.setState({
      sortingProjectsData : sortOn,
      sortProjectsOn : "survey_id",
      sortType : status
    });

      this.props.sortStudy(data);
  }
  
  gePauseProject(){
    let status="pause";
    this.setState({
      projectStatus : status,
      currentPage : 1
    });

    let data = {};
    data.sortOn = "survey_id";
    data.pageNumber = "1";
    data.orderIn = "DESC";
    data.isAscending = false;
    data.sortType = status;
    let sortOn = [];
    sortOn.push(data);
    this.setState({
      sortingProjectsData : sortOn,
      sortProjectsOn : "survey_id",
      sortType : status
    });

      this.props.sortStudy(data);
  }
  
  
  geCompleteProject(){
    let status="complete";
    this.setState({
      projectStatus : status,
      currentPage : 1
    });
    let data = {};
    data.sortOn = "survey_id";
    data.pageNumber = "1";
    data.orderIn = "DESC";
    data.isAscending = false;
    data.sortType = status;
    let sortOn = [];
    sortOn.push(data);
    this.setState({
      sortingProjectsData : sortOn,
      sortProjectsOn : "survey_id",
      sortType : status
    });

      this.props.sortStudy(data);
  }

handlePageChange(pageNumber){

  this.setState({ currentPage : pageNumber});
  
  const surveyIdSort = this.state.sortingProjectsData;
  if(surveyIdSort.length>0){
    let data = {};
    if(surveyIdSort[0].isAscending === true){
     
      data.sortOn = this.state.sortProjectsOn;
      data.pageNumber = pageNumber;
      data.orderIn = "ASC";
      data.isAscending = true;
      data.sortType = this.state.sortType;
    } else {
     
      data.sortOn = this.state.sortProjectsOn;
      data.pageNumber = pageNumber;
      data.orderIn = "DESC";
      data.isAscending = false;
      data.sortType = this.state.sortType;
    }
    this.props.sortStudy(data);

  } else {
    this.props.apiProjectListByPage(pageNumber);
  }
  
}



surveyIdOnClick(e){

  let sortColumnOn = e.target.id;
  const surveyIdSort = this.state.sortingProjectsData;
  if(surveyIdSort.length>0){
      if(this.state.sortProjectsOn === sortColumnOn){
          if(surveyIdSort[0].isAscending === true){
            // empty  here
            this.setState({
              sortingProjectsData : [],
              sortProjectsOn : ""
            });
            this.props.apiProjectListByPage(1);
          } else {
            // add true here
            let data = {};
            data.sortOn = sortColumnOn;
            data.pageNumber = "1";
            data.orderIn = "ASC";
            data.isAscending = true;
            data.sortType = this.state.sortType;
          let sortOn = [];
          sortOn.push(data);
          this.setState({
            sortingProjectsData : sortOn,
            sortProjectsOn : sortColumnOn
          });

            this.props.sortStudy(data);

          }
    } else {
      let data = {};
      data.sortOn = sortColumnOn;
      data.pageNumber = "1";
      data.orderIn = "DESC";
      data.isAscending = false;
      data.sortType = this.state.sortType;
      let sortOn = [];
      sortOn.push(data);
      this.setState({
        sortingProjectsData : sortOn,
        sortProjectsOn : sortColumnOn
      });

        this.props.sortStudy(data);
      }

  } else {
    let data = {};
    data.sortOn = sortColumnOn;
    data.pageNumber = "1";
    data.orderIn = "DESC";
    data.isAscending = false;
    data.sortType = this.state.sortType;
    let sortOn = [];
    sortOn.push(data);
    this.setState({
      sortingProjectsData : sortOn,
      sortProjectsOn : sortColumnOn
    });

      this.props.sortStudy(data);

  }
  
}

surveyNameOnClick(e){
  const surveyIdSort = this.state.sortingProjectsData;
  if(surveyIdSort.length>0){

      if(this.state.sortProjectsOn === "survey_name"){
          if(surveyIdSort[0].isAscending === true){
            // empty  here
            this.setState({
              sortingProjectsData : [],
              sortProjectsOn : ""
            });
            this.props.apiProjectListByPage(1);
          } else {
            // add true here
            let data = {};
            data.sortOn = "survey_name";
            data.pageNumber = "1";
            data.orderIn = "ASC";
            data.isAscending = true;
          let sortOn = [];
          sortOn.push(data);
          this.setState({
            sortingProjectsData : sortOn,
            sortProjectsOn : "survey_name"
          });
            this.props.sortStudy(data);

          }
    } else {
      let data = {};
      data.sortOn = "survey_name";
      data.pageNumber = "1";
      data.orderIn = "DESC";
      data.isAscending = false;
      let sortOn = [];
      sortOn.push(data);
      this.setState({
        sortingProjectsData : sortOn,
        sortProjectsOn : "survey_name"
      });
  
        this.props.sortStudy(data);
    }

  } else {
    let data = {};
    data.sortOn = "survey_name";
    data.pageNumber = "1";
    data.orderIn = "DESC";
    data.isAscending = false;
    let sortOn = [];
    sortOn.push(data);
    this.setState({
      sortingProjectsData : sortOn,
      sortProjectsOn : "survey_name"
    });

      this.props.sortStudy(data);
  }
}
// onChange(e){
//   if(e.target.value == ""){
//     this.props.studiesListBypage( 1, this.state.projectStatus);
//   }
//   this.setState({ [e.target.name] : e.target.value });
// }

searchStudy(){
  
  if(this.state.search){
    this.props.searchStudy( this.state.search );
  }else{
    this.props.apiProjectListByPage(1);
  }
  
}

handleSearch(e) {
  clearTimeout(this.interval);
  if (e.target.value) {
   this.setState({search: e.target.value})
    this.interval = setInterval(() => this.search(), 2000);
  } else {
    this.setState({search: e.target.value})
    this.props.apiProjectListByPage(1);
  }
}

search(){
  clearTimeout(this.interval);
  this.props.searchStudy( this.state.search );
}

    render(){
      // <span>&nbsp;🔽🔼</span>
      let projectList = "";
      if(this.props.apiProjectListData && this.props.apiProjectListData.length >0){
         
        let sr = (this.state.currentPage * 10)-10;
          projectList= this.props.apiProjectListData.map((list, i) => {  
          
            let statusBtnshow = ""
            if(list.survey_status === 21 || list.survey_status === "21"){
                statusBtnshow = <button className="cmpbtn" >Complete</button>
            }else if(list.survey_status === 22 || list.survey_status === "22"){
                statusBtnshow = <button className="livebtn">Live</button>
            }else if(list.survey_status === 33 || list.survey_status === "33" ){
                statusBtnshow = <button className="pause">Pause</button>
            }else{
                statusBtnshow = "Error!"
            }
              
                  return <tbody> <tr>  
                        <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom">{sr+i+1}</td>
                              <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">
                              <Link to = {{
                                            pathname: "/Api-Study-List-Update",
                                            state: {
                                                editstudyData : list,
                                              }
                                          }}
                                         >
                              {list.survey_id}
                              </Link>

                              {/*  onClick={this.setHeaderData} */}
                              </td>
                              <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.survey_name}</td>
                              <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.category}</td>
                              <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{statusBtnshow}</td>
                              <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.cpi}</td>
                              <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.complete_achieved}</td>
                              <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.complete_remaining}</td>
                              <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.complete_needed}</td>
                              <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.overall_ir}</td>
                              <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.overall_loi}</td>
                              </tr>                        
                               </tbody>
           });
        }else{
            projectList = "";
        }
   
        let projectTable ="";
        if(this.props.apiProjectListData && this.props.apiProjectListData.length >0){
          let surveyIdSpan = "";
          let surveyNameSpan = "";
          let cpiSpan = "";
          let totalCompleteSpan = "";
          let totalRemainingSpan = "";
          let totalSampleSpan = "";
          let avgIRSpan = "";
          let avgLOISpan = "";

          if(this.state.sortingProjectsData && this.state.sortingProjectsData.length > 0){
            const sortingOn = this.state.sortingProjectsData;
            if(this.state.sortProjectsOn === "survey_id"){
              if(sortingOn[0].isAscending === true){
                surveyIdSpan = <span>&nbsp;🔼</span>;
              } else {
                surveyIdSpan = <span>&nbsp;🔽</span>;
              }
            } else if(this.state.sortProjectsOn === "survey_name"){
              if(sortingOn[0].isAscending === true){
                surveyNameSpan = <span>&nbsp;🔼</span>;
              } else {
                surveyNameSpan = <span>&nbsp;🔽</span>;
              }
            } 

            else if(this.state.sortProjectsOn === "cpi"){
              if(sortingOn[0].isAscending === true){
                cpiSpan = <span>&nbsp;🔼</span>;
              } else {
                cpiSpan = <span>&nbsp;🔽</span>;
              }
            } else if(this.state.sortProjectsOn === "complete_achieved"){
              if(sortingOn[0].isAscending === true){
                totalCompleteSpan = <span>&nbsp;🔼</span>;
              } else {
                totalCompleteSpan = <span>&nbsp;🔽</span>;
              }
            } else if(this.state.sortProjectsOn === "complete_remaining"){
              if(sortingOn[0].isAscending === true){
                totalRemainingSpan = <span>&nbsp;🔼</span>;
              } else {
                totalRemainingSpan = <span>&nbsp;🔽</span>;
              }
            } else if(this.state.sortProjectsOn === "complete_needed"){
              if(sortingOn[0].isAscending === true){
                totalSampleSpan = <span>&nbsp;🔼</span>;
              } else {
                totalSampleSpan = <span>&nbsp;🔽</span>;
              }
            } else if(this.state.sortProjectsOn === "overall_ir"){
              if(sortingOn[0].isAscending === true){
                avgIRSpan = <span>&nbsp;🔼</span>;
              } else {
                avgIRSpan = <span>&nbsp;🔽</span>;
              }
            } else if(this.state.sortProjectsOn === "overall_loi"){
              if(sortingOn[0].isAscending === true){
                avgLOISpan = <span>&nbsp;🔼</span>;
              } else {
                avgLOISpan = <span>&nbsp;🔽</span>;
              }
            }
            
          } 


            projectTable = <table className="tbl-new-est">
          <thead>
            <tr>
                  <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">#</th>
                  <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom" id = "survey_id" onClick = {this.surveyIdOnClick}>SurveyId
                  {surveyIdSpan}
                  </th>
                  <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom" id = "survey_name" onClick = {this.surveyIdOnClick}>Survey Name
                  {surveyNameSpan}
                  </th>
                  <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">category</th>
                  <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Status</th>
                  <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom" id = "cpi" onClick = {this.surveyIdOnClick}>CPI
                  {cpiSpan}
                  </th>
                  <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom" id = "complete_achieved" onClick = {this.surveyIdOnClick}>Total Complete
                  {totalCompleteSpan}
                  </th>
                  <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom" id = "complete_remaining" onClick = {this.surveyIdOnClick}>Total Remaining
                  {totalRemainingSpan}
                  </th>
                  <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom" id = "complete_needed" onClick = {this.surveyIdOnClick}>Total Sample
                  {totalSampleSpan}
                  </th>
                  <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom" id = "overall_ir" onClick = {this.surveyIdOnClick}>Avg. IR
                  {avgIRSpan}
                  </th>
                  <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom" id = "overall_loi" onClick = {this.surveyIdOnClick}>Avg. LOI
                  {avgLOISpan}
                  </th>

              </tr>
              </thead>
            {projectList}
        </table>
        }else if(this.state.showloader === true){
            projectTable = <div className="datanotfound"> No record found</div>
        }else{
            projectTable =<div className="spiner-data"><div className="tblspinner"></div></div>
         
        }
      

        return(
            <div className="list-style">
                <div className="table-st">
                    <div className="alltablestruct forms-rd-shadow">
                         {/* serachbox and stausbtn */}
                        <div id="search-staus"  >

                            <div className="flex-show">
                                <input type="text" value={this.state.search} name="search" className="searchbox" placeholder="Search...." onChange={this.handleSearch} />
                                {/* <div>
                                    <button className="searchbtn" onClick={ this.searchStudy} style={{backgroundColor: "#FFFFFF", border: "none", outline: "none", marginTop: "5px", cursor: "pointer"}}> <img src={search} height="20px" width="20px"/></button>
                                </div> */}
                              </div>
                            <div className="flex-show">
                                <a href="JavaScript:void(0)" onClick={this.geAllProject}><div className="dropdown status-btn pad-right-2 status-font status-btn-shadow status-st active" id="all-rm-shadow"><span className="icon-All" style={{paddingLeft: "10px", paddingRight: "10px"}}>All</span></div></a>
                                <a href="JavaScript:void(0)" onClick={this.getInMarketProject}><div className="dropdown status-btn pad-right-2 status-font status-btn-shadow status-st icon-Market" id="market-rm-shadow"><span className="pad-right-2"><img src={marketimg} height="12px" width="12px"/></span><span >In Market</span></div></a>
                                <a href="JavaScript:void(0)" onClick={this.getLiveProject}><div className="dropdown status-btn pad-right-2 status-font status-btn-shadow status-st icon-Live" id="live-rm-shadow"><span className="pad-right-2"><img src={liveimg} height="12px" width="12px"/></span><span >Live</span></div></a>
                                <a href="JavaScript:void(0)" onClick={this.gePauseProject}><div className=" dropdown status-btn pad-right-2 status-font status-btn-shadow status-st icon-Pause" id="pause-rm-shadow"><span className="pad-right-2"><img src={pause} height="12px" width="12px"/></span><span className="">Pause</span></div></a>
                                <a href="JavaScript:void(0)" onClick={this.geCompleteProject}><div className="dropdown status-btn  pad-right-2 status-font status-btn-shadow status-st icon-Complete" id="com-rm-shadow"><span className="pad-right-2"><img src={right} height="16px" width="16px"/></span><span className="">Complete</span></div></a>
                            </div>

                            
                        </div>
                        {/* serachbox and stausbtn */}

                            {/* table */}
                            <div style={{marginTop: "20px", overflowX: "auto"}}>
                                 {projectTable}

                            </div>
                            {/* table */}
                   
                      <div id="pageinationstyle">
                       
                      <Pagination
                          activePage={this.state.currentPage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalPage}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                        </div>
                    
                    </div>
                </div>
            </div>
    )}
}

const mapStateToProps=(state)=>{
    return{
        apiProjectListSuccess : state.studies.apiProjectListSuccess,
        apiProjectListData : state.studies.apiProjectListData,
        apiProjectListPage : state.studies.apiProjectListPage
    }
  }
  
  export default connect(mapStateToProps)(ApiProjectListTable);
import React, { Component }  from 'react';
import { connect } from 'react-redux';
import DemographicHeader from '../../components/Demographics/demographicHeader.jsx';
import DemographicsListTable from '../../components/Demographics/demographicList.jsx';
import {ThirdPartyList} from '../../actions/actionsBag/vendor';
import add from '../../images/add.png';
import {Link} from 'react-router-dom';

    class DemographicsListView extends Component {

        constructor(props) {
            super(props)
            this.state = {

            }
            
        }

    componentWillMount() {
        let currentpage = 1;
        
      }

 


    render(){
        const header = 'Demographic List';
        const headerText = 'Here you can create and use demographic in your quota';
        const headerButton = <Link to="/create-demographic"><button className="create-btn create-btn-color" onClick={this.gotoCreateVendor}><img src={add} height="12px" width="12px"/><span> Create Demo</span></button></Link>

        return(
            <div style={{ width:"100%"}}>  
                <DemographicHeader
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                />
                <DemographicsListTable
                    
                />
            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        thirdPartyList : state.thirdParty.thirdPartyList,
        totalpages : state.thirdParty.totalpages
    }
  }
  
  export default connect(mapStateToProps)(DemographicsListView);
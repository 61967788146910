const initialState = {
    loading: false,
}

const spinner = (state = initialState , action) =>{
  switch (action.type) {

    case "SET_SPINNER_STARTED" :
      return {
        ...state,
        loading: true,
      }    

      case "SET_SPINNER_COMPLETED" :
      return {
        ...state,
        loading: false,
      }    
      
      default:
          return state;
        }

};
export default spinner;
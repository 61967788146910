import appConfig from "../apiConfig";

  export const getParticipantCountApi =(countYear, currentMonth, adminID)=> {

    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'dashboardParticipantsCount/'+ countYear + '/'+ currentMonth +'/'+ adminID, {
      method : "GET",
      headers : headers,
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }


  export const getParticipantDataApi =()=> {
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'dashboard/', {
      method : "GET",
      headers : headers,
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }

  export const thirdPartyParticipantApi =(id, adminID)=> {
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'dashboard/'+id+'/'+adminID, {
      method : "GET",
      headers : headers,
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }

  export const studyParticipantApi =(id)=> {
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'dashboardStudyParticipant/'+id.thirdPartyId+"/"+id.studyid, {
      method : "GET",
      headers : headers,
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }

  export const statusParticipantApi =(id)=> {
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'dashboard/'+id.thirdPartyId+"/"+id.studyId+"/"+id.status, {
      method : "GET",
      headers : headers,
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       console.log("", error)
       return error;
     }); 
  }

  export const getProgressApi =(id)=> {
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'getProgress/'+id.thirdPartyId+"/"+id.studyId, {
      method : "GET",
      headers : headers,
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       console.log("Create", error)
       return error;
     }); 
  }

  //getArchivingProgressApi
  export const getArchivingProgressApi =(id)=> {
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'getArchivingProgress/'+id.thirdPartyId+"/"+id.studyId, {
      method : "GET",
      headers : headers,
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       console.log("Create", error)
       return error;
     }); 
  }

  //getCountDataByMonthApi

  export const getCountDataByMonthApi =(countYear, countMonth, adminID)=> {
   
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'getParticipantsCountDataByMonth/'+countYear+'/'+countMonth + '/'+ adminID, {
      method : "GET",
      headers : headers,
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }


  //getCountDataByYearApi

  export const getCountDataByYearApi =(countYear, adminId)=> {
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'getParticipantsCountDataByYear/'+countYear+'/'+ adminId, {
      method : "GET",
      headers : headers,
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       console.log("Create third party", error)
       return error;
     }); 
  }

  //getUserListByAdminApi
  export const getUserListByAdminApi =(id , type)=> {
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'getUserByAdmin/'+id+'/'+ type, {
      method : "GET",
      headers : headers,
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }

  //getLiveStudiesByUserApi
  export const getLiveStudiesByUserApi =( currentPage, userid)=> {
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'getAllLiveStudiesList/'+currentPage+'/'+ userid, {
      method : "GET",
      headers : headers,
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }

  export const softDeleteLiveStudiesApi =(data)=> {
    let studyData = JSON.stringify(data);
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL + 'softDeleteLiveStudie/', {
      method : "POST",
      headers : headers,
      body : studyData
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }

  export const getServertimeApi =( )=> {
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'getServertime', {
      method : "GET",
      headers : headers,
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }
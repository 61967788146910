
export const alertMessage = (alertdata)=>{
    return {
      type : "SET_ALERT_MESSAGE",
      alertdata
    }
  }
  
  export const hideDialog = ()=>{
    return {
      type : "SET_HIDE_DIALOG"
    }
  }

  export const confirmAction = (data)=>{
    return {
      type : "SET_CONFIRM_DIALOG",
      data
    }
  }
  
  
  export const emptyDialogdata = ()=>{
    return {
      type : "SET_EMPTY_DIALOG",
    
    }
  }
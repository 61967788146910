import React, { Component }  from 'react';
import  '../../commonStyle/common.css';
import search from '../../images/search.png';
import liveimg from '../../images/play.png';
import pause from '../../images/pause.png';
import right from '../../images/correct.png';
import $ from 'jquery';
import {connect} from 'react-redux';
import { studiesList, softDelete , getAllclients} from '../../actions/actionsBag/study';
import { getThirdParty } from '../../actions/actionsBag/vendor';
import Pagination from "react-js-pagination";
import deletIcon from '../../images/delete.png';
import editpencil from '../../images/edit-pencil.png';
import {Link} from 'react-router-dom';
import { alertMessage, emptyDialogdata }  from '../../alertsDialog/Action';


class ArchivingStudyListTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: "",
            currentPage: 1,
            userId : "",
            userType : "",
            panrentProjectList : [],
            totalPage : 0,
            projectStatus : "all",
            showDeleteBtn : false,
            selectedVendorToFilter   : "Filter by vendor",
            selectedClientToFilter : "Filter by client",
            selectedVendorToFilterID : "",
            selectedClientToFilterID : ""
        }
        this.handlePageChange = this.handlePageChange.bind(this);
        this.geAllProject = this.geAllProject.bind(this);
        this.getLiveProject = this.getLiveProject.bind(this);
        this.gePauseProject = this.gePauseProject.bind(this);
        this.geCompleteProject = this.geCompleteProject.bind(this);
        this.deletProject = this.deletProject.bind(this);
        this.onChange = this.onChange.bind(this);
        this.searchStudy = this.searchStudy.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.search = this.search.bind(this);
        this.gotoCloneStudyList = this.gotoCloneStudyList.bind( this);
      }

    componentWillMount(){
      if(localStorage.getItem("is_StudyDelete") == 1)
        this.setState({ showDeleteBtn : true }) 
        
        this.props.dispatch(getThirdParty());
   
        this.props.dispatch(getAllclients( localStorage.getItem("auth_user_id"), localStorage.getItem("auth_user_type") ));
    }

  componentWillReceiveProps(nextProps){
    if(nextProps.studies.totalPages >0){
      this.setState({
        totalPage : nextProps.studies.totalPages
      });
     
  
    //  this.callMe();
    }else{
      this.setState({
        totalPage : 0
      });
    }
    if(nextProps.confirmDialog === true){
      // alert(nextProps.confirmData);
      this.props.dispatch(emptyDialogdata());
       this.props.dispatch(softDelete(nextProps.confirmData, this.state.currentPage));
    
  }

  }
  


    componentDidMount(){
    
      $('#fltvendor-btn').click( function(e) {
                  
        e.preventDefault(); // stops link from making page jump to the top
        e.stopPropagation(); // when you click the button, it stops the page from seeing it as clicking the body too
        $('.dropdownitemvendor').toggle();
        
      });
      $('.dropdownitemvendor').click( function(e) {
          
          // e.stopPropagation(); // when you click within the content area, it stops the page from seeing it as clicking the body too
          
      });
      
      $('body').click( function() {
        
          $('.dropdownitemvendor').hide();
          
      });
    

      $('#fltclient-btn').click( function(e) {
                  
        e.preventDefault(); // stops link from making page jump to the top
        e.stopPropagation(); // when you click the button, it stops the page from seeing it as clicking the body too
        $('.dropdownitemclient').toggle();
        
      });
      $('.dropdownitemclient').click( function(e) {
          
          // e.stopPropagation(); // when you click within the content area, it stops the page from seeing it as clicking the body too
          
      });
      
      $('body').click( function() {
        
          $('.dropdownitemclient').hide();
          
      });


      $(".dropdown").on('click',function() { 
          var t = (this.id); 
        if(t === "all-rm-shadow"){
          var all= document.getElementsByClassName("dropdown");
        
          for(let a=0; a< all.length; a++){
              if ( $( all[a] ).removeClass( "active" ) ) {
            
            }
          
            $(all[0]).addClass("active");
          }
        }else if(t === "live-rm-shadow"){
          
          var live= document.getElementsByClassName("dropdown");
        
          for(let a=0; a< live.length; a++){
              if ( $( live[a] ).removeClass( "active" ) ) {
            }
            $(live[1]).addClass("active");
          }
        }else if(t === "pause-rm-shadow"){
          var pause= document.getElementsByClassName("dropdown");
        
          for(var a=0; a< pause.length; a++){
              if ( $( pause[a] ).removeClass( "active" ) ) {
            
            }
          
            $(pause[2]).addClass("active");
          }
      }else if(t === "com-rm-shadow"){
          var com= document.getElementsByClassName("dropdown");
        
          for(let a=0; a< com.length; a++){
              if ( $( com[a] ).removeClass( "active" ) ) {
            
            }
          
            $(com[3]).addClass("active");
          }
        }
      });  

      // alert(this.container.offsetWidth +  ':'+ this.container.offsetHeight);
}

callMe(){
  $(document).ready(function(){
        $("#demo").find("p").hide();

        $(".demo").find("table").hide();
        
        $(".tbl").click(function(event) {
          // alert(event.target)
          //  event.stopPropagation();
            var $target = $(event.target);
            if ( $target.closest("td").attr(".demo") > 1 ) {
             
                $target.slideUp();
                $(".listexpandicon").removeClass("minus");
                } else {
                $target.closest("tr").next().find("table").toggle();
                $target.closest("tr").find(".listexpandicon").toggleClass("minus");
            }              
        });
    });
}
    

handlePageChange(pageNumber){

  this.setState({ currentPage : pageNumber});
  
  this.props.studiesListBypage(pageNumber, this.state.projectStatus);
}

geAllProject(){
  let status="all";
  this.setState({
    projectStatus : "all",
    currentPage : 1
  });
  this.props.studiesListBypage( 1, status);
}

getLiveProject(){
  let status="live";
  this.setState({
    projectStatus : "live",
    currentPage : 1
  });
  this.props.studiesListBypage( 1, status);
}

gePauseProject(){
  let status="pause";
  this.setState({
    projectStatus : "pause",
    currentPage : 1
  });
  this.props.studiesListBypage( 1, status);
}

geCompleteProject(){
  let status="completed";
  this.setState({
    projectStatus : "completed",
    currentPage : 1
  });
  this.props.studiesListBypage( 1, status);
}

editProject(){
  this.props.history.push('/Study-List-Update');
  alert("Edit project");
}
deletProject(deleteId){
  let alertData ={};
  alertData.type ="confirmalert";
  alertData.message = "Are you sure to delete this item ?";
  alertData.deleteId = deleteId;
  this.props.dispatch(alertMessage(alertData));
  // alert("Delete project");
}

setHeaderData(){
  localStorage.setItem("header-title","Study-Update");
  localStorage.setItem("header-text","Here you can update study");
}

gotoCloneStudyList(){
 
}

onChange(e){
  if(e.target.value == ""){
    this.props.studiesListBypage( 1, this.state.projectStatus);
  }
  this.setState({ [e.target.name] : e.target.value });
}

searchStudy(){
  
  if(this.state.search){
    this.props.searchStudy( this.state.search );
  }else{
    this.props.studiesListBypage( 1, this.state.projectStatus);
  }
  
}

handleSearch(e) {
  clearTimeout(this.interval);
  if (e.target.value) {
   this.setState({search: e.target.value})
    this.interval = setInterval(() => this.search(), 2000);
  } else {
    this.setState({search: e.target.value})
    this.props.studiesListBypage( 1, this.state.projectStatus);
  }
}

search(){
  clearTimeout(this.interval);
  this.props.searchStudy( this.state.search );
}


showselectedClient(e){
       
  if(e === 0){
      this.setState({
          selectedClientToFilterID : "",
          selectedClientToFilter   : "Filter by client",
      });
      let data = {};
      data.user_id = localStorage.getItem("auth_user_id");
      data.user_name = localStorage.getItem("auth_user_type");
      this.props.getStudiesByUser(data);
  }else{
      this.setState({
      selectedClientToFilterID : e.client_id,
      selectedClientToFilter   : e.name,
      selectedVendorToFilterID : "",
      selectedVendorToFilter   : "Filter by vendor",
      });
      this.props.getStudiesByClient(e);
  }
}


showselectedVendor(e){
       
  if(e === 0){
      this.setState({
        selectedVendorToFilterID : "",
          selectedVendorToFilter   : "Filter by vendor",
      });
      let data = {};
      data.user_id = localStorage.getItem("auth_user_id");
      data.user_name = localStorage.getItem("auth_user_type");
      this.props.getStudiesByUser(data);
  }else{
      this.setState({
      selectedVendorToFilterID : e._id,
      selectedVendorToFilter   : e.vendorName,
      selectedClientToFilterID : "",
          selectedClientToFilter   : "Filter by client",
      });
      this.props.getStudiesByVendor(e);
  }
}

    render(){
     
   
        let profileItems = "";
        let vendorsToFilterStudy = "";
        let clientToFilterStudy = "";
        if(this.props.clientsList &&  this.props.clientsList.length > 0){
          clientToFilterStudy = this.props.clientsList.map((item, i) => {
            return <li onClick={this.showselectedClient.bind(this, item)}>{item.name}</li>
            });
          }

          let vendorsDataToFilter = this.props.vendorsListForStudy;
        if(vendorsDataToFilter && vendorsDataToFilter.success === true && vendorsDataToFilter.result.length>0){
          vendorsToFilterStudy = vendorsDataToFilter.result.map((item, i) => {
            return <li onClick={this.showselectedVendor.bind(this, item)}>{item.vendorName}</li>
            });
        }  

        let studies = this.props.studies
        if (studies && studies.study  && studies.study.length > 0) {
          this.callMe();
                 
            profileItems = studies.study.map((list, i) => {
                
                let statusBtnshow = ""
                if(list.status === "completed" || list.status === "Completed"){
                    statusBtnshow = <button className="cmpbtn" >Complete</button>
                }else if(list.status === "live" || list.status === "Live"){
                    statusBtnshow = <button className="livebtn">Live</button>
                }else if(list.status === "pause" || list.status === "Pause" ){
                    statusBtnshow = <button className="pause">Pause</button>
                }else{
                    statusBtnshow = "Error!"
                }

                let paymentStatus = "";
                if(list.payment_received === 1){
                  paymentStatus = "Paid"
                }else{
                  paymentStatus = "Not Paid"
                }

                let viewDeleteBtn = "";
                if( this.state.showDeleteBtn){
                  viewDeleteBtn = <div className="icons" >
                  <div className="delete" onClick={this.deletProject.bind(this,list._id)}>
                    <img src={deletIcon}/>
                  </div>
                </div>
                }

                
                    return <tbody> <tr>  
                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom"><span className="plus listexpandicon"></span>{list._id}</td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom"><div className="ToolTip">{list.studyName.substring(0,12)}<div className="ToolTipText" style={{width: (list.studyName.length*8)+"px"}}>{list.studyName}</div></div></td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.name}</td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.completed}</td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.orignalRequirment}</td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.cpc}</td>
                                          <td className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">
                                          {statusBtnshow}
                                          </td>
                                          <td className="tbl-padd-top tbl-padd-left tbl-padd-bottom">
                                              <div className="bedg-1" >{list.allVendors}</div>
                                          </td>

                                          {list.allClonned > 0 ? (
                                          <td className="tbl  tbl-padd-top tbl-padd-left tbl-padd-bottom">
                                          <div className="bedg-2" style = {{ cursor:"pointer"}} >                                           
                                          <Link to = {{
                                                  pathname: "/Clone-Study",
                                                  state: {
                                                      cloneMainStudyID : list._id,
                                                    }
                                                }}
                                          onClick ={ this.gotoCloneStudyList} > {list.allClonned}</Link></div></td>
                                          ) : (
                                            <td className="tbl  tbl-padd-top tbl-padd-left tbl-padd-bottom">
                                          <div className="bedg-2" style = {{ cursor:"pointer"}} >                                           
                                          {list.allClonned}</div></td>
                                          )}
                                          
                                          <td className=" tbl-padd-top tbl-padd-left tbl-padd-bottom">
                                          <div className="flex-show">
                                              
                                          {/* {viewDeleteBtn} */}

                                          <div className="icons">
                                          <Link to = {{
                                                  pathname: "/Archiving-Study-List-Update",
                                                  state: {
                                                      editstudyData : list,
                                                    }
                                                }} 

                                          onClick={this.setHeaderData}><div className="pencil" ><img src={editpencil} /> </div></Link>
                                          </div>

                                              
                                            </div>
                                            </td>
                                </tr>
                                <tr><td colSpan="10" className="demo">
                                            <table className="expand-tbl"  border="0" cellspacing="0" cellpadding="0">
                                                <tbody>
                                                   
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>First Party Url</th>
                                                        <td className="tbl-td" >
                                                            <ul style={{ listStyleType: "disc",listStylePosition:"inside"}}>
                                                                <li style={{color: "black"}}><b>Live Link &nbsp;:&nbsp;</b>{list.firstPartyUrl}</li>
                                                                <li style={{color: "black"}}><b>Test Link &nbsp;:&nbsp;</b>{list.firstPartyUrlTest}</li>
                                                            </ul></td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}} >LOI</th>
                                                        <td className="tbl-td" >{list.loi}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>IR</th>
                                                        <td className="tbl-td">{list.IR} %</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Terminates</th>
                                                        <td className="tbl-td">{list.terminate}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Quotafulls</th>
                                                        <td className="tbl-td">{list.overQuota}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Hits</th>
                                                        <td className="tbl-td">{list.allParticipants}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Invoice No</th>
                                                        <td className="tbl-td">{list.receipt_no}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Invoice Date</th>
                                                        <td className="tbl-td"></td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Payment Status</th>
                                                        <td className="tbl-td">{paymentStatus}</td>
                                                    </tr>
                                                      {/* <tr>
                                                          <th className="tbl-th">Complete</th>
                                                          <td className="tbl-td">{list.totalCompletes}</td>
                                                      </tr> */}
                                                    
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Payment Amount</th>
                                                        <td className="tbl-td">{list.payment_received}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="tbl-th" style={{fontWeight: "500"}}>Payment Date</th>
                                                        <td className="tbl-td">{list.receipt_no}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td></tr>
                                </tbody>
             });
          }else{
            profileItems = "";
          }

          let showTable = "";
          if(studies && studies.study  && studies.study.length > 0){
            showTable = <table className="tbllayout ">
            <thead >
              <tr>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Study Id</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Study Name</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Client</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Complete</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">T.Req.</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">CPI</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Status</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Vendor</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Clone</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom" style={{textAlign: "center"}}>Action</th>
                </tr>
                </thead>
              {profileItems}
          </table>
          }else if(studies === null){
            showTable = <div className="datanotfound"> No record found</div>
          }else{
            showTable = <div className="spiner-data"><div className="tblspinner"></div></div>
          }


        return(
            <div className="list-style">
                <div className="table-st">
                    <div className="alltablestruct forms-rd-shadow">
                         {/* serachbox and stausbtn */}
                        <div id="search-staus"  >

                            <div className="flex-show">
                                <input type="text" value={this.state.search} name="search" className="searchbox" placeholder="Search...." onChange={this.handleSearch} />
                                {/* <div>
                                    <button className="searchbtn" onClick={ this.searchStudy} style={{backgroundColor: "#FFFFFF", border: "none", outline: "none", marginTop: "5px", cursor: "pointer"}}> <img src={search} height="20px" width="20px"/></button>
                                </div> */}

                            {/* <div style={{paddingLeft:"20px", paddingRight:"20px"}}><button className="list-btn list-btn-color" id="fltvendor-btn"><span >{this.state.selectedVendorToFilter}<div className="flter-btn-downarrow" style={{paddingLeft:"20px", paddingRight:"20px"}}></div></span></button>
                                <div class="dropdownitemvendor dropdown-shadow">
                                    <ul>
                                       {vendorsToFilterStudy}
                                    </ul>
                                </div>
                            </div> */}

                            {/* <div style={{ paddingRight:"20px"}}><button className="list-btn list-btn-color" id="fltclient-btn"><span >{this.state.selectedClientToFilter}<div className="flter-btn-downarrow" style={{paddingLeft:"20px", paddingRight:"20px"}}></div></span></button>
                                <div class="dropdownitemclient dropdown-shadow">
                                    <ul>
                                    {clientToFilterStudy}
                                    </ul>
                                </div>
                            </div> */}

                            </div>
                            
                            {/* <div className="flex-show">
                                <a href="JavaScript:void(0)" onClick={this.geAllProject}><div className="dropdown status-btn pad-right-2 status-font status-btn-shadow status-st active" id="all-rm-shadow"><span className="icon-All" style={{paddingLeft: "10px", paddingRight: "10px"}}>All</span></div></a>
                                <a href="JavaScript:void(0)" onClick={this.getLiveProject}><div className="dropdown status-btn pad-right-2 status-font status-btn-shadow status-st icon-Live" id="live-rm-shadow"><span className="pad-right-2"><img src={liveimg} height="12px" width="12px"/></span><span >Live</span></div></a>
                                <a href="JavaScript:void(0)" onClick={this.gePauseProject}><div className=" dropdown status-btn pad-right-2 status-font status-btn-shadow status-st icon-Pause" id="pause-rm-shadow"><span className="pad-right-2"><img src={pause} height="12px" width="12px"/></span><span className="">Pause</span></div></a>
                                <a href="JavaScript:void(0)" onClick={this.geCompleteProject}><div className="dropdown status-btn  pad-right-2 status-font status-btn-shadow status-st icon-Complete" id="com-rm-shadow"><span className="pad-right-2"><img src={right} height="16px" width="16px"/></span><span className="">Complete</span></div></a>
                            </div> */}
                        </div>
                        {/* serachbox and stausbtn */}

                            {/* table */}
                            <div style={{marginTop: "20px", overflowX: "auto"}}>
                                 {showTable}
                            </div>
                            {/* table */}
                   
                      <div id="pageinationstyle">
                       
                      <Pagination
                          activePage={this.state.currentPage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalPage}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                        </div>
                    
                    </div>
                </div>
            </div>
    )}
}

const mapStateToProps=(state)=>{
    return{
        studies: state.studies.studies,
        confirmDialog :  state.alertsDialog.confirmDialog,
        confirmData : state.alertsDialog.confirmData,
        clientsList    : state.studies.clientsList,
        vendorsListForStudy : state.thirdParty.vendorsListForStudy,
    }
  }
  
  export default connect(mapStateToProps)(ArchivingStudyListTable);
import React, { Component }  from 'react';
import StudyUpdate from '../Study/studyUpdate';
import UpdateStudyHeader from '../Study/updateStudyHeader';
import {connect} from 'react-redux';
import '../../pages/Study/tempstyle.css';
import $ from 'jquery';
import appConfig from '../../api/apiConfig';

class ActivityLogUpdateForm extends Component {

    constructor( props){
        super(props)
        this.state ={
           newUpdatedValue : ""
        }
       this.onValueChange = this.onValueChange.bind(this);
       this.updateActivity = this.updateActivity.bind(this);
    }


    onValueChange(e){
        if(e.target.value !== "")
        $("#error1").removeClass("showerror");
          this.setState({
            newUpdatedValue : e.target.value
          });
      }

      handleValidation(){
          let valid = true;
          if(this.state.newUpdatedValue === ""){
            valid = false;
            $("#error1").addClass("showerror");
                this.setState({
                   newValerror: "Enter some value"
                });
            }
          return valid;
      }

      updateActivity(){
          if(this.handleValidation()){

          }
      }




    render(){
        return(
            <div >
                  <div className="form-title">
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                                <div>
                                    <h4 className="rm-margin">Update Activity</h4>
                                    </div>
                                <div className="pro-tgl-btn pro-tgl-btn-shdw" id="tg-btn-10">
                                    <div id="rotate-icon-10">&#11167;</div>
                                </div>
                            </div>
                        <div className="url-mr-top tg-hide-act-log-form" id="hide-me-10">
                        <div className = "formgroup">
                                <div class="formgroup-col-7 rltv-pos" >
                                    <label>Update Requirement*</label>
                                    <input className="form-controll " name="role_type" placeholder="Update Requirement" value={this.state.newUpdatedValue} onChange={this.onValueChange} ></input>
                                    <div className="errordiv cus-width-8" id="error1" >{this.state.newValerror}</div>
                                </div>
                                <div class="formgroup-col-2" >
                                    <button onClick={this.updateActivity}  style={{marginTop: "27px", width: "-webkit-fill-available"}} className="list-btn list-btn-color">
                                  Update </button>
                                </div>
                            </div>
                        </div>
                      
                    </div>
            </div>

    )}
}

const mapStateToProps=(state)=>{
    return{
      
    }
  }
  
  export default connect(mapStateToProps)(ActivityLogUpdateForm);
import React, { Component }  from 'react';
import { connect } from 'react-redux';
import UserHeader from '../../components/User/userHeader.jsx';
import UpdateUserDiv from '../../components/User/updateUser.jsx';
import { getAllRolesByAdmin } from '../../actions/actionsBag/roles';
import { getOPmanager, updateUser, blankResUser } from '../../actions/actionsBag/users';
import goback from '../../images/goback.png';
import {Link} from 'react-router-dom';
import { alertMessage }  from '../../alertsDialog/Action';

    class UpdateUserView extends Component {

        constructor(props) {
            super(props)
            this.state = {
                editUserData : ""
            }
            this.getOPmanager = this.getOPmanager.bind(this);
            this.updateUser = this.updateUser.bind(this);
          }

          componentWillMount(){
            this.props.dispatch(getAllRolesByAdmin());
            
            if( this.props.location.state === undefined){
                this.props.history.push("error");
            }else{
        
            const { editUserData } = this.props.location.state;
               
            this.setState({
                editUserData : editUserData
              });
            }
          }

          componentWillReceiveProps( nextProps){
            if( nextProps.userUpdateSuccess && nextProps.userUpdateMessage){
              let alertData ={};
              alertData.type ="alert";
              alertData.message = nextProps.userUpdateMessage;
            this.props.dispatch(alertMessage(alertData));
                this.props.dispatch(blankResUser());
                this.props.history.push('/user-list');
            }else if( nextProps.userUpdateSuccess === false && nextProps.userUpdateMessage){
              let alertData ={};
              alertData.type ="alert";
              alertData.message = nextProps.userUpdateMessage;
            this.props.dispatch(alertMessage(alertData));
                this.props.dispatch(blankResUser());
            }
          }

          getOPmanager(){
            let userId = localStorage.getItem('auth_user_id');
            this.props.dispatch(getOPmanager(userId));
          }

          updateUser( data){
            this.props.dispatch(updateUser(data));
          }

    render(){
        const header = 'Update User';
        const headerText = 'Here you can update user';
        const headerButton = <Link to="/user-list"> <button className="back-btn back-btn-color" ><img src={goback} height="13px" width="13px" /><span> Back</span></button></Link>


        return(
            <div style={{ width:"100%"}}>  
                <UserHeader 
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                />
                <UpdateUserDiv
                    allRoleSuccess = {this.props.allRoleSuccess}
                    allRoleData = {this.props.allRoleData}
                    editUserData = {this.state.editUserData}
                    getOPmanager = {this.getOPmanager}
                    opManagerList = {this.props.opManagerList}
                    opManagerSuccess = {this.props.opManagerSuccess}
                    updateUser = {this.updateUser}
                />
            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        allRoleSuccess : state.roles.allRoleSuccess,
        allRoleData    : state.roles.allRoleData,
        opManagerList : state.users.opManagerList,
        opManagerSuccess : state.users.opManagerSuccess,
        userUpdateSuccess : state.users.userUpdateSuccess,
        userUpdateMessage : state.users.userUpdateMessage,
    }
  }
  
export default connect(mapStateToProps)(UpdateUserView);
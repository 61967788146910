import React, { Component }  from 'react';
import {createCloneStudy, initialiseInvitationRes } from '../../../actions/actionsBag/study';
import { alertMessage }  from '../../../alertsDialog/Action';
import { connect } from 'react-redux';

import $ from 'jquery';

class  CreateCloneForm  extends Component {
  constructor(props) {
    super(props)
    this.state = {
        noOfClone : "",
        newUpdatedComment: ""
    }
   this.sendInvitationToPanelreward = this.sendInvitationToPanelreward.bind(this);
   this.noOfCloneONChange = this.noOfCloneONChange.bind(this);
   
  }


  componentWillReceiveProps(nextProps){
      if(nextProps.cloneResp && nextProps.cloneResp.success){
        localStorage.setItem("is_clone_main_study", 1);
      }
    if(nextProps.cloneResp && nextProps.cloneResp.message){
        let alertData ={};
          alertData.type ="alert";
          alertData.message = nextProps.cloneResp.message;
          alertData.alertPushRoute = "pushToClonned";
        //   this.props.dispatch(initialiseInvitationRes());
        this.props.dispatch(alertMessage(alertData));

        this.props.moveToClonePage(localStorage.getItem("study_Id"));    


    }
  }

  noOfCloneONChange(e){
    if(e.target.value !== "")
    $("#error22").removeClass("showerror");
    if( e.target.value <= 50)
    this.setState({ noOfClone : e.target.value });
  }

  handleValidation1(){
    let valid= true;

    if (this.state.noOfClone === "") {
        valid = false;
        $("#error22").addClass("showerror");
        this.setState({
            redeemerror: "Enter Number"
        });
    }
        
     return valid;
}

  sendInvitationToPanelreward(){
       
    
    let data = {};
    data.studyId = localStorage.getItem("study_Id");
    data.study_name =  localStorage.getItem("study_Name");
    data.is_live = localStorage.getItem("study_Is_live");
    data.description = this.state.newUpdatedComment;
    data.number = this.state.noOfClone;

    
   
    if (this.handleValidation1()) {
       this.props.dispatch(createCloneStudy(data));
      }
  }


    render(){

        return(
            <div className="create-form">
            <div className="create-form-container">
            <div className="allformtruct forms-rd-shadow">
            <div className="form-title">
                <div style={{display: "flex", justifyContent: "space-between"}}>
                                <div>
                                    <h4 className="rm-margin">Study Name</h4>
                                    <p className="form-title-text">{localStorage.getItem("study_Name")}</p>
                                </div>
                                {/* <div className="pro-tgl-btn pro-tgl-btn-shdw" id="tg-btn-1">
                                    <div id="rotate-icon-1">&#11167;</div>
                                </div> */}
                            </div>
                    <hr/>
                    <div className = "formgroup">
                                <div class="formgroup-col-6 rltv-pos">
                                    <label>Enter Number of clone (Max : 50)</label>
                                    <input className="form-controll "  type="number"  min="1" max="51"  placeholder="clone" value={this.state.noOfClone} onChange={this.noOfCloneONChange}></input>
                                    <div className="errordiv cus-width-2" id="error22" >{this.state.redeemerror}</div>
                                </div>
                            </div>
                            <div style={{marginTop: "20px",textAlign:"center", float:"left"}}>
                                <button  className="list-btn" onClick={this.sendInvitationToPanelreward}> Create </button>
                            </div>
                    </div>
                    </div>
                </div>
            </div>
    )}
}
const mapStateToProps=(state)=>{
    return{
        cloneResp : state.studies.cloneResp
    }
  }
  export default connect(mapStateToProps)(CreateCloneForm);


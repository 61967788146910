import React, { Component }  from 'react';
import  '../../commonStyle/common.css';
import $ from 'jquery';
import {connect} from 'react-redux';
import Pagination from "react-js-pagination";
import deletIcon from '../../images/delete.png';
import editpencil from '../../images/edit-pencil.png';
import { getAllUserByAdmin, deleteUser, blankResUser } from '../../actions/actionsBag/users';
import {Link} from 'react-router-dom';
import { alertMessage,emptyDialogdata  }  from '../../alertsDialog/Action';
import { show } from '../../actions/actionsBag/study';

  class UserListTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            totalPage : 0,
            usersList : [],
            showloader : false,
        }
        this.handlePageChange = this.handlePageChange.bind(this);
      }


    componentWillReceiveProps(nextProps){
     
      if(nextProps.confirmDialog === true){
        // alert(nextProps.confirmData);
        this.props.dispatch(emptyDialogdata());
        this.props.dispatch(deleteUser(nextProps.confirmData));
    }

        if(nextProps.totalUsers && nextProps.totalUsers >0){
            this.setState({
                totalPage : nextProps.totalUsers,
                usersList : nextProps.usersList,
                showloader: true,
            });
        }else{
          this.setState({
            totalPage : 0,
            usersList : [],
            showloader: true,
        });
        }

        if(nextProps.removeUserSuccess && nextProps.removeUserMessage){
          // alert(nextProps.removeUserMessage);
          let alertData ={};
          alertData.type ="alert";
          alertData.message =  nextProps.removeUserMessage;
          this.props.dispatch(alertMessage(alertData));
          this.props.dispatch(blankResUser());
          let userId = localStorage.getItem('auth_user_id');
          let userType = localStorage.getItem("auth_user_type");
          this.props.dispatch(getAllUserByAdmin( 1, userId, userType));
        }else if(nextProps.removeUserMessage){
          let alertData ={};
          alertData.type ="alert";
          alertData.message =  nextProps.removeUserMessage;
          this.props.dispatch(alertMessage(alertData));
          this.props.dispatch(blankResUser());
        }
    }
  


  componentWillMount(){
    let userId = localStorage.getItem('auth_user_id');
    let userType = localStorage.getItem("auth_user_type");
    this.props.dispatch(getAllUserByAdmin( 1, userId, userType));

  $(".dropdown").on('click',function() { 
      var t = (this.id); 
    if(t === "all-rm-shadow"){
      var all= document.getElementsByClassName("dropdown");
    
      for(let a=0; a< all.length; a++){
          if ( $( all[a] ).removeClass( "active" ) ) {
        
        }
      
        $(all[0]).addClass("active");
      }
    }else if(t === "live-rm-shadow"){
      
      var live= document.getElementsByClassName("dropdown");
    
      for(let a=0; a< live.length; a++){
          if ( $( live[a] ).removeClass( "active" ) ) {
        }
        $(live[1]).addClass("active");
      }
    }else if(t === "pause-rm-shadow"){
      var pause= document.getElementsByClassName("dropdown");
    
      for(var a=0; a< pause.length; a++){
          if ( $( pause[a] ).removeClass( "active" ) ) {
        
        }
      
        $(pause[2]).addClass("active");
      }
  }else if(t === "com-rm-shadow"){
      var com= document.getElementsByClassName("dropdown");
    
      for(let a=0; a< com.length; a++){
          if ( $( com[a] ).removeClass( "active" ) ) {
        
        }
      
        $(com[3]).addClass("active");
      }
    }
  });  
}

    

  handlePageChange(pageNumber){

    this.setState({ currentPage : pageNumber, usersList: []});
    let userId = localStorage.getItem('auth_user_id');
    let userType = localStorage.getItem("auth_user_type");
    this.props.dispatch(getAllUserByAdmin( pageNumber, userId, userType));
  }

  editProject(){
    alert("Edit project");
  }
  deleteUser(id){

    let alertData ={};
    alertData.type ="confirmalert";
    alertData.message = "Are you sure to delete this item ?";
    alertData.deleteId = id;
    this.props.dispatch(alertMessage(alertData));
}

  tableZoom(){
    $(".alltablestruct").toggleClass("zoom-me");
    // $("#routercontainer").toggleClass("router-containter-pd");
    // document.getElementById("routercontainer").style.padding="0px";

  }

    render(){
       
        let usersList = "";
       
        let sr = (this.state.currentPage * 10)-10;
        if(this.state.usersList && this.state.usersList.length >0){  
         usersList= this.state.usersList.map((list, i) => {  
                
                    return <tbody> <tr>  
                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom">{sr+i+1}</td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.first_name +" "+list.last_name}</td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.user_name}</td>
                                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">
                                          { list.role_type!= null ? (
                                            <span>{list.role_type.toLowerCase().replace(/_/g," ")}</span>
                                            ) : (
                                              <span>{list.role_type}</span>
                                            )}
                                            </td>
                                          
                                        
                                          <td className=" tbl-padd-top tbl-padd-left tbl-padd-bottom">
                                          <div className="flex-show">
                                              
                                          <div className="icons">
                                                <div className="delete" onClick={this.deleteUser.bind( this, list.user_id )}>
                                                  <img src={deletIcon}/>
                                                </div>
                                              </div>

                                              <div className="icons">
                                                <Link to = {{      
                                                      pathname: '/update-user',
                                                      state: {
                                                          editUserData : list,
                                                        }
                                                    }}> <div className="pencil"> <img src={editpencil}  height="20px" width="20px"/> </div></Link>
                                              </div>
                                              
                                            </div>
                                            </td>
                                </tr>
                               
                                </tbody>
             });
          }else{
            usersList = "";
          }

          let showTable="";
          if(this.state.usersList && this.state.usersList.length >0){  
            showTable = <table className="tbl-new-est " >
            <thead >
              <tr>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">S.no.</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Name</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">User Name</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Role</th>
                    <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Action</th>
                </tr>
                </thead>
              {usersList}
          </table>
          }else if(this.state.showloader === true){
            showTable = <div className="datanotfound"> No record found</div>
          }else{
            showTable = <div className="spiner-data"><div className="tblspinner"></div></div>
          }
         
          


        return(
            <div className="list-style">
                <div className="table-st">
                    <div className="alltablestruct forms-rd-shadow">
                         {/* serachbox and stausbtn */}
                        <div id="search-staus">

                            <div className="flex-show tbl-h-font">
                              User List
                            </div>
                            
                            <div className="flex-show tbl-zoom" >
                            <span onClick={this.tableZoom} className="full-screen mr-rhs-20 mr-top-10"></span>
                            </div>
                        </div>
                        {/* serachbox and stausbtn */}

                            {/* table */}
                            <div style={{marginTop: "20px", overflowX: "auto"}}>
                                 {showTable}
                            </div>
                            {/* table */}
                   
                      <div id="pageinationstyle">
                       
                      <Pagination
                          activePage={this.state.currentPage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalPage}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                        />
                        </div>
                    
                    </div>
                </div>
            </div>
    )}
}

const mapStateToProps = (state) => {
  return {
    totalUsers : state.users.totalUsers,
    usersList : state.users.usersList,
    usersDataSuccess : state.users.usersDataSuccess,
    userUpdateSuccess : state.users.userUpdateSuccess,
    userUpdateMessage : state.users.userUpdateMessage,
    removeUserSuccess : state.users.removeUserSuccess,
    removeUserMessage : state.users.removeUserMessage,
    confirmDialog :  state.alertsDialog.confirmDialog,
    confirmData : state.alertsDialog.confirmData
  }
}

export default connect(mapStateToProps)(UserListTable);

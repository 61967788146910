import React, { Component }  from 'react';
import { connect } from 'react-redux';
import '../../commonStyle/common.css';
import './dashboardStyle.css';
import './commonStyle.css';
import $ from 'jquery';

  export default class DashboardHeaderDiv extends Component {

    constructor(props) {
        super(props)
        this.state = {
          showUsers : "block",
          checkYearOpen : true,

          loginUserID : "",
          selectedUserID : "0",
          selectedUser : "Select by user",
          yearsData: [  { "id": "2018-2019", "value" : "2018-2019", },
                        { "id": "2019-2020", "value" : "2019-2020", },
                        { "id": "2020-2021", "value" : "2020-2021", },
                        { "id": "2021-2022", "value" : "2021-2022", },
                        { "id": "2022-2023", "value" : "2022-2023", },
                        { "id": "2023-2024", "value" : "2023-2024", },
                        { "id": "2024-2025", "value" : "2024-2025", },
                        { "id": "2025-2026", "value" : "2025-2026", },
                        { "id": "2026-2027", "value" : "2026-2027", },
                        { "id": "2027-2028", "value" : "2027-2028", },
                        { "id": "2028-2029", "value" : "2028-2029", },
                        { "id": "2029-2030", "value" : "2029-2030", } ],
          selectedYear : "",
          monthsData : [{ "id": "0", "month" : "Select Month" },
                        { "id": "1", "month" : "January" },
                        { "id": "2", "month" : "February" },
                        { "id": "3", "month" : "March" },
                        { "id": "4", "month" : "April" },
                        { "id": "5", "month" : "May" },
                        { "id": "6", "month" : "June" },
                        { "id": "7", "month" : "July" },
                        { "id": "8", "month" : "August" },
                        { "id": "9", "month" : "September" },
                        { "id": "10", "month" : "October" },
                        { "id": "11", "month" : "November" },
                        { "id": "12", "month" : "December" }],
          selectedMonth : "",
          selectedMonthID : ""
        }
      }

    componentWillMount() {

      if( localStorage.getItem('auth_user_type') === "bde")
      this.setState({ showUsers : "none"})

        let found = this.state.monthsData.find(data => data.id  == this.props.countMonth);
        this.setState({ selectedMonth : found.month, 
                        selectedYear : this.props.countYear, 
                        selectedMonthID : this.props.countMonth, 
                        loginUserID :  this.props.dataUserID });
      }
     
      showselectedUser(e){
         if(e === 0){
          this.setState({
            selectedUserID : "",
            selectedUser   : "Select by user",
          });
          let adminID = localStorage.getItem('auth_user_id');
          this.props.getParticipantCountByUser( this.state.selectedYear, this.state.selectedMonthID, adminID);
         }else{
          this.setState({
            selectedUserID : e.user_id,
            selectedUser   : e.user_name
          });
          this.props.getParticipantCountByUser( this.state.selectedYear, this.state.selectedMonthID, e.user_id);
         }
      }

      showselectedYear(e){
        this.setState({ selectedYear : e.value});
        this.props.getParticipantCountByYear(e.value, this.state.selectedMonthID ,this.state.selectedUserID);
      }

      showselectedMonth(e){
        this.setState({ selectedMonth : e.month, selectedMonthID : e.id});
        this.props.getParticipantCountByMonth( this.state.selectedYear, e.id ,this.state.selectedUserID);
      }

      

    render(){

    let userItem = "";
    if(this.props.userListSuccess && this.props.usersListData.length>0){
      userItem = this.props.usersListData.map((item, i) => {
          return <a className="dashboard-dropdown-item" onClick={this.showselectedUser.bind(this, item)}>{item.user_name}</a>
        });
    }

    let yearItem = "";
    if( this.state.yearsData){
      yearItem = this.state.yearsData.map((item, i) => {
        return <a className="dashboard-dropdown-item" onClick={this.showselectedYear.bind(this, item)}>{item.value}</a>
      });
    }

    let monthItem = ""
    if( this.state.monthsData){
      monthItem = this.state.monthsData.map((item, i) => {
        return <a className="dashboard-dropdown-item" onClick={this.showselectedMonth.bind(this, item)}>{item.month}</a>
      });
    }

        return(
            <div className="router-header">
                <div className="router-header1 all-header " id = "headers-all">
                    <div >
                        <h2 className="head-two-st">Dashboard</h2>
                        <p className="paragraph-st color-secondary para-st-font">Here you can see overall of project report</p>
                    </div>
                <div>
                    {/* first button */}
                <div className="dashboard-col-auto" style={{ display:"flex",marginTop: "10px" }}>
                    <div className="dashboard-btn-group">
                        <button id="dashboard-btn-years" className="dashboard-btn dashboard-btn-danger  set-cus-family"  style={{ color: "#fff",backgroundColor: "#f30b2b", borderColor: "#e70b29", cursor: "pointer", minWidth: "100px", fontSize: "15px"}}> {this.state.selectedYear}<span className="dashboard-toggle-arrow"> </span>
                        </button>
                        <div className="dashboard-dropdown-menu showYear" x-placement="bottom-start" style={{ position: "absolute", transform: "translate3d(0px, 38px, 0px)", top:"-8px", left:"0px", willChange: "transform"}}>
                          {yearItem}
                        </div>
                    </div>
                    {/* second button */}
                    <div className="dashboard-btn-group">
                        <button id="dashboard-btn-months" className="dashboard-btn dashboard-btn-success set-cus-family"  style={{ cursor: "pointer", marginLeft: "5px", minWidth: "110px", fontSize: "15px"}}> { this.state.selectedMonth } <span className="dashboard-toggle-arrow"> </span>
                        </button>
                        <div className="dashboard-dropdown-menu showMonth" x-placement="bottom-start" style={{ position: "absolute", transform: "translate3d(0px, -75px, 0px)", top:"0px", left:"0px", willChange: "transform"}}>
                            {monthItem}
                        </div>
                    </div>
                    {/* third button */}
                    <div className="dashboard-btn-group " style={{ display: this.state.showUsers }} >
                        <button id="dashboard-btn-users" className="dashboard-btn dashboard-btn-dark set-cus-family"  style={{ cursor: "pointer", marginLeft: "5px" ,  minWidth: "110px", fontSize: "15px" }}>{this.state.selectedUser} <span className="dashboard-toggle-arrow">  </span>
                        </button>
                        <div className="dashboard-dropdown-menu showUsers" x-placement="bottom-start" style={{ position: "absolute", transform: "translate3d(0px, 38px, 0px)", top:"-8px", left:"0px", willChange: "transform"}}>
                            <a  className="dashboard-dropdown-item set-cus-family" onClick={this.showselectedUser.bind(this, 0)}>Select by user</a>
                            {userItem}
                          
                        </div>
                    </div>

                </div>
                </div>     
                </div>
            </div>
    )}
}

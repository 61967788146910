import React, { Component }  from 'react';
import { alertMessage }  from '../../../alertsDialog/Action';
import $ from 'jquery';
import { connect } from 'react-redux';
import ApplyDemoQuery from '../../../components/ProjectActivities/DemoMapping/applyDemoQuery';
import UpdateStudyHeader from '../../../components/Study/updateStudyHeader';
import { getDemoQueryDataForApply, studyDemoMapping , studyDemoAgeMapping ,blankResDemoMap, removeRangeOptions } from '../../../actions/actionsBag/demoMapping';
 
export class ApplyDemographics extends Component {
    constructor(props) {
        super(props)
        this.state = {
            demo_id : "",
            demo_name : "",
            study_id : ""
        }
        this.removeRangeOptions = this.removeRangeOptions.bind( this);
        this.studyDemoMapping = this.studyDemoMapping.bind(this);
        this.studyDemoAgeMapping = this.studyDemoAgeMapping.bind(this);
    }

    componentWillMount(){
       
    if( this.props.location.state === undefined){
        this.props.history.push("error");
        }else{
        let studyID = localStorage.getItem('study_Id');
      const { applyDemoData } = this.props.location.state;
      let demoId ="";
      if(applyDemoData._id)
      demoId = applyDemoData._id;
      if(applyDemoData.demographicId)
      demoId = applyDemoData.demographicId;
      this.setState({ demo_id : demoId, demo_name : applyDemoData.name, study_id : studyID });
      this.props.dispatch( getDemoQueryDataForApply( demoId, studyID));
        }
    }

    componentWillReceiveProps( nextProps){
        if(nextProps.demoMappingSuccess === true && nextProps.demoMappingMessage){
            let alertData ={};
            alertData.type ="alert";
            alertData.message =  nextProps.demoMappingMessage;
            this.props.dispatch(alertMessage(alertData));
            this.setState({currentPage : 1});
            this.props.dispatch(blankResDemoMap());
            window.history.back();
        }else if(nextProps.demoMappingMessage){
            let alertData ={};
            alertData.type ="alert";
            alertData.message =  nextProps.demoMappingMessage;
            this.props.dispatch(alertMessage(alertData));
            this.props.dispatch(blankResDemoMap());
        }
        if(nextProps.demoDeleteRangeSuccess === true && nextProps.demoDeleteRangeMessage){
        let alertData ={};
        alertData.type ="alert";
        alertData.message =  nextProps.demoDeleteRangeMessage;
        this.props.dispatch(alertMessage(alertData));
        this.setState({currentPage : 1});
        this.props.dispatch(blankResDemoMap());
        window.history.back();
        }else if(nextProps.demoDeleteRangeMessage){
            let alertData ={};
            alertData.type ="alert";
            alertData.message =  nextProps.demoDeleteRangeMessage;
            this.props.dispatch(alertMessage(alertData));
            this.props.dispatch(blankResDemoMap());
        }
    }

    
    studyDemoMapping( data){
        data.studyId = this.state.study_id;
        data.demographicId = this.state.demo_id;
        this.props.dispatch(studyDemoMapping( data));
    }

    
    studyDemoAgeMapping( data){
     //   this.props.dispatch(blankResDemoMap());
        data.studyId = this.state.study_id;
        data.demographicId = this.state.demo_id;
        this.props.dispatch(studyDemoAgeMapping( data));
    }
    

    gotoBack(){
        window.history.back();
    }

    removeRangeOptions(data){
       this.props.dispatch( removeRangeOptions( data));
    }

    

    render(){
        return(
            <div>
                
                <UpdateStudyHeader
                    selectActivity = {this.selectActivity}
                    editstudyData = {this.state.editstudyData}
                    gotoBack = {this.gotoBack}
                />

               <ApplyDemoQuery 
                    demo_id = { this.state.demo_id}
                    demo_name = { this.state.demo_name}
                    demoQueryData = { this.props.demoQueryData }
                    studyDemoMapping = { this.studyDemoMapping}
                    studyDemoAgeMapping = {this.studyDemoAgeMapping}
                    removeRangeOptions = {this.removeRangeOptions}
               />

            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        demoQueryData : state.demoMapped.demoQueryData,
        demoMappingSuccess : state.demoMapped.demoMappingSuccess,
        demoMappingMessage : state.demoMapped.demoMappingMessage,
        demoDeleteRangeSuccess : state.demoMapped.demoDeleteRangeSuccess,
        demoDeleteRangeMessage : state.demoMapped.demoDeleteRangeMessage,
    }
  }
  
  export default connect(mapStateToProps)(ApplyDemographics);
import React, { Component }  from 'react';
import { connect } from 'react-redux';
import UserHeader from '../../components/User/userHeader.jsx';
import UploadUniqueLinkView from '../../components/StudyUniqueLink/uploadUniqueLink.jsx';
import add from '../../images/add.png';
import {Link} from 'react-router-dom';
import goback from '../../images/goback.png';
    class UploadUniqueLink extends Component {

        constructor(props) {
            super(props)
            this.state = {

            }
            // this.gotoCreateVendor = this.gotoCreateVendor.bind(this);
        }

    componentWillMount() {
        let currentpage = 1;
        //this.props.dispatch(ThirdPartyList( currentpage));
      }
      goBack(){
        window.history.back();
    }

    //   gotoCreateVendor(){
    //    alert("gotoCreateVendor");

    //   }

    

    render(){
        const header = 'Unique Link Upload';
        const headerText = 'Here you can upload file of unique links';
        const headerButton = <button className="back-btn back-btn-color" onClick={this.goBack}><img src={goback} height="13px" width="13px" /><span> Back</span></button>

        return(
            <div style={{ width:"100%"}}>  
                <UserHeader
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                />
                <UploadUniqueLinkView 
                       
                />

            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
      
         
    }
  }
  
  export default connect(mapStateToProps)(UploadUniqueLink);
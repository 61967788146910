import React, { Component }  from 'react';
import  '../../commonStyle/common.css';
import $ from 'jquery';
import {connect} from 'react-redux';

import { getAllclients, getAllBDEbyAdmin,addStudies,emptyStudyResponse } from '../../actions/actionsBag/study';
import { alertMessage }  from '../../alertsDialog/Action';

class CreateStudyForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
          studyName: "",
          description: "",
          orignalRequirment: "",
          firstPartyUrl: "",
          firstPartyUrlTest: "",
          studyId: "",
          status: "",
          loi: "",
          IR: "",
          cpc: "",
          bdeUserId : "",
          invitationPopUpData:'',
          allowLink :false,
          allowDemo: false,
          allowRouter: false,
          clientID : ""
        }
        this.onStudyNameChange = this.onStudyNameChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.onRequirementChange = this.onRequirementChange.bind(this);
        this.onLoiChange = this.onLoiChange.bind(this);
        this.onIrChange = this.onIrChange.bind(this);
        this.onCostChange = this.onCostChange.bind(this);
        this.onFirstPartyUrlChange = this.onFirstPartyUrlChange.bind(this);
        this.onTestUrlChange = this.onTestUrlChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onBdeChange = this.onBdeChange.bind(this);
        this.onClientChange = this.onClientChange.bind(this);
        this.onAllowLinkChange = this.onAllowLinkChange.bind(this);
        this.onAllowDemoChange = this.onAllowDemoChange.bind(this);
        this.onAllowRouterChange = this.onAllowRouterChange.bind(this);
        this.addStudy = this.addStudy.bind(this);
        this.addPlaceholder = this.addPlaceholder.bind(this);
        this.addTestPlaceholder = this.addTestPlaceholder.bind(this);
    }

    componentWillMount(){
        // if(localStorage.getItem("auth_user_type") !== "bde"){
            this.props.dispatch(getAllBDEbyAdmin(localStorage.getItem('auth_user_id')));
        //   }
      

    this.props.dispatch(getAllclients( localStorage.getItem("auth_user_id"), localStorage.getItem("auth_user_type") ));
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.clientsList && nextProps.clientsList.length>0){
            this.setState({ clientListData : nextProps.clientsList });
          }

          if(nextProps.bdeUsersList && nextProps.bdeUsersList.length>0){
     
            this.setState({showBDEusersList : true, bdeUsersData : nextProps.bdeUsersList });
          }
          if(nextProps.studySuccess && nextProps.studySuccess.success === true){
            this.setState({
                studyName: "",
                description: "",
                orignalRequirment: "",
                firstPartyUrl: "",
                firstPartyUrlTest: "",
                studyId: "",
                status: "",
                loi: "",
                IR: "",
                cpc: "",
                bdeUserId : "",
                allowLink : false,
                allowDemo: false,
                allowRouter: false,
            });
          }
          if(nextProps.studySuccess && nextProps.studySuccess.success === true){
              let alertData ={};
              alertData.type ="alert";
              alertData.message = nextProps.studySuccess.message;
            this.props.dispatch(alertMessage(alertData));
            this.props.dispatch(emptyStudyResponse());
            //window.open('/studyList', '_self');
            this.props.goToList(nextProps.studySuccess.result);
        //    this.props.history.push("/studylist");
            
          }else if(nextProps.studySuccess && nextProps.studySuccess.message){
                let alertData ={};
                alertData.type ="alert";
                alertData.message = nextProps.studySuccess.message;
                this.props.dispatch(alertMessage(alertData));
                this.props.dispatch(emptyStudyResponse());
               
          }
        //   else if(nextProps.studySuccess === false && nextProps.studySuccess.message){
            // this.props.dispatch(alertMessage(nextProps.studySuccess.message));
            //     this.props.dispatch(emptyStudyResponse());
        //   }

    }

    componentDidMount(){

    }

    onStudyNameChange(e){
        if(e.target.value !== "")
            $("#error1").removeClass("showerror");
        
        if( e.target.value.charAt(0) ===" "){
            this.setState({
                studyName : e.target.value.trim()
            });
        }else{
            this.setState({
                studyName : e.target.value
            });
        }
    }

    onDescriptionChange(e){
        if(e.target.value !== "")
            $("#error2").removeClass("showerror");
        
        if( e.target.value.charAt(0) ===" "){
            this.setState({
                description : e.target.value.trim()
            });
        }else{
            this.setState({
                description : e.target.value
            });
        }
    }

    onRequirementChange(e){
        if(e.target.value !== "")
            $("#error3").removeClass("showerror");
        
        if( e.target.value.match(/^[0-9]+$/)){
            this.setState({
                orignalRequirment : e.target.value.trim()
            });
        }else if(e.target.value === ""){
            this.setState({ orignalRequirment : ""});
        }else{
            alert('Please Input Numeric Characters More Then Zero');
        }
    }

    onLoiChange(e){
        if(e.target.value !== "")
            $("#error4").removeClass("showerror");

        if( e.target.value.match(/^[0-9]+$/)){
            this.setState({
                loi : e.target.value
            });
        }else if(e.target.value === ""){
            this.setState({ loi : ""});
        }else{
            alert('Please Input Numeric Characters More Then Zero');
        }
    }

    onIrChange(e){
        if(e.target.value !== "")
            $("#error5").removeClass("showerror");
        
        if(e.target.value.match(/^[0-9]+$/)){
            if( parseInt(e.target.value) > 0 && parseInt(e.target.value) <= 100 ){
                this.setState({ IR: e.target.value});
            }
        }else if(e.target.value === ""){
            this.setState({ IR : ""});
        }else{
            alert('Please Input Numeric Characters More Then Zero');
        }
    }

    onCostChange(e){
        if(e.target.value !== "")
            $("#error6").removeClass("showerror");
        
        if( e.target.value.match(/^[0-9]+$/)){
            this.setState({
                cpc : e.target.value
            });
        }else if(e.target.value === ""){
            this.setState({ cpc : ""});
        }else{
            alert('Please Input Numeric Characters More Then Zero');
        }
    }

    onFirstPartyUrlChange(e){
        if(e.target.value !== "")
            $("#error7").removeClass("showerror");

        if( e.target.value.charAt(0) ===" "){
            this.setState({
                firstPartyUrl : e.target.value.trim()
            });
        }else{
            this.setState({
                firstPartyUrl : e.target.value
            });
        }
    }

    onTestUrlChange(e){
        if(e.target.value !== "")
            $("#error8").removeClass("showerror");
      
        if( e.target.value.charAt(0) ===" "){
            this.setState({
                firstPartyUrlTest : e.target.value.trim()
            });
        }else{
            this.setState({
                firstPartyUrlTest : e.target.value
            });
        }
    }

    onStatusChange(e){
        if(e.target.value !== "")
            $("#error9").removeClass("showerror");
        
        this.setState({
            status : e.target.value
        });
    }


    onBdeChange(e){
        if(e.target.value !== "")
            $("#error10").removeClass("showerror");
        
        this.setState({
            bdeUserId : e.target.value
        });
    }

    onClientChange(e){
        if(e.target.value !== "")
            $("#error11").removeClass("showerror");
        
        this.setState({
            clientID : e.target.value
        });
    }

    onAllowLinkChange(e){
        if(e.target.value !== false)
            $("#error12").removeClass("showerror");
        
        this.setState({
            allowLink : !this.state.allowLink
        });
    }

    onAllowDemoChange(e){
        if(e.target.value !== false)
            $("#error13").removeClass("showerror");
        
        this.setState({
            allowDemo: !this.state.allowDemo
        });
    }

    onAllowRouterChange(e){
        if(e.target.value === false)
            $("#error14").removeClass("showerror");
        

        this.setState({
            allowRouter: !this.state.allowRouter
        });
    }


    handleValidation(){
        let valid= true;

        if(this.state.studyName === ""){
            valid = false;
            $("#error1").addClass("showerror");
            this.setState({
                studynameerror: "Enter study name"
            });
        }

        // if(this.state.description === ""){
        //     valid = false;
        //     $("#error2").addClass("showerror");
        //     this.setState({
        //         descerror: "Enter study description"
        //     });
        // }

        if(this.state.orignalRequirment === ""){
            this.setState({
                reqerror: "Enter requirment"
            });
            valid = false;
            $("#error3").addClass("showerror");
        }else if(isNaN(this.state.orignalRequirment)){
            this.setState({
                reqerror: "Number only"
            });
            valid = false;
            $("#error3").addClass("showerror");
        }

        
        if(this.state.loi === ""){
            valid = false;
            $("#error4").addClass("showerror");
            this.setState({
                loierror: "Enter LOI "
            });
        }else if(isNaN(this.state.loi)){
            this.setState({
                loierror: "Number only"
            });
            valid = false;
            $("#error4").addClass("showerror");
        }
        
        
        if(this.state.IR === ""){
            valid = false;
            $("#error5").addClass("showerror");
            this.setState({
                irerror: "Enter IR "
            });
        }else if(isNaN(this.state.IR)){
            this.setState({
                irerror: "Number only"
            });
            valid = false;
            $("#error5").addClass("showerror");
        }

        
        if(this.state.cpc === ""){
            valid = false;
            $("#error6").addClass("showerror");
            this.setState({
                cpcerror: "Enter CPI "
            });
        }else if(isNaN(this.state.cpc)){
            this.setState({
                cpcerror: "Number only"
            });
            valid = false;
            $("#error6").addClass("showerror");
        }

        if(this.state.firstPartyUrl === ""){
            valid = false;
            $("#error7").addClass("showerror");
            this.setState({
                urlerror: "Enter first party url "
            });
        }

        // if(this.state.firstPartyUrlTest === ""){
        //     valid = false;
        //     $("#error8").addClass("showerror");
        //     this.setState({
        //         testurlerror: "Enter first party test url "
        //     });
        // }

        if(this.state.status === ""){
            valid = false;
            $("#error9").addClass("showerror");
            this.setState({
               statuserror: "Choose status"
            });
        }

        // if(this.state.bdeUserId === ""){
        //     valid = false;
        //     $("#error10").addClass("showerror");
        //     this.setState({
        //         bdeerror: "Choose BDE "
        //     });
        // }

        if(this.state.clientID === ""){
            valid = false;
            $("#error11").addClass("showerror");
            this.setState({
               clienterror: "Choose client"
            });
        }

        // if(this.state.allowLink === false){
        //     valid = false;
        //     $("#error12").addClass("showerror");
        //     this.setState({
        //        allowlinkerror: "Allow link"
        //     });
        // }

        // if(this.state.allowDemo === false){
        //     valid = false;
        //     $("#error13").addClass("showerror");
        //     this.setState({
        //        allowdemoerror: "Allow demo"
        //     });
        // }

        if(this.state.allowRouter === true && this.state.allowDemo === false){
            valid = false;
            $("#error13").addClass("showerror");
            this.setState({
                allowdemoerror: "Allow demo"
            });
        }
        
        return valid;
    }

    addStudy(){
       
        if(this.handleValidation()){
            let studyData = {};
            studyData.studyName = this.state.studyName;
            studyData.description = this.state.description;
            studyData.orignalRequirment = this.state.orignalRequirment;
            studyData.firstPartyUrl = this.state.firstPartyUrl;
            studyData.firstPartyUrlTest = this.state.firstPartyUrlTest;
            studyData.fees = this.state.fees;
            studyData.status = this.state.status;
            studyData.loi = this.state.loi;
            studyData.IR = this.state.IR;
            studyData.cpc = this.state.cpc;
            studyData.id = this.state.studyId;
            studyData.checked = this.state.allowLink;
            studyData.allowDemo = this.state.allowDemo;
            studyData.allowRouter = this.state.allowRouter;
            studyData.bdeUserId = this.state.bdeUserId;
            studyData.createdById = localStorage.getItem('auth_user_id');
            studyData.createdByRole = localStorage.getItem('auth_user_type');
            studyData.clientID = this.state.clientID;
            this.props.dispatch(addStudies(studyData));
        }
        
    }

    addPlaceholder() {
        if(this.state.firstPartyUrl){
            if(!this.state.firstPartyUrl.includes("<pid>")){
                this.setState({
                    firstPartyUrl: this.state.firstPartyUrl + "<pid>"
                });
            }
        }else{
            alert("Please enter first party url first !");
        }    
      }
      addTestPlaceholder() {
        if(this.state.firstPartyUrlTest){
            if(!this.state.firstPartyUrlTest.includes("<pid>")){
                this.setState({
                    firstPartyUrlTest: this.state.firstPartyUrlTest + "<pid>"
                });
            }
        }else{
            alert("Please enter first party test url first !");
        }    
      }

    render(){

        let clientsItem ="";
        let clients = this.state.clientListData;
        if(this.state.clientListData){
        clientsItem = clients.map((client,i) =>{
           return <option key={client.client_id} value ={client.client_id}>{client.name}</option>
        });
        }

        let bdeUsersItem = "";
        if(this.state.showBDEusersList){

            let bdeUsers = this.state.bdeUsersData;
            bdeUsersItem = bdeUsers.map((bde) =>       
              <option key={bde.user_id} value ={bde.user_id}>{bde.user_name}</option>
                );
            }
        return(
            <div className="create-form">
            <div className="create-form-container">
            <div className="allformtruct forms-rd-shadow">

            <div className="form-title">
                    <h4 className="rm-margin" style={{textAlign: "center"}}>New study form</h4>
                    <p className="form-title-text"  style={{textAlign: "center"}}>Create new study and generate redirect for client</p>
                    <hr/>
                    </div>

                    <div className = "formgroup">
                                <div class="formgroup-col-1 rltv-pos" >
                                    <label>Study Name</label>
                                    <input className="form-controll " value={this.state.studyName} onChange={this.onStudyNameChange}></input>
                                    <div className="errordiv" id="error1">{this.state.studynameerror}</div>
                                </div>
                               
                            </div>

                            {/* <div className = "formgroup">
                                <div class="formgroup-col-1 rltv-pos" >
                                    <label>Project Description</label>
                                    <input className="form-controll " value={this.state.description} onChange={this.onDescriptionChange}></input>
                                    <div className="errordiv" id="error2">{this.state.descerror}</div>
                                </div>
                            </div> */}

                            <div className = "formgroup">
                                <div class="formgroup-col-3 rltv-pos" >
                                    <label>Orignal Requirment</label>
                                    <input className="form-controll " value={this.state.orignalRequirment} onChange={this.onRequirementChange}></input>
                                    <div className="errordiv cus-width-6" id="error3">{this.state.reqerror}</div>
                                </div>
                                <div class="formgroup-col-3 rltv-pos" >
                                    <label>LOI In Min</label>
                                    <input className="form-controll " value={this.state.loi} onChange={this.onLoiChange}></input>
                                    <div className="errordiv cus-width-6" id="error4">{this.state.loierror}</div>
                                </div>
                                <div class="formgroup-col-3 rltv-pos" >
                                    <label>IR %</label>
                                    <input className="form-controll " value={this.state.IR} onChange={this.onIrChange}></input>
                                    <div className="errordiv cus-width-6" id="error5">{this.state.irerror}</div>
                                </div>
                                <div class="formgroup-col-3 rltv-pos" >
                                    <label>CPI</label>
                                    <input className="form-controll " value={this.state.cpc} onChange={this.onCostChange}></input>
                                    <div className="errordiv cus-width-6" id="error6">{this.state.cpcerror}</div>
                                </div>
                            </div>       


                            <div className = "formgroup">
                                <div class="formgroup-col-8 rltv-pos" >
                                    <label>First Party Live Url</label>
                                    <input className="form-controll " value={this.state.firstPartyUrl} onChange={this.onFirstPartyUrlChange}></input>
                                    <div className="errordiv cus-width-2" id="error7">{this.state.urlerror}</div>
                                </div>
                                <div class="formgroup-col-2" >
                                   
                                    <button onClick={ this.addPlaceholder } style={{marginTop: "27px", width: "-webkit-fill-available"}} className="list-btn list-btn-color">Add Placeholder</button>

                                </div>
                            </div>

                                     
                        <div className = "formgroup">
                                <div class="formgroup-col-8 rltv-pos" >
                                    <label>First Party Test Url</label>
                                    <input className="form-controll " value={this.state.firstPartyUrlTest} onChange={this.onTestUrlChange}></input>
                                    <div className="errordiv cus-width-2" id="error8">{this.state.testurlerror}</div>
                                </div>
                                <div class="formgroup-col-2" >
                                   
                                    <button onClick={ this.addTestPlaceholder } style={{marginTop: "27px", width: "-webkit-fill-available"}} className="list-btn list-btn-color">Add Placeholder</button>

                                </div>
                            </div>

                    <div className = "formgroup">
                                <div class="formgroup-col-4 rltv-pos" >
                                    <label>Status</label>
                                    <select className="form-controll" style={{width:"100%", padding: "2px"}} value={this.state.status} onChange={this.onStatusChange}>
                                        <option value="">Select Status</option>
                                        <option  value="live">Live</option>
                                        <option  value="pause">Pause</option>
                                        <option  value="completed">Complete</option>
                                    </select>
                                    <div className="errordiv cus-width-3" id="error9">{this.state.statuserror}</div>
                                </div>
                                <div class="formgroup-col-4 rltv-pos" >
                                    <label>BDE</label>
                                    <select className="form-controll" style={{width:"100%", padding: "2px"}} value={this.state.bdeUserId} onChange={this.onBdeChange}>
                                        <option value="">Select BDE</option>
                                     {bdeUsersItem}
                                    </select>
                                    <div className="errordiv cus-width-3" id="error10">{this.state.bdeerror}</div>
                                </div>
                               
                               
                                <div class="formgroup-col-4 rltv-pos" >
                                    <label>Client</label>
                                    <select className="form-controll" style={{width:"100%", padding: "2px"}} value={this.state.clientID}  onChange={this.onClientChange}>
                                        <option value="">Select Client</option>
                                       {clientsItem}
                                    </select>
                                    <div className="errordiv cus-width-3" id="error11">
                                     {this.state.clienterror}
                                    </div>
                                </div>
                            </div>

                    <div className="check-box-list flex-show check-list-st mr-top-30">
                        <div class="form-group mr-right-20 rltv-pos">
                            <input type="checkbox" id="html" onChange={this.onAllowLinkChange} checked={this.state.allowLink}/>
                           
                            <label for="html">Allow Unique Link</label>
                           
                        </div>

                        <div class="form-group mr-right-20  rltv-pos">
                            <div className="errordivcheck cus-width-2" id="error13">
                                    <div className="arrow_box">
                                        {this.state.allowdemoerror}
                                    </div>
                                </div>
                                <input type="checkbox" id="css"  onChange={this.onAllowDemoChange}  checked={this.state.allowDemo}/>
                                <label for="css">Allow Demographics</label>
                            </div>

                            {/* <div class="form-group mr-right-20  rltv-pos">
                                <input type="checkbox" id="javascript" onChange={this.onAllowRouterChange} checked={this.state.allowRouter} />
                                <label for="javascript">Allow Router</label>
                            </div> */}
                        </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                <button style={{marginTop: "40px"}} className="list-btn " onClick={this.addStudy}>Create Study</button>
                </div>
            </div>
            </div>
        </div>

    )}
}
const mapStateToProps=(state)=>{
    return{
        clientsList    : state.studies.clientsList,
        bdeUsersList : state.studies.bdeUsersList,
        studySuccess : state.studies.studySuccess,
    }
  }
  
  export default connect(mapStateToProps)(CreateStudyForm);
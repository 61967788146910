import React, { Component }  from 'react';
import '../../commonStyle/common.css';
import goback from '../../images/goback.png';

import { connect } from 'react-redux';
import {Link} from 'react-router-dom';

export class UpdateDemographicDiv extends Component {
    
    constructor(props) {
    
        super(props)
        this.state = {
            demographicName : "",
            demo_id : "",
            validationMsg : {},
            
        }
        this.onChange = this.onChange.bind(this);
        this.updateDemo = this.updateDemo.bind(this);
        this.updateDemoAndQuery = this.updateDemoAndQuery.bind( this);
      }
      

      componentDidMount(){
        if(this.props.editDemographicsData ){
            this.setState({ demographicName : this.props.editDemographicsData.name, demo_id : this.props.editDemographicsData._id });
          }
      }
     
      onChange(e) {

        this.setState({ [e.target.name]: e.target.value });
      }
    
      handleValidation() {
        let valid = true;
        let error = {}
    
        if (this.state.demographicName === "") {
    
          error.demographicName = "Can't leave empty"
          valid = false;
        }else{
    
          error.demographicName = "";
          valid = true;
        }
    
        this.setState({ validationMsg: error });
        return valid;
      }

      updateDemo() {
        let DemoData = {};
        DemoData.name = this.state.demographicName;
        if (this.handleValidation()) {
            this.props.updateDemo(DemoData, this.state.demo_id);
        }
      }

      updateDemoAndQuery(){
        this.props.updateDemoAndQuery(this.state.demo_id, this.state.demographicName);
      }


    render(){

        return(
 
            <div style={{marginTop: "2%"}}>
                <div className="table-st">
                    <div  className="forms-rd-shadow" style={{ width: "100%",paddingBottom:"50px", backgroundColor:"#ffffff", borderRadius:"4px" }}>
                    <div className="addVendor-main-div">
                    <div className="form-title">
                            <h4 className="rm-margin" style={{textAlign: "center"}}>Update Demographic</h4>
                           
                        </div>
                        <div className="form-title" style={{textAlign : "right"}}> <button onClick={this.updateDemoAndQuery}   className="list-btn list-btn-color">
                          <span>Update Questions And Options</span> </button>
                        </div>
                           
                            <div className = "formgroup">
                          
                                <div class="formgroup-col-1" >
                                   
                                    <input className="form-controll " name="demographicName" placeholder="Demographic Name" value={this.state.demographicName} onChange={this.onChange}/>
                                </div>
                            </div>
                            <div style={{textAlign: "center"}} >
                                    <button onClick={this.updateDemo}   style={{marginTop: "27px"}} className="list-btn list-btn-color">
                                    <span>Update Demographic</span> </button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
    )}
}


const mapStateToProps=(state)=>{
    return{
      
    }
  }
  export default connect(mapStateToProps)(UpdateDemographicDiv);
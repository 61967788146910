import appConfig from "../../api/apiConfig";

export const submitLoginApi = (data) => {
	let loginData  = JSON.stringify(data);
	const headers = Object.assign({
		'Content-Type': 'application/json',
	});
	const request = new Request(appConfig.siteURL + 'login/', {
		method: "POST",
		headers: headers,
		body: loginData
	
	});

	return fetch(request).then(response => {
		return response.json().then(result => {
			if(result.success){

				localStorage.setItem('admin_name', result.data.first_name);

    			sessionStorage.setItem("activeMenuId", "dash");
				localStorage.setItem('authenticate', result.token);
				localStorage.setItem('auth_user_id', result.data.user_id);
				localStorage.setItem('auth_user_type', result.data.role_type);

				localStorage.setItem('is_Dashboard', result.permission.is_Dashboard);

				localStorage.setItem('is_Study' ,result.permission.is_Study);
				localStorage.setItem('is_Study_create' , result.permission.is_Study_create);
				localStorage.setItem('is_Study_list' , result.permission.is_Study_list);
				localStorage.setItem('is_Study_update' , result.permission.is_Study_update);
				localStorage.setItem('is_StudyDelete', result.permission.is_StudyDelete);

				localStorage.setItem('is_Vendor' , result.permission.is_Vendor);
				localStorage.setItem('is_Vendor_create' , result.permission.is_Vendor_create);
				localStorage.setItem('is_Vendor_list' , result.permission.is_Vendor_list);
				localStorage.setItem('is_Vendor_update' , result.permission.is_Vendor_update);

				localStorage.setItem('is_Demographics' , result.permission.is_Demographics);
				localStorage.setItem('is_Demographics_create' , result.permission.is_Demographics_create);
				localStorage.setItem('is_Demographics_list' , result.permission.is_Demographics_list);
				localStorage.setItem('is_Demographics_update' , result.permission.is_Demographics_update);

				localStorage.setItem('is_Role' , result.permission.is_Role);
				localStorage.setItem('is_Role_create' , result.permission.is_Role_create);
				localStorage.setItem('is_Role_list' , result.permission.is_Role_list);
				localStorage.setItem('is_Role_update' , result.permission.is_Role_update);

				localStorage.setItem('is_user' , result.permission.is_user );
				localStorage.setItem('is_user_create' , result.permission.is_user_create );
				localStorage.setItem('is_user_list' , result.permission.is_user_list );
				localStorage.setItem('is_user_update' , result.permission.is_user_update );

				//study sub menu
				localStorage.setItem('is_ShowAddMapping' ,result.permission.is_ShowAddMapping);
				localStorage.setItem('is_ShowParticipantList' , result.permission.is_ShowParticipantList);
				localStorage.setItem('is_SendInvitation' , result.permission.is_SendInvitation);
				localStorage.setItem('is_CreateDemoMapping' , result.permission.is_CreateDemoMapping);
				localStorage.setItem('is_ShowAddConstraint' , result.permission.is_ShowAddConstraint);

				// client menus
				
				localStorage.setItem('is_client' ,result.permission.is_client);
				localStorage.setItem('is_client_create' , result.permission.is_client_create);
				localStorage.setItem('is_client_list' , result.permission.is_client_list);
				localStorage.setItem('is_client_update' , result.permission.is_client_update);
			
				// incoice menus
				localStorage.setItem('is_invoice' ,result.permission.is_invoice);
				localStorage.setItem('is_invoice_create' , result.permission.is_invoice_create);
				localStorage.setItem('is_invoice_list' , result.permission.is_invoice_list);
				
				//studymapping sub menu
				localStorage.setItem('is_ShowUpdateMapping' ,result.permission.is_ShowUpdateMapping);

				localStorage.setItem('is_send_email' ,result.permission.is_send_email);

				localStorage.setItem('is_archiving' ,result.permission.is_archiving);

				localStorage.setItem('reload_component' , true);
			  }
			return result;
		});
	}).catch(error => {
		console.log("login error: ", error)
		return error;
	});
}

import React, { Component }  from 'react';
import  '../../commonStyle/common.css';
import $ from 'jquery';
import {connect} from 'react-redux';
import Pagination from "react-js-pagination";
import deletIcon from '../../images/delete.png';
import editpencil from '../../images/edit-pencil.png';
import { getClientList, softClientDelete, emptyClientProps } from '../../actions/actionsBag/clientAction';
import { Link } from 'react-router-dom';
import { alertMessage,emptyDialogdata  }  from '../../alertsDialog/Action';

  class ClientListTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            totalPage : 0,
            clientList : [],
            showloader: false,
        }
        this.handlePageChange = this.handlePageChange.bind(this);
      }


    componentWillReceiveProps(nextProps){

      if(nextProps.confirmDialog === true){
        // alert(nextProps.confirmData);
        this.props.dispatch(emptyDialogdata());
        let data= {}
        data.clientId = nextProps.confirmData;
        data.userid = localStorage.getItem("auth_user_id");
       this.props.dispatch(softClientDelete(data));
      }
        if(nextProps.clientList && nextProps.clientList.length >0){
            this.setState({
                totalPage : nextProps.totalClients,
                clientList : nextProps.clientList,
                showloader: true,
            });
        }
  
        if(nextProps.clientDeleteSuccess === true && nextProps.clientDeleteMessage){
            let alertData ={};
            alertData.type ="alert";
            alertData.message =  nextProps.clientDeleteMessage;
            this.props.dispatch(alertMessage(alertData));
            this.props.dispatch(getClientList(1));
            this.setState({currentPage : 1});
            this.props.dispatch(emptyClientProps());
        }else if(nextProps.clientDeleteMessage){
            let alertData ={};
            alertData.type ="alert";
            alertData.message =  nextProps.clientDeleteMessage;
            this.props.dispatch(alertMessage(alertData));
            this.props.dispatch(emptyClientProps());
        }
    }
  


  componentWillMount(){
    this.props.dispatch(getClientList(1));

  $(".dropdown").on('click',function() { 
      var t = (this.id); 
    if(t === "all-rm-shadow"){
      var all= document.getElementsByClassName("dropdown");
    
      for(let a=0; a< all.length; a++){
          if ( $( all[a] ).removeClass( "active" ) ) {
        
        }
      
        $(all[0]).addClass("active");
      }
    }else if(t === "live-rm-shadow"){
      
      var live= document.getElementsByClassName("dropdown");
    
      for(let a=0; a< live.length; a++){
          if ( $( live[a] ).removeClass( "active" ) ) {
        }
        $(live[1]).addClass("active");
      }
    }else if(t === "pause-rm-shadow"){
      var pause= document.getElementsByClassName("dropdown");
    
      for(var a=0; a< pause.length; a++){
          if ( $( pause[a] ).removeClass( "active" ) ) {
        
        }
      
        $(pause[2]).addClass("active");
      }
  }else if(t === "com-rm-shadow"){
      var com= document.getElementsByClassName("dropdown");
    
      for(let a=0; a< com.length; a++){
          if ( $( com[a] ).removeClass( "active" ) ) {
        
        }
      
        $(com[3]).addClass("active");
      }
    }
  });  
}

  callMe(){
      $(function() {
          $("#demo").find("p").hide();

          $(".demo").find("table").hide();
          
          $(".tbl").click(function(event) {
              event.stopPropagation();
              var $target = $(event.target);
              if ( $target.closest("td").attr(".demo") > 1 ) {
                  $target.slideUp();
                  $(".listexpandicon").removeClass("minus");
                  } else {
                  $target.closest("tr").next().find("table").toggle();
                  $target.closest("tr").find(".listexpandicon").toggleClass("minus");
              }              
          });
      });
  }
    

  handlePageChange(pageNumber){

    this.setState({ currentPage : pageNumber, clientList : []});
    
    this.props.dispatch(getClientList(pageNumber));
  }

  editProject(){
    alert("Edit project");
  }

  softDeleteClient( id){
      let alertData ={};
      alertData.type ="confirmalert";
      alertData.message = "Are you sure to delete this item ?";
      alertData.deleteId = id;
      this.props.dispatch(alertMessage(alertData));
}

  tableZoom(){
    $(".alltablestruct").toggleClass("zoom-me");
    // $("#routercontainer").toggleClass("router-containter-pd");
    // document.getElementById("routercontainer").style.padding="0px";

  }

    render(){
        this.callMe();
        let clientList = "";
       

        if(this.state.clientList && this.state.clientList.length >0){
         
          let sr = (this.state.currentPage * 10)-10;
            clientList= this.state.clientList.map((list, i) => {  
            
                
                    return <tbody> <tr>  
                          <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-bottom">{sr+i+1}</td>
                                <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.name}</td>
                                <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.e_mail}</td>
                                <td  className="tbl tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">{list.company_name}</td>
                                
                              
                                <td className=" tbl-padd-top tbl-padd-left tbl-padd-bottom">
                                <div className="flex-show">
                                    
                                <div className="icons" >
                                      <div className="delete" onClick={this.softDeleteClient.bind(this,list.client_id)} >
                                        <img src={deletIcon}/>
                                      </div>
                                    </div>

                                    <div className="icons">
                                            <Link to = {{      
                                                  pathname: '/update-client',
                                                  state: {
                                                      editClientData : list,
                                                    }
                                                }}> <div className="pencil"> <img src={editpencil}  height="20px" width="20px"/> </div></Link>
                                          </div>
                                  </div>
                            </td>
                                </tr>
                                </tbody>
             });
          }else{
            clientList = "";
          }


            let showTable ="";
            if(this.state.clientList && this.state.clientList.length >0){
              showTable = <table className="tbl-new-est">
              <thead>
                <tr>
                      <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">S.no.</th>
                      <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Name</th>
                      <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Email-id</th>
                      <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Company Name</th>
                      <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Action</th>
                  </tr>
                  </thead>
                {clientList}
            </table>
            }else if(this.state.showloader === true){
              showTable = <div className="datanotfound"> No record found</div>
            }else{
              showTable =<div className="spiner-data"><div className="tblspinner"></div></div>
             
            }
           


        return(
            <div className="list-style">
                <div className="table-st">
                    <div className="alltablestruct forms-rd-shadow">
                         {/* serachbox and stausbtn */}
                        <div id="search-staus">

                            <div className="flex-show tbl-h-font">
                                 Client List
                            </div>
                            
                            <div className="flex-show tbl-zoom" >
                            <span onClick={this.tableZoom} className="full-screen mr-rhs-20 mr-top-10"></span>
                            </div>
                        </div>
                        {/* serachbox and stausbtn */}
                            {/* table */}
                            <div style={{marginTop: "20px", overflowX: "auto"}}>
                                  {showTable}
                            </div>
                            {/* table */}
                   
                      <div id="pageinationstyle">
                       
                        <Pagination
                          activePage={this.state.currentPage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalPage}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange}
                        />
                        </div>
                    
                    </div>
                </div>
            </div>
    )}
}

const mapStateToProps = (state) => {
  return {
    clientList : state.client.clientList,
    totalClients : state.client.totalClients,
    clientDeleteSuccess :  state.client.clientDeleteSuccess,
    clientDeleteMessage :  state.client.clientDeleteMessage,
    confirmDialog :  state.alertsDialog.confirmDialog,
    confirmData : state.alertsDialog.confirmData
  }
}

export default connect(mapStateToProps)(ClientListTable);

import appConfig from "../../api/apiConfig";

//getAllInvoicedApi
export const getAllInvoicedApi =(page)=> {
	let userId = localStorage.getItem("auth_user_id");
  let roleType = localStorage.getItem("auth_user_type");
	const headers = Object.assign({'Content-Type': 'application/json'});
	const request= new Request(appConfig.siteURL + 'getAllInvoiced/'+userId+'/'+roleType+'/'+page,{
	  method : "GET",
	  headers : headers,
	});
  
  return  fetch(request).then(response => {
	return  response.json().then(study => {
		return study;
	});
	 }).catch(error => {return error;});
	}


    // getAllReadyInvoicesApi
    export const getAllReadyInvoicesApi =(page)=>{
   
        let userId = localStorage.getItem("auth_user_id");
        let roleType = localStorage.getItem("auth_user_type");
        const headers = Object.assign({'Content-type':'application/json',
                                      'Accept':'*/*'});
       const request= new Request( appConfig.siteURL+'getAllReadyToInvoice/'+userId+'/'+roleType+'/'+page, {
         method : "GET",
         headers : headers,
      
       
       });
      
       return fetch(request).then(response => {
           return  response.json().then(statusResponse => {
             return statusResponse;
           });
         }).catch(error => {
          return error;
        });
       }
    
// invoiceCreateApi
export const invoiceCreateApi =(data)=>{
    let invoiceData = JSON.stringify(data);
   const headers = Object.assign({'Content-type':'application/json',
                                 'Accept':'*/*'});
  const request= new Request( appConfig.siteURL+'createInvoiced', {
    method : "POST",
    headers : headers,
     body : invoiceData
  
  });
 
  return fetch(request).then(response => {
      return  response.json().then(statusResponse => {
        return statusResponse;
      });
    }).catch(error => {
     return error;
   });
  }


    //approvedAllSelectedInvoiced
    export const approvedAllSelectedInvoicedApi =(data)=>{
      let invoiceData = JSON.stringify(data);
  
      let userId = localStorage.getItem("auth_user_id");
      let roleType = localStorage.getItem("auth_user_type");
  
     const headers = Object.assign({'Content-type':'application/json',
                                   'Accept':'*/*'});
    const request= new Request( appConfig.siteURL+'approvedAllSelectedInvoiced/'+userId+"/"+roleType, {
      method : "POST",
      headers : headers,
       body : invoiceData
    
    });
   
    return fetch(request).then(response => {
        return  response.json().then(statusResponse => {
          return statusResponse;
        });
      }).catch(error => {
       return error;
     });
    }
  

    // sendInvoiceApi
export const sendInvoiceApi =(data)=>{


  let sendInvoiceData = JSON.stringify(data);

 const headers = Object.assign({'Content-type':'application/json',
                               'Accept':'*/*'});
const request= new Request( appConfig.siteURL+'invoicedGenrateBillSend', {
  method : "POST",
  headers : headers,
 body : sendInvoiceData

});

return fetch(request).then(response => {
    return  response.json().then(statusResponse => {
      return statusResponse;
    });
  }).catch(error => {
   return error;
 });
}

    // sendInvoiceEmailApi

export const sendInvoiceEmailApi =(approvedInvoices)=>{
  let inoicesObj ={};

  inoicesObj=approvedInvoices
let sendInvoiceData = JSON.stringify(inoicesObj);

const headers = Object.assign({'Content-type':'application/json',
                             'Accept':'*/*'});
const request= new Request( appConfig.siteURL+'emailToApprovedInvoiced', {
method : "POST",
headers : headers,
body : sendInvoiceData

});

return fetch(request).then(response => {
  return  response.json().then(statusResponse => {
    return statusResponse;
  });
}).catch(error => {
 return error;
});
}
 

// deleteInvoiceApi
export const deleteInvoiceApi =(invoiceID)=>{

  let userId = localStorage.getItem("auth_user_id");
  let roleType = localStorage.getItem("auth_user_type");
  let data =  {}
  data.invoicedId = invoiceID;
  data.userId = userId;
  data.roleType = roleType;
  let deleteInvoiced = JSON.stringify(data);

 const headers = Object.assign({'Content-type':'application/json',
                               'Accept':'*/*'});
const request= new Request( appConfig.siteURL+'removedInvoiced', {
  method : "POST",
  headers : headers,
 body : deleteInvoiced

});

return fetch(request).then(response => {
    return  response.json().then(statusResponse => {
      return statusResponse;
    });
  }).catch(error => {
   return error;
 });
}


//getInvoiceByIdApi
export const getInvoiceByIdApi =(invoiceID)=>{

  const headers = Object.assign({'Content-type':'application/json',
                                'Accept':'*/*'});
 const request= new Request( appConfig.siteURL+'getInvoicedDataById/'+invoiceID, {
   method : "GET",
   headers : headers,
  
 
 });
 
 return fetch(request).then(response => {
     return  response.json().then(statusResponse => {
       return statusResponse;
     });
   }).catch(error => {
    return error;
  });
 }
 

 // generateFinalBillApi
export const generateFinalBillApi =(invoiceID)=>{

  let userId = localStorage.getItem("auth_user_id");
  let roleType = localStorage.getItem("auth_user_type");
  let data =  {}
  data.invoicedId = invoiceID;
  data.userId = userId;
  data.roleType = roleType;
  let finalInvoiced = JSON.stringify(data);

 const headers = Object.assign({'Content-type':'application/json',
                               'Accept':'*/*'});
const request= new Request( appConfig.siteURL+'updateFinelInvoiced', {
  method : "POST",
  headers : headers,
 body : finalInvoiced

});

return fetch(request).then(response => {
    return  response.json().then(statusResponse => {
      return statusResponse;
    });
  }).catch(error => {
   return error;
 });
}

import { stat } from "fs";

const initialState = {
	queryTextshow: "",
	totalDemographics : "",
	queryOptionShow: "",
	demoShow: true,
	queryOptionError: "",
	demoSuccess: "",
	demoError: "",
	queryOptionSuccess: "",
	querySuccess: "",
	show: true,
	showDemoList: "",
	showDemo: "",
	showQuery: "",
	query:"",
	queryOptionList : '',
}



const demographics = (state = initialState, action) => {
	//state.memberSponsees=[];
	switch (action.type) {

	case 'SET_HIDE_DEMO_LIST':
	return {
		...state,
		showDemoList: false,
		queryTextList: ""
	}

	case 'SET_QUERY_MESSAGE':
		return {
			...state,
			querySuccess: action.querySuccess,
			queryTextshow: false,
			queryOptionShow: true,
			demoShow: false
		}

	case 'SET_QUERY_UPDATE_RES':
		return {
			...state,
			demoQuestionSuccess : null,
			demoQuestionData    : null,
			queryUpdateSuccess  : action.response.success,
			queryUpdateMessage  : action.response.message,
		}
	case 'SET_EMPTY_QUERY_UPDATE_RES':
		return {
			...state,
			queryUpdateSuccess : null,
			queryUpdateMessage : null,
			querySuccess : null,
			updateDemoRes : null,
			demoQuestionSuccess : null,
			demoQuestionData : null
		}

	case 'SET_DELETE_QUES_RESPONSE':
		return{
			...state,
			queryUpdateSuccess  : action.response.success,
			queryUpdateMessage  : action.response.message,
		}
	
	case 'SET_DELETE_OPTION_RESPONSE':
			return{
				...state,
				optionDeleteSuccess  : action.response.success,
				optionDeleteMessage  : action.response.message,
			}

	case 'SET_QUERY_ERROR':
		return {
			...state,
			queryError: action.queryError,
			queryTextshow: false,
			queryOptionShow: false,
			demoShow: true
		}

	case 'SET_QUERY_DATA':
		return {
			...state,
			queryTextList: action.queryTextList,
			queryAndRange: action.queryTextList.demoAgeData,
			showQuery: true,
			showDemo: false
		}

	case 'SET_THIRD_PARTY':
		return {
			...state,
			result: action.result,
		}

	case 'SET_ADD_NEW_DEMO':
		return {
			...state,
			demoSuccess: action.demoSuccess,
			successDemo: true,
			query: null,
			queryOptionSuccess:null,
			querySuccess:null
			
		}

	case 'SET_ADD_DEMO_ERROR':
		return {
			...state,
			demoError: action.demoError,
		}

	case 'SET_NEW_DEMO_DATA':
		return {
			...state,
			demoList: action.demoList.demoList,
			totalDemographics : action.demoList.totalDemo,
			queryTextshow: false,
			queryOptionShow: false,
			showDemo: true,
			showQuery: false
		}

	case 'SET_QUERY_OPTION':
		return {
			...state,
			queryOptionSuccess : action.queryOptionSuccess,
			queryOptionList : action.queryOptionSuccess.options
		}

	case 'SET_QUERY_OPTION_ERROR':
		return {
			...state,
			queryOptionError: action.queryOptionError,
		}

	case 'SET_UPDATE_QUERY_REP':
	return {
		...state,
		updateQueryOptionRep: action.updateQueryOptionRep,
	}

	case 'SET_EDIT_DEMO':
	return {
		...state,
		item: action.item,
	}

	case 'SET_REDUCER_TO_ZERO':
	return {
		...state,
		item: "",
		successDemo: false,
		queryTextList: ""

	}

	case 'CREATE_DEMO_COMPLETED':
		return {
			...state,
			successDemo: false
		}

	case 'SET_UPDATE_DEMO_RES':
	return {
		...state,
		updateDemoRes: action.updateDemoRes,
	}

	case 'SET_QUERY_AND_OPTION':
	return {
		...state,
		queryOptionSuccess: action.queryAndOption.options,
		query: action.queryAndOption.question,
		demoSuccess: action.queryAndOption.demographics
	}

	case 'SET_QUERY_AND_RANGE':
	return {
		...state,
		queryAndRange: action.queryAndRange,
	}

	case "SET_PARTICIPANT_COUNT":
	return {
		...state,
		thirdPartyTotalCount: action.thirdPartyTotalCount,
	}

	case "SET_DEMO_QUESTION":
		return{
			...state,
			demoQuestionSuccess : action.response.success,
			demoQuestionData : action.response.query
		}

	case 'SET_EMPTY_OPTIONS_PROPS':
		return{
			...state,
			queryOptionSuccess : null,
			queryOptionError : null,
			updateQueryOptionRep : null,
			optionDeleteSuccess  : null,
			optionDeleteMessage : null
		}

	case 'SET_OPTION_LIST':
		return{
			...state,
			queryOptionList : action.response.options
		}

	case 'SET_DELETE_DEMO_RESPONSE':
		return{
			...state,
			demoDeleteSuccess  : action.response.success,
			demoDeleteMessage  : action.response.message,
		}

	case 'SET_EMPTY_DEMO_PROPS':
			return{
				...state,
				demoDeleteSuccess  : null,
				demoDeleteMessage  : null
			}

		default:
			return state;
	}

};
export default demographics;
import React, { Component }  from 'react';
import {getPreviewQuestions } from '../../../actions/actionsBag/demoMapping';
import { alertMessage }  from '../../../alertsDialog/Action';
import { connect,  } from 'react-redux';
import {Link} from 'react-router-dom';
// import Pagination from "react-js-pagination";
import deletIcon from '../../../images/delete.png';
import $ from 'jquery';

class  PreviewQuestions  extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: "",
            currentPage: 1,
            studyId : "",
            questionsList : [],
            totalPage : 0,
            showloader : false
        }

      }


  componentWillReceiveProps(nextProps){



    if( nextProps.questionsList && nextProps.questionsList.success && nextProps.questionsList.screenerData.length >0){
     
      this.setState({
        questionsList : nextProps.questionsList.screenerData,
        showloader: true,
      });
    }else{
      this.setState({
        showloader: true,
      });
    }


  }
  
  componentWillMount(){
    let studyId = localStorage.getItem("study_Id");
    this.setState({ studyId : studyId });
    this.props.dispatch(getPreviewQuestions( studyId));
  }

  
  tableZoom(){
    $(".alltablestruct").toggleClass("zoom-me");
    // $("#routercontainer").toggleClass("router-containter-pd");
    // document.getElementById("routercontainer").style.padding="0px";

  }

    render(){
        
        let roleItems = "";
       
        if (this.state.questionsList && this.state.questionsList.length> 0 ) {
         

            roleItems = this.state.questionsList.map((itemj , j) =>{
                
            if(Array.isArray(itemj)){


                let options = "";

                options =  itemj.map((item , i) =>{
                    return  <div class="form-group" style={{color:"black"}}  ><input   type = "checkbox" checked = {true} name  = {"From " + item.ageFrom + " to "+item.ageTo}  /> 
                    <label for={item.optionText}>{"From " + item.ageFrom + " to "+item.ageTo}</label>
                    </div>
                    // <div id="applyscreenform"><input  type = "checkbox" checked = {true} name  = {"From " + item.ageFrom + " to "+item.ageTo}  /> {"From " + item.ageFrom + " to "+item.ageTo}</div>
                   });
                
                   return  <div id="applyscreenform">
                   <h3  style={{color:"black", textAlign:"left"}} >Question {j+1} : &nbsp; {itemj[0].question}</h3>
                   <h5  style={{color:"black"}} > *This is range type screener.</h5>
                   
                   {options}
                  <hr/>
                  </div>


            }else if(itemj.queryType === "text"){

                return  <div id="applyscreenform">
                <h3  style={{color:"black" , textAlign:"left"}} >Question {j+1} : &nbsp; {itemj.question}</h3>
                <h5  style={{color:"black"}} > *This is text type screener.</h5>
                
               <hr/>
               </div>


            } else {

           

        let dropdownoptions = "";

        if(itemj.options.length > 0){
        dropdownoptions = itemj.options.map((item , i) =>{

            if(item.isSelected === "1"){
              return  <div class="form-group" style={{color:"black"}}  ><input   type = "checkbox" checked = {true} name  = {item.optionText}  /> 
              <label for={item.optionText}>{item.optionText}</label>
              </div>

            }
            else{
              return  <div  class="form-group" style={{color:"black"}} ><input  type = "checkbox" checked = {false} name  = {item.optionText}  />
                <label for={item.optionText}>{item.optionText}</label>
              </div>
              
            }
              
              });
          }
         
      return  <div   style={{color:"black"}} id="applyscreenform">
              <h3 style={{ color:"black", textAlign:"left" }}>Question {j+1} : &nbsp; {itemj.question} </h3>
              
              <h5>Selected Options :  </h5>
              
              <ul>
                    {dropdownoptions}
                  
              </ul>
              <hr/>
              </div>

            }
           });

          }else{
            roleItems = "";
          }


          let showTable = "";
          if ( (this.state.questionsList && this.state.questionsList.length > 0) ) {
            showTable =  <table className="tbl-new-est" >
             {roleItems}
            </table>
          }else if(this.state.showloader === true){
            showTable = <div className="datanotfound"> No record found</div>
          }else{
            showTable = <div className="spiner-data"><div className="tblspinner"></div></div>
          }
         


        return(
          <div style={{ width:"100%"}}>  
                
          <div style={{marginTop: "2%"}} className="list-style">
          <div className="table-st">
              <div className="forms-rd-shadow" style={{ width: "100%",paddingBottom:"50px", backgroundColor:"#ffffff", borderRadius:"4px" }}>
                  <div className="addVendor-main-div">
                  <div className="form-title">
                      <h3 className="rm-margin">Preview Questions</h3>
                  </div>
                  <hr/>
                  {showTable}
                  </div>
              </div>
          </div>
      </div>
      </div>
    )}
}

const mapStateToProps = (state) => {
  return {
    questionsList : state.demoMapped.questionsList,
  }
}

  export default connect(mapStateToProps)(PreviewQuestions);


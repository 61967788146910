import React, { Component }  from 'react';
import { connect } from 'react-redux';
import RoleHeader from '../../components/Role/roleHeader.jsx';
import RoleListTable from '../../components/Role/roleList.jsx';
import {ThirdPartyList} from '../../actions/actionsBag/vendor';
import add from '../../images/add.png';
import {Link} from 'react-router-dom';

    class RoleListView extends Component {

        constructor(props) {
            super(props)
            this.state = {

            }
            
        }

    componentWillMount() {
        let currentpage = 1;
        
      }

 


    render(){
        const header = 'Users Role List';
        const headerText = 'Here you can find and add user';
        const headerButton = <Link to="/create-role"><button className="create-btn create-btn-color" onClick={this.gotoCreateVendor}><img src={add} height="12px" width="12px"/><span> Add Role</span></button></Link>

        return(
            <div style={{ width:"100%"}}>  
                <RoleHeader
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                />
                <RoleListTable
                    
                />
            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        thirdPartyList : state.thirdParty.thirdPartyList,
        totalpages : state.thirdParty.totalpages,
    }
  }
  
  export default connect(mapStateToProps)(RoleListView);
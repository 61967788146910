import appConfig from "../apiConfig";

export const thirdPartyApi =(data)=> {
    let thirdPartyData = JSON.stringify(data);
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });

    let request ;
    if(data.id){
      request = new Request(appConfig.siteURL +'vendorsUpdate/'+data.id, {
        method : "POST",
        headers : headers,
        body : thirdPartyData
      });
    }else{
    request = new Request(appConfig.siteURL +'vendors/', {
      method : "POST",
      headers : headers,
      body : thirdPartyData
    });
  }
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
      
       return error;
     }); 
  }

  

  export const getThirdPartyApi =()=> {
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });

    let request  = new Request(appConfig.siteURL +'vendors', {
      method : "GET",
      headers : headers
    });
  
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
      
       return error;
     }); 
  }

  export const thirdPartyListApi =(currentpage)=> {
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'vendorsList/'+ currentpage, {
    //  const request= new Request('http://localhost:8089/api/vendorsList/'+ currentpage, {
      
      method : "GET",
      headers : headers,
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
      
       return error;
     }); 
  }

  export const removeThirdpartyApi =(id)=> {
    const headers = Object.assign({'Content-Type': 'application/json',
                                    });
    const request= new Request(appConfig.siteURL +'newSoftDelete/'+id, {
      method : "POST",
      headers : headers,
    });
  
  return  fetch(request).then(response => {
    return  response.json().then(result => {
        return result;
    });
     }).catch(error => {
       return error;
     }); 
  }
import React, { Component } from 'react';
import StudyUpdate from '../Study/studyUpdate';
import UpdateStudyHeader from '../Study/updateStudyHeader';
import { connect } from 'react-redux';
import '../../pages/Study/tempstyle.css';
import '../../commonStyle/common.css';
import $ from 'jquery';
import expandbutton from '../../images/expand-button.png';
import righicon from '../../images/righticon.png';
import download from '../../images/downloadicon.png';
import userboxicon from '../../images/userboxicon.png';

class ApiStudyQuota extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search: "all",
        }
        this.onChange = this.onChange.bind(this);
        this.participantAsExcel = this.participantAsExcel.bind(this);
    }
    componentDidMount() {

        $(document).ready(function () {

            $("#tg-btn-4").click(function () {
                $("#show-form-4").toggleClass("show-full-div");
                $("#quotadiv").toggleClass("quota-tbl-hide");
                $("#rotate-icon-4").toggleClass("rotate-tgl");

            });
        });
    }

    // componentWillReceiveProps(nextProps) {
    //     // console.log(nextProps);
    // }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    participantAsExcel() {
        this.props.participantAsExcelBystudy(this.state.search,false);
    }

    getQuotasRows = (qual, quotas) => {
        console.log("quali", quotas);

        const currentQualiQuotas = quotas.filter((qutoa)=>{
            if(qutoa.qualification_label===qual){
                return qutoa;
            }
        });
const rows = currentQualiQuotas.map((item)=>{
    let  label ='';
    // if(item.qualification_label==="Income" ||item.qualification_label==="Age" ){
    //     label = item.criteria[0].range_sets[0].to + " To " + item.criteria[0].range_sets[0].from
    // } else{
        label=item.condition_labels[0]
   // }

    return (
        <tr key = {item.quota_id}>
            <td width="200px">
                {label}
            </td>
            <td width="200px">
                {item.quantities.currently_open}
            </td>
            <td width="200px">
                {item.quantities.remaining}
            </td>
        </tr>
    )

});
        console.log(rows);
        return (rows)


    }


    render() {


        let showTable = "";
        if(this.props.updateQuotaApiStudy){
        const qualifications = this.props.updateQuotaApiStudy.studyDetails.qualifications;
        const quotas = this.props.updateQuotaApiStudy.studyDetails.quotas;
        const labelOfQuotas =  quotas.map(item => item.qualification_label).filter((value, index, self) => self.indexOf(value) === index)
        



        const qualificatiponsRows = labelOfQuotas.map((qual) => {
            return (
                <div key = {qual}>
                    <div className="quota-quali" >{qual}</div>
                    <table width="600px">
                        <tr>
                            <td width="200px"></td> <td width="200px">{"Currently Open"}</td><td width="200px">{"Remaining"}</td>
                        </tr>
                         {this.getQuotasRows(qual, quotas)}
                    </table>
                </div>

            );
        })
        if (labelOfQuotas.length > 0) {
            showTable = <div className="mappingtable" id="pro-tbl">
                {qualificatiponsRows}
            </div>
        }

    }
        return (
            <div >
                <div className="form-title">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <h4 className="rm-margin card-text-h4">Quotas</h4>
                        </div>
                        <div className="pro-tgl-btn pro-tgl-btn-shdw" id="tg-btn-4">
                            <div id="rotate-icon-4"><img src={expandbutton} height="15px" width="15px" /></div>
                        </div>
                    </div>
                    <div className="url-mr-top quota-tbl-hide" id="quotadiv">
                        {showTable}
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {

    }
}

export default connect(mapStateToProps)(ApiStudyQuota);
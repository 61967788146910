import React, { Component }  from 'react';
import StudyListHeader from '../../components/Study/studyListHeader';
import StudyListTable from '../../components/Study/studyListTable';
import { studiesList, softDelete , search, studiesListByFilter} from '../../actions/actionsBag/study';
import {connect} from 'react-redux';

export class StudyList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userId : "",
            userType : "",
            projectStatus : "all",
            loginUserID : "",
            filterOnClient : false,
            clientFilterId : "",
            filterOnVendor : false,
            vendorFilterId : ""
        }
        this.getStudiesByUser = this.getStudiesByUser.bind(this);
        this.studiesListBypage = this.studiesListBypage.bind(this);
        this.searchStudy = this.searchStudy.bind(this);
        this.getStudiesByClient = this.getStudiesByClient.bind(this);
        this.getStudiesByVendor = this.getStudiesByVendor.bind(this);
    }

    

    componentWillMount(){
        localStorage.removeItem("is_clone_main_study");
        let userBy = localStorage.getItem("auth_user_id");
        let userType = localStorage.getItem("auth_user_type");
        this.setState({ userId : userBy, userType : userType, loginUserID : userBy });
        this.props.dispatch(studiesList(1, userBy, userType, this.state.projectStatus));
        this.timer = null;
      }

      getStudiesByUser(data){
        this.setState({ userId : data.user_id, userType : data.role_type,
            filterOnClient : false,
            clientFilterId : "",
            filterOnVendor : false,
            vendorFilterId : ""
        });
        this.props.dispatch(studiesList(1, data.user_id, data.role_type, this.state.projectStatus ));
    }

    getStudiesByClient(data){
        let userBy = localStorage.getItem("auth_user_id");
        let userType = localStorage.getItem("auth_user_type");
        this.setState({ userId : userBy, 
            userType : userType, 
            loginUserID : userBy ,
            filterOnClient : true,
            clientFilterId : data.client_id,
            filterOnVendor : false,
            vendorFilterId : ""
        });
        this.props.dispatch(studiesListByFilter(1, "clientFilter",data.client_id, userBy, userType, this.state.projectStatus ));
    }

    getStudiesByVendor(data){
        let userBy = localStorage.getItem("auth_user_id");
        let userType = localStorage.getItem("auth_user_type");
        this.setState({ userId : userBy, 
            userType : userType, 
            loginUserID : userBy ,
            filterOnClient : false,
            clientFilterId : "",
            filterOnVendor : true,
            vendorFilterId :  data._id
        });
        this.props.dispatch(studiesListByFilter(1,"vendorFilter", data._id, userBy, userType , this.state.projectStatus ));
    }

    studiesListBypage( pageNumber , projectStatus){
        this.setState({ projectStatus : projectStatus })
        if(this.state.filterOnClient === true){
            this.props.dispatch(studiesListByFilter(pageNumber, "clientFilter",this.state.clientFilterId, this.state.userId, this.state.userType, projectStatus ));
   
        } else if(this.state.filterOnVendor === true){
            this.props.dispatch(studiesListByFilter(pageNumber, "vendorFilter",this.state.vendorFilterId, this.state.userId, this.state.userType, projectStatus ));
   
        } else {
            this.props.dispatch(studiesList( pageNumber, this.state.userId, this.state.userType, projectStatus));
        }
       
    }

    searchStudy( serchIteam){
        let data = {};
        data.searchItem = serchIteam;
        data.userID = this.state.userId;
        data.userType = this.state.userType;
        this.props.dispatch(search( data ));
    }

    render(){
        return(
            <div>
                <StudyListHeader
                    getStudiesByUser = { this.getStudiesByUser}
                />
                <StudyListTable
                    studiesListBypage = {this.studiesListBypage}
                    searchStudy = { this.searchStudy}
                    getStudiesByClient = { this.getStudiesByClient}
                    getStudiesByVendor = { this.getStudiesByVendor}
                    getStudiesByUser = { this.getStudiesByUser}
                />
            </div>
    )}
}

const mapStateToProps=(state)=>{
    return{
       
    }
  }
  
  export default connect(mapStateToProps)(StudyList);
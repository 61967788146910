import React, { Component }  from 'react';
import '../../commonStyle/common.css';
import { uploadDoc, blankResUploadUniqueLink } from '../../actions/actionsBag/study';
import { connect } from 'react-redux';
import add from '../../images/add.png';
import { alertMessage }  from '../../alertsDialog/Action';
import appConfig from "../../api/apiConfig";
import $ from 'jquery';

class  UploadUniqueLinkView  extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: "",
      validationMsg: {},
    }
    this.downloadSample = this.downloadSample.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.UploadUniqueLinkFile = this.UploadUniqueLinkFile.bind(this);
  }

  handleValidation() {
    let valid = true;
    let error = {};  

    if (this.state.file === "") {
      valid = false;
      $("#error1").addClass("showerror");
      this.setState({
        rolenameerror: "Please choose file"
      });
    }else if(this.state.file && !this.state.file.type.includes("csv")){
      valid = false;
      $("#error1").addClass("showerror");
      this.setState({
        rolenameerror: "Please upload csv file only!"
      });
    }else{
      this.setState({ rolenameerror : "" });
    }
    
    
    return valid;
  }

  handleFileUpload(e) {
    let file = e.target.files[0];
    this.setState({ file: file }); 
  }
    
  UploadUniqueLinkFile(){
    var formData = new FormData();
    formData.append('file', this.state.file);
    if (this.handleValidation()) {
        
         this.props.dispatch(uploadDoc(formData, localStorage.getItem("edit_study_id")))
    }
  }
  
  downloadSample(){
    window.open(appConfig.siteURL + 'downloadSample');
  }

  componentWillReceiveProps( nextProps){
  
    if( nextProps.uploadLinkRes && nextProps.uploadLinkRes.success && nextProps.uploadLinkRes.message){
      let alertData ={};
      alertData.type ="alert";
      alertData.message =  nextProps.uploadLinkRes.message;
      this.props.dispatch(alertMessage(alertData));
      this.props.dispatch(blankResUploadUniqueLink());
    }else if( nextProps.uploadLinkRes && nextProps.uploadLinkRes.success == false && nextProps.uploadLinkRes.message ){
      let alertData ={};
      alertData.type ="alert";
      alertData.message =  nextProps.uploadLinkRes.message;
      this.props.dispatch(alertMessage(alertData));
      this.props.dispatch(blankResUploadUniqueLink());
    }
  }


    render(){

        return(
            <div style={{marginTop: "2%"}}>
                <div className="table-st">
                    <div className="forms-rd-shadow" style={{ width: "100%",paddingBottom:"50px", backgroundColor:"#ffffff", borderRadius:"4px" }}>
                        <div className="addVendor-main-div">
                        <div className="form-title">
                            <h4 className="rm-margin">Upload new Unique links</h4>
                        </div>
                        <hr/>
                        <div className = "formgroup">
                                <div class="formgroup-col-7 rltv-pos" >
                                    <label>Maximum 100 links are allowed at one time *</label>
                                    <input className="form-controll " type="file"  onChange={this.handleFileUpload} name="numberClone" /><br />
                                    <div className="errordiv " id="error1" >{this.state.rolenameerror}</div>
                                </div>
                                <div class="formgroup-col-2" >
                                    <button  onClick={this.UploadUniqueLinkFile}  style={{marginTop: "27px", width: "-webkit-fill-available"}} className="list-btn list-btn-color">
                                    <img src={add} height="12px" width="12px"/><span> Upload </span></button>
                                </div>
                            </div>
                        <div className = "formgroup">
                            <div class="formgroup-col-7 rltv-pos" >
                                <button className="list-btn list-btn-color"  onClick={this.downloadSample} >Download Sample</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )}
}
const mapStateToProps=(state)=>{
    return{
        uploadLinkRes: state.studies.uploadLinkRes,
    }
  }
  export default connect(mapStateToProps)(UploadUniqueLinkView);


const initialState = {
	studySuccess: '',
	studies: "",
	apiProjectList: "",
	mapping: "",
	showMappingView: "",
	showList: "",
	showAPIProjectList: "",
	demoMappingList: "",
	showButton: "",
	participantList: "",
	uploadLinkRes: "",
	invitationResp: "",
	showProgress: "",
	studyUpdateSuccessfully: null,
	mappingSuccess: null,
	apiProjectListSuccess: null,
	apiProjectListData: null,
	apiProjectListPage: null
}



const studies = (state = initialState, action) => {

	switch (action.type) {

		case 'INITIALISE_FILE_RES':
			return {
				...state,
				uploadLinkRes: "",
				cloneStudyList: "",
				showCloneStudies: false,
			}

		case 'SET_UNIQUE_LINK_DATA':
			return {
				...state,
				uniqueLinkData: action.uniqueLinkData,
			}

		case 'SET_STUDIES_LIST_EMPTY':
			return {
				...state,
				studies: "",
				studyDeleteResult : null
			}


		case 'SET_API_PROJECT_LIST_EMPTY':
			return {
				...state,
				apiProjectListSuccess: null,
				apiProjectListData: null,
				apiProjectListPage: null
			}

		case 'SET_INVITATION_RESP':
			return {
				...state,
				invitationResp: action.invitationResp,
			}

		case 'SET_CREATE_CLONE_RESP':
			return {
				...state,
				cloneResp: action.cloneResp,
			}

		case 'SET_INVITATION_VENDORS_RESP':
			return {
				...state,
				invitationVendorResp: action.response
			}

		case 'SET_INITIALISE_INVITATION_RESP':
			return {
				...state,
				invitationResp: null,
				studySuccess: "",
				invitationVendorResp: ""

			}

		case 'SET_UPLOAD_DOC_RES':
			return {
				...state,
				uploadLinkRes: action.uploadLinkRes,
			}

		case 'SET_UPLOAD_DOC_EMPTY_RES':
			return {
				...state,
				uploadLinkRes: null,
			}

		case 'SET_PARTICIPANT_LIST':
			return {
				...state,
				participantList: action.participantList,
				showProgress: true
			}

		case 'SET_UPDATE_PARTICIPANT_RESPONSE':
			return {
				...state,
				updateParticipantResp: action.updateParticipantResp,
			}

		case 'SET_PARTICIPANT_RESPONSE':
			return {
				...state,
				participantResp: action.participantResp,
			}

		case 'SET_NEW_PARTICIPANT_RESPONSE':
			return {
				...state,
				participantNewResp: action.participantNewResp,
			}

		case 'SET_STUDIES_LIST':
			return {
				...state,
				studies: action.studies,
				showList: true,
				studyUpdateSuccessfully: null,
				studyDeleteResult : null
			}

			case 'SET_STUDIES_LIST_DELETE_RESULT':
				return {
					...state,
					studyDeleteResult: action.data,
				}
			

		case 'SET_CLONE_STUDIES_LIST':
			return {
				...state,
				cloneStudies: action.cloneStudies,
				showCloneStudiesList: true,
				studyUpdateSuccessfully: null,
			}

		case 'SET_API_PROJECT_LIST':
			return {
				...state,
				apiProjectListSuccess: action.apiProjectsList.success,
				apiProjectListData: action.apiProjectsList.result,
				apiProjectListPage: action.apiProjectsList.totalPages
			}

		case 'SET_STUDIES_MESSAGE':
			return {
				...state,
				studySuccess: action.studySuccess,
				//show: true,
				showButton: true,
				showProgress: "",
			}

		case 'SET_MAPPING_RESPONSE':
			return {
				...state,
				mapping: action.mapping,
				showMappingView: true,
				showDemoList: false,
				showConstraint: false,
				thirdPartListByStudyData: null,
			}

		case 'SET_ADD_MAPPING_SUCCESS_RES':
			return {
				mappingSuccess: action.response.success,
				mappingMsg: action.response.message,
			}

		case 'SET_MAPPING_FAIL_RESPONSE':
			return {
				...state,
				mappingSuccess: action.response.success,
				mappingMsg: action.response.message,
			}

		case 'SET_MAPPING_LIST_SUCCESS_FAIL':
			return {
				...state,
				mappingSuccess: null
			}

		case 'SET_UPDATE_MAPPING_RESPONSE':
			return {
				...state,
				updateMappingSuccess: action.response.success,
				updateMappingMessage: action.response.message,
			}

		case 'SET_EMPTY_UPDATE_MAPING_RES':
			return {
				...state,
				updateMappingSuccess: null,
				updateMappingMessage: null,
				mappingSuccess: null,
				mappingMsg: null,
			}

		case 'SET_SHOW_TO_INITIAL':
			return {
				...state,
				showMappingView: false,
				showList: "",
				mapping: "",
				showDemoList: false,
				showConstraint: false,
				showButton: "",
				studySuccess: "",
				participantList: "",
				cloneStudyList: "",
				showCloneStudies: false,
				participantList: false,
				showProgress: false
			}


		case 'SET_SHOW_STUDY':
			return {
				...state,
				showList: true,
			}

		case 'SET_SHOW_STUDY_LIST':
			return {
				...state,
				showList: false,
				showButton: true
			}

		case 'SET_DEMO_MAPIING_LIST':
			return {
				...state,
				demoMappingList: action.demoMappingList,
				showDemoList: true,
				showMappingView: false,
				showConstraint: false
			}

		case 'SET_DEMO_DATA_LIST':
			return {
				...state,
				DemoDataList: action.DemoDataList,

			}

		case 'SET_PARTICIPANT_REPLY_RES':
			return {
				...state,
				participantNewResp: action.participantReplyRes,
				participantNewStudyUrlSuccess: false

			}

		case 'SET_PARTICIPANT_REDIRECT_URL_RES':
			return {
				...state,
				participantNewResp: false,
				participantNewStudyUrlSuccess: action.participantRedirectUrlRes.newSurveyParticipant,
				participantNewRedirectUrlData: action.participantRedirectUrlRes.data,

			}


		case 'SET_LIST_OF_DEMO_MAPPING':
			return {
				...state,
				demoMapping: action.demoMapping,
				addConstraintRes: action.demoMapping,
				showConstraint: true,
				showMappingView: false,
				showDemoList: false,
				optionsData: ""
			}

		case 'SET_LIST_OF_OPTIONS':
			return {
				...state,
				optionsData: action.optionsData,

			}

		case 'SET_ADD_CONSTRAINT_RES':
			return {
				...state,
				addConstraintRes: action.addConstraintRes,
			}

		case 'SET_CLONE_STUDY_LIST':
			return {
				...state,
				cloneStudyList: action.studyList,
				showCloneStudies: true,
				showStudyList: true
			}

		case 'SET_BDE_USERS_LIST':
			return {
				...state,
				bdeUsersList: action.bdeData.data,
			}

		case 'SET_THIRDPARTY_LIST_BY_STUDY':
			return {
				...state,
				thirdPartListByStudySuccess: true,
				thirdPartListByStudyData: action.thirdPartList.thirdPartyList
			}

		case 'SET_ACTIVITY_LOG_ON_STUDY':
			return {
				...state,
				studyActivityLog: action.response,
			}

		case 'SET_UPDATE_RESPONSE_UNIQUE_FIELD':
			return {
				...state,
				updateResponseUniqueField: action.response,
			}

		case 'SET_EMPTY_RESPONSE_UNIQUE_FIELD':
			return {
				...state,
				updateResponseUniqueField: null,
				studyActivityLog: null
			}

		case 'SET_EDIT_STUDY_DATA':
			return {
				...state,
				editStudyData: action.editStudyData,
				showButton: true,
			}

		case 'SET_CLIENT_LIST_TO_STUDY':
			return {
				...state,
				clientsList: action.clientList.clients
			}

		case 'SET_UPDATE_STUDY_RESPONSE':
			return {
				...state,
				studyUpdateSuccess: action.response.success,
				studyUpdateMessage: action.response.message,
				studyUpdateData: action.response.result,

			}


		case 'SET_API_UPDATE_STUDY_RESPONSE':
			return {
				...state,
				studyApiUpdateSuccess: action.response.success,
				studyApiUpdateMessage: action.response.message,

			}

		case 'SET_STUDY_PROPS':
			return {
				...state,
				studySuccess: null,
				studyUpdateSuccess: null,
				studyUpdateMessage: null,
				studyApiUpdateSuccess: null,
				studyApiUpdateMessage: null,
			}

		case "SET_PARTICIPANT_COUNT":
			return {
				...state,
				thirdPartyTotalCount: action.thirdPartyTotalCount,
			}

		case "SET_UPDATE_STUDY_PROGRESS_DATA":
			return {
				...state,
				updateStudyProgressData: action.response,
			}

		case "SET_UPDATE_API_STUDY_PROGRESS_DATA":
			return {
				...state,
				updateSurveyStudyProgressData: action.response,
			}


		case "SET_QUOTA_DATA_API_STUDY":
			return {
				...state,
				updateQuotaApiStudy: action.response,
			}

		case 'SET_PROGRESS_DATA_STUDY_MAP':
			return {
				...state,
				progressDataStudyMap: action.response,
			}

		case 'SET_DEFAULT_VENDOR':
			return {
				...state,
				defaultVendorSuccess: action.response.success,
				defaultVendorId: action.response.defaultVendor,
			}

		case 'SET_CLONE_STUDY_LIST_ID':
			return {
				...state,
				cloneStudesIdSuccess: action.cloneStudiesId.success,
				cloneStudesIdData: action.cloneStudiesId
			}

		case 'SET_ALL_COUNTRY_LANG_CODE':
			return {
				...state,
				countryCodeSuccess: action.response.success,
				countryCodeData: action.response.countryCodes
			}


		default:
			return state;
	}

};
export default studies;
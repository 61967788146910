import React, { Component }  from 'react';
import  '../../commonStyle/common.css';
import $ from 'jquery';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import goback from '../../images/goback.png';

class UpdateStudyHeader extends Component {
    constructor(props) {
        super(props)
      
        this.state = {
            
        }
       
    }

    componentWillMount(){
      
        if(window.location.href && window.location.href.split('/').length>3 && window.location.href.split('/')[3] === "Study-List-Update"){
            localStorage.setItem("header-title","Study-Update");
            localStorage.setItem("header-text","Here you can update study");
        }
        if(window.location.href && window.location.href.split('/').length>3 && window.location.href.split('/')[3] === "add-mapping"){
            localStorage.setItem("header-title","Add-Mapping");
            localStorage.setItem("header-text","Here you can map the third party");
        }
        if(window.location.href && window.location.href.split('/').length>3 && window.location.href.split('/')[3] === "demo-mapping-list"){
            localStorage.setItem("header-title","Add-Demographics-Mapping");
            localStorage.setItem("header-text","Here you can mapped demographics, update and set quota on demographics");
        }
        if(window.location.href && window.location.href.split('/').length>3 && window.location.href.split('/')[3] === "participant-replies"){
            localStorage.setItem("header-title","Participants Replies on Demos");
            localStorage.setItem("header-text","Here you can view all the replies on demos");
        }
        
    }
  
    componentDidMount(){

        $(document).ready(function(){
        
            $('#flt-btn').click( function(e) {
                
                e.preventDefault(); // stops link from making page jump to the top
                e.stopPropagation(); // when you click the button, it stops the page from seeing it as clicking the body too
                $('.dropdownitem').toggle();
                
            });
            
            $('.dropdownitem').click( function(e) {
                
                // e.stopPropagation(); // when you click within the content area, it stops the page from seeing it as clicking the body too
                
            });
            
            $('body').click( function() {
               
                $('.dropdownitem').hide();
                
            });
            
        });

    }


    selectActivity(activityName){
        if(activityName === "add-mapping"){
            localStorage.setItem("header-title","Add-Mapping");
            localStorage.setItem("header-text","Here you can map the third party");
        }else if(activityName === "participant-list"){
            localStorage.setItem("header-title","Participant-List");
            localStorage.setItem("header-text","Here you can see all participants");
        }else if(activityName === "send-invitation"){
            localStorage.setItem("header-title","Send Invitation");
            localStorage.setItem("header-text","Here you can send invitation to your panelist");
        }else if(activityName === "demo-mapping-list"){
            localStorage.setItem("header-title","Add-Demographics-Mapping");
            localStorage.setItem("header-text","Here you can mapped demographics, update and set quota on demographics");
        }else if(activityName === "participant-replies"){
            localStorage.setItem("header-title","Participants Replies on Demos");
            localStorage.setItem("header-text","Here you can view all the replies on demos");
          }else if(activityName === "create-clone"){
            localStorage.setItem("header-title","Create Study Clone");
            localStorage.setItem("header-text","Here you can create clone of your study");
        }else if(activityName === "unique-link"){
            localStorage.setItem("header-title"," Study Unique link");
            localStorage.setItem("header-text","Here you can upload unique link of your study");
        }
  
        this.props.selectActivity(activityName);
    }

    showInvitationBox(){
        document.getElementById("invitaionbox").style.display = "flex";
    }

    render(){
        let showAddMapping = "";
        let showSendInvitation = "";
        let showCreateDemoMap = "";
        let showParticipantReplies = "";
        let showUploadUnikLink = "";
        let showCreateClone = <li onClick={() => this.selectActivity("create-clone")}>Create clone</li>
        if( localStorage.getItem("is_ShowAddMapping") == 1)
        showAddMapping = <li onClick={() => this.selectActivity("add-mapping")}>Add mapping</li>
        if( localStorage.getItem("is_SendInvitation") == 1)
        showSendInvitation = <li onClick={() => this.selectActivity("send-invitation")}>Send invitation</li>
        if( localStorage.getItem("is_CreateDemoMapping") == 1)
        showCreateDemoMap = <li onClick={() => this.selectActivity("demo-mapping-list")}>Create demo mapping</li>
        if( localStorage.getItem("is_CreateDemoMapping") == 1)
        showParticipantReplies = <li onClick={() => this.selectActivity("participant-replies")}>Participant Replies</li>
        
        if( localStorage.getItem("is_unique_link") == 1)
        showUploadUnikLink = <li onClick={() => this.selectActivity("unique-link")}>Upload unique link</li>

        return(
            <div className="router-header">
            <div className="router-header1 all-header">
            <div>
                        <h2 className="head-two-st">{localStorage.getItem("header-title")}</h2>
                        <p className="paragraph-st color-secondary para-st-font">{localStorage.getItem("header-text")}</p>
                     </div>

                    <div className="flex-show">
                        <div><button onClick={this.props.gotoBack} className="list-btn list-btn-color margin-right"><img src={goback} height="13px" width="13px" /><span> Back</span></button></div>
                        <div><button className="list-btn list-btn-color" id="flt-btn"><span >Project Activities<div className="flter-btn-downarrow" style={{paddingRight:"20px"}}></div></span></button>
                        <div class="dropdownitem dropdown-shadow">
                            <ul>
                                {showAddMapping} 
                                {/* <li onClick={() => this.selectActivity("participant-list")}>Participant list</li> */}
                                {showSendInvitation}
                                {showCreateDemoMap}
                                {showParticipantReplies}
                                {showCreateClone}
                                {showUploadUnikLink}
                                {/* <li >Show add constraints</li> */}
                            </ul>
                            </div>
                        </div>
                     </div>
            </div>
        </div>

    )}
}
const mapStateToProps=(state)=>{
    return{
        
    }
  }
  
  export default connect(mapStateToProps)(UpdateStudyHeader);
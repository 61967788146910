import {submitLoginApi} from "./loginApi";
import {startSpinner, endSpinner} from "../../loadingSpinner/Action"
export const submitLogOut = () => {
    return{
      type : "LOGOUT"
    }
  }
  export const setLogin =(result) => {
    return {
      type : "SET_AUTH_CLIENT",
      result
    }
  }
  
  export const setLoginFail =(result) => {
    return {
      type : "SET_AUTH_Fail",
      result
    }
  }

  
  export const emptyLoginProps =() => {
    return {
      type : "SET_EMPTY_LOGIN_PROPS",
      }
  }



export const submitLogin = (loginData) => {

    return dispatch => {
      dispatch(startSpinner())
      submitLoginApi(loginData).then((result) => {
        if(result.success){
        localStorage.setItem('authenticate', result.success);
        dispatch(endSpinner())
        dispatch(setLogin(result));
        }else{
          dispatch(endSpinner())
          dispatch(setLoginFail(result));
         }
      });
    }
  }
import React, { Component }  from 'react';
import StudyUpdate from '../Study/studyUpdate';
import UpdateStudyHeader from '../Study/updateStudyHeader';
import {connect} from 'react-redux';
import '../../pages/Study/tempstyle.css';
import $ from 'jquery';
import appConfig from '../../api/apiConfig';

class ActivityLogList extends Component {

    constructor( props){
        super(props)
        this.state ={
           
        }
       
    }

    render(){
        return(
            <div >
                  <div className="form-title">
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                                <div>
                                    <h4 className="rm-margin">Activity List</h4>
                                    </div>
                                <div className="pro-tgl-btn pro-tgl-btn-shdw" id="tg-btn-11">
                                    <div id="rotate-icon-11">&#11167;</div>
                                </div>
                            </div>
                        <div className="url-mr-top tg-hide-act-log-list" id="hide-me-11">
                        <table className="tbllayout ">
                                <thead>
                                    <tr style={{backgroundColor: "blue", color: "white"}}>
                                        <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">S.no.</th>
                                        <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Vendor Name</th>
                                        <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Email-id</th>
                                        <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Contact Number</th>
                                        <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Variable-1</th>
                                        <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Variable-2.</th>
                                        <th className="tbl-padd-top tbl-padd-left tbl-padd-right tbl-padd-bottom">Action</th>
                                    </tr>
                                    </thead>
                                {/* {vendorItems} */}
                            </table>
                        </div>
                    </div>
            </div>

    )}
}

const mapStateToProps=(state)=>{
    return{
      
    }
  }
  
  export default connect(mapStateToProps)(ActivityLogList);
import React, { Component }  from 'react';
import '../../commonStyle/common.css';
import { addThirdParty, emptyVendorProps } from '../../actions/actionsBag/vendor';
import { connect } from 'react-redux';
import add from '../../images/add.png';
import $ from 'jquery';
import './recipt.css';
import gnnlogo from './GNN horizontal logo.jpg';
import { sendInvoice, getInvoiceById,emptyResponseInvoiced, generateFinalBill,deleteInvoiceFromInvoice } from '../../actions/actionsBag/invoiceAction';
import { alertMessage,emptyDialogdata  }  from '../../alertsDialog/Action';
import converter  from 'number-to-words';
import InvoiceHeader from '../../components/Invoice/invoiceHeader';
import {Link} from 'react-router-dom';
import goback from '../../images/goback.png';
import appConfig from '../../api/apiConfig';

class  RecieptForm  extends Component {
  constructor(props) {
    super(props)
    this.state = {
      invoiceId : "",
      finalInvoice : "0",
      billingadd1 :"",
      billingadd2 : "",
      billingcity : "",
      billingcountry : "",
      billingpincode : "",
      billingState : "",
      billingEmail : "",
      invoiceID :"",
     
      receiptNo : "",
      cpc : "",
      totalParticipant : "",
      projectName : "",
      clientMobile : "",
      totalAmt : 0,
      todayDate:"",
      invoiceDate : "",
      client_id : "",
      client_name : "",
      study_name : "",
      totalParticipantCount : 0,
      total : 0,
      refence_no : "",
      invoice_no: "",
      invoice_date : "",
      company_name : "",
      countryName  : "",
      total_job_cost : "",
      total_job_cost_Words : "",
      client_email_id : "",
      cloneInvoiceData : "",
      finel_total_job_cost : '',
      imageUrl : "",
      currency : "",
      currencyTextToSend : ""
    }
  }

  componentWillMount(){
    if( this.props.location.state === undefined){
      this.props.history.push("error");
    }else{
      const { invoiceID } = this.props.location.state;
      this.setState({
        invoiceID : invoiceID
      });
      this.props.dispatch(getInvoiceById(invoiceID));
    }
  }

  componentWillReceiveProps(nextProps){
    let date = new Date();
    
    let todayDate = date.getDate()+'-'+date.getMonth() +'-'+ date.getFullYear();
  

    if(nextProps.invoiceDeleteSuccess){
      this.props.dispatch(emptyResponseInvoiced());
      let alertData ={};
      alertData.type ="alert";
      alertData.message =  "This invoice deleted successfully.";
      this.props.dispatch(alertMessage(alertData));

      this.props.history.push("/invoice-list");
    }else if(nextProps.invoiceDeleteSuccess === false){
      this.props.dispatch(emptyResponseInvoiced());
      let alertData ={};
      alertData.type ="alert";
      alertData.message =  "This invoice not deleted, please contact to support!";
      this.props.dispatch(alertMessage(alertData));
    }

   
    if(nextProps.invoiceUpdatedMessage){
      this.props.dispatch(emptyResponseInvoiced());
      let alertData ={};
      alertData.type ="alert";
      alertData.message =  nextProps.invoiceUpdatedMessage;
      this.props.dispatch(alertMessage(alertData));
      //this.props.dispatch(getInvoiceById(this.state.invoiceId));
    }

    if(nextProps.invoiceData && nextProps.invoiceData.length >0){
        let total_job_costWord = "Zero";
        if(nextProps.invoiceData[0].finel_total_job_cost && nextProps.invoiceData[0].finel_total_job_cost !== "null" ){
          total_job_costWord = converter.toWords(nextProps.invoiceData[0].finel_total_job_cost).toUpperCase();
        }
        let currencyText = "";
        if(nextProps.invoiceData[0].currency === "Euro"){
          currencyText = "€"
        } else if(nextProps.invoiceData[0].currency === "Japanees Yen"){
          currencyText = "¥"
        } else if(nextProps.invoiceData[0].currency === "Hong Kong Doller"){
          currencyText = "HK$"
        } else if(nextProps.invoiceData[0].currency === "U.S. Doller"){
          currencyText = "USD"
        } else {
          currencyText = "USD"
        }

        this.setState({
          finalInvoice : nextProps.invoiceData[0].is_finel_invoice,
          billingadd1 : nextProps.invoiceData[0].residence_add_1,
          billingadd2 : nextProps.invoiceData[0].residence_add_2,
          billingcity : nextProps.invoiceData[0]. residence_city,
          billingcountry : nextProps.invoiceData[0].residence_country,
          billingpincode : nextProps.invoiceData[0].residence_pin_code,
          billingcity : nextProps.invoiceData[0].residence_state,
          billingEmail : nextProps.invoiceData[0].e_mail,
          invoiceID : nextProps.invoiceData[0].invoiceID,
          receiptNo : nextProps.invoiceData[0].receipt_no,
          cpc : nextProps.invoiceData[0].cpc,
          totalParticipant : nextProps.invoiceData[0].totalParticipant,
          projectName : nextProps.invoiceData[0].study_name,
          clientMobile :  nextProps.invoiceData[0].mobile,
          todayDate : todayDate,
          invoiceDate : nextProps.invoiceData[0].createdAt,
          client_id : nextProps.invoiceData[0].client_id,
          client_name : nextProps.invoiceData[0].client_name,
          study_name : nextProps.invoiceData[0].study_name,
          refence_no : nextProps.invoiceData[0].refrence_no,
          invoice_no : nextProps.invoiceData[0].invoice_no,
          invoice_date : nextProps.invoiceData[0].invoice_date,
          company_name : nextProps.invoiceData[0].company_name,
          countryName  : nextProps.invoiceData[0].countryName,
          total_job_cost : nextProps.invoiceData[0].total_job_cost,
          total_job_cost_Words : total_job_costWord,
          client_email_id : nextProps.invoiceData[0].client_email_id,
          finel_total_job_cost : nextProps.invoiceData[0].finel_total_job_cost,
          imageUrl : nextProps.invoiceData[0].imageUrl,
          currency : nextProps.invoiceData[0].currency,
          currencyTextToSend : currencyText
        });
    }

    if( nextProps.invoiceHasClone && nextProps.cloneInvoiceData){
      this.setState({ cloneInvoiceData : nextProps.cloneInvoiceData });
    }
   
    if(nextProps.invoiceMailMessage){
      this.props.dispatch(emptyResponseInvoiced());
      alert(nextProps.invoiceMailMessage);
    }
  }

  goBack(){
    window.history.back();
  }

  generateBill(){
    this.props.dispatch(generateFinalBill(this.state.invoiceID));
  }

  cancelBill(){
    this.props.dispatch(deleteInvoiceFromInvoice(this.state.invoiceID));
  }

  sendInvoice(e){
       
    let data ={};
    data.finalInvoice = this.state.finalInvoice;
    data.billingadd1 = this.state.billingadd1;
    data.billingadd2 = this.state.billingadd2;
    data.billingcity = this.state.billingcity;
    data.billingpincode = this.state.billingpincode;
    data.billingState = this.state.billingState;
    data.billingEmail = this.state.billingEmail;
    data.invoicedId = this.state.invoiceID;
    data.receiptNo = this.state.receiptNo;
    data.cpc = this.state.cpc;
    data.totalParticipant = this.state.totalParticipant;
    data.projectName = this.state.projectName;
    data.clientMobile = this.state.clientMobile;
    data.totalAmt = this.state.totalAmt;
    data.todayDate = this.state.todayDate.split("T")[0];
    data.invoiceDate = this.state.invoiceDate.split("T")[0];
    data.client_id = this.state.client_id;
    data.client_name = this.state.client_name;
    data.study_name = this.state.study_name;
    data.refence_no = this.state.refence_no;
    data.invoice_no = this.state.invoice_no;
    data.invoice_date = this.state.invoice_date;
    data.company_name = this.state.company_name;
    data.countryName  = this.state.countryName;
    data.total_job_cost = this.state.total_job_cost;
    data.total_job_cost_Words = this.state.total_job_cost_Words;
    data.client_email_id = this.state.client_email_id;
    data.finel_total_job_cost = this.state.finel_total_job_cost;
    data.cloneInvoiceData = this.state.cloneInvoiceData;
    data.imageUrl = this.state.imageUrl;
    data.currency = this.state.currencyTextToSend;
    this.props.dispatch(sendInvoice(data));
  }
  
    render(){

      let showFinalInvoiceBtn ="";
      let showCancelBtn ="";
    
      let currencyText = this.state.currencyTextToSend;
      
      if(this.state.finalInvoice == "1"){
        showFinalInvoiceBtn = <button className="create-btn"  onClick={() => this.sendInvoice()}>Send Invoice</button>
      }else{
        showFinalInvoiceBtn = <button className="create-btn" onClick={() => this.generateBill()}>Approve</button>
        showCancelBtn = <button className="create-btn" onClick={() => this.cancelBill()}>Disapprove</button>
      }

      const header = 'Invoice Reciept';
      const headerText = 'Here you can see all invoice details.';
      const headerButton = <Link to="/invoice-list"> <button className="back-btn back-btn-color" ><img src={goback} height="13px" width="13px" /><span> Back</span></button></Link>

      let showCloneStudiesInvoiceDetails = '';
      let sr=1;
      if( this.state.cloneInvoiceData && this.state.cloneInvoiceData.length > 0){
        showCloneStudiesInvoiceDetails = this.state.cloneInvoiceData.map((list, i) => {
          if( i%2 == 0){
            return <tr id="tbl-data-white">
            <td id="table-data-left">{i+sr+1}</td>
            <td id="table-data-mid">{ list.study_name}</td>
          <td id="table-data-mid">{ list.cpc}&nbsp; {currencyText}</td>
            <td id="table-data-mid">{ list.totalParticipant } </td>
            <td id="table-data-right">{ list.total_job_cost}&nbsp; {currencyText}</td>
          </tr>
          }else{
          return <tr id="tbl-data-gray">
                  <td id="table-data-left">{i+sr+1}</td>
                  <td id="table-data-mid">{ list.study_name}</td>
                  <td id="table-data-mid">{ list.cpc}&nbsp; {currencyText}</td>
                  <td id="table-data-mid">{ list.totalParticipant } </td>
                  <td id="table-data-right">{ list.total_job_cost}&nbsp; {currencyText}</td>
                </tr>
          }
          
        });
      }

        return(
            <div>
               <InvoiceHeader 
                header = {header}
                headerText = {headerText}
                headerButton = {headerButton}
            />



            
            <div className="list-style">
                <div className="table-st">
                    <div className="alltablestruct forms-rd-shadow">
                      <div id="rec-pad">
                      <h4  id="rep-h4">Invoice for Client</h4>
                            <hr/>
                            <div id="reciept-back">
                              <table id="logo-title">
                                <tbody>
                                  <tr>
                                    <td id="border-rm"><div><img src={gnnlogo} height="100%" width="230px"/></div></td>
                                    <td id="border-rm"><div><img src={appConfig.url.baseURL+"clients/logo/"+this.state.imageUrl} height="50px" width="230px"/></div></td>
                                  
                                 
                                    <td id="border-rm"><h1 id="rec-h1">INVOICE</h1></td>
                                  </tr>
                                </tbody>
                              </table>
                              <div id="refno"><b>Ref.&nbsp;</b>{this.state.refence_no}</div>
                              <div id="projectname"><b>Project Name:</b>&nbsp;&nbsp;30540 - Business - International</div>
                             
                              <div  style={{paddingRight: "50px", paddingLeft: "50px"}}>
                              <table id="tbl-full">
                                <tbody>
                                  <tr>
                                    <th className="padd align">Invoice to:</th>
                                    <th className="padd align">{this.state.company_name}</th>
                                    <td className="padd align blank">&nbsp;</td>
                                    <th className="padd align">Invoice#</th>
                                    <th className="padd align">{ this.state.invoice_no}</th>
                                  </tr>
                                  <tr>
                                    <th className="padd align"></th>
                                    <td className="padd align">{this.state.billingadd1}, {this.state.billingcity}, </td>
                                    <td className="padd align blank">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <th className="padd align">Date:</th>
                                    <th className="padd align">{ this.state.invoiceDate.split("T")[0]}</th>
                                  </tr>
                                  <tr>
                                    <th className="padd align"></th>
                                    <td className="padd align">{ this.state.countryName}, {this.state.billingpincode} </td>
                                    <td className="padd align blank">&nbsp;</td>
                                    <th className="padd align"></th>
                                    <th className="padd align"></th>
                                  </tr>
                                </tbody>
                              </table>
                              </div>

                              <div id="mr-top-30">
                              <table id="table-data">
                                <tbody>
                                  <tr>
                                   <th id="table-data-left">SL.</th>
                                   <th id="table-data-mid">Description</th>
                                   <th id="table-data-mid">CPI</th>
                                   <th  id="table-data-mid">Completes</th>
                                   <th  id="table-data-right">Total</th>
                                  </tr>
                                  <tr id="tbl-data-gray">
                                   <td id="table-data-left">1</td>
                                   <td id="table-data-mid">{this.state.study_name}</td>
                                   <td id="table-data-mid">{ this.state.cpc}&nbsp; {currencyText}</td>
                                   <td id="table-data-mid">{ this.state.totalParticipant } </td>
                                   <td id="table-data-right">{ this.state.total_job_cost}&nbsp; {currencyText}</td>
                                  </tr>
                                  {showCloneStudiesInvoiceDetails}
                                  {/* <tr id="tbl-data-white">
                                   <td id="table-data-left">&nbsp;</td>
                                   <td id="table-data-left">&nbsp;</td>
                                   <td id="table-data-left">&nbsp;</td>
                                   <td id="table-data-left">&nbsp;</td>
                                   <td id="table-data-left">&nbsp;</td>
                                  </tr> */}
                                  {/* <tr id="tbl-data-gray">
                                   <td id="table-data-left">&nbsp;</td>
                                   <td id="table-data-left">&nbsp;</td>
                                   <td id="table-data-left">&nbsp;</td>
                                   <td id="table-data-left">&nbsp;</td>
                                   <td id="table-data-left">&nbsp;</td>
                                  </tr> */}
                                </tbody>
                              </table>
                              </div>

                              <div id="mr-top-30">
                              <table id="grand-total-tbl">
                                <tbody>
                                  <tr>
                                   <th id="grand-total-tbl-left"style={{width:"50%"}}>TRN: 1234567890123445454</th>
                                   <th id="grand-total-tbl-left"></th>
                                   <th  id="grand-total-tbl-left">Sub Total:</th>
                                   <th  id="grand-total-tbl-right">{ this.state.finel_total_job_cost}&nbsp; {currencyText}</th>
                                  </tr>
                                  <tr>
                                   <th id="grand-total-tbl-left"></th>
                                   <th id="grand-total-tbl-left"></th>
                                   <th id="grand-total-tbl-left">VAT:</th>
                                   <th  id="grand-total-tbl-right">0.00%</th>
                                  </tr>
                                  <tr>
                                   <th id="grand-total-tbl-left">{currencyText} &nbsp;{ this.state.total_job_cost_Words} &nbsp;ONLY</th>
                                   <th  id="grand-total-tbl-left" ></th>
                                   <th  id="grand-total-tbl-left">
                                     <div><hr id="amthr"/></div>
                                     Total:
                                     <div><hr id="amthr"/></div>
                                     </th>
                                   <th id="grand-total-tbl-right">
                                   <div><hr id="amthr"/></div>
                                   { this.state.finel_total_job_cost}&nbsp;
                                   {currencyText}
                                    <div><hr id="amthr"/></div>
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                              </div>


                              <div id="mr-top-30" style={{paddingLeft:"50px"}}>
                              <table id="rec-add-tbl">
                                <tbody>
                                  <tr>
                                   <th id="rec-add-tbl-h" style={{width:"30%"}}>Payment Info:</th>
                                   <th  id="rec-add-tbl-h"></th>
                                  </tr>
                                  <tr>
                                   <td id="rec-add-tbl-h">BankName:</td>
                                   <td id="rec-add-tbl-d" >Emirates Islamic Bank</td>
                                  </tr>
                                  <tr>
                                   <td id="rec-add-tbl-h" style={{width:"30%"}}>Bank Correspondence(Branch):</td>
                                   <td id="rec-add-tbl-d">Bank street bur Dubai-Dubai-UAE</td>
                                  </tr>
                                  <tr>
                                   <td id="rec-add-tbl-h" style={{width:"30%"}}>Company Name:</td>
                                   <td id="rec-add-tbl-d">GNN Facility Managenment LLC</td>
                                  </tr>
                                  <tr>
                                   <td id="rec-add-tbl-h" style={{width:"30%"}}>Company Address:</td>
                                   <td id="rec-add-tbl-d">Suites number 302 Al safabuilding bank street bur Dubai UAE</td>
                                  </tr>
                                  <tr>
                                   <td id="rec-add-tbl-h" style={{width:"30%"}}>Account Number:</td>
                                   <td id="rec-add-tbl-d">370-7416-6832-02</td>
                                  </tr>
                                  <tr>
                                   <td id="rec-add-tbl-h" style={{width:"30%"}}>IBAN Number:</td>
                                   <td id="rec-add-tbl-d">AE-08034-0003-7040-1668-3202</td>
                                  </tr>
                                  <tr>
                                   <td id="rec-add-tbl-h" style={{width:"30%"}}>Swift Code of Bank:</td>
                                   <td id="rec-add-tbl-d">ME-BL-AE-AD-XXX</td>
                                  </tr>
                                </tbody>
                              </table>
                              </div>

                              <div id="mr-top-30">
                              <table id="rec-add-tbl">
                                <tbody>
                                  <tr>
                                   <th id="bottom-left"><b>Thank you for your business</b></th>
                                   <th id="bottom-right"><b>Autorised Sign</b></th>
                                  </tr>
                                  </tbody>
                                  </table>
                                  </div>
                        </div>
                        <div id="txt-center-padd">
                        {showCancelBtn}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                          {showFinalInvoiceBtn}
                          </div>
                      </div>
                </div>
            </div>
            </div>
            </div>
    )}
}
const mapStateToProps=(state)=>{
    return{
      invoiceData : state.invoice.invoiceData,
      invoiceHasClone : state.invoice.invoiceHasClone,
      cloneInvoiceData : state.invoice.cloneInvoiceData,
      invoiceUpdatedMessage : state.invoice.invoiceUpdatedMessage,
      invoiceMailMessage : state.invoice.invoiceMailMessage,
      invoiceDeleteSuccess : state.invoice.invoiceDeleteSuccess,
    }
  }
  export default connect(mapStateToProps)(RecieptForm);


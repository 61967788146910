import React, { Component }  from 'react';
import { connect } from 'react-redux';
import ClientHeader from '../../components/Client/clientHeader';
import ClientListTable from '../../components/Client/clientList.jsx';
import {ThirdPartyList} from '../../actions/actionsBag/vendor';
import add from '../../images/add.png';
import {Link} from 'react-router-dom';

    class ClientListView extends Component {

        constructor(props) {
            super(props)
            this.state = {

            }
            // this.gotoCreateVendor = this.gotoCreateVendor.bind(this);
            //this.vendorList = this.vendorList.bind(this);
        }

    componentWillMount() {
        let currentpage = 1;
        //this.props.dispatch(ThirdPartyList( currentpage));
      }

    //   gotoCreateVendor(){
    //    alert("gotoCreateVendor");

    //   }

    //   vendorList(currentpage){
    //     this.props.dispatch(ThirdPartyList( currentpage));
    //   }


    render(){
        const header = 'Client List';
        const headerText = 'Here you can find and add your client';
        const headerButton = <Link to="/create-client"><button className="create-btn create-btn-color" onClick={this.gotoCreateVendor}><img src={add} height="12px" width="12px"/><span> Add Client</span></button></Link>

        return(
            <div style={{ width:"100%"}}>  
                <ClientHeader
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                />
                <ClientListTable />
                
            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        thirdPartyList : state.thirdParty.thirdPartyList,
        totalpages : state.thirdParty.totalpages
    }
  }
  
  export default connect(mapStateToProps)(ClientListView);
import React, { Component }  from 'react';
import StudyUpdate from '../../components/Study/studyUpdate';
import UpdateStudyHeader from '../../components/Study/updateStudyHeader';
import {connect} from 'react-redux';
import '../../pages/Study/tempstyle.css';
import '../../commonStyle/common.css';
import $ from 'jquery';

import download from '../../images/downloadimg.png';

class AddProjectMapping extends Component {
    constructor(props) {
        super(props)
        this.state = {
            thirdPartyId : "",
            quota : "",
            stopEditingData : ""
        }
        this.addMappingToProject = this.addMappingToProject.bind(this);
        this.onVendorChange = this.onVendorChange.bind(this);
        this.onQuotaChange = this.onQuotaChange.bind(this);
    }
    componentWillMount(){
        if(localStorage.getItem("edit_study_data") === "stop"){
            this.setState({
                stopEditingData : true
            });
        }
    }

    componentDidMount(){

       
    }

    onVendorChange(e){
        if(e.target.value === ""){
          
        }else{
            $("#error1").removeClass("showerror");
        }
        this.setState({
            thirdPartyId: e.target.value
        });
    }

    onQuotaChange(e){
        if(Number.isInteger(parseInt(e.target.value))){
            if(parseInt(e.target.value) > 0 ){
              this.setState({ quota: parseInt(e.target.value )});
            }
          }else if( e.target.value === ''){
            this.setState({ quota: ''});
          }else{
            $("#error2").removeClass("showerror");
            // alert("Please only enter numeric characters in vendor quota!");
          }
       
    }


    handleValidation(){
        let valid = true;
            if(this.state.thirdPartyId === ""){
                valid = false;
                $("#error1").addClass("showerror");
                this.setState({
                    clienterror: "Choose third party"
                });
            }

            

            if(this.state.quota === ""){
                valid = false;
                $("#error2").addClass("showerror");
                this.setState({
                  quotaerror: "Enter requirement."
                });
              }


        return valid;
    }


    addMappingToProject(){
        if(this.handleValidation()){
            let mappingData = {};
            mappingData.thirdPartyId = this.state.thirdPartyId;
            mappingData.vendorQuota = this.state.quota;
            this.props.addMapping(mappingData);
            this.setState({ thirdPartyId: "", quota :"" });
        }
    }
    
    render(){

        let thirdPartyItemlist = "";
        if(this.props.thirdPartListByStudyData && this.props.thirdPartListByStudyData.length>0){        
            thirdPartyItemlist = this.props.thirdPartListByStudyData.map((item, index) => {
                return <option key={index} value={item._id}>{item.vendorName}</option>
            });
        }

        return(
            <div className="form-title">
               
                    <div style={{display: "flex",justifyContent: "space-between",width: "100%",paddingRight: "5%"}}>
                        <div className="formgroup rltv-pos" style={{width:"50%",paddingRight: "2%"}}>
                        <select name="thirdPartyId" disabled={this.state.stopEditingData} value={this.state.thirdPartyId} style={{width: "100%"}} className="form-controll " onChange={this.onVendorChange}>
                            <option value="">Select Third Party</option>
                            {thirdPartyItemlist}
                        </select>
                        <div className="erroraddmap cus-width-4" id="error1">{this.state.clienterror}</div>
                        </div>
                        <div className="formgroup rltv-pos" style={{width:"30%",paddingRight: "5%"}}>
                        <input  style={{width: "100%"}} disabled={this.state.stopEditingData} className="form-controll " value={this.state.quota} placeholder="Requirement"  onChange={this.onQuotaChange}></input>
                        <div className="erroraddmap cus-width-5" id="error2">{this.state.quotaerror}</div>
                        </div>
                        <div style={{width:"20%",float: "right"}}>
                            <button onClick={this.addMappingToProject} disabled={this.state.stopEditingData}  className="list-btn " style={{width: "150px",marginTop: "20px"}}>Add Mapping</button>
                        </div>
                    </div>
            </div>
    )}
}

const mapStateToProps=(state)=>{
    return{
      
    }
  }
  
  export default connect(mapStateToProps)(AddProjectMapping);
const initialState = {
    showAlert: false,
    
}

const alertsDialog = (state = initialState , action) =>{
  switch (action.type) {

    case "SET_ALERT_MESSAGE" :
      return {
        ...state,
        showAlert: true,
        alertType : action.alertdata.type,
        alertMessage : action.alertdata.message,
        alertData : action.alertdata.deleteId,
        alertPushRoute : action.alertdata.alertPushRoute,
        // alertMoreData : action.alertData.moreData
      }    

      case "SET_HIDE_DIALOG" :
      return {
        ...state,
        showAlert: false,
        alertMessage : null,
        confirmDialog : false,
        alertPushRoute : null,
        confirmData : null
      }    
      
      case "SET_CONFIRM_DIALOG":
        return{
            ...state,
            confirmDialog : true,
            confirmData : action.data,
        }
        case "SET_EMPTY_DIALOG":
          return{
            ...state,
            confirmDialog : false,
            confirmData : null
          }

      default:
          return state;
        }

};
export default alertsDialog;
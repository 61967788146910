import React, { Component }  from 'react';
import { connect } from 'react-redux';
import DemographicHeader from '../../components/Demographics/demographicHeader.jsx';
import AddDemographicsDiv from '../../components/Demographics/addDemographic.jsx';
import {addNewDemo} from '../../actions/actionsBag/Demographic';
import goback from '../../images/goback.png';
import { alertMessage }  from '../../alertsDialog/Action';
import {Link} from 'react-router-dom';

    class CreateDemographicView extends Component {

        constructor(props) {
            super(props)
            this.state = {

            }
            this.addNewDemo = this.addNewDemo.bind(this);
        }

    componentWillMount() {

       
      }

      addNewDemo( data){
        //this.props.history.push("/add-question");
        this.props.dispatch(addNewDemo( data));
      }

      componentWillReceiveProps(nextProps){
          if(nextProps.demoSuccess && nextProps.demoSuccess.success  && nextProps.demoSuccess.message ){
            let alertData ={};
            alertData.type ="alert";
            alertData.message = nextProps.demoSuccess.message;
            this.props.dispatch(alertMessage(alertData));
            this.props.history.push({
              pathname : '/add-question',
              state:{ demographicsData :  nextProps.demoSuccess.demographics }
            });
          }else if(nextProps.demoSuccess && (nextProps.demoSuccess.success === false)  && nextProps.demoSuccess.message){
            let alertData ={};
            alertData.type ="alert";
            alertData.message = nextProps.demoSuccess.message;
          this.props.dispatch(alertMessage(alertData));
          }
      }

    render(){

        const header = 'Create Demographic';
        const headerText = 'Here you can add new demographic';
        const headerButton = <Link to="/demographics-list"> <button className="back-btn back-btn-color" ><img src={goback} height="13px" width="13px" /><span> Back</span></button></Link>


        return(
            <div style={{ width:"100%"}}>  
                <DemographicHeader 
                    header = {header}
                    headerText = {headerText}
                    headerButton = {headerButton}
                />
                <AddDemographicsDiv
                addNewDemo = {this.addNewDemo}
                />
            </div>
    )}
}

const mapStateToProps = (state) => {
    return {
        successDemo: state.demographics.successDemo,
        demoSuccess : state.demographics.demoSuccess
    }
  }
  
export default connect(mapStateToProps)(CreateDemographicView);
import { stat } from "fs";

const initialState = {

}



const client = (state = initialState, action) => {

	switch (action.type) {

		case 'SET_CLIENT_ADD':
			return {
                ...state,
                clientAddSuccess : action.clientAddResult.success,
                clientAddMessage : action.clientAddResult.message,
			}

        case 'SET_CLIENT_EMPTY_PROPS':
            return{
                ...state,
                clientAddSuccess : null,
                clientAddMessage : null,
                clientUpdateSuccess : null,
                clientUpdateMessage : null,
                clientDeleteSuccess :  null,
                clientDeleteMessage : null,
                clientList : null,
                invoiceStudyDataSuccess : null,
                invoiceStudyData : null,
                invoiceStudyResMsg : null,
            }
        case 'SET_CLIENT_LIST':
            return{
                ...state,
                clientList : action.clientsResult.clients,
                totalClients : action.clientsResult.totalClient
            }
        
        case 'SET_CLIENT_DATA' :
            return{
                ...state,
                clientData : action.clientData.client,

            }

        case 'SET_CLIENT_UPDATE':
            return{
                ...state,
                clientUpdateSuccess : action.clientUpdate.success,
                clientUpdateMessage : action.clientUpdate.message
            }

        case 'SET_CLIENT_DELETE':
            return{
                ...state,
                clientDeleteSuccess : action.clientDelete.success,
                clientDeleteMessage : action.clientDelete.message
            }

        case 'SET_ALL_COUNTRY_LIST':
            return{
                ...state,
                countryList : action.response,
            }

        case 'SET_INVOICE_STUDY_DATA':
            return{
                ...state,
                invoiceStudyDataSuccess : action.response.success,
                invoiceStudyData : action.response.invoiceStudyData,
                invoiceStudyResMsg : action.response.message,
            }

        case 'SET_LOGO_UPLOAD_RESULT':
            return{
                ...state,
               
                uploadLogoResult : action.response,
            }

        case 'SET_CLIENT_LOGO_EMPTY_PROPS':
            return{
                ...state,
                
                uploadLogoResult : null,
            }
            
            

		default:
		return state;
	}

};
export default client;